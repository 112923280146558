<template>
  <div>
    <div class="py-5 container-fluid">
      <div id="topinfo">
        <div class="row mt-4 justify-content-center">
          <div class="col-12">
            <div class="card">
              <div class="card-header text-center">
                <h4 class="font-weight-bolder">Powder Amount Saving</h4>
              </div>
              <div class="card-body">
                <div class="card-body">
                  <div class="row">
                    <p class="mr-3">
                      Powder Amount Saving suggests a reduction on the powder amount settings, made possible by the reciprocator optimization results.
                    </p>
                    <div v-if="last_optimization_powdersavings_percentage">
                      <div class="d-flex flex-wrap">
                        <p class="mr-3">
                          <b>Current gun throughput:</b> {{ received_powderoutput_data.desired_output }} [{{ $store.state.units_system[$store.state.user_data.unit_system].grams }}/min]. &nbsp;
                        </p>
                        <p class="mr-3">
                          <b>Suggested gun throughput:</b> {{ recommendedExpectedOutputRounded }} [{{ $store.state.units_system[$store.state.user_data.unit_system].grams }}/min]. &nbsp;
                          <b>Powder saved:</b> {{ lastOptimizationPowderSavingPercentage }}%.
                        </p>
                      </div>
                    </div>
                    <div v-else>
                      <p class="mr-3">
                        No powder saving optimization was performed. Run a reciprocator optimization to get a suggested gun throughput and powder saving %.
                      </p>
                    </div>
                    <!-- <div class="table-responsive">
                      <table class="table table-bordered">
                        <thead>
                        </thead>
                        <tbody>
                          <tr>
                            <th>
                              <p class="mt-3">
                                At Powder Output calibration, after equalizing gun outputs, gun throughput was:
                              </p>
                            </th>
                            <td>
                              <p class="mt-3">
                                {{ received_powderoutput_data.desired_output }} [{{ $store.state.units_system[$store.state.user_data.unit_system].grams }}/min].
                              </p>
                            </td>
                          </tr>
                          <tr>
                            <th>
                              <p class="mt-3">
                                The powder saving achieved in the reciprocator optimization is:
                              </p>
                            </th>
                            <td>
                              <div v-if="last_optimization_powdersavings_percentage">
                                <p class="mt-3">
                                  {{ last_optimization_powdersavings_percentage.toFixed(1) }}% powder saved
                                </p>
                              </div>
                              <div v-else>
                                <p class="mt-3">
                                  No powder saving optimization was performed.
                                  <br>
                                  Run a reciprocator optimization to get a saving %.
                                </p>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div> -->
                    <div class="col-12">
                      <h5 class="text-start">Powder Amount Setting</h5>
                      <div class="table-responsive">
                      </div>
                      <table class="table table-border table-bordered">
                        <thead>
                          <tr>
                            <th>Powder throughput [{{ $store.state.units_system[$store.state.user_data.unit_system].grams }}/min]</th>
                            <th>Powder Amount Parameters</th>
                            <th>Powder Consumption Chart Comparison</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>
                              <div class="mt-2 col-6 mb-2">
                                <div v-if="expectedPowderPerMinuteRounded == recommendedExpectedOutputRounded">
                                  <p>
                                    The optimized
                                    <br>
                                    gun throughput is:
                                  </p>
                                </div>
                                <input
                                  v-model="gunExpectedOutput"
                                  :class="gunExpectedOutput < 0 ? 'form-control is-invalid' : 'form-control'"
                                  type="number"
                                  min="0"
                                  :step="$store.state.user_data.unit_system === 'metric' ? 1 : 0.01"
                                  :disabled="false"
                                  @change="getPowderAmountParametersFromExpectedOutput(); drawBarChart();"
                                  @blur="saveFormProgress()"
                                />
                              </div>
                            </td>
                            <td>
                              <div v-for="(n, index) in received_data.total_pistols" :key="n" class="row mb-3">
                                <div class="text-start">
                                  <label class="mt-2 mb-2"> Powder Amount Parameter Gun {{ index + 1 }} </label>
                                  <div class="col-3 mt-2 mb-2">
                                    <input
                                      v-model="powder_amount_parameters[index]"
                                      :class="
                                        powder_amount_parameters[index] > received_powderoutput_data.max_powder_output ||
                                        powder_amount_parameters[index] < received_powderoutput_data.min_powder_output
                                          ? 'form-control is-invalid'
                                          : 'form-control'
                                      "
                                      type="number"
                                      :min="received_powderoutput_data.min_powder_output"
                                      :max="received_powderoutput_data.max_powder_output"
                                      :step="received_powderoutput_data.max_powder_output > 10 ? 1 : 0.1"
                                      @change="getPowderAmountParametersFromParameter(n - 1, powder_amount_parameters[n - 1])"
                                      @blur="saveFormProgress()"
                                    />
                                  </div>
                                </div>
                              </div>
                            </td>
                            <td>
                              <div class="d-flex justify-content-center">
                                <canvas id="powder-amount-saving-chart" class="chart-canvas" height="100%" style="max-width: 100%; min-width: 250px;"></canvas>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div class="row mt-4">
                    <div class="col-5">
                      <h5>
                        Thickness Measurements [{{
                          this.$store.state.units_system[$store.state.user_data.unit_system].thickness
                        }}]
                      </h5>
                      <div class="table-responsive">
                        <table class="table table-sm text-center text-xs">
                          <thead>
                            <tr>
                              <th>Measurement</th>
                              <th>Thickness</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-for="(_, index) in thickness_measurements" :key="index">
                              <td>{{ index + 1 }}</td>
                              <td>
                                <input
                                  type="number"
                                  class="form-control text-center"
                                  style="width: 50%; margin: 0 auto"
                                  v-model="thickness_measurements[index]"
                                  min="0"
                                  @change="
                                    checkLastRow(index);
                                    saveFormProgress();
                                  "
                                  @keydown="numericOnly"
                                />
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <button class="mt-2 btn btn-primary" @click="showThicknessGraph">
                          Show Thickness Distribution Chart
                        </button>
                      </div>
                    </div>
                    <div class="col-7">
                      <div class="col-12 mt-3">
                        <h5 class="text-center">Thickness Distribution Chart</h5>
                        <canvas id="measurement-chart" class="chart-canvas" height="300"></canvas>
                        <div v-if="showStatistics(received_data.measurements)" class="row justify-content-between mt-2">
                          <div class="card col mt-2 text-center m-1">
                            <h6 class="font-weight-bolder">Initial Benchmark</h6>
                            <h6>Mean: {{ getThicknessMeasurementsMean(received_data.measurements) }}</h6>
                            <h6>Std: {{ getThicknessMeasurementsStd(received_data.measurements) }}</h6>
                            <h6>Min: {{ measurementsMin(received_data.measurements) }}</h6>
                            <h6>Max: {{ measurementsMax(received_data.measurements) }}</h6>
                          </div>
                          <div v-if="showStatistics(received_powderoutput_data.thickness_measurements)" class="card col mt-2 text-center m-1">
                            <h6 class="font-weight-bolder">Powder Output stage</h6>
                            <h6>Mean: {{ getThicknessMeasurementsMean(received_powderoutput_data.thickness_measurements) }}</h6>
                            <h6>Std: {{ getThicknessMeasurementsStd(received_powderoutput_data.thickness_measurements) }}</h6>
                            <h6>Min: {{ measurementsMin(received_powderoutput_data.thickness_measurements) }}</h6>
                            <h6>Max: {{ measurementsMax(received_powderoutput_data.thickness_measurements) }}</h6>
                          </div>
                          <div v-if="showStatistics(thickness_measurements)" class="card col mt-2 text-center m-1">
                            <h6 class="font-weight-bolder">Powder Amount Saving stage</h6>
                            <h6>Mean: {{ getThicknessMeasurementsMean(thickness_measurements) }}</h6>
                            <h6>Std: {{ getThicknessMeasurementsStd(thickness_measurements) }}</h6>
                            <h6>Min: {{ measurementsMin(thickness_measurements) }}</h6>
                            <h6>Max: {{ measurementsMax(thickness_measurements) }}</h6>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
              <div class="row justify-content-center mt-6">
                  <button
                  :class="`btn ${valuesUpdated ? 'btn-success' : 'btn-secondary'}`"
                  @click="saveFormProgress()"
                  style="width: 90%"
                >
                  SAVE
                </button>
                </div>
              </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <stepper-line-visit :lineId="lineId" :visit="visit" />
    </div>
  </div>
</template>

<script>
import StepperLineVisit from "./components/StepperLineVisit.vue";
import setTooltip from "@/assets/js/tooltip.js";
import Chart from "chart.js/auto";
import axios from "axios";
import annotationPlugin from "chartjs-plugin-annotation";
import {
  generateX,
  gaussian,
  generateY,
  calculateMu,
  calculateSigma,
  generateXforMultipleMuSigma,
  generateYwithXvalues,
} from "./utils/gaussianchart";

import {
  getThicknessMeasurementsMean,
  getThicknessMeasurementsStd,
  measurementsMin,
  measurementsMax,
  showStatistics,
  numericOnly,
} from "./utils/utils";

Chart.register(annotationPlugin);

export default {
  name: "PowderOutput",
  components: {
    StepperLineVisit,
  },
  props: {
    lineId: {
      type: String,
      default: "",
    },
    visitId: {
      type: String,
      default: "",
    },
  },
  data() {
    this.$i18n.locale = this.$store.state.user_data.language;

    return {
      visit: null,
      thickness_measurements: [null, null, null, null, null],
      desired_output: "",
      gaussianChart: {
        labels: [],
        datasets: [],
      },
      last_optimization_powdersavings_percentage: null,
      recommended_expected_output_per_minute: null,
      gaussian_min: 0,
      gaussian_max: 0,
      received_data: {
        minimum_target_thickness: null,
        maximum_target_thickness: null,
        reciprocator_period: null,
        guns_movement_range: null,
        line_speed: null,
        coated_height: null,
        nozzle_angle: null,
        gun_settings: null,
        measurements: Array(5).fill(null),
      },

      received_powderoutput_data: {
        min_powder_output: null,
        max_powder_output: null,
        powderoutput_measurements: null,
        thickness_measurements: Array(5).fill(null),
        desired_output: null,
      },
      received_powderamount_data: {
        thickness_measurements: Array(5).fill(null),
        desired_output: null,
      },

      max_metric_decimals: 0,
      max_imperial_decimals: 2,

      // Powder Amount Calibration variables
      expected_powder_per_minute: null,
      powder_amount_parameters: [],
    };
  },

  computed: {
    recommendedExpectedOutputRounded() {
      return this.recommended_expected_output_per_minute ? this.recommended_expected_output_per_minute.toFixed(1) : null;
    },
    lastOptimizationPowderSavingPercentage() {
      return this.last_optimization_powdersavings_percentage ? this.last_optimization_powdersavings_percentage.toFixed(1) : null;
    },
    expectedPowderPerMinuteRounded() {
      return this.expected_powder_per_minute ? this.expected_powder_per_minute.toFixed(1) : null;
    },
    valuesUpdated() {
      const isThicknessNotEqual =
        JSON.stringify(this.thickness_measurements) !==
        JSON.stringify(this.received_powderamount_data.thickness_measurements);

        const isDesiredOutputNotEqual =
        this.expected_powder_per_minute !== this.received_powderamount_data.desired_output;

      return (
        isThicknessNotEqual ||
        isDesiredOutputNotEqual
      );
    },
    gunExpectedOutput: {
      get() {
        if (this.expected_powder_per_minute == null || this.expected_powder_per_minute == "") {
          return this.expected_powder_per_minute;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (this.expected_powder_per_minute * this.$store.state.conversion_factors.g_to_oz).toFixed(2),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(this.expected_powder_per_minute.toFixed(0));
        } else {
          return this.expected_powder_per_minute;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.expected_powder_per_minute = value;
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.expected_powder_per_minute = value / this.$store.state.conversion_factors.g_to_oz;
          } else if (this.$store.state.user_data.unit_system === "metric") {
            this.expected_powder_per_minute = value;
          }
        }
      },
    },
  },
  mounted() {
    this.$store.state.isAbsolute = true;
    setTooltip(this.$store.state.bootstrap);
    this.getData();
  },
  beforeUnmount() {
    this.$store.state.isAbsolute = false;
  },
  methods: {
    generateX,
    gaussian,
    generateY,
    calculateMu,
    calculateSigma,
    generateXforMultipleMuSigma,
    generateYwithXvalues,
    getThicknessMeasurementsMean,
    getThicknessMeasurementsStd,
    measurementsMin,
    measurementsMax,
    showStatistics,
    numericOnly,
    async getData() {
      await this.getBenchmarkData();
      await this.getLineData();
      await this.getVisit();
      await this.getPowderSavingPercentage();
      await this.getPowderOutputStageData();
      await this.getPowderAmountSavingStageData();

      await this.computePowderAmountParameters();
      
      this.drawBarChart();
      this.showThicknessGraph();

      this.saveFormProgress();
    },
    async computePowderAmountParameters() {
      if (this.isPowderOutputMeasurementsFilled() && this.isValidExpectedThroughput) {
        try {
          let body = {
            line: this.lineId,
            time_interval: 60,
            measures_list: this.generateMeasurementList(this.received_powderoutput_data.powderoutput_measurements),
            powder_per_minute: this.expected_powder_per_minute,
            gun_idx: null,
            powder_amount_param: null,
          };

          let response = await axios.post("/api/v1/fp/computepowderamountcalibration/", body);
          this.powder_amount_parameters = response.data.powder_amount_params;
          this.roundPowderAmountParameters();
        } catch (error) {
          console.error(error);
        }
      }
    },
    generateMeasurementList(powderoutput_measurements) {
      const results = [];

      powderoutput_measurements.map(gun => {
        const validPairs = gun.gun_measurements.filter(pair => {
          return pair.setting !== "" && pair.weight !== "";
        });

        const result = {
          powderAmount: validPairs.map(pair => parseFloat(pair.setting)),
          emptyBagWeight: Array(validPairs.length).fill(0),
          fullBagWeight: validPairs.map(pair => parseFloat(pair.weight)),
        };

        if (result.powderAmount.length > 0 || result.fullBagWeight.length > 0) {
          results.push(result);
        }
      });

      return JSON.stringify(results);
    },
    getPowderAmountParametersFromParameter(gun_index, powder_amount_parameter) {
      if (powder_amount_parameter == null || powder_amount_parameter == "") {
        this.clearPowderAmountParameters();
        return;
      }

      if (
        powder_amount_parameter < this.received_powderoutput_data.min_powder_output
        || powder_amount_parameter > this.received_powderoutput_data.max_powder_output
      ) {
        this.powder_amount_parameters[gun_index] = null;
        this.$swal({
          title: this.$t("Invalid Powder Amount Parameter input"),
          text:
            this.$t("The powder amount parameter is out of range.") +
            " " +
            "Min: " +
            this.received_powderoutput_data.min_powder_output +
            ", " +
            "Max: " +
            this.received_powderoutput_data.max_powder_output,
          icon: "error",
          confirmButtonText: "OK",
        });
      }

      let body = {
        line: this.lineId,
        time_interval: 60,
        measures_list: this.generateMeasurementList(this.received_powderoutput_data.powderoutput_measurements),
        powder_per_minute: null,
        gun_idx: gun_index,
        powder_amount_param: powder_amount_parameter,
      };

      axios.post("/api/v1/fp/computepowderamountcalibration/", body).then(response => {
        this.powder_amount_parameters = response.data.powder_amount_params;
        this.roundPowderAmountParameters();
        this.checkPowderAmountResultsRangeAlert();
        this.expected_powder_per_minute = parseFloat(response.data.powder_per_minute.toFixed(1));
        this.checkPowderThroughputAlert();
        this.saveFormProgress();
      });
    },
    roundPowderAmountParameters() {
      this.powder_amount_parameters = this.powder_amount_parameters.map(param => {
        return parseFloat(param.toFixed(1));
      });
    },
    clearPowderAmountParameters() {
      this.powder_amount_parameters = Array.from({ length: this.received_data.total_pistols }, () => null);
      this.expected_powder_per_minute = null;
    },
    checkPowderThroughputAlert() {
      if (this.expected_powder_per_minute <= 0) {
        this.$swal({
          title: "Invalid Expected Throughput",
          text: "The expected throughput must be greater than 0.",
          icon: "error",
          confirmButtonText: "OK",
        });

        this.clearPowderAmountParameters();
        if (this.recommended_expected_output_per_minute) {
          this.expected_powder_per_minute = this.recommended_expected_output_per_minute;
        } else {
          this.recommended_expected_output_per_minute = this.received_powderoutput_data.desired_output;
        }
      }
    },
    async getPowderAmountParametersFromExpectedOutput() {
      if (
        this.expected_powder_per_minute == null ||
        this.expected_powder_per_minute == "" ||
        this.expected_powder_per_minute <= 0
      ) {
        if (this.expected_powder_per_minute <= 0) {
          this.$swal({
            title: "Invalid Expected Throughput",
            text: "The expected throughput must be greater than 0.",
            icon: "error",
            confirmButtonText: "OK",
          });
          this.expected_powder_per_minute = null;
        }
        return;
      }
      let body = {
        line: this.lineId,
        time_interval: 60,
        measures_list: this.generateMeasurementList(this.received_powderoutput_data.powderoutput_measurements),
        powder_per_minute: this.expected_powder_per_minute,
        gun_idx: null,
        powder_amount_param: null,
      };

      let response = await axios.post("/api/v1/fp/computepowderamountcalibration/", body);

      this.powder_amount_parameters = response.data.powder_amount_params;

      this.checkPowderAmountResultsRangeAlert();
    },
    checkPowderAmountResultsRangeAlert() {
      let calibration_out_of_range_popup_shown = false;
      for (let index = 0; index < this.powder_amount_parameters.length; index++) {
        if (
          this.powder_amount_parameters[index] !== null &&
          this.powder_amount_parameters[index] !== "" &&
          (this.powder_amount_parameters[index] < this.received_powderoutput_data.min_powder_output ||
            this.powder_amount_parameters[index] > this.received_powderoutput_data.max_powder_output)
        ) {
          if (!calibration_out_of_range_popup_shown) {
            this.$swal({
              title: "Calibration Out of Range",
              text: `${"The resulting Powder Amount setting is out of range for Gun"} ${index + 1}.\n Min: ${
                this.received_powderoutput_data.min_powder_output
              }, Max: ${this.received_powderoutput_data.max_powder_output}.
              ${"Obtained Parameter"}: ${this.powder_amount_parameters[index]}`,
              icon: "error",
              confirmButtonText: "OK",
            });
            calibration_out_of_range_popup_shown = true;
          }
        }
      }
    },
    async getPowderOutputStageData() {
      try {
        let response = await axios.get("/api/v1/onsite/powderoutput/" + this.visitId);

        if (response.data !== null) {
          this.received_powderoutput_data.min_powder_output = response.data.min_powder_output;
          this.received_powderoutput_data.max_powder_output = response.data.max_powder_output;

          this.received_powderoutput_data.powderoutput_measurements = JSON.parse(
            response.data.powder_amount_measurements,
          );

          this.received_powderoutput_data.thickness_measurements = JSON.parse(response.data.thickness_measurements);

          this.received_powderoutput_data.desired_output = response.data.desired_output;

          if (response.data.desired_output !== null) {
            this.expected_powder_per_minute = response.data.desired_output;
          }
        }
      } catch (error) {
        console.error(error);
      }
      this.$store.state.isLoaded = false;
    },
    async getPowderAmountSavingStageData() {
      const response = await axios.get("/api/v1/onsite/powderamountsaving/" + this.visitId + "/")

      this.received_powderamount_data.desired_output = response.data.desired_output;
      this.received_powderamount_data.thickness_measurements = JSON.parse(response.data.thickness_measurements);

      if (response.data.thickness_measurements !== null) {
        this.thickness_measurements = JSON.parse(response.data.thickness_measurements);
      }

      if (response.data.desired_output !== null) {
        this.expected_powder_per_minute = response.data.desired_output;
      }

      if (
        this.received_powderoutput_data.desired_output != null
        && this.last_optimization_powdersavings_percentage != null
      ) {
        this.recommended_expected_output_per_minute = this.received_powderoutput_data.desired_output * (100 - this.last_optimization_powdersavings_percentage) / 100;

        if (response.data.desired_output == null || response.data.desired_output == this.received_powderoutput_data.desired_output) {
          this.expected_powder_per_minute = this.recommended_expected_output_per_minute
        }
      }
    },
    isValidExpectedThroughput() {
      let is_valid_expected_throughput = (
        this.expected_powder_per_minute !== null
        && this.expected_powder_per_minute !== ""
        && this.expected_powder_per_minute > 0)

        return is_valid_expected_throughput;
    },
    isPowderOutputMeasurementsFilled() {
      let total_empty_measures = this.received_powderoutput_data.powderoutput_measurements.map(
        powderoutput_measurement => powderoutput_measurement.gun_measurements)
        .filter(gun_measurment =>
          gun_measurment.some(
            gun_measurment => gun_measurment.setting == "" || gun_measurment.setting == null || gun_measurment.weight == "" || gun_measurment.weight == null),
        ).length

      let is_powder_output_measurements_filled = total_empty_measures == 0;

      return is_powder_output_measurements_filled;
    },
    async getLineData() {
      let line_response = await axios.get("/api/v1/fp/line/" + this.lineId);
      this.received_data.total_pistols = JSON.parse(line_response.data.total_pistols)[0];
    },
    async getBenchmarkData() {
      try {
        let response = await axios.get(`/api/v1/onsite/benchmarkstage/` + this.visitId);

        this.received_data.minimum_target_thickness = response.data.minimum_target_thickness;
        this.received_data.maximum_target_thickness = response.data.maximum_target_thickness;
        this.received_data.reciprocator_period = response.data.reciprocator_period;
        this.received_data.guns_movement_range = response.data.guns_movement_range;
        this.received_data.line_speed = response.data.line_speed;
        this.received_data.coated_height = response.data.coated_height;
        this.received_data.nozzle_angle = response.data.nozzle_angle;
        this.received_data.measurements = JSON.parse(response.data.measurements);
        if (this.received_data.measurements === null || this.received_data.measurements === "") {
          this.received_data.measurements = Array(5).fill(null);
        } else if (this.received_data.measurements.length < 5) {
          this.received_data.measurements = this.received_data.measurements.concat(
            Array(5 - this.received_data.measurements.length).fill(null),
          );
        }
        if (response.data.gun_settings !== null) {
          this.received_data.gun_settings = response.data.gun_settings;
        } else {
          this.received_data.gun_settings = Array.from({ length: 1 }, () => ({
            // fix hardcoded length
            voltage: "",
            amps: "",
            air: "",
            distance: "",
            powder: "",
          }));
        }
      } catch (error) {
        console.error(error);
      }
      this.$store.state.isLoaded = false;
    },
    getFormProgressStatus() {
      const PROGRESS_STATUS_PENDING = "Pending";
      const PROGRESS_STATUS_IN_PROGRESS = "In Progress";
      const PROGRESS_STATUS_COMPLETED = "Completed";

      if (Array.isArray(this.thickness_measurements) &&
        this.thickness_measurements.length > 1 && (
        this.expected_powder_per_minute !== null ||
        this.expected_powder_per_minute !== "")
      ) {
        return PROGRESS_STATUS_COMPLETED;
      } else if (
        (Array.isArray(this.thickness_measurements) && this.thickness_measurements.length >= 1) ||
        this.expected_powder_per_minute !== ""
      ) {
        return PROGRESS_STATUS_IN_PROGRESS;
      } else {
        return PROGRESS_STATUS_PENDING;
      }
    },
    saveFormProgress() {
      try {
        axios
        .patch(
          "/api/v1/onsite/powderamountsaving/" + this.visitId + "/",
          {
            thickness_measurements: JSON.stringify(this.thickness_measurements),
            desired_output: this.expected_powder_per_minute,
            progress_status: this.getFormProgressStatus(),
          }
        );
      } catch (error) {
        console.error(error);
      }
    },
    async getVisit() {
      try {
        let response = await axios.get("/api/v1/onsite/visit/" + this.visitId);
        this.visit = JSON.parse(JSON.stringify(response.data));
      } catch (error) {
        console.error(error);
      }
    },
    async getPowderSavingPercentage() {
      const response = await axios.get("/api/v1/onsite/reciprocator/" + this.visitId + "/")

      if (response.data.optimal_setting_powder_waste !== null && response.data.current_setting_powder_waste) {
        this.last_optimization_powdersavings_percentage = (response.data.current_setting_powder_waste - response.data.optimal_setting_powder_waste);
      }
    },
    checkLastRow(index) {
      let notEmptyMeasurements = this.thickness_measurements.filter(m => m !== null && m !== "");
      if (
        index + 1 === this.thickness_measurements.length
        && this.thickness_measurements[index] !== ""
        && notEmptyMeasurements.length === this.thickness_measurements.length
      ) {
        this.thickness_measurements.push("");
      }
      else if (
        (this.thickness_measurements[index] == "" || this.thickness_measurements[index] == null)
        && (this.thickness_measurements[index + 1] == "" || this.thickness_measurements[index + 1] == null)
      ) {
        this.thickness_measurements.splice(index, 1);
      }
    },
    generateXLabels() {
      const mu = this.calculateMu(this.thickness_measurements);
      const sigma = this.calculateSigma(this.thickness_measurements, mu);

      const benchmarkMu = this.calculateMu(this.received_data.measurements);
      const benchmarkSigma = this.calculateSigma(this.received_data.measurements, benchmarkMu);

      const powderOutputMu = this.calculateMu(this.received_powderoutput_data.thickness_measurements);
      const powderOutputSigma = this.calculateSigma(this.received_powderoutput_data.thickness_measurements, powderOutputMu);

      this.gaussianChart.labels = this.generateXforMultipleMuSigma([
        [mu, sigma],
        [benchmarkMu, benchmarkSigma],
        [powderOutputMu, powderOutputSigma],
      ]);

      const Xarray = this.gaussianChart.labels.map(num => parseFloat(num));

      this.gaussian_min = Xarray.findIndex(num => num > this.received_data.minimum_target_thickness);
      this.gaussian_max = Xarray.findIndex(num => num > this.received_data.maximum_target_thickness);
    },
    benchmarkThicknessMeasurementsUpdateChartData() {
      if (this.received_data.measurements == null) {
        return
      }

      const benchmarkMu = this.calculateMu(this.received_data.measurements);
      const benchmarkSigma = this.calculateSigma(this.received_data.measurements, benchmarkMu);

      this.gaussianChart.datasets[0] = {
        label: "Initial Benchmark",
        data: this.generateYwithXvalues(benchmarkSigma, benchmarkMu, this.gaussianChart.labels),
      };
    },
    powderOutputThicknessMeasurementsUpdateChartData() {
      if (this.received_powderoutput_data.thickness_measurements == null && this.received_powderoutput_data.thickness_measurements.length <= 2) {
        return
      }

      const powderOutputMu = this.calculateMu(this.received_powderoutput_data.thickness_measurements);
      const powderOutputSigma = this.calculateSigma(this.received_powderoutput_data.thickness_measurements, powderOutputMu);

      const arr = this.gaussianChart.labels.map(num => parseFloat(num));

      let index = arr.findIndex(num => num > this.received_data.minimum_target_thickness);
      let index_max = arr.findIndex(num => num > this.received_data.maximum_target_thickness);

      this.gaussian_min = index > 0 ? index - 1 : index;
      this.gaussian_max = index_max > 0 ? index_max - 1 : arr.length - 1;

      this.gaussianChart.datasets[1] = {
        label: "Powder Output stage",
        data: this.generateYwithXvalues(powderOutputSigma, powderOutputMu, this.gaussianChart.labels),
      };
    },
    thicknessMeasurementsUpdateChartData() {
      const mu = this.calculateMu(this.thickness_measurements);
      const sigma = this.calculateSigma(this.thickness_measurements, mu);

      this.gaussianChart.datasets[2] = {
        label: "Powder Amount Saving stage",
        data: this.generateYwithXvalues(sigma, mu, this.gaussianChart.labels),
      };
    },
    addPair(gunIndex) {
      this.powderoutput_measurements[gunIndex].gun_measurements.push({ setting: "", weight: "" });
    },
    deletePair(gunIndex) {
      this.powderoutput_measurements[gunIndex].gun_measurements.pop();
    },
    createDatasetConfig(label, data, pointBackgroundColor, borderColor, gradientStroke) {
      return {
        label: label,
        tension: 0.4,
        borderWidth: 3,
        pointRadius: 0,
        pointBackgroundColor: pointBackgroundColor,
        borderColor: borderColor,
        backgroundColor: gradientStroke,
        data: data,
        maxBarThickness: 6,
      };
    },
    createChartConfig(labels, datasets, yTitle, xTitle) {
      return {
        type: "line",
        data: {
          labels: labels,
          datasets: datasets,
        },
        options: {
          responsive: true,
          maintainAspectRatio: true,
          interaction: {
            intersect: false,
            mode: "index",
          },
          scales: {
            y: {
              grid: {
                drawBorder: false,
                display: true,
                drawOnChartArea: true,
                drawTicks: false,
                borderDash: [5, 5],
              },
              title: {
                display: true,
                text: yTitle,
              },
              ticks: {
                display: true,
                padding: 10,
                color: "#9ca2b7",
              },
            },
            x: {
              grid: {
                drawBorder: false,
                display: true,
                drawOnChartArea: true,
                drawTicks: true,
                borderDash: [5, 5],
              },
              title: {
                display: true,
                text: xTitle,
              },
              ticks: {
                display: true,
                color: "#9ca2b7",
                padding: 10,
              },
            },
          },
        },
      };
    },
    createBarChartConfig() {
      const backgroundColors = ['rgba(255, 99, 132, 0.2)', 'rgba(54, 162, 235, 0.2)']; // Red and Blue
      const borderColors = ['rgb(255, 99, 132)', 'rgb(54, 162, 235)']; // Red and Blue
      const optimizedBackgroundColors = ['rgba(54, 162, 235, 0.2)', 'rgba(54, 162, 235, 0.2)']; // Red and Blue
      const optimizedBorderColors = ['rgba(84, 182, 235, 0.8)', 'rgb(84, 182, 245)']; // Red and Blue

      const data = {
        labels: ['Consumption'],
        datasets: [
          {
            label: 'Previous Powder Output',
            data: [this.received_powderoutput_data.desired_output  * this.received_data.total_pistols ], // replace with actual data
            backgroundColor: backgroundColors,
            borderColor: borderColors,
            borderWidth: 1
          },
          {
            label: 'Optimized Powder Output',
            data: [this.expected_powder_per_minute * this.received_data.total_pistols ],
            backgroundColor: optimizedBackgroundColors,
            borderColor: optimizedBorderColors,
            borderWidth: 1
          }
        ]
      };

      const config = {
        type: 'bar',
        data: data,
        options: {
          scales: {
            y: {
              beginAtZero: true,
              title: {
          display: true,
          text: 'Total Guns Throughput g/min'
        },
            }
          }
        },
      };
      return config;
    },
    drawBarChart() {
      var canvas = document.getElementById('powder-amount-saving-chart').getContext("2d");

      let chartStatus = Chart.getChart("powder-amount-saving-chart");
        if (chartStatus != undefined) {
          chartStatus.destroy();
        }

      new Chart(canvas, this.createBarChartConfig());

    },
    createChartConfigWithAnnotation(labels, datasets, yTitle, xTitle) {
      return {
        type: "line",
        data: {
          labels: labels,
          datasets: datasets,
        },
        options: {
          responsive: true,
          maintainAspectRatio: true,
          plugins: {
            annotation: {
              annotations: {
                minThicknessLine: {
                  type: "line",
                  xMin: this.gaussian_min,
                  xMax: this.gaussian_min,
                  borderColor: "red",
                  borderWidth: 2,
                  borderDash: [6, 6],
                  label: {
                    content: "Min",
                    enabled: true,
                    position: "end",
                  },
                },
                maxThicknessLine: {
                  type: "line",
                  xMin: this.gaussian_max,
                  xMax: this.gaussian_max,
                  borderColor: "red",
                  borderWidth: 2,
                  borderDash: [6, 6],
                  label: {
                    content: "Max",
                    enabled: true,
                    position: "end",
                  },
                },
              },
            },
          },
          interaction: {
            intersect: false,
            mode: "index",
          },
          scales: {
            y: {
              grid: {
                drawBorder: false,
                display: true,
                drawOnChartArea: true,
                drawTicks: false,
                borderDash: [5, 5],
              },
              title: {
                display: true,
                text: yTitle,
              },
              ticks: {
                display: true,
                padding: 10,
                color: "#9ca2b7",
              },
            },
            x: {
              grid: {
                drawBorder: false,
                display: true,
                drawOnChartArea: true,
                drawTicks: true,
                borderDash: [5, 5],
              },
              title: {
                display: true,
                text: xTitle,
              },
              ticks: {
                display: true,
                color: "#9ca2b7",
                padding: 10,
              },
            },
          },
        },
      };
    },
    drawChart(id, yTitle, xTitle) {
      var canvas = document.getElementById(id).getContext("2d");
      var gradientStroke = canvas.createLinearGradient(0, 230, 0, 50);

      let chartStatus = Chart.getChart(id);
      if (chartStatus != undefined) {
        chartStatus.destroy();
      }

      const colors = [
        "#3A416F",
        "#17c1e8",
        // "#f64e60",
        // "#f1b44c",
        // "#5c7cfa",
        "#20c997",
        // "#6c757d",
        // "#495057",
        // "#dee2e6",
      ];

      const chartDatasets = this.gaussianChart.datasets.map((dataset, index) => {
        return this.createDatasetConfig(
          dataset.label,
          dataset.data,
          colors[index],
          colors[index],
          gradientStroke,
        );
      });

      if (
        id === "measurement-chart" &&
        this.received_data.minimum_target_thickness !== null &&
        this.received_data.maximum_target_thickness !== null
      ) {
        new Chart(canvas, this.createChartConfigWithAnnotation(this.gaussianChart.labels, chartDatasets, yTitle, xTitle));
      } else {
        new Chart(canvas, this.createChartConfig(this.gaussianChart.labels, chartDatasets, yTitle, xTitle));
      }
    },
    showThicknessGraph() {
      this.generateXLabels();
      this.benchmarkThicknessMeasurementsUpdateChartData();
      this.powderOutputThicknessMeasurementsUpdateChartData();
      this.thicknessMeasurementsUpdateChartData();
      this.drawChart("measurement-chart", "Probability", "Thickness");
    },
  },
};
</script>
<style scoped>

.table-bordered {
  border: 1px solid #dee2e6;
  border-collapse: collapse; /* Ensure borders are collapsed */
}

.table-bordered th,
.table-bordered td {
  border: 1px solid #dee2e6;
}

.table-border tbody tr:last-child td {
    border-width: 1px;
}

.chart-canvas {
  max-height: 400px;
}

</style>
