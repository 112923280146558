export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "EN": {
        "Visits for Line": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visits for Line"])},
        "New Visit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Visit"])}
      },
      "DE": {
        "Visits for Line": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visits for Line"])},
        "New Visit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Visit"])}
      },
      "ES": {
        "Visits for Line": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visits for Line"])},
        "New Visit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Visit"])}
      },
      "FR": {
        "Visits for Line": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visits for Line"])},
        "New Visit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Visit"])}
      },
      "IT": {
        "Visits for Line": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visits for Line"])},
        "New Visit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Visit"])}
      },
      "PL": {
        "Visits for Line": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visits for Line"])},
        "New Visit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Visit"])}
      },
      "TR": {
        "Visits for Line": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visits for Line"])},
        "New Visit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Visit"])}
      },
      "ZH": {
        "Visits for Line": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visits for Line"])},
        "New Visit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Visit"])}
      }
    }
  })
}
