export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "EN": {
        "": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
        "To allow a greater covered height, increase the gun movement range of the Line.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To allow a greater covered height, increase the gun movement range of the Line."])},
        "Animation generation unsuccessful": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Animation generation unsuccessful"])},
        "Applied powder cannot be visualized as an animation for this line and parameters. Optimal settings and results images are still available in the results section.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applied powder cannot be visualized as an animation for this line and parameters. Optimal settings and results images are still available in the results section."])},
        "Overcoated powder:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Overcoated powder:"])},
        "Oversprayed powder:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oversprayed powder:"])},
        "The amount of extra powder applied because of an uneven powder distribution on the substrate.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The amount of extra powder applied because of an uneven powder distribution on the substrate."])},
        "The powder that does not stick to the part and is then not reclaimed by the recycling system.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The powder that does not stick to the part and is then not reclaimed by the recycling system."])},
        "Coated height must be smaller than the maximum mechanical range covered by the guns, of ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coated height must be smaller than the maximum mechanical range covered by the guns, of "])},
        "Gun movement range cannot be greater than the maximum possible mechanical range of the line:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gun movement range cannot be greater than the maximum possible mechanical range of the line:"])},
        "Loading optimization type...": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loading optimization type..."])},
        "Clear & start new optimization for line": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clear & start new optimization for line"])},
        "Please fill the following fields: ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please fill the following fields: "])},
        "Line speed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Line speed"])},
        "Coated height": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coated height"])},
        "Coated width": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coated width"])},
        "Powder model": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Powder model"])},
        "Specific Powder Optimization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Specific Powder Optimization"])},
        "Multi-Powder Optimization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Multi-Powder Optimization"])},
        "Input value out of range": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Input value out of range"])},
        "was saved successfully.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["was saved successfully."])},
        "Missing Required Fields": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Missing Required Fields"])},
        "Gun movement range cannot be 0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gun movement range cannot be 0"])},
        "Line speed cannot be 0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Line speed cannot be 0"])},
        "Line speed must be greater than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Line speed must be greater than"])},
        "Line speed must be less than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Line speed must be less than"])},
        "Covered height must be greater than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Covered height must be greater than"])},
        "Simulation visualization coated width must be greater than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Simulation visualization coated width must be greater than"])},
        "Gun movement speed cannot be slower than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gun movement speed cannot be slower than"])},
        "Use a faster guns velocity or a different movement range & period time combination.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Use a faster guns velocity or a different movement range & period time combination."])},
        "Gun movement speed cannot be faster than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gun movement speed cannot be faster than"])},
        "Use a slower guns velocity or a different movement range & period time combination.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Use a slower guns velocity or a different movement range & period time combination."])},
        "Gun movement period cannot be longer than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gun movement period cannot be longer than"])},
        "Use a shorter guns period or a different movement range & period time combination.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Use a shorter guns period or a different movement range & period time combination."])},
        "Gun movement period must be greater than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gun movement period must be greater than"])},
        "No fields were changed from the previous optimization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No fields were changed from the previous optimization"])},
        "Do you want to recompute it anyway?": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you want to recompute it anyway?"])},
        "Cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
        "Yes, recompute it!": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes, recompute it!"])},
        "OK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OK"])},
        "Active guns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active guns"])},
        "Active guns per column": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active guns per column"])},
        "Gun movement range": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gun movement range"])},
        "Gun Movement Speed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gun Movement Speed"])},
        "Gun Movement Period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gun Movement Period"])},
        "Save Optimization to Library": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save Optimization to Library"])},
        "Optimization Saved to Library": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optimization Saved to Library"])},
        "optional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["optional"])},
        "Optimization Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optimization Name"])},
        "New Optimization for line": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Optimization for line"])},
        "Status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
        "Optimum found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optimum found"])},
        "Ready to find optimum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ready to find optimum"])},
        "Optimization details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optimization details"])},
        "Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
        "Line": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Line"])},
        "Optimization type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optimization type"])},
        "Select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select"])},
        "Powder Models": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Powder Models"])},
        "Calibrated Powder Optimization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calibrated Powder Optimization"])},
        "Pre-Calibrated Powder Optimization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pre-Calibrated Powder Optimization"])},
        "Generalistic Optimization for any powder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generalistic Optimization for any powder"])},
        "Select Powder Model": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Powder Model"])},
        "No Powder Models Created. To create one go to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Powder Models Created. To create one go to"])},
        "Optimization parameters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optimization parameters"])},
        "Line Speed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Line Speed"])},
        "Distance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distance"])},
        "Covered height": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Covered height"])},
        "Simulation width": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Simulation width"])},
        "Continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continue"])},
        "Compare with Current Settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compare with Current Settings"])},
        "Number of active guns 1st column": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of active guns 1st column"])},
        "Please select one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select one"])},
        "Number of active guns 2nd column": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of active guns 2nd column"])},
        "Number of active guns 3rd column": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of active guns 3rd column"])},
        "Number of active guns 4th column": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of active guns 4th column"])},
        "Number of active guns 5th column": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of active guns 5th column"])},
        "Number of active guns 6th column": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of active guns 6th column"])},
        "Number of active guns 7th column": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of active guns 7th column"])},
        "Active guns on one side": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active guns on one side"])},
        "Gun movement range": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gun movement range"])},
        "Gun movement period time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gun movement period time"])},
        "Gun movement speed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gun movement speed"])},
        "Compute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compute"])},
        "Optimal machine settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optimal machine settings"])},
        "Simulation results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Simulation results"])},
        "Current Settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current Settings"])},
        "Uniformity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uniformity"])},
        "Overspray": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Overspray"])},
        "Optimal Settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optimal Settings"])},
        "Uniformity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uniformity"])},
        "Visualize graphs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visualize graphs"])},
        "Calculation running": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calculation running"])},
        "Uniformity comparison": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uniformity comparison"])},
        "Applied spray": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applied spray"])},
        "Movement chart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Movement chart"])},
        "Applied spray animation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applied spray animation"])},
        "Current Settings Coated Area": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current Settings Coated Area"])},
        "Optimal Settings Coated Area": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optimal Settings Coated Area"])},
        "Current Settings Gun Movement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current Settings Gun Movement"])},
        "Optimal Settings Gun Movement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optimal Settings Gun Movement"])},
        "Current Settings Coated Area": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current Settings Coated Area"])},
        "Optimal Settings Coated Area": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optimal Settings Coated Area"])},
        "Comments section for the generated report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comments section for the generated report"])},
        "Hide Optimal Settings in the report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hide Optimal Settings in the report"])},
        "Save Edited Comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save Edited Comments"])},
        "Generate Report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generate Report"])},
        "Generating optimization report...": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generating optimization report..."])},
        "To use a different optimization type create a new Optimization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To use a different optimization type create a new Optimization"])},
        "If you want to use a different set of Powder Models, create a new Optimization.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you want to use a different set of Powder Models, create a new Optimization."])},
        "Select Specific Powder optimization to use one or more powder models to compute the optimal settings. Select the Generalistic Optimization option to get a powder agnostic optimization solution.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Specific Powder optimization to use one or more powder models to compute the optimal settings. Select the Generalistic Optimization option to get a powder agnostic optimization solution."])},
        "Select the Powder Models to be used during the computation of optimal settings.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select the Powder Models to be used during the computation of optimal settings."])},
        "Line velocity value in [meters/minute] units.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Line velocity value in [meters/minute] units."])},
        "Distance from the gun nozzle to the substrate.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distance from the gun nozzle to the substrate."])},
        "Vertical height for which the uniformity will be optimized.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vertical height for which the uniformity will be optimized."])},
        "Only relevant for visualization purposes, determines the horizontal width, of the simulation image shown on the results section.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Only relevant for visualization purposes, determines the horizontal width, of the simulation image shown on the results section."])},
        "Fill this section with your Gun Settings to visualize how they compare with the optimal settings.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fill this section with your Gun Settings to visualize how they compare with the optimal settings."])},
        "Clear current settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clear current settings"])},
        "Number of active guns on the first column of guns. The minimum is 0 and the maximum is": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of active guns on the first column of guns. The minimum is 0 and the maximum is"])},
        "Number of active guns on the second column of guns. The minimum is 0 and the maximum is": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of active guns on the second column of guns. The minimum is 0 and the maximum is"])},
        "Number of active guns on the third column of guns. The minimum is 0 and the maximum is": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of active guns on the third column of guns. The minimum is 0 and the maximum is"])},
        "Number of active guns on the fourth column of guns. The minimum is 0 and the maximum is": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of active guns on the fourth column of guns. The minimum is 0 and the maximum is"])},
        "Number of active guns on the fifth column of guns. The minimum is 0 and the maximum is": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of active guns on the fifth column of guns. The minimum is 0 and the maximum is"])},
        "Number of active guns on one side of the booth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of active guns on one side of the booth"])},
        "Activate Toggle to visualize simulation images": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activate Toggle to visualize simulation images"])},
        "low": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["low"])},
        "medium": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["medium"])},
        "high": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["high"])},
        "Overspray penalization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Overspray penalization"])},
        "Nozzle angle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nozzle angle"])},
        "Optimize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optimize"])},
        "Nozzle rotation angle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nozzle rotation angle"])},
        "degrees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["degrees"])},
        "Optimal Settings Nozzle Angle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optimal Settings Nozzle Angle"])},
        "Current Settings Nozzle Angle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current Settings Nozzle Angle"])},
        "Overspray penalization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Overspray penalization"])},
        "Selection of optimal setting in terms of powder waste penalization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selection of optimal setting in terms of powder waste penalization"])},
        "High (prioritize reducing overspray waste)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["High (prioritize reducing overspray waste)"])},
        "Medium (balanced uniformity & overspray)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Medium (balanced uniformity & overspray)"])},
        "Low (prioritize for uniformity)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Low (prioritize for uniformity)"])},
        "Unique optimal solution found.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unique optimal solution found."])},
        "Overspray vs. uniformity trade-off": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Overspray vs. uniformity trade-off"])},
        "Selection of optimal setting in terms of uniformity vs. powder waste. Select an option according to your powder recycling capabilities and uniformity needs.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selection of optimal setting in terms of uniformity vs. powder waste. Select an option according to your powder recycling capabilities and uniformity needs."])},
        "Maximize uniformity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maximize uniformity"])},
        "Minimize overspray": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minimize overspray"])},
        "Balance overspray & uniformity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Balance overspray & uniformity"])},
        "guns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["guns"])},
        "Powder Usage Pie Chart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Powder Usage Pie Chart"])},
        "Optimal Settings Pie Chart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optimal Settings Pie Chart"])},
        "Current Settings Pie Chart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current Settings Pie Chart"])},
        "Powder Waste": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Powder Waste"])},
        "Calibrate Powder Amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calibrate Powder Amount"])},
        "Powder Amount Calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Powder Amount Calibration"])},
        "Please select a reference gun": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select a reference gun"])},
        "Powder Amount Parameter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Powder Amount Parameter"])},
        "Gun": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gun"])},
        "Expected throughput": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expected throughput"])},
        "Activate Toggle to open the pistol powder amount calibration panel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activate Toggle to open the pistol powder amount calibration panel"])},
        "Please select a powder amount calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select a powder amount calibration"])},
        "Calibration Out of Range": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calibration Out of Range"])},
        "The required settings to obtain this throughput are out of range for gun": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The required settings to obtain this throughput are out of range for gun"])},
        "Obtained Parameter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obtained Parameter"])},
        "Invalid Powder Amount Parameter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid Powder Amount Parameter"])},
        "The powder amount parameter must be between the minimum and maximum values defined in the calibration.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The powder amount parameter must be between the minimum and maximum values defined in the calibration."])},
        "The expected throughput must be greater than 0.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The expected throughput must be greater than 0."])},
        "Invalid Expected Throughput": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid Expected Throughput"])},
        "Time it takes for one gun to move from the lowest point to the highest point and back.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Time it takes for one gun to move from the lowest point to the highest point and back."])},
        "Distance between highest and lowest point of one moving gun": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distance between highest and lowest point of one moving gun"])},
        "Speed at which the gun moves": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Speed at which the gun moves"])},
        "Share Optimization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Share Optimization"])},
        "Copied to Clipboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copied to Clipboard"])},
        "Copy to Clipboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copy to Clipboard"])},
        "Sharable link:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sharable link:"])}
      },
      "DE": {
        "": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
        "To allow a greater covered height, increase the gun movement range of the Line.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Um eine größere beschichtete Höhe zu ermöglichen, erhöhen Sie den Bewegungsbereich der Pistole der Linie."])},
        "Animation generation unsuccessful": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erzeugung von Animationen erfolglos"])},
        "Applied powder cannot be visualized as an animation for this line and parameters. Optimal settings and results images are still available in the results section.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das aufgetragene Pulver kann für diese Linie und Parameter nicht als Animation dargestellt werden. Optimale Einstellungen und Ergebnisbilder sind weiterhin im Ergebnisbereich verfügbar."])},
        "Overcoated powder:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Overcoated powder:"])},
        "Oversprayed powder:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oversprayed powder:"])},
        "The amount of extra powder applied because of an uneven powder distribution on the substrate.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Menge an zusätzlichem Pulver, die aufgrund einer ungleichmäßigen Verteilung des Pulvers auf dem Substrat aufgetragen wird."])},
        "The powder that does not stick to the part and is then not reclaimed by the recycling system.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Pulver, das nicht auf dem Teil haften bleibt und vom Recycling-System nicht zurückgewonnen wird."])},
        "Coated height must be smaller than the maximum mechanical range covered by the guns, of ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die beschichtete Höhe muss kleiner sein als die maximale mechanische Reichweite der Pistolen, von "])},
        "Gun movement range cannot be greater than the maximum possible mechanical range of the line:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Bewegungsbereich der Pistolen kann nicht größer sein als die maximal mögliche mechanische Reichweite der Linie:"])},
        "Loading optimization type...": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optimierungstyp laden..."])},
        "Clear & start new optimization for line": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Löschen und neue Optimierung für Linie starten"])},
        "Please fill the following fields: ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte füllen Sie die folgenden Felder aus: "])},
        "Line speed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kettengeschwindigkeit"])},
        "Coated height": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beschichtete Höhe"])},
        "Coated width": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beschichtete Breite"])},
        "Powder model": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puder-Modell"])},
        "Specific Powder Optimization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spezifische Puder-Optimierung"])},
        "Multi-Powder Optimization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Multi-Pulver-Optimierung"])},
        "Input value out of range": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eingabewert außerhalb des Bereichs"])},
        "was saved successfully.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["wurde erfolgreich gespeichert."])},
        "Missing Required Fields": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fehlende Pflichtfelder"])},
        "Gun movement range cannot be 0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bewegungsbereich der Pistolen kann nicht 0 sein"])},
        "Line speed cannot be 0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kettengeschwindigkeit kann nicht 0 sein"])},
        "Line speed must be greater than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Kettengeschwindigkeit muss größer sein als"])},
        "Line speed must be less than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kettengeschwindigkeit muss kleiner sein als"])},
        "Covered height must be greater than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beschichtete Höhe muss größer sein als"])},
        "Simulation visualization coated width must be greater than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Simulationsvisualisierung beschichtete Breite muss größer sein als"])},
        "Gun movement speed cannot be slower than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Bewegungsgeschwindigkeit der Pistole kann nicht langsamer sein als"])},
        "Use a faster guns velocity or a different movement range & period time combination.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verwenden Sie eine schnellere Pistolengeschwindigkeit oder eine andere Kombination aus Bewegungsbereich und Zeitspanne."])},
        "Gun movement speed cannot be faster than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Bewegungsgeschwindigkeit der Pistole kann nicht schneller sein als"])},
        "Use a slower guns velocity or a different movement range & period time combination.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verwenden Sie eine langsamere Pistolengeschwindigkeit oder eine andere Kombination aus Bewegungsbereich und Zeitspanne."])},
        "Gun movement period cannot be longer than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Bewegungszeit der Pistole kann nicht länger sein als"])},
        "Use a shorter guns period or a different movement range & period time combination.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verwenden Sie eine kürzere Pistolenzeit oder eine andere Kombination aus Bewegungsbereich und Zeitspanne."])},
        "Gun movement period must be greater than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Bewegungszeit der Pistole muss größer sein als"])},
        "No fields were changed from the previous optimization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bei der vorherigen Optimierung wurden keine Felder geändert."])},
        "Do you want to recompute it anyway?": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Möchten Sie trotzdem eine Neuberechnung durchführen?"])},
        "Cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abbrechen"])},
        "Yes, recompute it!": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ja, berechnen Sie sie neu!"])},
        "OK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OK"])},
        "Active guns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktive Pistolen"])},
        "Active guns per column": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktive Pistolen pro Spalte"])},
        "Gun movement range": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hubhöhe"])},
        "Gun Movement Speed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pistolen-Bewegungsgeschwindigkeit"])},
        "Gun Movement Period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intervalzeit der Pistolen"])},
        "Save Optimization to Library": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optimierung in der Bibliothek speichern"])},
        "Optimization Saved to Library": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In der Bibliothek gespeicherte Optimierung"])},
        "optional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["optional"])},
        "Optimization Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optimierung"])},
        "New Optimization for line": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neue Optimierung für Anlage"])},
        "Status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
        "Optimum found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optimum gefunden"])},
        "Ready to find optimum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bereit"])},
        "Optimization details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Details det Optimierung"])},
        "Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
        "Line": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anlage"])},
        "Optimization type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Art der Optimierung"])},
        "Select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wählen Sie"])},
        "Powder Models": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pulver-Modelle"])},
        "Calibrated Powder Optimization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kalibrierte Sprühbilder"])},
        "Pre-Calibrated Powder Optimization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorkalibrierte Sprühbilder"])},
        "Generalistic Optimization for any powder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generalistische Optimierung für beliebige Pulver"])},
        "Select Powder Model": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pulvermodell auswählen"])},
        "No Powder Models Created. To create one go to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Sprühbilder erstellt. Um eines zu erstellen, gehen Sie zu"])},
        "Optimization parameters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optimierungsparameter"])},
        "Line Speed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kettengeschwindigkeit"])},
        "Distance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abstand"])},
        "Covered height": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bauteilhöhe"])},
        "Simulation width": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Simulationsbreite"])},
        "Continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weiter"])},
        "Compare with Current Settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vergleich mit aktuellen Einstellungen"])},
        "Number of active guns 1st column": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anzahl der aktiven Pistolen 1. Spalte"])},
        "Please select one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte wählen Sie eine"])},
        "Number of active guns 2nd column": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anzahl der aktiven Pistolen 2. Spalte"])},
        "Number of active guns 3rd column": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anzahl aktiver Pistolen 3. Spalte"])},
        "Number of active guns 4th column": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anzahl der aktiven Pistolen 4. Spalte"])},
        "Number of active guns 5th column": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anzahl der aktiven Pistolen 5. Spalte"])},
        "Number of active guns 6th column": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anzahl der aktiven Pistolen 6. Spalte"])},
        "Number of active guns 7th column": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anzahl der aktiven Pistolen 7. Spalte"])},
        "Active guns on one side": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktive Pistolen auf einer Seite"])},
        "Gun movement range": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hubhöhe"])},
        "Gun movement period time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intervalzeit der Pistolen"])},
        "Gun movement speed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bewegungsgeschwindigkeit der Pistolen"])},
        "Compute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optimieren"])},
        "Optimal machine settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optimale Maschineneinstellungen"])},
        "Simulation results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ergebnisse der Simulation"])},
        "Current Settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktuelle Einstellungen"])},
        "Uniformity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gleichmäßigkeit"])},
        "Overspray": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Overspray"])},
        "Optimal Settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optimale Einstellungen"])},
        "Uniformity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gleichmäßigkeit"])},
        "Visualize graphs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diagramme visualisieren"])},
        "Calculation running": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Berechnung läuft"])},
        "Uniformity comparison": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vergleich der Gleichmäßigkeit"])},
        "Applied spray": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auftragsverteilung"])},
        "Movement chart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bewegungsdiagramm"])},
        "Applied spray animation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Animation"])},
        "Current Settings Coated Area": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktuelle Einstellungen Beschichtete Fläche"])},
        "Optimal Settings Coated Area": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optimale Einstellungen Beschichtete Fläche"])},
        "Current Settings Gun Movement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktuelle Pistolenbewegung"])},
        "Optimal Settings Gun Movement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optimale Pistolenbewegung"])},
        "Current Settings Coated Area": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktuelle Auftragsverteilung"])},
        "Optimal Settings Coated Area": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optimale Auftragsverteilung"])},
        "Comments section for the generated report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kommentarbereich für den generierten Bericht"])},
        "Hide Optimal Settings in the report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optimale Einstellungen im Bericht ausblenden"])},
        "Save Edited Comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bearbeitete Kommentare speichern"])},
        "Generate Report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bericht generieren"])},
        "Generating optimization report...": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generierung von Optimierungsberichten..."])},
        "To use a different optimization type create a new Optimization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Um einen anderen Optimierungstyp zu verwenden, erstellen Sie eine neue Optimierung"])},
        "If you want to use a different set of Powder Models, create a new Optimization.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wenn Sie einen anderen Satz von Pulvermodellen verwenden möchten, erstellen Sie eine neue Optimierung."])},
        "Select Specific Powder optimization to use one or more powder models to compute the optimal settings. Select the Generalistic Optimization option to get a powder agnostic optimization solution.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wählen Sie Spezifische Pulveroptimierung, um ein oder mehrere Sprühbilder zur Berechnung der optimalen Einstellungen zu verwenden. Wählen Sie die Option Generalistische Optimierung, um eine pulverunabhängige Optimierungslösung zu erhalten."])},
        "Select the Powder Models to be used during the computation of optimal settings.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wählen Sie die Sprühbilder aus, die bei der Berechnung der optimalen Einstellungen verwendet werden sollen."])},
        "Line velocity value in [meters/minute] units.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wert der Kettengeschwindigkeit in der Einheit [Meter/Minute]."])},
        "Distance from the gun nozzle to the substrate.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abstand von der Pistolendüse zum Substrat."])},
        "Vertical height for which the uniformity will be optimized.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vertikale Höhe, für die die Gleichmäßigkeit optimiert werden soll."])},
        "Only relevant for visualization purposes, determines the horizontal width, of the simulation image shown on the results section.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nur für die Visualisierung relevant, bestimmt die horizontale Breite des Simulationsbildes, das im Ergebnisbereich angezeigt wird."])},
        "Fill this section with your Gun Settings to visualize how they compare with the optimal settings.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Füllen Sie diesen Abschnitt mit Ihren Pistoleneinstellungen, um zu sehen, wie sie im Vergleich zu den optimalen Einstellungen aussehen."])},
        "Clear current settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktuelle Einstellungen löschen"])},
        "Number of active guns on the first column of guns. The minimum is 0 and the maximum is": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anzahl der aktiven Pistolen in der ersten Spalte der Pistolen. Der Mindestwert ist 0 und der Höchstwert ist"])},
        "Number of active guns on the second column of guns. The minimum is 0 and the maximum is": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anzahl der aktiven Pistolen in der zweiten Pistolenspalte. Der Mindestwert ist 0 und der Höchstwert ist"])},
        "Number of active guns on the third column of guns. The minimum is 0 and the maximum is": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anzahl der aktiven Pistolen in der dritten Pistolenspalte. Der Minimalwert ist 0 und der Maximalwert ist"])},
        "Number of active guns on the fourth column of guns. The minimum is 0 and the maximum is": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anzahl der aktiven Pistolen in der vierten Pistolenspalte. Der Minimalwert ist 0 und der Maximalwert ist"])},
        "Number of active guns on the fifth column of guns. The minimum is 0 and the maximum is": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anzahl der aktiven Pistolen in der fünften Pistolenspalte. Der Minimalwert ist 0 und der Maximalwert ist"])},
        "Number of active guns on one side of the booth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anzahl der aktiven Pistolen auf einer Seite der Kabine"])},
        "Activate Toggle to visualize simulation images": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktivieren Sie Toggle, um Simulationsbilder zu visualisieren"])},
        "low": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["niedrig"])},
        "medium": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mittel"])},
        "high": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hoch"])},
        "Overspray penalization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestrafung von Overspray"])},
        "Nozzle angle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Düsenwinkel"])},
        "Optimize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optimieren"])},
        "Nozzle rotation angle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Düsenrotationswinkel"])},
        "degrees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grad"])},
        "Optimal Settings Nozzle Angle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optimale Einstellungen Düsenwinkel"])},
        "Current Settings Nozzle Angle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktuelle Einstellungen Düsenwinkel"])},
        "Selection of optimal setting in terms of powder waste penalization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auswahl der optimalen Einstellung in Bezug auf die Bestrafung von Pulverabfällen"])},
        "High (prioritize reducing overspray waste)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hoch (Priorität bei der Reduzierung von Overspray-Abfällen)"])},
        "Medium (balanced uniformity & overspray)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mittel (ausgewogene Gleichmäßigkeit & Overspray)"])},
        "Low (prioritize for uniformity)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niedrig (Priorität für Gleichmäßigkeit)"])},
        "Unique optimal solution found.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einzigartige optimale Lösung gefunden."])},
        "Overspray vs. uniformity trade-off": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abwägung Overspray vs. Gleichmäßigkeit"])},
        "Selection of optimal setting in terms of uniformity vs. powder waste. Select an option according to your powder recycling capabilities and uniformity needs.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auswahl der optimalen Einstellung in Bezug auf Gleichmäßigkeit und Pulverabfall. Wählen Sie eine Option entsprechend Ihren Pulverrecyclingfähigkeiten und Gleichförmigkeitsanforderungen."])},
        "Maximize uniformity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maximieren Sie die Gleichmäßigkeit"])},
        "Minimize overspray": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Overspray minimieren"])},
        "Balance overspray & uniformity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gleichgewicht zwischen Overspray und Gleichförmigkeit"])},
        "guns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pistolen"])},
        "Powder Usage Pie Chart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kreisdiagramm zum Pulververbrauch"])},
        "Optimal Settings Pie Chart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kuchendiagramm Optimale Einstellungen"])},
        "Current Settings Pie Chart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kuchendiagramm Optimale Einstellungen"])},
        "Powder Waste": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pulverabfall"])},
        "Calibrate Powder Amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pulvermenge kalibrieren"])},
        "Powder Amount Calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kalibrierung der Pulvermenge"])},
        "Please select a reference gun": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte wählen Sie eine Referenzpistole"])},
        "Powder Amount Parameter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pulvermengenparameter"])},
        "Gun": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pistole"])},
        "Expected throughput": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erwartete Durchsatzrate"])},
        "Activate Toggle to open the pistol powder amount calibration panel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktivieren Sie den Umschalter, um das Kalibrierungsfenster für die Pulvermenge zu öffnen"])},
        "Please select a powder amount calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte wählen Sie eine Pulvermengenkalibrierung"])},
        "Calibration Out of Range": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kalibrierung außerhalb des Bereichs"])},
        "The required settings to obtain this throughput are out of range for gun": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die erforderlichen Einstellungen zur Erzielung dieses Durchsatzes liegen außerhalb des Bereichs für die Pistole"])},
        "Obtained Parameter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erhaltener Parameter"])},
        "Invalid Powder Amount Parameter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ungültiger Pulvermengenparameter"])},
        "The powder amount parameter must be between the minimum and maximum values defined in the calibration.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Pulvermengenparameter muss zwischen den im Kalibrierung definierten Minimal- und Maximalwerten liegen."])},
        "The expected throughput must be greater than 0.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der erwartete Durchsatz muss größer als 0 sein."])},
        "Invalid Expected Throughput": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ungültiger erwarteter Durchsatz"])},
        "Time it takes for one gun to move from the lowest point to the highest point and back.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zeit, die eine Pistole benötigt, um vom niedrigsten Punkt zum höchsten Punkt und zurück zu fahren."])},
        "Distance between highest and lowest point of one moving gun": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abstand zwischen höchstem und niedrigstem Punkt einer beweglichen Pistole"])},
        "Speed at which the gun moves": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geschwindigkeit, mit der die Pistole sich bewegt"])},
        "Share Optimization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optimierungslink teilen"])},
        "Sharable link:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teilbarer Link:"])},
        "Copied to Clipboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In die Zwischenablage kopiert"])},
        "Copy to Clipboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In die Zwischenablage kopieren"])}
      },
      "ES": {
        "": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
        "To allow a greater covered height, increase the gun movement range of the Line.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Para permitir una mayor altura cubierta, aumente el máximo rango de movimiento de las pistolas."])},
        "Animation generation unsuccessful": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La animación no se ha podido generar"])},
        "Applied powder cannot be visualized as an animation for this line and parameters. Optimal settings and results images are still available in the results section.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El polvo aplicado no puede visualizarse como animación para esta línea y parámetros. Los ajustes óptimos y las imágenes de los resultados siguen estando disponibles en la sección de resultados."])},
        "Overcoated powder:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Overcoated powder:"])},
        "Oversprayed powder:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oversprayed powder:"])},
        "The amount of extra powder applied because of an uneven powder distribution on the substrate.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["flujo de pintura adicional aplicada debido a una distribución desigual del polvo sobre el sustrato."])},
        "The powder that does not stick to the part and is then not reclaimed by the recycling system.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El polvo que no se adhiere a la pieza y que no es recuperado por el sistema de reciclado."])},
        "Coated height must be smaller than the maximum mechanical range covered by the guns, of ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La altura cubierta por la optimización debe ser menor que el máximo alcance mecánico de las pistolas, de "])},
        "Gun movement range cannot be greater than the maximum possible mechanical range of the line:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El rango del movimiento de las pistolas no puede ser superior al máximo alcance mecánico de la línea:"])},
        "Loading optimization type...": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cargando tipo de optimización..."])},
        "Clear & start new optimization for line": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Limpiar todo y empezar una nueva optimización"])},
        "Please fill the following fields: ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rellene los siguientes campos: "])},
        "Line speed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Velocidad de la línea"])},
        "Coated height": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Altura cubierta"])},
        "Coated width": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anchura cubierta"])},
        "Powder model": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modelo de polvo"])},
        "Specific Powder Optimization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optimización para polvos específicos"])},
        "Multi-Powder Optimization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optimización Multi-Polvo"])},
        "Input value out of range": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valor fuera de rango"])},
        "was saved successfully.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["se ha guardado correctamente."])},
        "Missing Required Fields": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Faltan campos obligatorios"])},
        "Gun movement range cannot be 0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El rango de movimiento de las pistolas no puede ser 0"])},
        "Line speed cannot be 0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La velocidad de la línea no puede ser 0"])},
        "Line speed must be greater than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La velocidad de la línea debe ser mayor que"])},
        "Line speed must be less than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La velocidad de la línea debe ser menor que"])},
        "Covered height must be greater than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La altura cubierta por la optimización debe ser mayor que"])},
        "Simulation visualization coated width must be greater than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El ancho de la visualización de la simulación debe ser mayor que"])},
        "Gun movement speed cannot be slower than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La velocidad de las pistolas no puede ser inferior a"])},
        "Use a faster guns velocity or a different movement range & period time combination.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utiliza una velocidad de las pistolas más rápida o una combinación de rango de movimiento y periodo."])},
        "Gun movement speed cannot be faster than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La velocidad de movimiento de las pistolas no puede ser superior a"])},
        "Use a slower guns velocity or a different movement range & period time combination.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utiliza una velocidad de las pistolas más lenta o una combinación diferente de rango de movimiento y periodo."])},
        "Gun movement period cannot be longer than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El periodo del movimiento de la pistola no puede ser mayor que"])},
        "Use a shorter guns period or a different movement range & period time combination.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utiliza un periodo de movimiento de las pistolas más corto o una combinación diferente de rango de movimiento y periodo."])},
        "Gun movement period must be greater than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El periodo del movimiento de las pistolas debe ser mayor que"])},
        "No fields were changed from the previous optimization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se ha modificado ningún campo de la optimización."])},
        "Do you want to recompute it anyway?": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Desea volver a calcular de todos modos?"])},
        "Cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancelar"])},
        "Yes, recompute it!": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sí, ¡recalcúla la optimización!"])},
        "OK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OK"])},
        "Active guns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numero de Pistolas activas"])},
        "Active guns per column": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pistolas activas por columna"])},
        "Gun movement range": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rango de movimiento de las pistolas"])},
        "Gun Movement Speed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Velocidad de movimiento de las pistolas"])},
        "Gun Movement Period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Periodo de movimiento de las pistolas"])},
        "Save Optimization to Library": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guardadar optimización en la biblioteca"])},
        "Optimization Saved to Library": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optimización guardada en la biblioteca"])},
        "optional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["opcional"])},
        "Optimization Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre de la optimización"])},
        "New Optimization for line": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nueva optimización para la línea"])},
        "Status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estado"])},
        "Optimum found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configuración Óptima encontrada"])},
        "Ready to find optimum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Listo para encontrar Configuración Óptima"])},
        "Optimization details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detalles de la optimización"])},
        "Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre"])},
        "Line": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Línea"])},
        "Optimization type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo de optimización"])},
        "Select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleccione"])},
        "Powder Models": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modelos de polvo"])},
        "Calibrated Powder Optimization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optimización con un polvo calibrado"])},
        "Pre-Calibrated Powder Optimization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optimización con un polvo precalibrado"])},
        "Generalistic Optimization for any powder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optimización generalista para cualquier polvo"])},
        "Select Powder Model": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleccionar Modelo de Polvo"])},
        "No Powder Models Created. To create one go to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se han creado modelos de polvo. Para crear uno vaya a"])},
        "Optimization parameters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parámetros de la optimización"])},
        "Line Speed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Velocidad de la línea"])},
        "Distance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distancia"])},
        "Covered height": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Altura cubierta"])},
        "Simulation width": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anchura de la simulación"])},
        "Continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continuar"])},
        "Compare with Current Settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comparar con la configuración actual"])},
        "Number of active guns 1st column": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de pistolas activas 1ª columna"])},
        "Please select one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleccione una"])},
        "Number of active guns 2nd column": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de pistolas activas 2ª columna"])},
        "Number of active guns 3rd column": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de pistolas activas 3ª columna"])},
        "Number of active guns 4th column": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de pistolas activas 4ª columna"])},
        "Number of active guns 5th column": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de pistolas activas 5ª columna"])},
        "Number of active guns 6th column": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de pistolas activas 6ª columna"])},
        "Number of active guns 7th column": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de pistolas activas 7ª columna"])},
        "Active guns on one side": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pistolas activas en un lado de la cabina"])},
        "Gun movement range": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rango de movimiento de las pistolas"])},
        "Gun movement period time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Periodo del movimiento de las pistolas"])},
        "Gun movement speed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Velocidad de movimiento de las pistolas"])},
        "Compute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calcular"])},
        "Optimal machine settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajustes óptimos de la máquina"])},
        "Simulation results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resultados de la simulación"])},
        "Current Settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configuración actual"])},
        "Uniformity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uniformidad"])},
        "Overspray": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sobrepulverización"])},
        "Optimal Settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajustes óptimos"])},
        "Uniformity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uniformidad"])},
        "Visualize graphs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visualizar gráficos"])},
        "Calculation running": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cálculo en proceso"])},
        "Uniformity comparison": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comparación de la uniformidad"])},
        "Applied spray": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pintura aplicada"])},
        "Movement chart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gráfico del movimiento"])},
        "Applied spray animation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Animación de la pintura aplicada"])},
        "Current Settings Coated Area": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configuración actual Área recubierta"])},
        "Optimal Settings Coated Area": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajustes óptimos Área recubierta"])},
        "Current Settings Gun Movement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configuración actual Movimiento de las pistolas"])},
        "Optimal Settings Gun Movement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajustes óptimos Movimiento de las pistolas"])},
        "Current Settings Coated Area": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configuración actual Área recubierta"])},
        "Optimal Settings Coated Area": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajustes óptimos Área recubierta"])},
        "Comments section for the generated report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sección de comentarios libres para el informe"])},
        "Hide Optimal Settings in the report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ocultar los ajustes óptimos en el informe"])},
        "Save Edited Comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guardar comentarios editados"])},
        "Generate Report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generar informe"])},
        "Generating optimization report...": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generando informe de la optimización..."])},
        "To use a different optimization type create a new Optimization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Para utilizar un tipo de optimización diferente cree una nueva Optimización"])},
        "If you want to use a different set of Powder Models, create a new Optimization.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si desea utilizar un conjunto diferente de Modelos de polvo, cree una nueva Optimización."])},
        "Select Specific Powder optimization to use one or more powder models to compute the optimal settings. Select the Generalistic Optimization option to get a powder agnostic optimization solution.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleccione Optimización específica de polvo para utilizar uno o más modelos de polvo para calcular los ajustes óptimos. Seleccione la opción Optimización generalista para obtener una solución de optimización agnóstica al polvo."])},
        "Select the Powder Models to be used during the computation of optimal settings.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleccione los Modelos de polvo que se utilizarán durante el cálculo de los ajustes óptimos."])},
        "Line velocity value in [meters/minute] units.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valor de velocidad de la línea en unidades [metros/minuto]."])},
        "Distance from the gun nozzle to the substrate.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distancia de la boquilla de la pistola al sustrato."])},
        "Vertical height for which the uniformity will be optimized.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Altura vertical para la que se optimizará la uniformidad."])},
        "Only relevant for visualization purposes, determines the horizontal width, of the simulation image shown on the results section.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sólo relevante a efectos de visualización, determina la anchura de las imagenes de la simulación mostradas en la sección de resultados."])},
        "Fill this section with your Gun Settings to visualize how they compare with the optimal settings.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rellene esta sección con sus ajustes actuales para visualizar la comparación con los ajustes óptimos."])},
        "Clear current settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Borrar estos ajustes actuales"])},
        "Number of active guns on the first column of guns. The minimum is 0 and the maximum is": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de pistolas activas en la primera columna. El mínimo es 0 y el máximo es"])},
        "Number of active guns on the second column of guns. The minimum is 0 and the maximum is": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de pistolas activas en la segunda columna. El mínimo es 0 y el máximo es"])},
        "Number of active guns on the third column of guns. The minimum is 0 and the maximum is": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de pistolas activas en la tercera columna. El mínimo es 0 y el máximo es"])},
        "Number of active guns on the fourth column of guns. The minimum is 0 and the maximum is": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de pistolas activas en la cuarta columna. El mínimo es 0 y el máximo es"])},
        "Number of active guns on the fifth column of guns. The minimum is 0 and the maximum is": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de pistolas activas en la quinta columna. El mínimo es 0 y el máximo es"])},
        "Number of active guns on one side of the booth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de pistolas activas en un lado de la cabina"])},
        "Activate Toggle to visualize simulation images": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activar para visualizar las imágenes de la simulación"])},
        "low": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bajo"])},
        "medium": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["medio"])},
        "high": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["alto"])},
        "Overspray penalization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Penalización a la sobrepulverización (polvo desperdiciado)"])},
        "Nozzle angle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ángulo de la boquilla"])},
        "Optimize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optimizar"])},
        "Nozzle rotation angle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ángulo de rotación de la boquilla"])},
        "degrees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["grados"])},
        "Optimal Settings Nozzle Angle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajustes óptimos Ángulo de la boquilla"])},
        "Current Settings Nozzle Angle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configuración actual Ángulo de la boquilla"])},
        "Selection of optimal setting in terms of powder waste penalization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selección de la configuración óptima en términos de penalización de desperdicio de polvo"])},
        "High (prioritize reducing overspray waste)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alta (priorizar la reducción del polvo desperdiciado)"])},
        "Medium (balanced uniformity & overspray)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Media (balance medio entre uniformidad y sobrepulverización)"])},
        "Low (prioritize for uniformity)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Baja (priorizar la uniformidad)"])},
        "Unique optimal solution found.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se ha encontrado una única configuración óptima."])},
        "Overspray vs. uniformity trade-off": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sobrepulverización vs. uniformidad"])},
        "Selection of optimal setting in terms of uniformity vs. powder waste. Select an option according to your powder recycling capabilities and uniformity needs.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selección del ajuste óptimo en términos de uniformidad frente a desperdicio de polvo. Seleccione una opción en función de su capacidad de reciclaje de polvo y de sus necesidades de uniformidad."])},
        "Maximize uniformity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maximizar la uniformidad"])},
        "Minimize overspray": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minimizar la sobrepulverización (deperdicio de polvo)"])},
        "Balance overspray & uniformity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Equilibrio entre sobrepulverización y uniformidad"])},
        "guns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pistolas"])},
        "Powder Usage Pie Chart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grafico del uso de la pintura"])},
        "Optimal Settings Pie Chart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configuración óptima"])},
        "Current Settings Pie Chart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configuración actual"])},
        "Powder Waste": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desperdicio de polvo"])},
        "Calibrate Powder Amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calibrar flujo de pintura"])},
        "Powder Amount Calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calibración de flujo de pintura"])},
        "Please select a reference gun": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por favor, seleccione una pistola de referencia"])},
        "Powder Amount Parameter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parámetro de flujo de pintura"])},
        "Gun": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pistola"])},
        "Expected throughput": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Flujo esperado"])},
        "Activate Toggle to open the pistol powder amount calibration panel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active el interruptor para abrir el panel de calibración de flujo de pintura de la pistola"])},
        "Please select a powder amount calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por favor, seleccione una calibración de flujo de pintura"])},
        "Calibration Out of Range": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calibración fuera de rango"])},
        "The required settings to obtain this throughput are out of range for gun": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Los ajustes necesarios para obtener este flujo están fuera de rango para la pistola"])},
        "Obtained Parameter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parámetro obtenido"])},
        "Invalid Powder Amount Parameter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parámetro de flujo de pintura no válido"])},
        "The powder amount parameter must be between the minimum and maximum values defined in the calibration.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El parámetro de flujo de pintura debe estar entre los valores mínimo y máximo definidos en la calibración."])},
        "The expected throughput must be greater than 0.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El flujo esperado debe ser mayor que 0."])},
        "Invalid Expected Throughput": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Flujo esperado no válido"])},
        "Time it takes for one gun to move from the lowest point to the highest point and back.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tiempo que tarda una pistola en moverse desde el punto más bajo hasta el punto más alto y volver."])},
        "Distance between highest and lowest point of one moving gun": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distancia entre el punto más alto y el punto más bajo de una pistola en movimiento"])},
        "Speed at which the gun moves": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Velocidad a la que se mueve la pistola"])},
        "Share Optimization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compartir enlace de la optimización"])},
        "Sharable link:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enlace para compartir:"])},
        "Copied to Clipboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copiado al portapapeles"])},
        "Copy to Clipboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copiar al portapapeles"])}
      },
      "FR": {
        "": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
        "To allow a greater covered height, increase the gun movement range of the Line.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pour permettre une hauteur de revêtement plus grande, augmentez la portée de mouvement des pistolets de la ligne."])},
        "Animation generation unsuccessful": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La génération d'animation n'a pas abouti"])},
        "Applied powder cannot be visualized as an animation for this line and parameters. Optimal settings and results images are still available in the results section.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La poudre appliquée ne peut pas être visualisée sous forme d'animation pour cette ligne et ces paramètres. Les réglages optimaux et les images des résultats sont toujours disponibles dans la section des résultats."])},
        "Overcoated powder:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Overcoated powder:"])},
        "Oversprayed powder:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oversprayed powder:"])},
        "The amount of extra powder applied because of an uneven powder distribution on the substrate.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La quantité de poudre supplémentaire appliquée en raison d'une distribution inégale de la poudre sur le substrat."])},
        "The powder that does not stick to the part and is then not reclaimed by the recycling system.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La poudre qui n'adhère pas à la pièce et qui n'est donc pas récupérée par le système de recyclage."])},
        "Coated height must be smaller than the maximum mechanical range covered by the guns, of ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La hauteur revêtue doit être inférieure à la portée mécanique maximale couverte par les pistolets, de "])},
        "Gun movement range cannot be greater than the maximum possible mechanical range of the line:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La portée de mouvement des pistolets ne peut être supérieure à la portée mécanique maximale possible de la ligne:"])},
        "Loading optimization type...": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chargement du type d'optimisation..."])},
        "Clear & start new optimization for line": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Effacer et commencer une nouvelle optimisation de la ligne"])},
        "Please fill the following fields: ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez remplir les champs suivants: "])},
        "Line speed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vitesse de la ligne"])},
        "Coated height": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hauteur du revêtement"])},
        "Coated width": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Largeur du revêtement"])},
        "Powder model": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modèle de poudre"])},
        "Specific Powder Optimization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optimisation des poudres spécifiques"])},
        "Multi-Powder Optimization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optimisation multi-poudres"])},
        "Input value out of range": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valeur d'entrée en dehors de la plage"])},
        "was saved successfully.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["a été enregistrée avec succès."])},
        "Missing Required Fields": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Champs obligatoires manquants"])},
        "Gun movement range cannot be 0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La portée des mouvements des pistolets ne peut pas être égale à 0"])},
        "Line speed cannot be 0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La vitesse de la ligne ne peut pas être égale à 0"])},
        "Line speed must be greater than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La vitesse de la ligne doit être supérieure à"])},
        "Line speed must be less than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La vitesse de la ligne doit être inférieure à"])},
        "Covered height must be greater than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La hauteur du revêtement doit être supérieure à"])},
        "Simulation visualization coated width must be greater than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La largeur du revêtement de la visualisation de simulation doit être supérieure à"])},
        "Gun movement speed cannot be slower than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La vitesse de mouvement des pistolets ne peut pas être inférieure à"])},
        "Use a faster guns velocity or a different movement range & period time combination.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utiliser une vitesse de pistolet plus rapide ou une combinaison différente de portée de mouvement et de durée."])},
        "Gun movement speed cannot be faster than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La vitesse de mouvement des pistolets ne peut pas être supérieure à"])},
        "Use a slower guns velocity or a different movement range & period time combination.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilisez une vitesse de pistolet plus lente ou une combinaison différente de plage de mouvement et de durée de période."])},
        "Gun movement period cannot be longer than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La période de mouvement des pistolets ne peut pas être plus longue que"])},
        "Use a shorter guns period or a different movement range & period time combination.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilisez une période de mouvement du pistolet plus courte ou une combinaison différente d'amplitude de mouvement et de durée de période."])},
        "Gun movement period must be greater than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La période de mouvement des pistolets doit être supérieure à"])},
        "No fields were changed from the previous optimization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun champ n'a été modifié par rapport à l'optimisation précédente"])},
        "Do you want to recompute it anyway?": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voulez-vous quand même recalculer l'optimisation ?"])},
        "Cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuler"])},
        "Yes, recompute it!": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oui, recalculer !"])},
        "OK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OK"])},
        "Active guns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pistolets actifs"])},
        "Active guns per column": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pistolets actifs par colonne"])},
        "Gun movement range": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portée de mouvement des pistolets"])},
        "Gun Movement Speed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vitesse de mouvement du pistolet"])},
        "Gun Movement Period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Période de mouvement du pistolet"])},
        "Save Optimization to Library": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sauvegarde de l'optimisation dans la bibliothèque"])},
        "Optimization Saved to Library": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optimisation sauvegardée dans la bibliothèque"])},
        "optional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["facultatif"])},
        "Optimization Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom de l'optimisation"])},
        "New Optimization for line": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouvelle optimisation pour la ligne"])},
        "Status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statut"])},
        "Optimum found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optimum trouvé"])},
        "Ready to find optimum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prêt à trouver l'optimum"])},
        "Optimization details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Détails de l'optimisation"])},
        "Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom de la ligne"])},
        "Line": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ligne"])},
        "Optimization type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type d'optimisation"])},
        "Select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionner"])},
        "Powder Models": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modèles de poudres"])},
        "Calibrated Powder Optimization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optimisation de la poudre calibrée"])},
        "Pre-Calibrated Powder Optimization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optimisation des poudres pré-calibrées"])},
        "Generalistic Optimization for any powder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optimisation généraliste pour n'importe quelle poudre"])},
        "Select Powder Model": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionner un modèle de poudre"])},
        "No Powder Models Created. To create one go to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun modèle de poudre n'a été créé. Pour en créer un, allez à"])},
        "Optimization parameters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paramètres d'optimisation"])},
        "Line Speed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vitesse de la ligne"])},
        "Distance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distance"])},
        "Covered height": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hauteur couverte"])},
        "Simulation width": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Largeur de la simulation"])},
        "Continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continuer"])},
        "Compare with Current Settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comparer avec les paramètres actuels"])},
        "Number of active guns 1st column": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre de pistolets actifs 1ère colonne"])},
        "Please select one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez sélectionner un"])},
        "Number of active guns 2nd column": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre de pistolets actifs 2ème colonne"])},
        "Number of active guns 3rd column": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre de pistolets actifs 3ème colonne"])},
        "Number of active guns 4th column": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre de pistolets actifs 4ème colonne"])},
        "Number of active guns 5th column": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre de pistolets actifs 5ème colonne"])},
        "Number of active guns 6th column": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre de pistolets actifs 6ème colonne"])},
        "Number of active guns 7th column": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre de pistolets actifs 7ème colonne"])},
        "Active guns on one side": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pistolets actifs sur un côté"])},
        "Gun movement range": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portée du mouvement des pistolets"])},
        "Gun movement period time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durée de la période de mouvement des pistolets"])},
        "Gun movement speed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vitesse de mouvement des pistolets"])},
        "Compute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calculer"])},
        "Optimal machine settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paramètres optimaux de la machine"])},
        "Simulation results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Résultats de la simulation"])},
        "Current Settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réglages actuels"])},
        "Uniformity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uniformité"])},
        "Overspray": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Surpulvérisation"])},
        "Optimal Settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réglages optimaux"])},
        "Uniformity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uniformité"])},
        "Visualize graphs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visualiser les graphiques"])},
        "Calculation running": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calculs en cours"])},
        "Uniformity comparison": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comparaison de l'uniformité"])},
        "Applied spray": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pulvérisation appliquée"])},
        "Movement chart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Graphique de mouvement"])},
        "Applied spray animation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Animation de la pulvérisation appliquée"])},
        "Current Settings Coated Area": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réglages actuels Surface enrobée"])},
        "Optimal Settings Coated Area": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réglages optimaux Surface enrobée"])},
        "Current Settings Gun Movement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réglages actuels Mouvement des pistolets"])},
        "Optimal Settings Gun Movement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réglages optimaux Mouvement des pistolets"])},
        "Current Settings Coated Area": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réglages actuels de la zone enduite"])},
        "Optimal Settings Coated Area": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réglages optimaux de la zone enduite"])},
        "Comments section for the generated report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Section des commentaires pour le rapport généré"])},
        "Hide Optimal Settings in the report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cacher les paramètres optimaux dans le rapport"])},
        "Save Edited Comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sauvegarder les commentaires édités"])},
        "Generate Report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Générer le rapport"])},
        "Generating optimization report...": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Générer un rapport d'optimisation..."])},
        "To use a different optimization type create a new Optimization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pour utiliser un type d'optimisation différent, créez une nouvelle optimisation."])},
        "If you want to use a different set of Powder Models, create a new Optimization.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si vous souhaitez utiliser un ensemble différent de modèles de poudre, créez une nouvelle optimisation."])},
        "Select Specific Powder optimization to use one or more powder models to compute the optimal settings. Select the Generalistic Optimization option to get a powder agnostic optimization solution.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionnez l'option Optimisation spécifique à la poudre pour utiliser un ou plusieurs modèles de poudre afin de calculer les paramètres optimaux. Sélectionnez l'option Optimisation généraliste pour obtenir une solution d'optimisation indépendante des poudres."])},
        "Select the Powder Models to be used during the computation of optimal settings.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionnez les modèles de poudre à utiliser lors du calcul des paramètres optimaux."])},
        "Line velocity value in [meters/minute] units.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valeur de la vitesse de la ligne en unités [mètres/minute]."])},
        "Distance from the gun nozzle to the substrate.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distance entre la buse du pistolet et le substrat."])},
        "Vertical height for which the uniformity will be optimized.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hauteur verticale pour laquelle l'uniformité sera optimisée."])},
        "Only relevant for visualization purposes, determines the horizontal width, of the simulation image shown on the results section.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La largeur horizontale de l'image de simulation affichée dans la section des résultats n'est utile qu'à des fins de visualisation."])},
        "Fill this section with your Gun Settings to visualize how they compare with the optimal settings.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remplissez cette section avec les paramètres de vos pistolets pour visualiser leur comparaison avec les paramètres optimaux."])},
        "Clear current settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Effacer les paramètres actuels"])},
        "Number of active guns on the first column of guns. The minimum is 0 and the maximum is": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre de pistolets actifs dans la première colonne de pistolets. Le minimum est 0 et le maximum est"])},
        "Number of active guns on the second column of guns. The minimum is 0 and the maximum is": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre de pistolets actifs dans la deuxième colonne de pistolets. Le minimum est 0 et le maximum est"])},
        "Number of active guns on the third column of guns. The minimum is 0 and the maximum is": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre de pistolets actifs sur la troisième colonne de pistolets. Le minimum est 0 et le maximum est"])},
        "Number of active guns on the fourth column of guns. The minimum is 0 and the maximum is": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre de pistolets actifs sur la quatrième colonne de pistolets. Le minimum est 0 et le maximum est"])},
        "Number of active guns on the fifth column of guns. The minimum is 0 and the maximum is": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre de pistolets actifs sur la cinquième colonne de pistolets. Le minimum est 0 et le maximum est"])},
        "Number of active guns on one side of the booth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre de pistolets actifs sur un côté de la cabine"])},
        "Activate Toggle to visualize simulation images": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activer la bascule pour visualiser les images de simulation"])},
        "Overspray penalization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pénalisation de la surpulvérisation"])},
        "low": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["faible"])},
        "medium": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["moyen"])},
        "high": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["haut"])},
        "Nozzle angle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Angle de la buse"])},
        "Optimize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optimiser"])},
        "Nozzle rotation angle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Angle de rotation de la buse"])},
        "degrees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["degrés"])},
        "Optimal Settings Nozzle Angle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paramètres optimaux Angle de la buse"])},
        "Current Settings Nozzle Angle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paramètres actuels Angle de la buse"])},
        "Selection of optimal setting in terms of powder waste penalization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélection du réglage optimal en termes de pénalisation des déchets de poudre"])},
        "High (prioritize reducing overspray waste)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Haute (priorité à la réduction des déchets de surpulvérisation)"])},
        "Medium (balanced uniformity & overspray)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moyenne (uniformité et surpulvérisation équilibrées)"])},
        "Low (prioritize for uniformity)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Basse (priorité à l'uniformité)"])},
        "Unique optimal solution found.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une solution optimale unique a été trouvée."])},
        "Overspray vs. uniformity trade-off": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Surpulvérisation vs. uniformité"])},
        "Selection of optimal setting in terms of uniformity vs. powder waste. Select an option according to your powder recycling capabilities and uniformity needs.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélection du réglage optimal en termes d'uniformité par rapport à la perte de poudre. Sélectionnez une option en fonction de vos capacités de recyclage de la poudre et de vos besoins en matière d'uniformité."])},
        "Maximize uniformity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maximiser l'uniformité"])},
        "Minimize overspray": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minimiser la surpulvérisation"])},
        "Balance overspray & uniformity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Équilibrer la pulvérisation et l'uniformité"])},
        "guns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pistolets"])},
        "Powder Usage Pie Chart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diagramme à secteurs de l'utilisation de la poudre"])},
        "Optimal Settings Pie Chart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diagramme à secteurs des réglages optimaux"])},
        "Current Settings Pie Chart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diagramme à secteurs des réglages actuels"])},
        "Powder Waste": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Déchets de poudre"])},
        "Calibrate Powder Amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calibrer la quantité de poudre"])},
        "Powder Amount Calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calibration de la quantité de poudre"])},
        "Please select a reference gun": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez sélectionner un pistolet de référence"])},
        "Powder Amount Parameter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paramètre de la quantité de poudre"])},
        "Gun": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pistolet"])},
        "Expected throughput": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Débit attendu"])},
        "Activate Toggle to open the pistol powder amount calibration panel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activer le basculement pour ouvrir le panneau de calibrage de la quantité de poudre du pistolet"])},
        "Please select a powder amount calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez sélectionner un calibrage de la quantité de poudre"])},
        "Calibration Out of Range": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calibrage hors de portée"])},
        "The required settings to obtain this throughput are out of range for gun": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les réglages nécessaires pour obtenir ce débit sont hors de portée pour le pistolet"])},
        "Obtained Parameter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paramètre obtenu"])},
        "Invalid Powder Amount Parameter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paramètre de quantité de poudre non valide"])},
        "The powder amount parameter must be between the minimum and maximum values defined in the calibration.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le paramètre de quantité de poudre doit être compris entre les valeurs minimale et maximale définies dans le calibrage."])},
        "The expected throughput must be greater than 0.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le débit attendu doit être supérieur à 0."])},
        "Invalid Expected Throughput": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Débit attendu non valide"])},
        "Time it takes for one gun to move from the lowest point to the highest point and back.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Temps nécessaire à un pistolet pour se déplacer du point le plus bas au point le plus haut et revenir."])},
        "Distance between highest and lowest point of one moving gun": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distance entre le point le plus haut et le point le plus bas d'un pistolet en mouvement"])},
        "Speed at which the gun moves": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vitesse à laquelle le pistolet se déplace"])},
        "Share Optimization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partager le lien d'optimisation"])},
        "Sharable link:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lien partageable:"])},
        "Copied to Clipboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copié dans le presse-papiers"])},
        "Copy to Clipboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copier dans le presse-papiers"])}
      },
      "IT": {
        "": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
        "To allow a greater covered height, increase the gun movement range of the Line.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Per consentire un'altezza di rivestimento maggiore, aumentare la portata di movimento delle pistole della linea."])},
        "Animation generation unsuccessful": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generazione dell'animazione non riuscita"])},
        "Applied powder cannot be visualized as an animation for this line and parameters. Optimal settings and results images are still available in the results section.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La polvere applicata non può essere visualizzata come animazione per questa linea e per questi parametri. Le impostazioni ottimali e le immagini dei risultati sono comunque disponibili nella sezione dei risultati."])},
        "Overcoated powder:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Overcoated powder:"])},
        "Oversprayed powder:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oversprayed powder:"])},
        "The amount of extra powder applied because of an uneven powder distribution on the substrate.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La quantità di polvere extra applicata a causa di una distribuzione non uniforme della polvere sul substrato."])},
        "The powder that does not stick to the part and is then not reclaimed by the recycling system.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La polvere che non aderisce al pezzo e che non viene recuperata dal sistema di riciclaggio."])},
        "Coated height must be smaller than the maximum mechanical range covered by the guns, of ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'altezza rivestita deve essere inferiore alla massima portata meccanica coperta dalle pistole, di "])},
        "Gun movement range cannot be greater than the maximum possible mechanical range of the line:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La portata di movimento delle pistole non può essere superiore alla portata meccanica massima possibile della linea:"])},
        "Loading optimization type...": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Caricamento del tipo di ottimizzazione..."])},
        "Clear & start new optimization for line": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancella e avvia una nuova ottimizzazione per la linea"])},
        "Please fill the following fields: ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compilare i seguenti campi: "])},
        "Line speed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Velocità della linea"])},
        "Coated height": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Altezza del rivestimento"])},
        "Coated width": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Larghezza del rivestimento"])},
        "Powder model": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modello di polvere"])},
        "Specific Powder Optimization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ottimizzazione di polveri specifiche"])},
        "Multi-Powder Optimization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ottimizzazione di più polveri"])},
        "Input value out of range": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valore di ingresso fuori dall'intervallo"])},
        "was saved successfully.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["è stato salvato con successo."])},
        "Missing Required Fields": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Campi obbligatori mancanti"])},
        "Gun movement range cannot be 0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il raggio di movimento delle pistole non può essere 0"])},
        "Line speed cannot be 0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La velocità della linea non può essere 0"])},
        "Line speed must be greater than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La velocità della linea deve essere maggiore di"])},
        "Line speed must be less than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La velocità della linea deve essere inferiore a"])},
        "Covered height must be greater than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'altezza del rivestimento deve essere maggiore di"])},
        "Simulation visualization coated width must be greater than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La larghezza del rivestimento della visualizzazione di simulazione deve essere maggiore di"])},
        "Gun movement speed cannot be slower than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La velocità di movimento delle pistole non può essere inferiore a"])},
        "Use a faster guns velocity or a different movement range & period time combination.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilizzare una velocità di movimento della pistola più elevata o una diversa combinazione di intervallo di movimento e di tempo."])},
        "Gun movement speed cannot be faster than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La velocità di movimento delle pistole non può essere superiore a"])},
        "Use a slower guns velocity or a different movement range & period time combination.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilizzare una velocità della pistola più lenta o una diversa combinazione di intervallo di movimento e periodo."])},
        "Gun movement period cannot be longer than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il periodo di movimento delle pistole non può essere più lungo di"])},
        "Use a shorter guns period or a different movement range & period time combination.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilizzare un periodo di movimento della pistola più breve o una diversa combinazione di intervallo di movimento e periodo di tempo."])},
        "Gun movement period must be greater than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il periodo di movimento delle pistole deve essere maggiore di"])},
        "No fields were changed from the previous optimization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessun campo è stato modificato rispetto all'ottimizzazione precedente"])},
        "Do you want to recompute it anyway?": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Volete comunque ricalcolarla?"])},
        "Cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annulla"])},
        "Yes, recompute it!": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sì, ricalcolo!"])},
        "OK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OK"])},
        "Active guns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pistole attive"])},
        "Active guns per column": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pistole attive per colonna"])},
        "Gun movement range": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rango di movimento delle pistole"])},
        "Gun Movement Speed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Velocità di movimento della pistola"])},
        "Gun Movement Period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Periodo di movimento della pistola"])},
        "Save Optimization to Library": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salvare l'ottimizzazione nella libreria"])},
        "Optimization Saved to Library": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ottimizzazione salvata nella libreria"])},
        "optional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["opzionale"])},
        "Optimization Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome dell'ottimizzazione"])},
        "New Optimization for line": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nuova ottimizzazione per la linea"])},
        "Status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stato"])},
        "Optimum found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ottimale trovato"])},
        "Ready to find optimum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pronto per l'ottimizzazione"])},
        "Optimization details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dettagli dell'ottimizzazione"])},
        "Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome"])},
        "Line": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Linea"])},
        "Optimization type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo di ottimizzazione"])},
        "Select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selezionare"])},
        "Powder Models": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modelli di polvere"])},
        "Calibrated Powder Optimization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ottimizzazione con modello di polvere calibrato"])},
        "Pre-Calibrated Powder Optimization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ottimizzazione con modello di polvere pre-calibrato"])},
        "Generalistic Optimization for any powder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ottimizzazione generalista per qualsiasi polvere"])},
        "Select Powder Model": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selezionare il modello di polvere"])},
        "No Powder Models Created. To create one go to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non sono stati creati modelli di polvere. Per crearne uno, andare su"])},
        "Optimization parameters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parametri di ottimizzazione"])},
        "Line Speed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Velocità della linea"])},
        "Distance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distanza"])},
        "Covered height": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Altezza coperta"])},
        "Simulation width": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Larghezza di simulazione"])},
        "Continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continua"])},
        "Compare with Current Settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confronta con le impostazioni correnti"])},
        "Number of active guns 1st column": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numero di pistole attive 1º colonna"])},
        "Please select one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selezionare una"])},
        "Number of active guns 2nd column": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numero di pistole attive nella 2º colonna"])},
        "Number of active guns 3rd column": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numero di pistole attive nella 3º colonna"])},
        "Number of active guns 4th column": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numero di pistole attive nella 4º colonna"])},
        "Number of active guns 5th column": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numero di pistole attive nella 5º colonna"])},
        "Number of active guns 6th column": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numero di pistole attive nella 6º colonna"])},
        "Number of active guns 7th column": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numero di pistole attive nella 7º colonna"])},
        "Active guns on one side": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pistole attive su un lato"])},
        "Gun movement range": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rango di movimento delle pistole"])},
        "Gun movement period time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tempo di movimento delle pistole"])},
        "Gun movement speed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Velocità di movimento delle pistole"])},
        "Compute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calcola"])},
        "Optimal machine settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impostazioni ottimali della macchina"])},
        "Simulation results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Risultati della simulazione"])},
        "Current Settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impostazioni attuali"])},
        "Uniformity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uniformità"])},
        "Overspray": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Polvere Persa"])},
        "Optimal Settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impostazioni ottimali"])},
        "Uniformity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uniformità"])},
        "Visualize graphs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visualizzazione dei grafici"])},
        "Calculation running": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esecuzione del calcolo"])},
        "Uniformity comparison": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confronto dell'uniformità"])},
        "Applied spray": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spruzzo applicato"])},
        "Movement chart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grafico del movimento"])},
        "Applied spray animation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Animazione dello spruzzo applicato"])},
        "Current Settings Coated Area": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impostazioni attuali Area rivestita"])},
        "Optimal Settings Coated Area": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impostazioni ottimali Area rivestita"])},
        "Current Settings Gun Movement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impostazioni attuali Movimento delle pistole"])},
        "Optimal Settings Gun Movement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impostazioni ottimali Movimento pistole"])},
        "Current Settings Coated Area": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impostazioni attuali Area rivestita"])},
        "Optimal Settings Coated Area": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impostazioni ottimali Area rivestita"])},
        "Comments section for the generated report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sezione commenti per il rapporto generato"])},
        "Hide Optimal Settings in the report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nascondi le impostazioni ottimali nel rapporto"])},
        "Save Edited Comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salva i commenti modificati"])},
        "Generate Report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Genera rapporto"])},
        "Generating optimization report...": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generazione del rapporto di ottimizzazione..."])},
        "To use a different optimization type create a new Optimization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Per utilizzare un tipo di ottimizzazione diverso, creare una nuova Ottimizzazione"])},
        "If you want to use a different set of Powder Models, create a new Optimization.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se si desidera utilizzare una serie diversa di modelli di polvere, creare una nuova ottimizzazione."])},
        "Select Specific Powder optimization to use one or more powder models to compute the optimal settings. Select the Generalistic Optimization option to get a powder agnostic optimization solution.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selezionare l'ottimizzazione Polvere specifica per utilizzare uno o più modelli di polvere per calcolare le impostazioni ottimali. Selezionare l'opzione Ottimizzazione generalista per ottenere una soluzione di ottimizzazione agnostica della polvere."])},
        "Select the Powder Models to be used during the computation of optimal settings.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selezionare i modelli di polvere da utilizzare durante il calcolo delle impostazioni ottimali."])},
        "Line velocity value in [meters/minute] units.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valore della velocità di linea in unità [metri/minuto]."])},
        "Distance from the gun nozzle to the substrate.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distanza dall'ugello della pistola al substrato."])},
        "Vertical height for which the uniformity will be optimized.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Altezza verticale per la quale verrà ottimizzata l'uniformità."])},
        "Only relevant for visualization purposes, determines the horizontal width, of the simulation image shown on the results section.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rilevante solo ai fini della visualizzazione, determina la larghezza orizzontale dell'immagine di simulazione mostrata nella sezione dei risultati."])},
        "Fill this section with your Gun Settings to visualize how they compare with the optimal settings.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Riempire questa sezione con le impostazioni della pistola per visualizzare il confronto con le impostazioni ottimali."])},
        "Clear current settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancella le impostazioni correnti"])},
        "Number of active guns on the first column of guns. The minimum is 0 and the maximum is": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numero di pistole attive nella prima colonna di pistole. Il minimo è 0 e il massimo è"])},
        "Number of active guns on the second column of guns. The minimum is 0 and the maximum is": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numero di pistole attive nella seconda colonna di pistole. Il minimo è 0 e il massimo è"])},
        "Number of active guns on the third column of guns. The minimum is 0 and the maximum is": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numero di pistole attive nella terza colonna di pistole. Il minimo è 0 e il massimo è"])},
        "Number of active guns on the fourth column of guns. The minimum is 0 and the maximum is": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numero di pistole attive nella quarta colonna di pistole. Il minimo è 0 e il massimo è"])},
        "Number of active guns on the fifth column of guns. The minimum is 0 and the maximum is": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numero di pistole attive nella quinta colonna di pistole. Il minimo è 0 e il massimo è"])},
        "Number of active guns on one side of the booth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numero di pistole attive su un lato della cabina"])},
        "Activate Toggle to visualize simulation images": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attivare la funzione Toggle per visualizzare le immagini della simulazione."])},
        "Overspray penalization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Penalizzazione della polvere persa"])},
        "low": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["basso"])},
        "medium": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["medio"])},
        "high": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["alto"])},
        "Nozzle angle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Angolo dell'ugello"])},
        "Optimize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ottimizzare"])},
        "Nozzle rotation angle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Angolo di rotazione dell'ugello"])},
        "degrees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["gradi"])},
        "Optimal Settings Nozzle Angle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impostazioni ottimali Angolo dell'ugello"])},
        "Current Settings Nozzle Angle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impostazioni correnti Angolo dell'ugello"])},
        "Selection of optimal setting in terms of powder waste penalization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selezione dell'impostazione ottimale in termini di penalizzazione degli sprechi di polvere"])},
        "High (prioritize reducing overspray waste)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alto (priorità alla riduzione degli sprechi di polvere)"])},
        "Medium (balanced uniformity & overspray)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Medio (uniformità e polvere persa bilanciate)"])},
        "Low (prioritize for uniformity)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Basso (priorità all'uniformità)"])},
        "Unique optimal solution found.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trovata unica soluzione ottimale."])},
        "Overspray vs. uniformity trade-off": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confronto tra overspray e uniformità"])},
        "Selection of optimal setting in terms of uniformity vs. powder waste. Select an option according to your powder recycling capabilities and uniformity needs.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selezione dell'impostazione ottimale in termini di uniformità rispetto allo spreco di polvere. Selezionate un'opzione in base alle vostre capacità di riciclaggio della polvere e alle vostre esigenze di uniformità."])},
        "Maximize uniformity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Massimizzare l'uniformità"])},
        "Minimize overspray": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ridurre al minimo l'overspray"])},
        "Balance overspray & uniformity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Equilibrio tra overspray e uniformità"])},
        "guns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pistole"])},
        "Powder Usage Pie Chart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grafico dell'uso della polvere"])},
        "Optimal Settings Pie Chart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grafico a torta delle impostazioni ottimali"])},
        "Current Settings Pie Chart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grafico a torta delle impostazioni correnti"])},
        "Powder Waste": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spreco di polvere"])},
        "Calibrate Powder Amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calibrare la quantità di polvere"])},
        "Powder Amount Calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calibrazione della quantità di polvere"])},
        "Please select a reference gun": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selezionare un pistola di riferimento"])},
        "Powder Amount Parameter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parametro della quantità di polvere"])},
        "Gun": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pistola"])},
        "Expected throughput": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Produzione attesa"])},
        "Activate Toggle to open the pistol powder amount calibration panel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attivare il basculamento per aprire il pannello di calibrazione della quantità di polvere della pistola"])},
        "Please select a powder amount calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selezionare una calibrazione della quantità di polvere"])},
        "Calibration Out of Range": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calibrazione fuori intervallo"])},
        "The required settings to obtain this throughput are out of range for gun": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le impostazioni richieste per ottenere questa produzione sono fuori intervallo per la pistola"])},
        "Obtained Parameter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parametro ottenuto"])},
        "Invalid Powder Amount Parameter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parametro della quantità di polvere non valido"])},
        "The powder amount parameter must be between the minimum and maximum values defined in the calibration.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il parametro della quantità di polvere deve essere compreso tra i valori minimi e massimi definiti nella calibrazione."])},
        "The expected throughput must be greater than 0.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La produzione attesa deve essere maggiore di 0."])},
        "Invalid Expected Throughput": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Produzione attesa non valida"])},
        "Time it takes for one gun to move from the lowest point to the highest point and back.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tempo necessario per una pistola per spostarsi dal punto più basso al punto più alto e tornare."])},
        "Distance between highest and lowest point of one moving gun": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distanza tra il punto più alto e il punto più basso di una pistola in movimento"])},
        "Speed at which the gun moves": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Velocità con cui si muove la pistola"])},
        "Share Optimization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Condividi l'ottimizzazione"])},
        "Sharable link:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link condivisibile:"])},
        "Copy to Clipboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copia negli appunti"])},
        "Copied to Clipboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copiato negli appunti"])}
      },
      "PL": {
        "": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
        "To allow a greater covered height, increase the gun movement range of the Line.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aby umożliwić większą wysokość pokrycia, zwiększ zakres ruchu pistoletów linii."])},
        "Animation generation unsuccessful": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generowanie animacji nie powiodło się"])},
        "Applied powder cannot be visualized as an animation for this line and parameters. Optimal settings and results images are still available in the results section.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zastosowany proszek nie może być wizualizowany jako animacja dla tej linii i parametrów. Optymalne ustawienia i obrazy wyników są nadal dostępne w sekcji wyników."])},
        "Overcoated powder:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Overcoated powder:"])},
        "Oversprayed powder:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oversprayed powder:"])},
        "The amount of extra powder applied because of an uneven powder distribution on the substrate.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ilość dodatkowego proszku nałożonego z powodu nierównomiernego rozprowadzenia proszku na podłożu."])},
        "The powder that does not stick to the part and is then not reclaimed by the recycling system.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Proszek, który nie przylega do części i nie jest odzyskiwany przez system recyklingu."])},
        "Coated height must be smaller than the maximum mechanical range covered by the guns, of ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wysokość powłoki musi być mniejsza niż maksymalny zakres mechaniczny pokryty przez pistolety, z "])},
        "Gun movement range cannot be greater than the maximum possible mechanical range of the line:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zasięg ruchu pistoletów nie może być większy niż maksymalny możliwy zasięg mechaniczny linii:"])},
        "Loading optimization type...": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ładowanie typu optymalizacji..."])},
        "Clear & start new optimization for line": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wyczyść i rozpocznij nową optymalizację dla linii"])},
        "Please fill the following fields: ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wypełnij następujące pola: "])},
        "Line speed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prędkość linii"])},
        "Coated height": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wysokość powłoki"])},
        "Coated width": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Szerokość powłoki"])},
        "Powder model": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Model proszku"])},
        "Specific Powder Optimization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optymalizacja konkretnego proszku"])},
        "Multi-Powder Optimization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optymalizacja wielu proszków"])},
        "Input value out of range": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wartość wejściowa poza zakresem"])},
        "was saved successfully.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["została pomyślnie zapisana."])},
        "Missing Required Fields": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brakujące wymagane pola"])},
        "Gun movement range cannot be 0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zakres ruchu pistoletów nie może wynosić 0"])},
        "Line speed cannot be 0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prędkość liniowa nie może wynosić 0"])},
        "Line speed must be greater than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prędkość liniowa musi być większa niż"])},
        "Line speed must be less than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prędkość linii musi być mniejsza niż"])},
        "Covered height must be greater than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wysokość powłoki musi być większa niż"])},
        "Simulation visualization coated width must be greater than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Szerokość powłoki wizualizacji symulacji musi być większa niż"])},
        "Gun movement speed cannot be slower than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prędkość ruchu pistoletów nie może być mniejsza niż"])},
        "Use a faster guns velocity or a different movement range & period time combination.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Użyj większej prędkości pistoletów lub innej kombinacji zakresu ruchu i czasu."])},
        "Gun movement speed cannot be faster than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prędkość ruchu pistoletów nie może być większa niż"])},
        "Use a slower guns velocity or a different movement range & period time combination.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Użyj wolniejszej prędkości pistoletów lub innej kombinacji zakresu ruchu i czasu."])},
        "Gun movement period cannot be longer than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Okres ruchu pistoletów nie może być dłuższy niż"])},
        "Use a shorter guns period or a different movement range & period time combination.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Użyj krótszego okresu ruchu pistoletów lub innej kombinacji zakresu ruchu i czasu."])},
        "Gun movement period must be greater than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Okres ruchu pistoletów musi być większy niż"])},
        "No fields were changed from the previous optimization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Żadne pola nie zostały zmienione w stosunku do poprzedniej optymalizacji"])},
        "Do you want to recompute it anyway?": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Czy mimo to chcesz przeprowadzić ponowne obliczenia?"])},
        "Cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anuluj"])},
        "Yes, recompute it!": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tak, oblicz ponownie!"])},
        "OK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OK"])},
        "Active guns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktywne pistolety"])},
        "Active guns per column": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktywne pistolety na kolumnę"])},
        "Gun movement range": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zasięg ruchu pistoletu"])},
        "Gun Movement Speed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prędkość ruchu pistoletu"])},
        "Gun Movement Period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Okres ruchu pistoletu"])},
        "Save Optimization to Library": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zapisywanie optymalizacji w bibliotece"])},
        "Optimization Saved to Library": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optymalizacja zapisana w bibliotece"])},
        "optional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["opcjonalny"])},
        "Optimization Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nazwa optymalizacji"])},
        "New Optimization for line": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nowa optymalizacja dla linii"])},
        "Status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
        "Optimum found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Znaleziono optimum"])},
        "Ready to find optimum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gotowy do znalezienia optimum"])},
        "Optimization details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Szczegóły optymalizacji"])},
        "Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nazwa"])},
        "Line": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Linia"])},
        "Optimization type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Typ optymalizacji"])},
        "Select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wybór"])},
        "Powder Models": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modele proszków"])},
        "Calibrated Powder Optimization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skalibrowana optymalizacja proszku"])},
        "Pre-Calibrated Powder Optimization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wstępnie skalibrowana optymalizacja proszku"])},
        "Generalistic Optimization for any powder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optymalizacja ogólna dla dowolnego proszku"])},
        "Select Powder Model": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wybierz model proszku"])},
        "No Powder Models Created. To create one go to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nie utworzono modeli proszku. Aby go utworzyć, przejdź do"])},
        "Optimization parameters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parametry optymalizacji"])},
        "Line Speed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prędkość linii"])},
        "Distance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odległość"])},
        "Covered height": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pokryta wysokość"])},
        "Simulation width": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Szerokość symulacji"])},
        "Continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontynuuj"])},
        "Compare with Current Settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Porównaj z bieżącymi ustawieniami"])},
        "Number of active guns 1st column": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liczba aktywnych pistoletów 1. kolumna"])},
        "Please select one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wybierz jeden"])},
        "Number of active guns 2nd column": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liczba aktywnych pistoletów 2. kolumna"])},
        "Number of active guns 3rd column": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liczba aktywnych pistoletów 3. kolumna"])},
        "Number of active guns 4th column": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liczba aktywnych pistoletów 4. kolumna"])},
        "Number of active guns 5th column": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liczba aktywnych pistoletów 5. kolumna"])},
        "Number of active guns 6th column": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liczba aktywnych pistoletów 6. kolumna"])},
        "Number of active guns 7th column": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liczba aktywnych pistoletów 7. kolumna"])},
        "Active guns on one side": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktywne pistolety po jednej stronie"])},
        "Gun movement range": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zasięg ruchu pistoletów"])},
        "Gun movement period time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Czas ruchu pistoletów"])},
        "Gun movement speed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prędkość ruchu pistoletów"])},
        "Compute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oblicz"])},
        "Optimal machine settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optymalne ustawienia maszyny"])},
        "Simulation results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wyniki symulacji"])},
        "Current Settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bieżące ustawienia"])},
        "Uniformity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jednorodność"])},
        "Overspray": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nadmierny natrysk"])},
        "Optimal Settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optymalne ustawienia"])},
        "Uniformity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jednorodność"])},
        "Visualize graphs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wizualizacja wykresów"])},
        "Calculation running": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uruchamianie obliczeń"])},
        "Uniformity comparison": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Porównanie jednorodności"])},
        "Applied spray": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zastosowany natrysk"])},
        "Movement chart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wykres ruchu"])},
        "Applied spray animation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Animacja natrysku"])},
        "Current Settings Coated Area": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bieżące ustawienia Powlekany obszar"])},
        "Optimal Settings Coated Area": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ustawienia optymalne Obszar powlekany"])},
        "Current Settings Gun Movement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bieżące ustawienia Ruch pistoletów"])},
        "Optimal Settings Gun Movement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optymalne ustawienia ruchu pistoletów"])},
        "Current Settings Coated Area": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bieżące ustawienia Pokryty obszar"])},
        "Optimal Settings Coated Area": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ustawienia optymalne Obszar pokryty"])},
        "Comments section for the generated report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sekcja komentarzy do wygenerowanego raportu"])},
        "Hide Optimal Settings in the report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ukryj ustawienia optymalne w raporcie"])},
        "Save Edited Comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zapisz edytowane komentarze"])},
        "Generate Report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generowanie raportu"])},
        "Generating optimization report...": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generowanie raportów optymalizacji..."])},
        "To use a different optimization type create a new Optimization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aby użyć innego typu optymalizacji, utwórz nową optymalizację"])},
        "If you want to use a different set of Powder Models, create a new Optimization.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jeśli chcesz użyć innego zestawu modeli proszku, utwórz nową optymalizację."])},
        "Select Specific Powder optimization to use one or more powder models to compute the optimal settings. Select the Generalistic Optimization option to get a powder agnostic optimization solution.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wybierz opcję Specific Powder optimization, aby użyć jednego lub więcej modeli proszku do obliczenia optymalnych ustawień. Wybierz opcję Generalistic Optimization, aby uzyskać rozwiązanie optymalizacyjne niezależne od proszku."])},
        "Select the Powder Models to be used during the computation of optimal settings.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wybierz modele proszku, które mają być używane podczas obliczania optymalnych ustawień."])},
        "Line velocity value in [meters/minute] units.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wartość prędkości liniowej w jednostkach [metry/minutę]."])},
        "Distance from the gun nozzle to the substrate.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odległość od dyszy pistoletu do podłoża."])},
        "Vertical height for which the uniformity will be optimized.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wysokość pionowa, dla której zostanie zoptymalizowana jednorodność."])},
        "Only relevant for visualization purposes, determines the horizontal width, of the simulation image shown on the results section.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ma znaczenie tylko dla celów wizualizacji, określa szerokość poziomą obrazu symulacji wyświetlanego w sekcji wyników."])},
        "Fill this section with your Gun Settings to visualize how they compare with the optimal settings.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wypełnij tę sekcję ustawieniami pistoletu, aby zobaczyć, jak wypadają one w porównaniu z ustawieniami optymalnymi."])},
        "Clear current settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wyczyść bieżące ustawienia"])},
        "Number of active guns on the first column of guns. The minimum is 0 and the maximum is": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liczba aktywnych pistoletów w pierwszej kolumnie pistoletów. Minimum to 0, a maksimum to"])},
        "Number of active guns on the second column of guns. The minimum is 0 and the maximum is": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liczba aktywnych pistoletów w drugiej kolumnie pistoletów. Minimalna wartość to 0, a maksymalna to"])},
        "Number of active guns on the third column of guns. The minimum is 0 and the maximum is": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liczba aktywnych pistoletów w trzeciej kolumnie pistoletów. Minimalna wartość to 0, a maksymalna to"])},
        "Number of active guns on the fourth column of guns. The minimum is 0 and the maximum is": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liczba aktywnych pistoletów w czwartej kolumnie pistoletów. Minimalna wartość to 0, a maksymalna to"])},
        "Number of active guns on the fifth column of guns. The minimum is 0 and the maximum is": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liczba aktywnych pistoletów w piątej kolumnie pistoletów. Minimalna wartość to 0, a maksymalna to"])},
        "Number of active guns on one side of the booth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liczba aktywnych pistoletów po jednej stronie kabiny"])},
        "Activate Toggle to visualize simulation images": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktywuj przełącznik, aby wyświetlić obrazy symulacji"])},
        "Overspray penalization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Karanie za nadmierne rozpylanie"])},
        "low": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["niski"])},
        "medium": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["średni"])},
        "high": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["wysoki"])},
        "Nozzle angle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kąt dyszy"])},
        "Optimize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optymalizuj"])},
        "Nozzle rotation angle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kąt obrotu dyszy"])},
        "degrees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["stopnie"])},
        "Optimal Settings Nozzle Angle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optymalne ustawienia kąta dyszy"])},
        "Current Settings Nozzle Angle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bieżące ustawienia kąta dyszy"])},
        "Selection of optimal setting in terms of powder waste penalization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wybór optymalnego ustawienia pod względem penalizacji odpadów proszkowych"])},
        "High (prioritize reducing overspray waste)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wysoki (priorytetem jest zmniejszenie ilości odpadów nadmiernego rozpylania)"])},
        "Medium (balanced uniformity & overspray)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Średni (zrównoważona jednorodność i nadmierne rozpylanie)"])},
        "Low (prioritize for uniformity)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niski (priorytet dla jednolitości)"])},
        "Unique optimal solution found.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Znaleziono unikalne optymalne rozwiązanie."])},
        "Overspray vs. uniformity trade-off": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kompromis między nadmiernym rozpylaniem a jednorodnością"])},
        "Selection of optimal setting in terms of uniformity vs. powder waste. Select an option according to your powder recycling capabilities and uniformity needs.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wybór optymalnego ustawienia pod względem jednorodności i strat proszku. Wybierz opcję zgodnie z możliwościami recyklingu proszku i potrzebami w zakresie jednorodności."])},
        "Maximize uniformity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maksymalizacja jednorodności"])},
        "Minimize overspray": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minimalizacja nadmiernego rozpylania"])},
        "Balance overspray & uniformity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Równowaga między nadmiernym rozpylaniem a jednorodnością"])},
        "guns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pistolety"])},
        "Powder Usage Pie Chart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wykres kołowy zużycia proszku"])},
        "Optimal Settings Pie Chart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wykres kołowy optymalnych ustawień"])},
        "Current Settings Pie Chart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wykres kołowy bieżących ustawień"])},
        "Powder Waste": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odpady proszkowe"])},
        "Calibrate Powder Amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skalibruj ilość proszku"])},
        "Powder Amount Calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kalibracja ilości proszku"])},
        "Please select a reference gun": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wybierz pistolet referencyjny"])},
        "Powder Amount Parameter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parametr ilości proszku"])},
        "Gun": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pistolet"])},
        "Expected throughput": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Przepustowość oczekiwana"])},
        "Activate Toggle to open the pistol powder amount calibration panel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktywuj przełącznik, aby otworzyć panel kalibracji ilości proszku z pistoletu"])},
        "Please select a powder amount calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wybierz kalibrację ilości proszku"])},
        "Calibration Out of Range": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kalibracja poza zakresem"])},
        "The required settings to obtain this throughput are out of range for gun": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wymagane ustawienia do uzyskania tej przepustowości są poza zakresem dla pistoletu"])},
        "Obtained Parameter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parametr uzyskany"])},
        "Invalid Powder Amount Parameter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nieprawidłowy parametr ilości proszku"])},
        "The powder amount parameter must be between the minimum and maximum values defined in the calibration.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parametr ilości proszku musi mieścić się między wartościami minimalną i maksymalną zdefiniowanymi w kalibracji."])},
        "The expected throughput must be greater than 0.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oczekiwana przepustowość musi być większa niż 0."])},
        "Invalid Expected Throughput": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nieprawidłowa oczekiwana przepustowość"])},
        "Time it takes for one gun to move from the lowest point to the highest point and back.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Czas, jaki potrzeba na przesunięcie jednego pistoletu od najniższego punktu do najwyższego i z powrotem."])},
        "Distance between highest and lowest point of one moving gun": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odleg łość między najwyższym i najniższym punktem jednego ruchomego pistoletu"])},
        "Speed at which the gun moves": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prędkość, z jaką porusza się pistolet"])},
        "Share Optimization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Udostępnij optymalizacji"])},
        "Sharable link:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link do udostępnienia:"])},
        "Copy to Clipboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kopiuj do schowka"])},
        "Copied to Clipboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skopiowano do schowka"])}
      },
      "TR": {
        "": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
        "To allow a greater covered height, increase the gun movement range of the Line.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Daha büyük bir kaplanmış yüksekliğe izin vermek için Hat'ın tabanca hareket aralığını artırın."])},
        "Animation generation unsuccessful": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Animasyon üretimi başarısız"])},
        "Applied powder cannot be visualized as an animation for this line and parameters. Optimal settings and results images are still available in the results section.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uygulanan toz, bu hat ve parametreler için animasyon olarak görselleştirilemez. Optimum ayarlar ve sonuç görüntüleri hala sonuçlar bölümünde mevcuttur."])},
        "Overcoated powder:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Overcoated powder:"])},
        "Oversprayed powder:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oversprayed powder:"])},
        "The amount of extra powder applied because of an uneven powder distribution on the substrate.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alt tabaka üzerinde eşit olmayan toz dağılımı nedeniyle uygulanan ekstra toz miktarı."])},
        "The powder that does not stick to the part and is then not reclaimed by the recycling system.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parçaya yapışmayan ve daha sonra geri dönüşüm sistemi tarafından geri kazanılmayan toz."])},
        "Coated height must be smaller than the maximum mechanical range covered by the guns, of ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kaplanmış yükseklik, tabancaların kapsadığı maksimum mekanik menzilden daha küçük olmalıdır: "])},
        "Gun movement range cannot be greater than the maximum possible mechanical range of the line:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tabancaların hareket menzili, hattın mümkün olan maksimum mekanik menzilinden daha büyük olamaz:"])},
        "Loading optimization type...": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optimizasyon türü yükleniyor..."])},
        "Clear & start new optimization for line": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hat için yeni optimizasyonu temizleyin ve başlatın"])},
        "Please fill the following fields: ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lütfen aşağıdaki alanları doldurunuz: "])},
        "Line speed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hat hızı"])},
        "Coated height": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kaplamalı yükseklik"])},
        "Coated width": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kaplamalı genişlik"])},
        "Powder model": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toz modeli"])},
        "Specific Powder Optimization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spesifik Toz Optimizasyonu"])},
        "Multi-Powder Optimization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Çoklu Toz Optimizasyonu"])},
        "Input value out of range": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Giriş değeri aralık dışında"])},
        "was saved successfully.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["başarıyla kaydedildi."])},
        "Missing Required Fields": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eksik Gerekli Alanlar"])},
        "Gun movement range cannot be 0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tabancaların hareket menzili 0 olamaz"])},
        "Line speed cannot be 0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hat hızı 0 olamaz"])},
        "Line speed must be greater than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hat hızı aşağıdakilerden daha yüksek olmalıdır"])},
        "Line speed must be less than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hat hızı aşağıdakilerden daha az olmalıdır"])},
        "Covered height must be greater than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kaplanmış yükseklik aşağıdakilerden daha büyük olmalıdır"])},
        "Simulation visualization coated width must be greater than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Simülasyon görselleştirme kaplamalı genişlik şundan büyük olmalıdır"])},
        "Gun movement speed cannot be slower than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tabancaların hareket hızı aşağıdakilerden daha yavaş olamaz"])},
        "Use a faster guns velocity or a different movement range & period time combination.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Daha hızlı bir tabanca hızı veya farklı bir hareket aralığı ve süre kombinasyonu kullanın."])},
        "Gun movement speed cannot be faster than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tabanca hareket hızı aşağıdakilerden daha hızlı olamaz"])},
        "Use a slower guns velocity or a different movement range & period time combination.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Daha yavaş bir tabanca hızı veya farklı bir hareket aralığı ve süre kombinasyonu kullanın."])},
        "Gun movement period cannot be longer than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tabancanın hareket süresi şundan daha uzun olamaz"])},
        "Use a shorter guns period or a different movement range & period time combination.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Daha kısa bir tabanca süresi veya farklı bir hareket aralığı ve süre kombinasyonu kullanın."])},
        "Gun movement period must be greater than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tabanca hareket süresi aşağıdakilerden daha büyük olmalıdır"])},
        "No fields were changed from the previous optimization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Önceki optimizasyona göre hiçbir alan değiştirilmedi"])},
        "Do you want to recompute it anyway?": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yine de yeniden hesaplamak ister misiniz?"])},
        "Cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İptal et"])},
        "Yes, recompute it!": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evet, yeniden hesaplayın!"])},
        "OK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TAMAM"])},
        "Active guns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktif tabancalar"])},
        "Active guns per column": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sütun başına aktif tabancalar"])},
        "Gun movement range": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tabanca hareket aralığı"])},
        "Gun Movement Speed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tabanca Hareket Hızı"])},
        "Gun Movement Period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tabanca Hareket Dönemi"])},
        "Save Optimization to Library": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optimizasyonu Kütüphaneye Kaydet"])},
        "Optimization Saved to Library": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kütüphaneye Kaydedilen Optimizasyon"])},
        "optional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["isteğe bağlı"])},
        "Optimization Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optimizasyon Adı"])},
        "New Optimization for line": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hat için Yeni Optimizasyon"])},
        "Status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durum"])},
        "Optimum found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optimum bulundu"])},
        "Ready to find optimum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optimum bulmaya hazır"])},
        "Optimization details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optimizasyon detayları"])},
        "Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İsim"])},
        "Line": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hat"])},
        "Optimization type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optimizasyon türü"])},
        "Select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seçiniz"])},
        "Powder Models": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toz Modelleri"])},
        "Calibrated Powder Optimization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kalibre Edilmiş Toz Optimizasyonu"])},
        "Pre-Calibrated Powder Optimization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Önceden Kalibre Edilmiş Toz Optimizasyonu"])},
        "Generalistic Optimization for any powder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Herhangi bir toz için Genelistik Optimizasyon"])},
        "Select Powder Model": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toz Modelini Seçin"])},
        "No Powder Models Created. To create one go to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oluşturulmuş Toz Modeli Yok. Bir tane oluşturmak için şu adrese gidin"])},
        "Optimization parameters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optimizasyon parametreleri"])},
        "Line Speed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hat Hızı"])},
        "Distance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mesafe"])},
        "Covered height": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kapalı yükseklik"])},
        "Simulation width": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Simülasyon genişliği"])},
        "Continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Devam et"])},
        "Compare with Current Settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mevcut Ayarlarla Karşılaştırın"])},
        "Number of active guns 1st column": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktif tabanca sayısı 1. sütun"])},
        "Please select one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lütfen birini seçin"])},
        "Number of active guns 2nd column": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktif tabanca sayısı 2. sütun"])},
        "Number of active guns 3rd column": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktif tabanca sayısı 3. sütun"])},
        "Number of active guns 4th column": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktif tabanca sayısı 4. sütun"])},
        "Number of active guns 5th column": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktif tabanca sayısı 5. sütun"])},
        "Number of active guns 6th column": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktif tabanca sayısı 6. sütun"])},
        "Number of active guns 7th column": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktif tabanca sayısı 7. sütun"])},
        "Active guns on one side": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bir tarafta aktif tabancalar"])},
        "Gun movement range": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tabancaların hareket aralığı"])},
        "Gun movement period time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tabancaların hareket süresi"])},
        "Gun movement speed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tabancaların hareket hızı"])},
        "Compute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hesaplama"])},
        "Optimal machine settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optimum makine ayarları"])},
        "Simulation results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Simülasyon sonuçları"])},
        "Current Settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geçerli Ayarlar"])},
        "Uniformity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tekdüzelik"])},
        "Overspray": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aşırı püskürtme"])},
        "Optimal Settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optimal Ayarlar"])},
        "Uniformity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tekdüzelik"])},
        "Visualize graphs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grafikleri görselleştirin"])},
        "Calculation running": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Çalışan hesaplama"])},
        "Uniformity comparison": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tekdüzelik karşılaştırması"])},
        "Applied spray": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uygulanan sprey"])},
        "Movement chart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hareket tablosu"])},
        "Applied spray animation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uygulamalı sprey animasyonu"])},
        "Current Settings Coated Area": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mevcut Ayarlar Kaplamalı Alan"])},
        "Optimal Settings Coated Area": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optimum Ayarlar Kaplanmış Alan"])},
        "Current Settings Gun Movement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mevcut Ayarlar Tabanca Hareketi"])},
        "Optimal Settings Gun Movement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optimal Ayarlar Tabanca Hareketi"])},
        "Current Settings Coated Area": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mevcut Ayarlar Kaplamalı Alan"])},
        "Optimal Settings Coated Area": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optimum Ayarlar Kaplanmış Alan"])},
        "Comments section for the generated report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oluşturulan rapor için yorumlar bölümü"])},
        "Hide Optimal Settings in the report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Raporda Optimal Ayarları Gizle"])},
        "Save Edited Comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Düzenlenmiş Yorumları Kaydet"])},
        "Generate Report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rapor Oluştur"])},
        "Generating optimization report...": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optimizasyon raporu oluşturma..."])},
        "To use a different optimization type create a new Optimization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Farklı bir optimizasyon türü kullanmak için yeni bir Optimizasyon oluşturun"])},
        "If you want to use a different set of Powder Models, create a new Optimization.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Farklı bir Toz Modeli seti kullanmak istiyorsanız, yeni bir Optimizasyon oluşturun."])},
        "Select Specific Powder optimization to use one or more powder models to compute the optimal settings. Select the Generalistic Optimization option to get a powder agnostic optimization solution.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optimum ayarları hesaplamak üzere bir veya daha fazla toz modeli kullanmak için Spesifik Toz optimizasyonunu seçin. Tozdan bağımsız bir optimizasyon çözümü elde etmek için Genelistik Optimizasyon seçeneğini seçin."])},
        "Select the Powder Models to be used during the computation of optimal settings.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optimum ayarların hesaplanması sırasında kullanılacak Toz Modellerini seçin."])},
        "Line velocity value in [meters/minute] units.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Metre/dakika] biriminde hat hızı değeri."])},
        "Distance from the gun nozzle to the substrate.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tabanca nozülünden alt tabakaya olan mesafe."])},
        "Vertical height for which the uniformity will be optimized.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Homojenliğin optimize edileceği dikey yükseklik."])},
        "Only relevant for visualization purposes, determines the horizontal width, of the simulation image shown on the results section.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sadece görselleştirme amacıyla ilgili olup, sonuçlar bölümünde gösterilen simülasyon görüntüsünün yatay genişliğini belirler."])},
        "Fill this section with your Gun Settings to visualize how they compare with the optimal settings.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optimum ayarlarla nasıl karşılaştırıldıklarını görselleştirmek için bu bölümü Tabanca Ayarlarınızla doldurun."])},
        "Clear current settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geçerli ayarları temizle"])},
        "Number of active guns on the first column of guns. The minimum is 0 and the maximum is": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tabancaların ilk sütunundaki aktif tabanca sayısı. Minimum 0 ve maksimum"])},
        "Number of active guns on the second column of guns. The minimum is 0 and the maximum is": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tabancaların ikinci sütunundaki aktif tabanca sayısı. Minimum 0 ve maksimum"])},
        "Number of active guns on the third column of guns. The minimum is 0 and the maximum is": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tabancaların üçüncü sütunundaki aktif tabanca sayısı. Minimum 0 ve maksimum"])},
        "Number of active guns on the fourth column of guns. The minimum is 0 and the maximum is": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tabancaların dördüncü sütunundaki aktif tabanca sayısı. Minimum 0 ve maksimum"])},
        "Number of active guns on the fifth column of guns. The minimum is 0 and the maximum is": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tabancaların beşinci sütunundaki aktif tabanca sayısı. Minimum 0 ve maksimum"])},
        "Number of active guns on one side of the booth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kabinin bir tarafındaki aktif tabanca sayısı"])},
        "Activate Toggle to visualize simulation images": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Simülasyon görüntülerini görselleştirmek için Toggle özelliğini etkinleştirin"])},
        "Overspray penalization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aşırı püskürtme cezalandırması"])},
        "low": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["düşük"])},
        "medium": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["orta"])},
        "high": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["yüksek"])},
        "Nozzle angle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nozül açısı"])},
        "Optimize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optimize et"])},
        "Nozzle rotation angle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nozül dönüş açısı"])},
        "degrees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["derece"])},
        "Optimal Settings Nozzle Angle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optimal Ayarlar Nozül Açısı"])},
        "Current Settings Nozzle Angle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geçerli Ayarlar Nozül Açısı"])},
        "Selection of optimal setting in terms of powder waste penalization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toz atık cezalandırması açısından en uygun ayarın seçimi"])},
        "High (prioritize reducing overspray waste)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yüksek (aşırı püskürtme atığını azaltmaya öncelik verin)"])},
        "Medium (balanced uniformity & overspray)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Orta (dengeli homojenlik ve aşırı püskürtme)"])},
        "Low (prioritize for uniformity)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Düşük (tekdüzelik için önceliklendirin)"])},
        "Unique optimal solution found.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benzersiz optimum çözüm bulundu."])},
        "Overspray vs. uniformity trade-off": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aşırı püskürtme ile homojenlik arasındaki denge"])},
        "Selection of optimal setting in terms of uniformity vs. powder waste. Select an option according to your powder recycling capabilities and uniformity needs.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toz atığına karşı homojenlik açısından en uygun ayarın seçimi. Toz geri dönüşüm kapasitenize ve homojenlik ihtiyaçlarınıza göre bir seçenek belirleyin."])},
        "Maximize uniformity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Homojenliği en üst düzeye çıkarın"])},
        "Minimize overspray": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aşırı püskürtmeyi en aza indirin"])},
        "Balance overspray & uniformity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aşırı püskürtme ve homojenliği dengeleyin"])},
        "guns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tabancalar"])},
        "Powder Usage Pie Chart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toz Kullanımı Pasta Grafiği"])},
        "Optimal Settings Pie Chart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optimal Ayarlar Pasta Grafiği"])},
        "Current Settings Pie Chart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mevcut Ayarlar Pasta Grafiği"])},
        "Powder Waste": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toz Atık"])},
        "Calibrate Powder Amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toz Miktarını Kalibre Et"])},
        "Powder Amount Calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toz Miktarı Kalibrasyonu"])},
        "Please select a reference gun": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lütfen bir referans tabanca seçin"])},
        "Powder Amount Parameter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toz Miktarı Parametresi"])},
        "Gun": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tabanca"])},
        "Expected throughput": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beklenen verimlilik"])},
        "Activate Toggle to open the pistol powder amount calibration panel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tabanca toz miktarı kalibrasyon panelini açmak için Toggle'ı etkinleştirin"])},
        "Please select a powder amount calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lütfen bir toz miktarı kalibrasyonu seçin"])},
        "Calibration Out of Range": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kalibrasyon Aralık Dışı"])},
        "The required settings to obtain this throughput are out of range for gun": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bu verimliliği elde etmek için gerekli ayarlar tabanca için aralık dışında"])},
        "Obtained Parameter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elde Edilen Parametre"])},
        "Invalid Powder Amount Parameter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geçersiz Toz Miktarı Parametresi"])},
        "The powder amount parameter must be between the minimum and maximum values defined in the calibration.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toz miktarı parametresi, kalibrasyonda tanımlanan minimum ve maksimum değerler arasında olmalıdır."])},
        "The expected throughput must be greater than 0.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beklenen verimlilik 0'dan büyük olmalıdır."])},
        "Invalid Expected Throughput": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geçersiz Beklenen Verimlilik"])},
        "Time it takes for one gun to move from the lowest point to the highest point and back.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bir tabancanın en düşük noktadan en yüksek noktaya ve geri dönmesi için gereken süre."])},
        "Distance between highest and lowest point of one moving gun": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bir hareketli tabancanın en yüksek ve en düşük noktası arasındaki mesafe"])},
        "Speed at which the gun moves": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tabancanın hareket ettiği hız"])},
        "Share Optimization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optimizasyon Bağlantısı"])},
        "Sharable link:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paylaşılabilir bağlantı:"])},
        "Sharable link:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paylaşılabilir bağlantı:"])},
        "Copy to Clipboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Panoya Kopyala"])}
      },
      "ZH": {
        "": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
        "To allow a greater covered height, increase the gun movement range of the Line.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["为了允许更大的覆盖高度，请增加生产线的枪支移动范围。"])},
        "Animation generation unsuccessful": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["动画生成不成功"])},
        "Applied powder cannot be visualized as an animation for this line and parameters. Optimal settings and results images are still available in the results section.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["对于该生产线和参数，应用的粉末无法以动画形式显示。最佳设置和结果图像仍可在结果部分查看。"])},
        "Overcoated powder:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Overcoated powder:"])},
        "Oversprayed powder:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oversprayed powder:"])},
        "The amount of extra powder applied because of an uneven powder distribution on the substrate.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["由于粉末在基材上分布不均匀而多喷涂的粉末量。"])},
        "The powder that does not stick to the part and is then not reclaimed by the recycling system.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["未粘在部件上的粉末，回收系统无法回收。"])},
        "Coated height must be smaller than the maximum mechanical range covered by the guns, of ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["涂层高度必须小于枪支覆盖的最大机械范围，为："])},
        "Gun movement range cannot be greater than the maximum possible mechanical range of the line:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["手枪的移动范围不能大于生产线的最大机械范围。:"])},
        "Loading optimization type...": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["加载优化类型..."])},
        "Clear & start new optimization for line": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["清除并重新优化生产线"])},
        "Please fill the following fields: ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请填写以下字段： "])},
        "Line speed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["生产线速度"])},
        "Coated height": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["涂层高度"])},
        "Coated width": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["涂层宽度"])},
        "Powder model": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["粉末型号"])},
        "Specific Powder Optimization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["特定粉末优化"])},
        "Multi-Powder Optimization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["多粉优化"])},
        "Input value out of range": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["输入值超出范围"])},
        "was saved successfully.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已成功保存。"])},
        "Missing Required Fields": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["缺少必填字段"])},
        "Gun movement range cannot be 0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["手枪移动范围不能为 0"])},
        "Line speed cannot be 0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["线路速度不能为 0"])},
        "Line speed must be greater than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["行进速度必须大于"])},
        "Line speed must be less than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["线速度必须小于"])},
        "Covered height must be greater than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["涂层高度必须大于"])},
        "Simulation visualization coated width must be greater than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["模拟可视化涂层宽度必须大于"])},
        "Gun movement speed cannot be slower than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["手枪移动速度不能低于"])},
        "Use a faster guns velocity or a different movement range & period time combination.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["使用更快的手枪速度或不同的移动范围和时间组合。"])},
        "Gun movement speed cannot be faster than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["手枪移动速度不能快于"])},
        "Use a slower guns velocity or a different movement range & period time combination.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["使用较慢的手枪速度或不同的移动范围和周期组合。"])},
        "Gun movement period cannot be longer than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["手枪移动时间不能长于"])},
        "Use a shorter guns period or a different movement range & period time combination.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["使用较短的手枪移动周期或不同的移动范围和周期组合。"])},
        "Gun movement period must be greater than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["手枪移动周期必须大于"])},
        "No fields were changed from the previous optimization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["与上次优化相比，没有更改字段"])},
        "Do you want to recompute it anyway?": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["还是要重新计算？"])},
        "Cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["取消"])},
        "Yes, recompute it!": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["是的，重新计算！"])},
        "OK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["确定"])},
        "Active guns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["现役手枪"])},
        "Active guns per column": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["每列使用中的手枪"])},
        "Gun movement range": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["手枪移动范围"])},
        "Gun Movement Speed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["手枪移动速度"])},
        "Gun Movement Period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["手枪移动周期"])},
        "Save Optimization to Library": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["将优化保存到资料库"])},
        "Optimization Saved to Library": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["保存到库的优化"])},
        "optional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["可选的"])},
        "Optimization Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["优化名称"])},
        "New Optimization for line": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["生产线的新优化"])},
        "Status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["状态"])},
        "Optimum found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["找到最佳状态"])},
        "Ready to find optimum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["准备查找最佳值"])},
        "Optimization details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["优化详细信息"])},
        "Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["优化名称"])},
        "Line": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["线路"])},
        "Optimization type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["优化类型"])},
        "Select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["选择"])},
        "Powder Models": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["粉末模型"])},
        "Calibrated Powder Optimization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["校准粉末优化"])},
        "Pre-Calibrated Powder Optimization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["预校准粉末优化"])},
        "Generalistic Optimization for any powder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["针对任何粉末的通用优化"])},
        "Select Powder Model": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["选择粉末模型"])},
        "No Powder Models Created. To create one go to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["未创建粉末模型。要创建粉末模型，请转到"])},
        "Optimization parameters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["优化参数"])},
        "Line Speed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["线速度"])},
        "Distance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["距离"])},
        "Covered height": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["覆盖高度"])},
        "Simulation width": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["模拟宽度"])},
        "Continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["继续"])},
        "Compare with Current Settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["与当前设置比较"])},
        "Number of active guns 1st column": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["活动手枪数量 第 1 栏"])},
        "Please select one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请选择一个"])},
        "Number of active guns 2nd column": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["活动手枪数量 第 2 栏"])},
        "Number of active guns 3rd column": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["活动手枪数量 第三栏"])},
        "Number of active guns 4th column": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["现役手枪数量 第 4 栏"])},
        "Number of active guns 5th column": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["现役手枪数量 第 5 栏"])},
        "Number of active guns 6th column": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["现役手枪数量 第 6 栏"])},
        "Number of active guns 7th column": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["现役手枪数量 第 7 栏"])},
        "Active guns on one side": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["一侧活动手枪"])},
        "Gun movement range": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["手枪移动范围"])},
        "Gun movement period time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["手枪移动时间"])},
        "Gun movement speed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["手枪移动速度"])},
        "Compute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["计算"])},
        "Optimal machine settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最佳机器设置"])},
        "Simulation results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["模拟结果"])},
        "Current Settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["当前设置"])},
        "Uniformity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["均匀性"])},
        "Overspray": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["过喷"])},
        "Optimal Settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最佳设置"])},
        "Uniformity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["均匀性"])},
        "Visualize graphs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["可视化图表"])},
        "Calculation running": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["计算运行"])},
        "Uniformity comparison": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["均匀度比较"])},
        "Applied spray": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["应用喷雾"])},
        "Movement chart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["运动图表"])},
        "Applied spray animation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["喷涂动画"])},
        "Current Settings Coated Area": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["当前设置 涂层面积"])},
        "Optimal Settings Coated Area": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最佳设置喷涂面积"])},
        "Current Settings Gun Movement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["当前设置手枪移动"])},
        "Optimal Settings Gun Movement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最佳设置手枪移动"])},
        "Current Settings Coated Area": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["当前设置涂装区域"])},
        "Optimal Settings Coated Area": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最佳设置涂层区域"])},
        "Comments section for the generated report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["生成报告的评论部分"])},
        "Hide Optimal Settings in the report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["在报告中隐藏最佳设置"])},
        "Save Edited Comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["保存已编辑的注释"])},
        "Generate Report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["生成报告"])},
        "Generating optimization report...": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["生成优化报告..."])},
        "To use a different optimization type create a new Optimization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["要使用不同的优化类型，请创建新优化"])},
        "If you want to use a different set of Powder Models, create a new Optimization.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["如果要使用一组不同的粉末模型，请创建一个新的优化。"])},
        "Select Specific Powder optimization to use one or more powder models to compute the optimal settings. Select the Generalistic Optimization option to get a powder agnostic optimization solution.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["选择特定粉末优化，使用一个或多个粉末模型来计算最佳设置。选择通用优化（Generalistic Optimization）选项，获得与粉末无关的优化解决方案。"])},
        "Select the Powder Models to be used during the computation of optimal settings.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["选择计算优化设置时要使用的粉末模型。"])},
        "Line velocity value in [meters/minute] units.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["线速度值，单位为 [米/分钟]。"])},
        "Distance from the gun nozzle to the substrate.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["喷枪喷嘴到基底的距离。"])},
        "Vertical height for which the uniformity will be optimized.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["将优化均匀性的垂直高度。"])},
        "Only relevant for visualization purposes, determines the horizontal width, of the simulation image shown on the results section.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["仅用于可视化目的，决定显示在结果部分的模拟图像的水平宽度。"])},
        "Fill this section with your Gun Settings to visualize how they compare with the optimal settings.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["在此部分填写您的喷枪设置，以便直观地显示它们与最佳设置的比较。"])},
        "Clear current settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["清除当前设置"])},
        "Number of active guns on the first column of guns. The minimum is 0 and the maximum is": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["第一列手枪中的活动手枪数量。最小值为 0，最大值为"])},
        "Number of active guns on the second column of guns. The minimum is 0 and the maximum is": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["第二列手枪的活动手枪数量。最小值为 0，最大值为"])},
        "Number of active guns on the third column of guns. The minimum is 0 and the maximum is": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["第三列已激活手枪的数量。最小值为 0，最大值为"])},
        "Number of active guns on the fourth column of guns. The minimum is 0 and the maximum is": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["第四列手枪中的现役手枪数量。最小值为 0，最大值为"])},
        "Number of active guns on the fifth column of guns. The minimum is 0 and the maximum is": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["第五列手枪中的现役手枪数量。最小值为 0，最大值为"])},
        "Number of active guns on one side of the booth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["展台一侧的活动手枪数量"])},
        "Activate Toggle to visualize simulation images": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["激活 切换 可视化模拟图像"])},
        "Overspray penalization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["过喷处罚"])},
        "low": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["低"])},
        "medium": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["中"])},
        "high": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["高"])},
        "Nozzle angle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["喷嘴角度"])},
        "Optimize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["优化"])},
        "Nozzle rotation angle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["喷嘴旋转角度"])},
        "degrees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["度"])},
        "Optimal Settings Nozzle Angle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最佳设置喷嘴角度"])},
        "Current Settings Nozzle Angle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["当前设置喷嘴角度"])},
        "Selection of optimal setting in terms of powder waste penalization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["从粉末浪费惩罚角度选择最佳设置"])},
        "High (prioritize reducing overspray waste)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["高（优先减少过喷废料）"])},
        "Medium (balanced uniformity & overspray)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["中等（均衡的均匀度和过喷）"])},
        "Low (prioritize for uniformity)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["低（优先考虑统一性）"])},
        "Unique optimal solution found.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["找到唯一的最佳解决方案。"])},
        "Overspray vs. uniformity trade-off": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["过喷与均匀性的权衡"])},
        "Selection of optimal setting in terms of uniformity vs. powder waste. Select an option according to your powder recycling capabilities and uniformity needs.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["从均匀性与粉末浪费的角度选择最佳设置。根据您的粉末回收能力和均匀性需求选择一个选项。"])},
        "Maximize uniformity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最大化均匀性"])},
        "Minimize overspray": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["尽量减少过喷"])},
        "Balance overspray & uniformity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["平衡过喷和均匀性"])},
        "guns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["枪"])},
        "Powder Usage Pie Chart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["粉末用量饼图"])},
        "Optimal Settings Pie Chart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最佳设置饼图"])},
        "Current Settings Pie Chart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["当前设置饼图"])},
        "Powder Waste": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["粉末废料"])},
        "Calibrate Powder Amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["校准粉末量"])},
        "Powder Amount Calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["粉末量校准"])},
        "Please select a reference gun": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请选择一个参考枪"])},
        "Powder Amount Parameter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["粉末量参数"])},
        "Gun": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["枪"])},
        "Expected throughput": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["预期吞吐量"])},
        "Activate Toggle to open the pistol powder amount calibration panel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["激活切换以打开手枪粉末量校准面板"])},
        "Please select a powder amount calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请选择粉末量校准"])},
        "Calibration Out of Range": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["校准超出范围"])},
        "The required settings to obtain this throughput are out of range for gun": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["获取此吞吐量所需的设置超出了枪支的范围"])},
        "Obtained Parameter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["获得的参数"])},
        "Invalid Powder Amount Parameter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["无效的粉末量参数"])},
        "The powder amount parameter must be between the minimum and maximum values defined in the calibration.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["粉末量参数必须在校准中定义的最小值和最大值之间。"])},
        "The expected throughput must be greater than 0.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["预期吞吐量必须大于 0。"])},
        "Invalid Expected Throughput": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["无效的预期吞吐量"])},
        "Time it takes for one gun to move from the lowest point to the highest point and back.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["一支枪从最低点到最高点再返回所需的时间。"])},
        "Distance between highest and lowest point of one moving gun": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["一支移动枪的最高点和最低点之间的距离"])},
        "Speed at which the gun moves": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["枪移动的速度"])},
        "Share Optimization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["分享优化链接"])},
        "Sharable link:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["可共享链接："])},
        "Copy to Clipboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["复制到剪贴板"])},
        "Copied to Clipboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已复制到剪贴板"])}
      }
    }
  })
}
