
<template>

    <div class="row card mt-4" v-if="visit != null">
    
        <div class="col-12">
          <div class="table-responsive" style="margin: 0px;">
            <table id="order-list" class="table table-flush">
              <thead class="thead-light">
                <tr>
                  <th class="text-center">Date</th>
                  <th class="text-center">Benchmark & Target</th>
                  <th class="text-center">Health Check</th>
                  <th class="text-center">Powder Output</th>
                  <th class="text-center">Spray Pattern</th>
                  <th class="text-center">Reciprocator</th>
                  <th class="text-center">Powder Amount Saving</th>
                </tr>
              </thead>
              <tbody>
                <tr :key="visit.date">
                  <visit-row
                    :date-time="formattedDate(visit.created_at)"
                    :benchmark-checked="visit.benchmarkstage ? visit.benchmarkstage.progress_status : 'Pending'"
                    :health-checked="visit.healthcheckstage ? visit.healthcheckstage.progress_status : 'Pending'"
                    :powder-output-checked="visit.powderoutputstage ? visit.powderoutputstage.progress_status : 'Pending'"
                    :spray-pattern-checked="visit.spraypatternstage ? visit.spraypatternstage.progress_status : 'Pending'"
                    :reciprocator-checked="visit.reciprocatorstage ? visit.reciprocatorstage.progress_status : 'Pending'"
                    :powderamountsaving-checked="visit.powderamountsavingstage ? visit.powderamountsavingstage.progress_status : 'Pending'"
                    :line-id="lineId"
                    :visit-id="visit.id"
                  ></visit-row>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
</template>

<script>

 import VisitRow from "../../components/VisitRow.vue";

export default {
  name: "StepperLineVisit",
  props: {
    lineId: {
      type: String,
      default: "",
    },
    visit: {
      type: Object,
      default: () => ({}),
    }
  },
  components: {
    VisitRow,
  },

  data() {
    return {
      visit_v: {
          date: "2021-09-01",
          benchmarkstage: { progress_status: "Completed" },
          healthcheckstage: { progress_status: "Completed" },
          powderoutputstage: { progress_status: "Completed" },
          spraypatternstage: { progress_status: "Completed" },
          reciprocatorstage: { progress_status: "Completed" },
          powderamountsavingstage: { progress_status: "Completed" },
        },



    };
  },
//   mounted() {
//     this.getVisit();
//   },
  methods: {
    formattedDate(date) {
      return new Date(date).toLocaleDateString();
    },
    // async getVisit() {
    //   try {
    //     let response = await axios.get("/api/v1/onsite/visit/" + this.visitId);
    //     this.visit = JSON.parse(JSON.stringify(response.data));
    //   } catch (error) {
    //     console.error(error);
    //   }
    // },
  },
};
</script>