
  export function generateX(mu, sigma) {

    const xValues = [];
    const start = Math.max(0, mu - 4 * sigma);
    const end = mu + 4 * sigma;
    for (let i = start; i <= end; i += 1) {
      xValues.push(Math.round(i));
    }
    return xValues;
  }


export function generateXforMultipleMuSigma(muSigmaPairs) {
    const xValues = [];

    let minStart = Infinity;
    let maxEnd = -Infinity;

    muSigmaPairs.forEach(([mu, sigma]) => {
        const start = Math.max(0, mu - 4 * sigma);
        const end = mu + 4 * sigma;
        if (start < minStart) minStart = start;
        if (end > maxEnd) maxEnd = end;
    });

    for (let i = minStart; i <= maxEnd; i += 1) {
        xValues.push(i);
    }

    return xValues;
  }


  export function gaussian(x, mu, sigma) {
    const gaussianConstant = 1 / Math.sqrt(2 * Math.PI);
    const new_x = (x - mu) / sigma;
    return (gaussianConstant * Math.exp(-0.5 * new_x * new_x)) / sigma;
  }
  
  export function generateY(sigma, mu) {
    const scaleFactor = 1;
    return generateX(mu, sigma).map(x => {
      const y = gaussian(x, mu, sigma);
      return parseFloat(y * scaleFactor);
    });
  }

  export function generateYwithXvalues(sigma, mu, xValues) {
    const scaleFactor = 1;
    return xValues.map(x => {
      const y = gaussian(x, mu, sigma);
      return parseFloat(y * scaleFactor);
    });
  }

  export function calculateMu(measurements) {
    const validMeasurements = measurements.filter(m => m !== null && m !== "");
    return parseFloat((validMeasurements.reduce((a, b) => a + b, 0) / validMeasurements.length).toPrecision(2));
  };

  export function calculateSigma(measurements, mu) {
    const validMeasurements = measurements.filter(m => m !== null && m !== "");
    return parseFloat(
      Math.sqrt(
        validMeasurements.reduce((sq, n) => sq + Math.pow(n - mu, 2), 0) / (validMeasurements.length - 1),
      ).toPrecision(2),
    );
  };
