<template>
  <div class="py-1 container-fluid">
    <div class="row">
      <div class="row gy-1 gx-2">
        <div class="col">
          <div v-if="!calculation_in_progress">
            <div
              v-if="currentPage != firstPage"
              class="card"
            >
              <div class="card-body">
                <h5 class="text-left">{{ $t("Creating Powder Model for") }} for {{ line.name }}</h5>
                <div class="row mt-2">
                  <div class="col-md-6">
                    <h6 class="font-weight mb-4">{{ $t("Configuration for Experiment") }} {{ currentPage }}</h6>
                    <div class="table-responsive">
                      <table class="table table-bordered mt-4 mb-1">
                        <tbody>
                          <tr>
                            <th>{{ $t("Gun Nozzle Slit orientation") }}</th>
                            <td>
                              {{ (((currentPage - 1) % 4) &lt; 2 ? $t(`Horizontal (standard orientation)`) : $t(`Vertical (turned 90°)`)) }}
                            </td>
                          </tr>
                          <tr>
                            <th>{{ $t("Gun movement") }}</th>
                            <td>{{ $t("Deactivated reciprocator. Non-moving guns.") }}</td>
                          </tr>
                          <tr>
                            <th>{{ $t("Line speed") }}</th>
                            <td>
                              {{ calibrationLineSpeed }} [{{
                                $store.state.units_system[$store.state.user_data.unit_system].velocity_minutes
                              }}].
                            </td>
                          </tr>
                          <tr>
                            <th>{{ $t("Gun to substrate distance") }}</th>
                            <td v-if="currentPage < 5">
                              {{ maxPistolToSubstrateDistance }} [{{
                                $store.state.units_system[$store.state.user_data.unit_system].distance
                              }}]
                            </td>
                            <td v-if="currentPage > 4">
                              {{ minPistolToSubstrateDistance }} [{{
                                $store.state.units_system[$store.state.user_data.unit_system].distance
                              }}]
                            </td>
                          </tr>
                          <tr>
                            <th>{{ $t("Air parameter") }}</th>
                            <td v-if="currentPage % 2 === 0">
                              {{ powdermodel_calibration_measures.min_air_parameter_measurements }}
                            </td>
                            <td v-if="currentPage % 2 === 1">
                              {{ powdermodel_calibration_measures.max_air_parameter_measurements }}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div
                    v-if="(currentPage - 1) % 4 < 2"
                    class="col-md-6"
                  >
                    <figure>
                      <img
                        alt="Image placeholder"
                        :src="
                          s3_storage_base_URL + 'flightpath-platform/public-assets/static/horizontal-nozzle-scaled.png'
                        "
                        class="w-100 mx-auto d-block"
                        style="max-width: 500px; margin: auto; position: relative"
                      />
                      <figcaption class="text-center">
                        {{ $t("Experiment") }} {{ currentPage }} {{ $t("Nozzle orientation") }}.
                      </figcaption>
                    </figure>
                  </div>
                  <div
                    v-else
                    class="col-md-6"
                  >
                    <figure>
                      <img
                        alt="Image placeholder"
                        :src="
                          s3_storage_base_URL + 'flightpath-platform/public-assets/static/vertical-nozzle-scaled.png'
                        "
                        class="w-100 mx-auto d-block"
                        style="max-width: 500px; margin: auto; position: relative"
                      />
                      <figcaption class="text-center">
                        {{ $t("Experiment") }} {{ currentPage }} {{ $t("Nozzle orientation") }}.
                      </figcaption>
                    </figure>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row gy-2 gx-2">
        <div class="col">
          <div class="card">
            <div class="card-body">
              <div v-if="!calculation_in_progress">
                <div v-if="isInFirstPage">
                  <h5 class="text-left">{{ $t("Creating Powder Model for") }} {{ line.name }}</h5>
                  <div class="row mt-2">
                    <h6 class="font-weight">{{ $t("Calibration Parameters") }}</h6>
                    <div class="col-sm-4">
                      <label class="lg">{{ $t("Powder model name") }}</label>
                      <input
                        v-model="powdermodel.name"
                        :class="name_input_error ? 'form-control is-invalid' : 'form-control'"
                        type="text"
                        @input="name_input_error = false"
                      />
                    </div>
                    <div class="col-sm-4">
                      <label class="lg"
                        >{{ $t("Line speed") }} [{{
                          $store.state.units_system[$store.state.user_data.unit_system].velocity_minutes
                        }}]</label
                      >
                      <input
                        v-model.lazy="calibrationLineSpeed"
                        :class="line_speed_input_error ? 'form-control is-invalid' : 'form-control'"
                        type="number"
                        min="0"
                        @input="line_speed_input_error = false"
                        @keydown="numericOnly"
                      />
                    </div>
                    <div class="col-sm-4">
                      <label class="lg"
                        >{{ $t("MIN") }} {{ $t("Gun to substrate distance") }} [{{
                          $store.state.units_system[$store.state.user_data.unit_system].distance
                        }}]</label
                      >
                      <input
                        v-model.lazy="minPistolToSubstrateDistance"
                        :class="
                          min_pistol_to_substrate_distance_input_error ? `form-control is-invalid` : `form-control`
                        "
                        type="number"
                        @keydown="numericOnly"
                        @input="min_pistol_to_substrate_distance_input_error = false"
                      />
                    </div>
                  </div>
                  <div class="mt-4 row">
                    <div class="col-4">
                      <label
                        >{{ $t("MAX") }} {{ $t("Gun to substrate distance") }} [{{
                          $store.state.units_system[$store.state.user_data.unit_system].distance
                        }}]</label
                      >
                      <input
                        v-model.lazy="maxPistolToSubstrateDistance"
                        :class="
                          max_pistol_to_substrate_distance_input_error ? `form-control is-invalid` : `form-control`
                        "
                        type="number"
                        @keydown="numericOnly"
                        @input="max_pistol_to_substrate_distance_input_error = false"
                      />
                    </div>
                    <div class="col-4">
                      <label>
                        {{ $t("MIN") }} {{ $t("Air parameter") }}
                        <i
                          :title="[
                            $t('MIN') + ' ' + $t('Airflow or air volume selected in the powder coating machine'),
                          ]"
                          class="fa fa-info-circle"
                          aria-hidden="true"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          data-container="body"
                          data-animation="true"
                        >
                        </i>
                      </label>
                      <input
                        v-model="powdermodel_calibration_measures.min_air_parameter_measurements"
                        :class="min_air_parameter_input_error ? `form-control is-invalid` : `form-control`"
                        type="number"
                        @keydown="numericOnly"
                        @input="min_air_parameter_input_error = false"
                      />
                    </div>
                    <div class="col-4">
                      <label>
                        {{ $t("MAX") }} {{ $t("Air parameter") }}
                        <i
                          :title="[
                            $t('MAX') + ' ' + $t('Airflow or air volume selected in the powder coating machine'),
                          ]"
                          class="fa fa-info-circle"
                          aria-hidden="true"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          data-container="body"
                          data-animation="true"
                        >
                        </i>
                      </label>
                      <input
                        v-model="powdermodel_calibration_measures.max_air_parameter_measurements"
                        :class="max_air_parameter_input_error ? `form-control is-invalid` : `form-control`"
                        type="number"
                        @keydown="numericOnly"
                        @input="max_air_parameter_input_error = false"
                      />
                    </div>
                  </div>
                  <div class="row mt-2">
                    <div
                      v-if="$store.state.flightpath_pro_license"
                      class="col-md-3"
                    >
                      <label class="mt-4">{{ $t("Nozzle Type") }}</label>
                      <input
                        v-model.lazy="powdermodel_calibration_measures.nozzles_type"
                        :class="nozzles_type_input_error ? 'form-control is-invalid' : 'form-control'"
                        type="text"
                        @input="nozzles_type_input_error = false"
                      />
                    </div>
                    <div
                      v-if="$store.state.flightpath_pro_license"
                      class="col-md-3"
                    >
                      <label class="mt-4">{{ $t("Powder Manufacturer") }}</label>
                      <select
                        id="choices-category"
                        v-model="powdermodel_calibration_measures.powder_manufacturer"
                        :class="powder_manufacturer_input_error ? 'form-control is-invalid' : 'form-control'"
                        name="powder-manufacturer-choices"
                        style="appearance: listbox"
                        @input="powder_manufacturer_input_error = false"
                      >
                        <option
                          v-for="powder_manufacturer_option in powder_manufacturer_options"
                          :key="powder_manufacturer_option"
                          :selected="powdermodel_calibration_measures.powder_manufacturer"
                          :value="powder_manufacturer_option"
                        >
                          {{ $t(powder_manufacturer_option) }}
                        </option>
                      </select>
                    </div>
                    <div
                      v-if="$store.state.flightpath_pro_license"
                      class="col-md-3"
                    >
                      <label class="mt-4">{{ powderLabel }}</label>
                      <input
                        v-model.lazy="powdermodel_calibration_measures.powder_article_id"
                        :class="powder_article_id_input_error ? 'form-control is-invalid' : 'form-control'"
                        type="text"
                        @input="powder_article_id_input_error = false"
                      />
                    </div>
                    <div
                      v-if="$store.state.flightpath_pro_license"
                      class="col-md-3"
                    >
                      <label class="mt-4">{{ $t("Batch ID") }}</label>
                      <input
                        v-model.lazy="powdermodel_calibration_measures.batch_id"
                        :class="batch_id_input_error ? 'form-control is-invalid' : 'form-control'"
                        type="text"
                        @input="batch_id_input_error = false"
                      />
                    </div>
                  </div>
                  <div class="accordion">
                    <div class="accordion-item mb-0">
                      <h5 id="panelsStayOpen-headingOne">
                        <button
                          class="accordion-button collapsed mb-0"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#panelsStayOpen-collapseParams"
                          aria-expanded="false"
                          aria-controls="panelsStayOpen-collapseParams"
                          style="padding: 0"
                        >
                          <p
                            class="font-weight-bolder mt-3"
                            style="
                              font-weight: 700 !important;
                              font-size: 1.05rem;
                              vertical-align: middle;
                              margin-bottom: 0;
                            "
                          >
                            {{ $t("Optional Parameters") }}
                            <i
                              class="fa fa-chevron-down"
                              aria-hidden="true"
                            ></i>
                            <i
                              class="fa fa-chevron-up"
                              aria-hidden="true"
                            ></i>
                          </p>
                        </button>
                      </h5>
                      <div
                        id="panelsStayOpen-collapseParams"
                        class="accordion-collapse collapse"
                        aria-labelledby="panelsStayOpen-headingOne"
                      >
                        <div
                          class="accordion-body"
                          style="padding: 0"
                        >
                          <div class="mb-0 row">
                            <div
                              v-if="$store.state.flightpath_standard_license"
                              class="col-md-3"
                            >
                              <label class="mt-3">{{ $t("Nozzle Type") }}</label>
                              <input
                                v-model.lazy="powdermodel_calibration_measures.nozzles_type"
                                style="background-color: rgb(241, 241, 241)"
                                :class="`form-control`"
                                type="text"
                              />
                            </div>
                            <div
                              v-if="$store.state.flightpath_pro_license"
                              class="col-md-3"
                            >
                              <label class="mt-3">{{ operationalHoursLabel }}</label>
                              <input
                                v-model.lazy="powdermodel_calibration_measures.pump_operational_hours"
                                :class="'form-control'"
                                type="text"
                                style="background-color: rgb(241, 241, 241)"
                              />
                            </div>
                            <div
                              v-if="$store.state.flightpath_standard_license"
                              class="col-md-3"
                            >
                              <label class="mt-3">{{ $t("Powder Manufacturer") }}</label>
                              <input
                                v-model.lazy="powdermodel_calibration_measures.powder_manufacturer"
                                style="background-color: rgb(241, 241, 241)"
                                :class="`form-control`"
                                type="text"
                              />
                            </div>
                            <div
                              v-if="$store.state.flightpath_standard_license"
                              class="col-md-3"
                            >
                              <label class="mt-3">{{ $t("Powder ID") }}</label>
                              <input
                                v-model.lazy="powdermodel_calibration_measures.powder_article_id"
                                style="background-color: rgb(241, 241, 241)"
                                :class="`form-control`"
                                type="text"
                              />
                            </div>
                            <div class="col-md-3">
                              <label class="mt-3">
                                {{ $t("Powder Amount") }}
                                <i
                                  :title="$t('Quantity of powder dispensed by the gun per unit time')"
                                  class="fa fa-info-circle"
                                  aria-hidden="true"
                                  data-bs-toggle="tooltip"
                                  data-bs-placement="top"
                                  data-container="body"
                                  data-animation="true"
                                >
                                </i>
                              </label>
                              <input
                                v-model="powdermodel_calibration_measures.powder_amount"
                                :class="`form-control`"
                                type="number"
                                min="0"
                                style="background-color: rgb(241, 241, 241)"
                                @keydown="numericOnly"
                              />
                            </div>
                            <div class="col-md-3">
                              <label class="mt-3">{{ $t("Booth side used for calibration") }} </label>
                              <select
                                id="choices-category"
                                v-model="powdermodel_calibration_measures.booth_side"
                                class="form-control"
                                name="choices-category"
                                style="appearance: listbox; background-color: rgb(241, 241, 241)"
                              >
                                <option
                                  v-for="booth_side_option in booth_side_options"
                                  :key="booth_side_option"
                                  :selected="powdermodel_calibration_measures.booth_side"
                                  :value="booth_side_option"
                                >
                                  {{ $t(booth_side_option) }}
                                </option>
                              </select>
                            </div>
                            <div
                              v-if="$store.state.flightpath_pro_license"
                              class="col-md-3"
                            >
                              <label class="mt-3">{{ $t("Guns Spacing") }} </label>
                              <select
                                id="choices-category"
                                v-model="powdermodel_calibration_measures.guns_spacing"
                                class="form-control"
                                name="choices-category"
                                style="appearance: listbox; background-color: rgb(241, 241, 241)"
                              >
                                <option
                                  v-for="guns_spacing_option in guns_spacing_options"
                                  :key="guns_spacing_option"
                                  :selected="powdermodel_calibration_measures.guns_spacing"
                                  :value="guns_spacing_option"
                                >
                                  {{ $t(guns_spacing_option) }}
                                </option>
                              </select>
                            </div>
                            <div
                              v-if="$store.state.flightpath_pro_license"
                              class="col-md-3"
                            >
                              <label class="mt-3"
                                >{{ $t("Booth Temperature") }} [{{
                                  $store.state.units_system[$store.state.user_data.unit_system].temperature
                                }}]</label
                              >
                              <input
                                v-model="boothTemperature"
                                :class="`form-control`"
                                type="number"
                                min="0"
                                style="background-color: rgb(241, 241, 241)"
                                @keydown="numericOnly"
                              />
                            </div>
                            <div
                              v-if="$store.state.flightpath_pro_license"
                              class="col-md-3"
                            >
                              <label class="mt-3">{{ $t("Booth Humidity") }} [%]</label>
                              <input
                                v-model="powdermodel_calibration_measures.booth_humidity"
                                :class="`form-control`"
                                type="number"
                                min="0"
                                style="background-color: rgb(241, 241, 241)"
                                @keydown="numericOnly"
                              />
                            </div>
                            <div :class="$store.state.flightpath_standard_license ? 'col-md-9' : 'col-md-6'">
                              <label class="mt-3">{{ $t("Additional Info") }}</label>
                              <input
                                v-model="powdermodel_calibration_measures.additional_info"
                                style="background-color: rgb(241, 241, 241)"
                                :class="`form-control`"
                                type="text"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row-12 float-end mt-4 mb-2">
                    <soft-button
                      color="success"
                      size="sm"
                      variant="contained"
                      @click="onClickNextPage"
                    >
                      {{ $t("Continue to Experiment") }} 1
                    </soft-button>
                  </div>
                </div>
                <div v-if="currentPage === 1">
                  <div class="row">
                    <h6 class="font-weight">{{ $t("Measurements for Experiment") }} 1</h6>
                    <div class="col-3">
                      <label class="mt-4"
                        >{{ $t("Point") }} 1 [{{
                          $store.state.units_system[$store.state.user_data.unit_system].thickness
                        }}]</label
                      >
                      <input
                        v-model.lazy="experiment1Point1"
                        :class="experiment_1_point_1_input_error ? `form-control is-invalid` : `form-control`"
                        type="number"
                        @keydown="numericOnly"
                        @input="experiment_1_point_1_input_error = false"
                      />
                    </div>
                    <div class="col-3">
                      <label class="mt-4"
                        >{{ $t("Point") }} 2 [{{
                          $store.state.units_system[$store.state.user_data.unit_system].thickness
                        }}]</label
                      >
                      <input
                        v-model.lazy="experiment1Point2"
                        :class="experiment_1_point_2_input_error ? `form-control is-invalid` : `form-control`"
                        type="number"
                        @keydown="numericOnly"
                        @input="experiment_1_point_2_input_error = false"
                      />
                    </div>
                    <div class="col-3">
                      <label class="mt-4"
                        >{{ $t("Point") }} 3 [{{
                          $store.state.units_system[$store.state.user_data.unit_system].thickness
                        }}]</label
                      >
                      <input
                        v-model.lazy="experiment1Point3"
                        :class="experiment_1_point_3_input_error ? `form-control is-invalid` : `form-control`"
                        type="number"
                        @keydown="numericOnly"
                        @input="experiment_1_point_3_input_error = false"
                      />
                    </div>
                    <div class="col-3">
                      <label class="mt-4"
                        >{{ $t("Point") }} 4 [{{
                          $store.state.units_system[$store.state.user_data.unit_system].thickness
                        }}]</label
                      >
                      <input
                        v-model.lazy="experiment1Point4"
                        :class="experiment_1_point_4_input_error ? `form-control is-invalid` : `form-control`"
                        type="number"
                        @keydown="numericOnly"
                        @input="experiment_1_point_4_input_error = false"
                      />
                    </div>
                    <div class="col-3">
                      <label class="mt-4"
                        >{{ $t("Point") }} 5 [{{
                          $store.state.units_system[$store.state.user_data.unit_system].thickness
                        }}]</label
                      >
                      <input
                        v-model.lazy="experiment1Point5"
                        :class="experiment_1_point_5_input_error ? `form-control is-invalid` : `form-control`"
                        type="number"
                        @keydown="numericOnly"
                        @input="experiment_1_point_5_input_error = false"
                      />
                    </div>
                    <div class="col-3">
                      <label class="mt-4"
                        >{{ $t("Point") }} 6 [{{
                          $store.state.units_system[$store.state.user_data.unit_system].thickness
                        }}]</label
                      >
                      <input
                        v-model.lazy="experiment1Point6"
                        :class="experiment_1_point_6_input_error ? `form-control is-invalid` : `form-control`"
                        type="number"
                        @keydown="numericOnly"
                        @input="experiment_1_point_6_input_error = false"
                      />
                    </div>
                    <div class="col-3">
                      <label class="mt-4"
                        >{{ $t("Point") }} 7 [{{
                          $store.state.units_system[$store.state.user_data.unit_system].thickness
                        }}]</label
                      >
                      <input
                        v-model.lazy="experiment1Point7"
                        :class="experiment_1_point_7_input_error ? `form-control is-invalid` : `form-control`"
                        type="number"
                        @keydown="numericOnly"
                        @input="experiment_1_point_7_input_error = false"
                      />
                    </div>
                  </div>
                  <div class="row-12 float-end mt-4 mb-2">
                    <soft-button
                      color="success"
                      size="sm"
                      variant="contained"
                      @click="onClickNextPage"
                    >
                      {{ $t("Continue to Experiment") }} 2
                    </soft-button>
                  </div>
                </div>
                <div v-if="currentPage === 2">
                  <div class="row">
                    <h6 class="font-weight">{{ $t("Measurements for Experiment") }} 2</h6>
                    <div class="col-3">
                      <label class="mt-4"
                        >{{ $t("Point") }} 1 [{{
                          $store.state.units_system[$store.state.user_data.unit_system].thickness
                        }}]</label
                      >
                      <input
                        v-model.lazy="experiment2Point1"
                        :class="experiment_2_point_1_input_error ? `form-control is-invalid` : `form-control`"
                        type="number"
                        @keydown="numericOnly"
                        @input="experiment_2_point_1_input_error = false"
                      />
                    </div>
                    <div class="col-3">
                      <label class="mt-4"
                        >{{ $t("Point") }} 2 [{{
                          $store.state.units_system[$store.state.user_data.unit_system].thickness
                        }}]</label
                      >
                      <input
                        v-model.lazy="experiment2Point2"
                        :class="experiment_2_point_2_input_error ? `form-control is-invalid` : `form-control`"
                        type="number"
                        @keydown="numericOnly"
                        @input="experiment_2_point_2_input_error = false"
                      />
                    </div>
                    <div class="col-3">
                      <label class="mt-4"
                        >{{ $t("Point") }} 3 [{{
                          $store.state.units_system[$store.state.user_data.unit_system].thickness
                        }}]</label
                      >
                      <input
                        v-model.lazy="experiment2Point3"
                        :class="experiment_2_point_3_input_error ? `form-control is-invalid` : `form-control`"
                        type="number"
                        @keydown="numericOnly"
                        @input="experiment_2_point_3_input_error = false"
                      />
                    </div>
                    <div class="col-3">
                      <label class="mt-4"
                        >{{ $t("Point") }} 4 [{{
                          $store.state.units_system[$store.state.user_data.unit_system].thickness
                        }}]</label
                      >
                      <input
                        v-model.lazy="experiment2Point4"
                        :class="experiment_2_point_4_input_error ? `form-control is-invalid` : `form-control`"
                        type="number"
                        @keydown="numericOnly"
                        @input="experiment_2_point_4_input_error = false"
                      />
                    </div>
                    <div class="col-3">
                      <label class="mt-4"
                        >{{ $t("Point") }} 5 [{{
                          $store.state.units_system[$store.state.user_data.unit_system].thickness
                        }}]</label
                      >
                      <input
                        v-model.lazy="experiment2Point5"
                        :class="experiment_2_point_5_input_error ? `form-control is-invalid` : `form-control`"
                        type="number"
                        @keydown="numericOnly"
                        @input="experiment_2_point_5_input_error = false"
                      />
                    </div>
                    <div class="col-3">
                      <label class="mt-4"
                        >{{ $t("Point") }} 6 [{{
                          $store.state.units_system[$store.state.user_data.unit_system].thickness
                        }}]</label
                      >
                      <input
                        v-model.lazy="experiment2Point6"
                        :class="experiment_2_point_6_input_error ? `form-control is-invalid` : `form-control`"
                        type="number"
                        @keydown="numericOnly"
                        @input="experiment_2_point_6_input_error = false"
                      />
                    </div>
                    <div class="col-3">
                      <label class="mt-4"
                        >{{ $t("Point") }} 7 [{{
                          $store.state.units_system[$store.state.user_data.unit_system].thickness
                        }}]</label
                      >
                      <input
                        v-model.lazy="experiment2Point7"
                        :class="experiment_2_point_7_input_error ? `form-control is-invalid` : `form-control`"
                        type="number"
                        @keydown="numericOnly"
                        @input="experiment_2_point_7_input_error = false"
                      />
                    </div>
                  </div>
                  <div class="row-12 float-end mt-4 mb-2">
                    <soft-button
                      color="success"
                      size="sm"
                      variant="contained"
                      @click="onClickNextPage"
                    >
                      {{ $t("Continue to Experiment") }} 3
                    </soft-button>
                  </div>
                </div>
                <div v-if="currentPage === 3">
                  <div class="row">
                    <h6 class="font-weight">{{ $t("Measurements for Experiment") }} 3</h6>
                    <div class="col-3">
                      <label class="mt-4"
                        >{{ $t("Point") }} 1 [{{
                          $store.state.units_system[$store.state.user_data.unit_system].thickness
                        }}]</label
                      >
                      <input
                        v-model.lazy="experiment3Point1"
                        :class="experiment_3_point_1_input_error ? `form-control is-invalid` : `form-control`"
                        type="number"
                        @keydown="numericOnly"
                        @input="experiment_3_point_1_input_error = false"
                      />
                    </div>
                    <div class="col-3">
                      <label class="mt-4"
                        >{{ $t("Point") }} 2 [{{
                          $store.state.units_system[$store.state.user_data.unit_system].thickness
                        }}]</label
                      >
                      <input
                        v-model.lazy="experiment3Point2"
                        :class="experiment_3_point_2_input_error ? `form-control is-invalid` : `form-control`"
                        type="number"
                        @keydown="numericOnly"
                        @input="experiment_3_point_2_input_error = false"
                      />
                    </div>
                    <div class="col-3">
                      <label class="mt-4"
                        >{{ $t("Point") }} 3 [{{
                          $store.state.units_system[$store.state.user_data.unit_system].thickness
                        }}]</label
                      >
                      <input
                        v-model.lazy="experiment3Point3"
                        :class="experiment_3_point_3_input_error ? `form-control is-invalid` : `form-control`"
                        type="number"
                        @keydown="numericOnly"
                        @input="experiment_3_point_3_input_error = false"
                      />
                    </div>
                    <div class="col-3">
                      <label class="mt-4"
                        >{{ $t("Point") }} 4 [{{
                          $store.state.units_system[$store.state.user_data.unit_system].thickness
                        }}]</label
                      >
                      <input
                        v-model.lazy="experiment3Point4"
                        :class="experiment_3_point_4_input_error ? `form-control is-invalid` : `form-control`"
                        type="number"
                        @keydown="numericOnly"
                        @input="experiment_3_point_4_input_error = false"
                      />
                    </div>
                    <div class="col-3">
                      <label class="mt-4"
                        >{{ $t("Point") }} 5 [{{
                          $store.state.units_system[$store.state.user_data.unit_system].thickness
                        }}]</label
                      >
                      <input
                        v-model.lazy="experiment3Point5"
                        :class="experiment_3_point_5_input_error ? `form-control is-invalid` : `form-control`"
                        type="number"
                        @keydown="numericOnly"
                        @input="experiment_3_point_5_input_error = false"
                      />
                    </div>
                    <div class="col-3">
                      <label class="mt-4"
                        >{{ $t("Point") }} 6 [{{
                          $store.state.units_system[$store.state.user_data.unit_system].thickness
                        }}]</label
                      >
                      <input
                        v-model.lazy="experiment3Point6"
                        :class="experiment_3_point_6_input_error ? `form-control is-invalid` : `form-control`"
                        type="number"
                        @keydown="numericOnly"
                        @input="experiment_3_point_6_input_error = false"
                      />
                    </div>
                    <div class="col-3">
                      <label class="mt-4"
                        >{{ $t("Point") }} 7 [{{
                          $store.state.units_system[$store.state.user_data.unit_system].thickness
                        }}]</label
                      >
                      <input
                        v-model.lazy="experiment3Point7"
                        :class="experiment_3_point_7_input_error ? `form-control is-invalid` : `form-control`"
                        type="number"
                        @keydown="numericOnly"
                        @input="experiment_3_point_7_input_error = false"
                      />
                    </div>
                  </div>
                  <div class="row-12 float-end mt-4 mb-2">
                    <soft-button
                      color="success"
                      size="sm"
                      variant="contained"
                      @click="onClickNextPage"
                    >
                      {{ $t("Continue to Experiment") }} 4
                    </soft-button>
                  </div>
                </div>
                <div v-if="currentPage === 4">
                  <div class="row">
                    <h6 class="font-weight">{{ $t("Measurements for Experiment") }} 4</h6>
                    <div class="col-3">
                      <label class="mt-4"
                        >{{ $t("Point") }} 1 [{{
                          $store.state.units_system[$store.state.user_data.unit_system].thickness
                        }}]</label
                      >
                      <input
                        v-model.lazy="experiment4Point1"
                        :class="experiment_4_point_1_input_error ? `form-control is-invalid` : `form-control`"
                        type="number"
                        @keydown="numericOnly"
                        @input="experiment_4_point_1_input_error = false"
                      />
                    </div>
                    <div class="col-3">
                      <label class="mt-4"
                        >{{ $t("Point") }} 2 [{{
                          $store.state.units_system[$store.state.user_data.unit_system].thickness
                        }}]</label
                      >
                      <input
                        v-model.lazy="experiment4Point2"
                        :class="experiment_4_point_2_input_error ? `form-control is-invalid` : `form-control`"
                        type="number"
                        @keydown="numericOnly"
                        @input="experiment_4_point_2_input_error = false"
                      />
                    </div>
                    <div class="col-3">
                      <label class="mt-4"
                        >{{ $t("Point") }} 3 [{{
                          $store.state.units_system[$store.state.user_data.unit_system].thickness
                        }}]</label
                      >
                      <input
                        v-model.lazy="experiment4Point3"
                        :class="experiment_4_point_3_input_error ? `form-control is-invalid` : `form-control`"
                        type="number"
                        @keydown="numericOnly"
                        @input="experiment_4_point_3_input_error = false"
                      />
                    </div>
                    <div class="col-3">
                      <label class="mt-4"
                        >{{ $t("Point") }} 4 [{{
                          $store.state.units_system[$store.state.user_data.unit_system].thickness
                        }}]</label
                      >
                      <input
                        v-model.lazy="experiment4Point4"
                        :class="experiment_4_point_4_input_error ? `form-control is-invalid` : `form-control`"
                        type="number"
                        @keydown="numericOnly"
                        @input="experiment_4_point_4_input_error = false"
                      />
                    </div>
                    <div class="col-3">
                      <label class="mt-4"
                        >{{ $t("Point") }} 5 [{{
                          $store.state.units_system[$store.state.user_data.unit_system].thickness
                        }}]</label
                      >
                      <input
                        v-model.lazy="experiment4Point5"
                        :class="experiment_4_point_5_input_error ? `form-control is-invalid` : `form-control`"
                        type="number"
                        @keydown="numericOnly"
                        @input="experiment_4_point_5_input_error = false"
                      />
                    </div>
                    <div class="col-3">
                      <label class="mt-4"
                        >{{ $t("Point") }} 6 [{{
                          $store.state.units_system[$store.state.user_data.unit_system].thickness
                        }}]</label
                      >
                      <input
                        v-model.lazy="experiment4Point6"
                        :class="experiment_4_point_6_input_error ? `form-control is-invalid` : `form-control`"
                        type="number"
                        @keydown="numericOnly"
                        @input="experiment_4_point_6_input_error = false"
                      />
                    </div>
                    <div class="col-3">
                      <label class="mt-4"
                        >{{ $t("Point") }} 7 [{{
                          $store.state.units_system[$store.state.user_data.unit_system].thickness
                        }}]</label
                      >
                      <input
                        v-model.lazy="experiment4Point7"
                        :class="experiment_4_point_7_input_error ? `form-control is-invalid` : `form-control`"
                        type="number"
                        @keydown="numericOnly"
                        @input="experiment_4_point_7_input_error = false"
                      />
                    </div>
                  </div>
                  <div class="row-12 float-end mt-4 mb-2">
                    <soft-button
                      color="success"
                      size="sm"
                      variant="contained"
                      @click="onClickNextPage"
                    >
                      {{ $t("Continue to Experiment") }} 5
                    </soft-button>
                  </div>
                </div>
                <div v-if="currentPage === 5">
                  <div class="row">
                    <h6 class="font-weight">{{ $t("Measurements for Experiment") }} 5</h6>
                    <div class="col-3">
                      <label class="mt-4"
                        >{{ $t("Point") }} 1 [{{
                          $store.state.units_system[$store.state.user_data.unit_system].thickness
                        }}]</label
                      >
                      <input
                        v-model.lazy="experiment5Point1"
                        :class="experiment_5_point_1_input_error ? `form-control is-invalid` : `form-control`"
                        type="number"
                        @keydown="numericOnly"
                        @input="experiment_5_point_1_input_error = false"
                      />
                    </div>
                    <div class="col-3">
                      <label class="mt-4"
                        >{{ $t("Point") }} 2 [{{
                          $store.state.units_system[$store.state.user_data.unit_system].thickness
                        }}]</label
                      >
                      <input
                        v-model.lazy="experiment5Point2"
                        :class="experiment_5_point_2_input_error ? `form-control is-invalid` : `form-control`"
                        type="number"
                        @keydown="numericOnly"
                        @input="experiment_5_point_2_input_error = false"
                      />
                    </div>
                    <div class="col-3">
                      <label class="mt-4"
                        >{{ $t("Point") }} 3 [{{
                          $store.state.units_system[$store.state.user_data.unit_system].thickness
                        }}]</label
                      >
                      <input
                        v-model.lazy="experiment5Point3"
                        :class="experiment_5_point_3_input_error ? `form-control is-invalid` : `form-control`"
                        type="number"
                        @keydown="numericOnly"
                        @input="experiment_5_point_3_input_error = false"
                      />
                    </div>
                    <div class="col-3">
                      <label class="mt-4"
                        >{{ $t("Point") }} 4 [{{
                          $store.state.units_system[$store.state.user_data.unit_system].thickness
                        }}]</label
                      >
                      <input
                        v-model.lazy="experiment5Point4"
                        :class="experiment_5_point_4_input_error ? `form-control is-invalid` : `form-control`"
                        type="number"
                        @keydown="numericOnly"
                        @input="experiment_5_point_4_input_error = false"
                      />
                    </div>
                    <div class="col-3">
                      <label class="mt-4"
                        >{{ $t("Point") }} 5 [{{
                          $store.state.units_system[$store.state.user_data.unit_system].thickness
                        }}]</label
                      >
                      <input
                        v-model.lazy="experiment5Point5"
                        :class="experiment_5_point_5_input_error ? `form-control is-invalid` : `form-control`"
                        type="number"
                        @keydown="numericOnly"
                        @input="experiment_5_point_5_input_error = false"
                      />
                    </div>
                    <div class="col-3">
                      <label class="mt-4"
                        >{{ $t("Point") }} 6 [{{
                          $store.state.units_system[$store.state.user_data.unit_system].thickness
                        }}]</label
                      >
                      <input
                        v-model.lazy="experiment5Point6"
                        :class="experiment_5_point_6_input_error ? `form-control is-invalid` : `form-control`"
                        type="number"
                        @keydown="numericOnly"
                        @input="experiment_5_point_6_input_error = false"
                      />
                    </div>
                    <div class="col-3">
                      <label class="mt-4"
                        >{{ $t("Point") }} 7 [{{
                          $store.state.units_system[$store.state.user_data.unit_system].thickness
                        }}]</label
                      >
                      <input
                        v-model.lazy="experiment5Point7"
                        :class="experiment_5_point_7_input_error ? `form-control is-invalid` : `form-control`"
                        type="number"
                        @keydown="numericOnly"
                        @input="experiment_5_point_7_input_error = false"
                      />
                    </div>
                  </div>
                  <div class="row-12 float-end mt-4 mb-2">
                    <soft-button
                      color="success"
                      size="sm"
                      variant="contained"
                      @click="onClickNextPage"
                    >
                      {{ $t("Continue to Experiment") }} 6
                    </soft-button>
                  </div>
                </div>
                <div v-if="currentPage === 6">
                  <div class="row">
                    <h6 class="font-weight">{{ $t("Measurements for Experiment") }} 6</h6>
                    <div class="col-3">
                      <label class="mt-4"
                        >{{ $t("Point") }} 1 [{{
                          $store.state.units_system[$store.state.user_data.unit_system].thickness
                        }}]</label
                      >
                      <input
                        v-model.lazy="experiment6Point1"
                        :class="experiment_6_point_1_input_error ? `form-control is-invalid` : `form-control`"
                        type="number"
                        @keydown="numericOnly"
                        @input="experiment_6_point_1_input_error = false"
                      />
                    </div>
                    <div class="col-3">
                      <label class="mt-4"
                        >{{ $t("Point") }} 2 [{{
                          $store.state.units_system[$store.state.user_data.unit_system].thickness
                        }}]</label
                      >
                      <input
                        v-model.lazy="experiment6Point2"
                        :class="experiment_6_point_2_input_error ? `form-control is-invalid` : `form-control`"
                        type="number"
                        @keydown="numericOnly"
                        @input="experiment_6_point_2_input_error = false"
                      />
                    </div>
                    <div class="col-3">
                      <label class="mt-4"
                        >{{ $t("Point") }} 3 [{{
                          $store.state.units_system[$store.state.user_data.unit_system].thickness
                        }}]</label
                      >
                      <input
                        v-model.lazy="experiment6Point3"
                        :class="experiment_6_point_3_input_error ? `form-control is-invalid` : `form-control`"
                        type="number"
                        @keydown="numericOnly"
                        @input="experiment_6_point_3_input_error = false"
                      />
                    </div>
                    <div class="col-3">
                      <label class="mt-4"
                        >{{ $t("Point") }} 4 [{{
                          $store.state.units_system[$store.state.user_data.unit_system].thickness
                        }}]</label
                      >
                      <input
                        v-model.lazy="experiment6Point4"
                        :class="experiment_6_point_4_input_error ? `form-control is-invalid` : `form-control`"
                        type="number"
                        @keydown="numericOnly"
                        @input="experiment_6_point_4_input_error = false"
                      />
                    </div>
                    <div class="col-3">
                      <label class="mt-4"
                        >{{ $t("Point") }} 5 [{{
                          $store.state.units_system[$store.state.user_data.unit_system].thickness
                        }}]</label
                      >
                      <input
                        v-model.lazy="experiment6Point5"
                        :class="experiment_6_point_5_input_error ? `form-control is-invalid` : `form-control`"
                        type="number"
                        @keydown="numericOnly"
                        @input="experiment_6_point_5_input_error = false"
                      />
                    </div>
                    <div class="col-3">
                      <label class="mt-4"
                        >{{ $t("Point") }} 6 [{{
                          $store.state.units_system[$store.state.user_data.unit_system].thickness
                        }}]</label
                      >
                      <input
                        v-model.lazy="experiment6Point6"
                        :class="experiment_6_point_6_input_error ? `form-control is-invalid` : `form-control`"
                        type="number"
                        @keydown="numericOnly"
                        @input="experiment_6_point_6_input_error = false"
                      />
                    </div>
                    <div class="col-3">
                      <label class="mt-4"
                        >{{ $t("Point") }} 7 [{{
                          $store.state.units_system[$store.state.user_data.unit_system].thickness
                        }}]</label
                      >
                      <input
                        v-model.lazy="experiment6Point7"
                        :class="experiment_6_point_7_input_error ? `form-control is-invalid` : `form-control`"
                        type="number"
                        @keydown="numericOnly"
                        @input="experiment_6_point_7_input_error = false"
                      />
                    </div>
                  </div>
                  <div class="row-12 float-end mt-4 mb-2">
                    <soft-button
                      color="success"
                      size="sm"
                      variant="contained"
                      @click="onClickNextPage"
                    >
                      {{ $t("Continue to Experiment") }} 7
                    </soft-button>
                  </div>
                </div>
                <div v-if="currentPage === 7">
                  <div class="row">
                    <h6 class="font-weight">{{ $t("Measurements for Experiment") }} 7</h6>
                    <div class="col-3">
                      <label class="mt-4"
                        >{{ $t("Point") }} 1 [{{
                          $store.state.units_system[$store.state.user_data.unit_system].thickness
                        }}]</label
                      >
                      <input
                        v-model.lazy="experiment7Point1"
                        :class="experiment_7_point_1_input_error ? `form-control is-invalid` : `form-control`"
                        type="number"
                        @keydown="numericOnly"
                        @input="experiment_7_point_1_input_error = false"
                      />
                    </div>
                    <div class="col-3">
                      <label class="mt-4"
                        >{{ $t("Point") }} 2 [{{
                          $store.state.units_system[$store.state.user_data.unit_system].thickness
                        }}]</label
                      >
                      <input
                        v-model.lazy="experiment7Point2"
                        :class="experiment_7_point_2_input_error ? `form-control is-invalid` : `form-control`"
                        type="number"
                        @keydown="numericOnly"
                        @input="experiment_7_point_2_input_error = false"
                      />
                    </div>
                    <div class="col-3">
                      <label class="mt-4"
                        >{{ $t("Point") }} 3 [{{
                          $store.state.units_system[$store.state.user_data.unit_system].thickness
                        }}]</label
                      >
                      <input
                        v-model.lazy="experiment7Point3"
                        :class="experiment_7_point_3_input_error ? `form-control is-invalid` : `form-control`"
                        type="number"
                        @keydown="numericOnly"
                        @input="experiment_7_point_3_input_error = false"
                      />
                    </div>
                    <div class="col-3">
                      <label class="mt-4"
                        >{{ $t("Point") }} 4 [{{
                          $store.state.units_system[$store.state.user_data.unit_system].thickness
                        }}]</label
                      >
                      <input
                        v-model.lazy="experiment7Point4"
                        :class="experiment_7_point_4_input_error ? `form-control is-invalid` : `form-control`"
                        type="number"
                        @keydown="numericOnly"
                        @input="experiment_7_point_4_input_error = false"
                      />
                    </div>
                    <div class="col-3">
                      <label class="mt-4"
                        >{{ $t("Point") }} 5 [{{
                          $store.state.units_system[$store.state.user_data.unit_system].thickness
                        }}]</label
                      >
                      <input
                        v-model.lazy="experiment7Point5"
                        :class="experiment_7_point_5_input_error ? `form-control is-invalid` : `form-control`"
                        type="number"
                        @keydown="numericOnly"
                        @input="experiment_7_point_5_input_error = false"
                      />
                    </div>
                    <div class="col-3">
                      <label class="mt-4"
                        >{{ $t("Point") }} 6 [{{
                          $store.state.units_system[$store.state.user_data.unit_system].thickness
                        }}]</label
                      >
                      <input
                        v-model.lazy="experiment7Point6"
                        :class="experiment_7_point_6_input_error ? `form-control is-invalid` : `form-control`"
                        type="number"
                        @keydown="numericOnly"
                        @input="experiment_7_point_6_input_error = false"
                      />
                    </div>
                    <div class="col-3">
                      <label class="mt-4"
                        >{{ $t("Point") }} 7 [{{
                          $store.state.units_system[$store.state.user_data.unit_system].thickness
                        }}]</label
                      >
                      <input
                        v-model.lazy="experiment7Point7"
                        :class="experiment_7_point_7_input_error ? `form-control is-invalid` : `form-control`"
                        type="number"
                        @keydown="numericOnly"
                        @input="experiment_7_point_7_input_error = false"
                      />
                    </div>
                  </div>
                  <div class="row-12 float-end mt-4 mb-2">
                    <soft-button
                      color="success"
                      size="sm"
                      variant="contained"
                      @click="onClickNextPage"
                    >
                      {{ $t("Continue to Experiment") }} 8
                    </soft-button>
                  </div>
                </div>
                <div v-if="currentPage === 8">
                  <div class="row">
                    <h6 class="font-weight">{{ $t("Measurements for Experiment") }} 8</h6>
                    <div class="col-3">
                      <label class="mt-4"
                        >{{ $t("Point") }} 1 [{{
                          $store.state.units_system[$store.state.user_data.unit_system].thickness
                        }}]</label
                      >
                      <input
                        v-model.lazy="experiment8Point1"
                        :class="experiment_8_point_1_input_error ? `form-control is-invalid` : `form-control`"
                        type="number"
                        @keydown="numericOnly"
                        @input="experiment_8_point_1_input_error = false"
                      />
                    </div>
                    <div class="col-3">
                      <label class="mt-4"
                        >{{ $t("Point") }} 2 [{{
                          $store.state.units_system[$store.state.user_data.unit_system].thickness
                        }}]</label
                      >
                      <input
                        v-model.lazy="experiment8Point2"
                        :class="experiment_8_point_2_input_error ? `form-control is-invalid` : `form-control`"
                        type="number"
                        @keydown="numericOnly"
                        @input="experiment_8_point_2_input_error = false"
                      />
                    </div>
                    <div class="col-3">
                      <label class="mt-4"
                        >{{ $t("Point") }} 3 [{{
                          $store.state.units_system[$store.state.user_data.unit_system].thickness
                        }}]</label
                      >
                      <input
                        v-model.lazy="experiment8Point3"
                        :class="experiment_8_point_3_input_error ? `form-control is-invalid` : `form-control`"
                        type="number"
                        @keydown="numericOnly"
                        @input="experiment_8_point_3_input_error = false"
                      />
                    </div>
                    <div class="col-3">
                      <label class="mt-4"
                        >{{ $t("Point") }} 4 [{{
                          $store.state.units_system[$store.state.user_data.unit_system].thickness
                        }}]</label
                      >
                      <input
                        v-model.lazy="experiment8Point4"
                        :class="experiment_8_point_4_input_error ? `form-control is-invalid` : `form-control`"
                        type="number"
                        @keydown="numericOnly"
                        @input="experiment_8_point_4_input_error = false"
                      />
                    </div>
                    <div class="col-3">
                      <label class="mt-4"
                        >{{ $t("Point") }} 5 [{{
                          $store.state.units_system[$store.state.user_data.unit_system].thickness
                        }}]</label
                      >
                      <input
                        v-model.lazy="experiment8Point5"
                        :class="experiment_8_point_5_input_error ? `form-control is-invalid` : `form-control`"
                        type="number"
                        @keydown="numericOnly"
                        @input="experiment_8_point_5_input_error = false"
                      />
                    </div>
                    <div class="col-3">
                      <label class="mt-4"
                        >{{ $t("Point") }} 6 [{{
                          $store.state.units_system[$store.state.user_data.unit_system].thickness
                        }}]</label
                      >
                      <input
                        v-model.lazy="experiment8Point6"
                        :class="experiment_8_point_6_input_error ? `form-control is-invalid` : `form-control`"
                        type="number"
                        @keydown="numericOnly"
                        @input="experiment_8_point_6_input_error = false"
                      />
                    </div>
                    <div class="col-3">
                      <label class="mt-4"
                        >{{ $t("Point") }} 7 [{{
                          $store.state.units_system[$store.state.user_data.unit_system].thickness
                        }}]</label
                      >
                      <input
                        v-model.lazy="experiment8Point7"
                        :class="experiment_8_point_7_input_error ? `form-control is-invalid` : `form-control`"
                        type="number"
                        @keydown="numericOnly"
                        @input="experiment_8_point_7_input_error = false"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="calculation_in_progress">
                <div style="width: 100%; height: 100%">
                  <div
                    class="spinner-border"
                    style="margin: auto; display: table; width: 100px; height: 100px"
                    role="status"
                  >
                    <span class="sr-only">{{ $t("Fitting PowderModel Distribution from measurements") }}...</span>
                  </div>
                </div>
                <p
                  class="mt-3"
                  style="margin: auto; text-align: center"
                >
                  {{ $t("Fitting PowderModel Distribution from measurements") }}...
                </p>
              </div>
            </div>
          </div>
          <div
            v-if="!calculation_in_progress"
            class="mt-2 d-flex flex-column justify-content-center"
          >
            <button
              type="button"
              class="mt-4 mb-2 btn bg-gradient-success"
              @click="postPowderModel"
            >
              {{ $t("Create Powder Distribution Model") }}
            </button>
          </div>
          <div v-if="!calculation_in_progress">
            <soft-pagination
              class="justify-content-center mt-3 mb-3"
              color="success"
              aria-label="Experiment thickness measures input pages"
            >
              <soft-pagination-item
                prev
                :disabled="isInFirstPage"
                @click="onClickPrevPage"
              />
              <soft-pagination-item
                label="0"
                :active="currentPage === 0"
                @click="currentPage = 0"
              />
              <soft-pagination-item
                label="1"
                :active="currentPage === 1"
                @click="currentPage = 1"
              />
              <soft-pagination-item
                label="2"
                :active="currentPage === 2"
                @click="currentPage = 2"
              />
              <soft-pagination-item
                label="3"
                :active="currentPage === 3"
                @click="currentPage = 3"
              />
              <soft-pagination-item
                label="4"
                :active="currentPage === 4"
                @click="currentPage = 4"
              />
              <soft-pagination-item
                label="5"
                :active="currentPage === 5"
                @click="currentPage = 5"
              />
              <soft-pagination-item
                label="6"
                :active="currentPage === 6"
                @click="currentPage = 6"
              />
              <soft-pagination-item
                label="7"
                :active="currentPage === 7"
                @click="currentPage = 7"
              />
              <soft-pagination-item
                label="8"
                :active="currentPage === 8"
                @click="currentPage = 8"
              />
              <soft-pagination-item
                next
                :disabled="isInLastPage"
                @click="onClickNextPage"
              />
            </soft-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import setTooltip from "@/assets/js/tooltip.js";
import router from "@/router";
import SoftPagination from "@/components/SoftPagination.vue";
import SoftPaginationItem from "@/components/SoftPaginationItem.vue";
import SoftButton from "@/components/SoftButton.vue";

export default {
  name: "AdvancedPowderModelAdd",
  components: {
    SoftButton,
    SoftPagination,
    SoftPaginationItem,
  },
  props: {
    lineId: {
      type: String,
      default: null,
    },
  },
  data() {
    this.$i18n.locale = this.$store.state.user_data.language;
    return {
      powdermodel: {
        id: null,
        name: null,
        line: null,
        h_distance: null,
        h_air: null,
        h_bias: null,
        v_distance: null,
        v_air: null,
        v_bias: null,
        beta_distance: null,
        beta_air: null,
        beta_bias: null,
        m_distance: null,
        m_air: null,
        m_bias: null,
      },
      powdermodel_calibration_measures: {
        calibration_type: "advanced",
        calibration_line_speed: null,

        powder_manufacturer: null,
        powder_article_id: null,
        batch_id: null,
        powder_amount: null,
        additional_info: null,
        booth_side: null,
        booth_temperature: null,
        booth_humidity: null,
        guns_spacing: null,

        max_pistol_to_substrate_distance_measurements: null,
        min_pistol_to_substrate_distance_measurements: null,
        max_air_parameter_measurements: null,
        min_air_parameter_measurements: null,

        experiment_1_point_1: null,
        experiment_1_point_2: null,
        experiment_1_point_3: null,
        experiment_1_point_4: null,
        experiment_1_point_5: null,
        experiment_1_point_6: null,
        experiment_1_point_7: null,

        experiment_2_point_1: null,
        experiment_2_point_2: null,
        experiment_2_point_3: null,
        experiment_2_point_4: null,
        experiment_2_point_5: null,
        experiment_2_point_6: null,
        experiment_2_point_7: null,

        experiment_3_point_1: null,
        experiment_3_point_2: null,
        experiment_3_point_3: null,
        experiment_3_point_4: null,
        experiment_3_point_5: null,
        experiment_3_point_6: null,
        experiment_3_point_7: null,

        experiment_4_point_1: null,
        experiment_4_point_2: null,
        experiment_4_point_3: null,
        experiment_4_point_4: null,
        experiment_4_point_5: null,
        experiment_4_point_6: null,
        experiment_4_point_7: null,

        experiment_5_point_1: null,
        experiment_5_point_2: null,
        experiment_5_point_3: null,
        experiment_5_point_4: null,
        experiment_5_point_5: null,
        experiment_5_point_6: null,
        experiment_5_point_7: null,

        experiment_6_point_1: null,
        experiment_6_point_2: null,
        experiment_6_point_3: null,
        experiment_6_point_4: null,
        experiment_6_point_5: null,
        experiment_6_point_6: null,
        experiment_6_point_7: null,

        experiment_7_point_1: null,
        experiment_7_point_2: null,
        experiment_7_point_3: null,
        experiment_7_point_4: null,
        experiment_7_point_5: null,
        experiment_7_point_6: null,
        experiment_7_point_7: null,

        experiment_8_point_1: null,
        experiment_8_point_2: null,
        experiment_8_point_3: null,
        experiment_8_point_4: null,
        experiment_8_point_5: null,
        experiment_8_point_6: null,
        experiment_8_point_7: null,
      },
      calculation_in_progress: false,
      line: [],
      booth_side_options: ["right", "left"],
      guns_spacing_options: ["evenly spaced", "unevenly spaced"],
      powder_manufacturer_options: ["AkzoNobel", "Other"],
      firstPage: 0,
      currentPage: 0,
      lastPage: 8,
      s3_storage_base_URL: "https://d21tewxstkbxbj.cloudfront.net/",
      max_metric_decimals: 2,
      max_imperial_decimals: 2,
      max_temperature_decimals: 1,
      name_input_error: false,
      line_input_error: false,
      min_pistol_to_substrate_distance_input_error: false,
      max_pistol_to_substrate_distance_input_error: false,
      min_air_parameter_input_error: false,
      max_air_parameter_input_error: false,
      line_speed_input_error: false,
      powder_article_id_input_error: false,
      batch_id_input_error: false,
      nozzles_type_input_error: false,
      powder_manufacturer_input_error: false,
      experiment_1_point_1_input_error: false,
      experiment_1_point_2_input_error: false,
      experiment_1_point_3_input_error: false,
      experiment_1_point_4_input_error: false,
      experiment_1_point_5_input_error: false,
      experiment_1_point_6_input_error: false,
      experiment_1_point_7_input_error: false,

      experiment_2_point_1_input_error: false,
      experiment_2_point_2_input_error: false,
      experiment_2_point_3_input_error: false,
      experiment_2_point_4_input_error: false,
      experiment_2_point_5_input_error: false,
      experiment_2_point_6_input_error: false,
      experiment_2_point_7_input_error: false,

      experiment_3_point_1_input_error: false,
      experiment_3_point_2_input_error: false,
      experiment_3_point_3_input_error: false,
      experiment_3_point_4_input_error: false,
      experiment_3_point_5_input_error: false,
      experiment_3_point_6_input_error: false,
      experiment_3_point_7_input_error: false,

      experiment_4_point_1_input_error: false,
      experiment_4_point_2_input_error: false,
      experiment_4_point_3_input_error: false,
      experiment_4_point_4_input_error: false,
      experiment_4_point_5_input_error: false,
      experiment_4_point_6_input_error: false,
      experiment_4_point_7_input_error: false,

      experiment_5_point_1_input_error: false,
      experiment_5_point_2_input_error: false,
      experiment_5_point_3_input_error: false,
      experiment_5_point_4_input_error: false,
      experiment_5_point_5_input_error: false,
      experiment_5_point_6_input_error: false,
      experiment_5_point_7_input_error: false,

      experiment_6_point_1_input_error: false,
      experiment_6_point_2_input_error: false,
      experiment_6_point_3_input_error: false,
      experiment_6_point_4_input_error: false,
      experiment_6_point_5_input_error: false,
      experiment_6_point_6_input_error: false,
      experiment_6_point_7_input_error: false,

      experiment_7_point_1_input_error: false,
      experiment_7_point_2_input_error: false,
      experiment_7_point_3_input_error: false,
      experiment_7_point_4_input_error: false,
      experiment_7_point_5_input_error: false,
      experiment_7_point_6_input_error: false,
      experiment_7_point_7_input_error: false,

      experiment_8_point_1_input_error: false,
      experiment_8_point_2_input_error: false,
      experiment_8_point_3_input_error: false,
      experiment_8_point_4_input_error: false,
      experiment_8_point_5_input_error: false,
      experiment_8_point_6_input_error: false,
      experiment_8_point_7_input_error: false,
    };
  },

  computed: {
    maxPistolToSubstrateDistance: {
      get() {
        if (
          this.powdermodel_calibration_measures.max_pistol_to_substrate_distance_measurements == null ||
          this.powdermodel_calibration_measures.max_pistol_to_substrate_distance_measurements == ""
        ) {
          return this.powdermodel_calibration_measures.max_pistol_to_substrate_distance_measurements;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (
              this.powdermodel_calibration_measures.max_pistol_to_substrate_distance_measurements *
              this.$store.state.conversion_factors.cm_to_in
            ).toFixed(this.max_imperial_decimals),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(
            this.powdermodel_calibration_measures.max_pistol_to_substrate_distance_measurements.toFixed(
              this.max_metric_decimals,
            ),
          );
        } else {
          return null;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.powdermodel_calibration_measures.max_pistol_to_substrate_distance_measurements = value;
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.powdermodel_calibration_measures.max_pistol_to_substrate_distance_measurements =
              value / this.$store.state.conversion_factors.cm_to_in;
          } else {
            this.powdermodel_calibration_measures.max_pistol_to_substrate_distance_measurements = value;
          }
        }
      },
    },
    minPistolToSubstrateDistance: {
      get() {
        if (
          this.powdermodel_calibration_measures.min_pistol_to_substrate_distance_measurements == null ||
          this.powdermodel_calibration_measures.min_pistol_to_substrate_distance_measurements == ""
        ) {
          return this.powdermodel_calibration_measures.min_pistol_to_substrate_distance_measurements;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (
              this.powdermodel_calibration_measures.min_pistol_to_substrate_distance_measurements *
              this.$store.state.conversion_factors.cm_to_in
            ).toFixed(this.max_imperial_decimals),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(
            this.powdermodel_calibration_measures.min_pistol_to_substrate_distance_measurements.toFixed(
              this.max_metric_decimals,
            ),
          );
        } else {
          return null;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.powdermodel_calibration_measures.min_pistol_to_substrate_distance_measurements = value;
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.powdermodel_calibration_measures.min_pistol_to_substrate_distance_measurements =
              value / this.$store.state.conversion_factors.cm_to_in;
          } else {
            this.powdermodel_calibration_measures.min_pistol_to_substrate_distance_measurements = value;
          }
        }
      },
    },
    calibrationLineSpeed: {
      get() {
        if (
          this.powdermodel_calibration_measures.calibration_line_speed == null ||
          this.powdermodel_calibration_measures.calibration_line_speed == ""
        ) {
          return this.powdermodel_calibration_measures.calibration_line_speed;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (
              this.powdermodel_calibration_measures.calibration_line_speed *
              this.$store.state.conversion_factors.m_to_ft
            ).toFixed(this.max_imperial_decimals),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(
            this.powdermodel_calibration_measures.calibration_line_speed.toFixed(this.max_metric_decimals),
          );
        } else {
          return null;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.powdermodel_calibration_measures.calibration_line_speed = value;
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.powdermodel_calibration_measures.calibration_line_speed =
              value / this.$store.state.conversion_factors.m_to_ft;
          } else {
            this.powdermodel_calibration_measures.calibration_line_speed = value;
          }
        }
      },
    },
    boothTemperature: {
      get() {
        if (
          this.powdermodel_calibration_measures.booth_temperature == null ||
          this.powdermodel_calibration_measures.booth_temperature == ""
        ) {
          return this.powdermodel_calibration_measures.booth_temperature;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (
              this.powdermodel_calibration_measures.booth_temperature * this.$store.state.conversion_factors.c_to_f +
              this.$store.state.conversion_factors.c_to_f_offset
            ).toFixed(this.max_temperature_decimals),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(
            this.powdermodel_calibration_measures.booth_temperature.toFixed(this.max_temperature_decimals),
          );
        } else {
          return null;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.powdermodel_calibration_measures.booth_temperature = value;
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.powdermodel_calibration_measures.booth_temperature =
              (value - this.$store.state.conversion_factors.c_to_f_offset) /
              this.$store.state.conversion_factors.c_to_f;
          } else if (this.$store.state.user_data.unit_system === "metric") {
            this.powdermodel_calibration_measures.booth_temperature = value;
          } else {
            this.powdermodel_calibration_measures.booth_temperature = null;
          }
        }
      },
    },
    operationalHoursLabel: {
      get() {
        if (this.line.pump_type == "injector_venturi") {
          return this.$t("Operational Hours Injector");
        } else if (this.line.pump_type == "dense_phase") {
          return this.$t("Operational Hours Since Last Maintanance");
        } else {
          return this.$t("Pump Operational Hours");
        }
      },
    },
    powderLabel: {
      get() {
        if (
          this.powdermodel_calibration_measures.powder_manufacturer === null ||
          this.powdermodel_calibration_measures.powder_manufacturer === ""
        ) {
          return this.$t("Powder ID");
        } else if (this.powdermodel_calibration_measures.powder_manufacturer.toLowerCase().includes("akzo")) {
          return "Interpon ID";
        } else {
          return this.$t("Powder ID");
        }
      },
    },
    experiment1Point1: {
      get() {
        if (
          this.powdermodel_calibration_measures.experiment_1_point_1 == null ||
          this.powdermodel_calibration_measures.experiment_1_point_1 == ""
        ) {
          return this.powdermodel_calibration_measures.experiment_1_point_1;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (
              this.powdermodel_calibration_measures.experiment_1_point_1 *
              this.$store.state.conversion_factors.um_to_mil
            ).toFixed(this.max_imperial_decimals),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(
            this.powdermodel_calibration_measures.experiment_1_point_1.toFixed(this.max_metric_decimals),
          );
        } else {
          return null;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.powdermodel_calibration_measures.experiment_1_point_1 = value;
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.powdermodel_calibration_measures.experiment_1_point_1 =
              value / this.$store.state.conversion_factors.um_to_mil;
          } else {
            this.powdermodel_calibration_measures.experiment_1_point_1 = value;
          }
        }
      },
    },
    experiment1Point2: {
      get() {
        if (
          this.powdermodel_calibration_measures.experiment_1_point_2 == null ||
          this.powdermodel_calibration_measures.experiment_1_point_2 == ""
        ) {
          return this.powdermodel_calibration_measures.experiment_1_point_2;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (
              this.powdermodel_calibration_measures.experiment_1_point_2 *
              this.$store.state.conversion_factors.um_to_mil
            ).toFixed(this.max_imperial_decimals),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(
            this.powdermodel_calibration_measures.experiment_1_point_2.toFixed(this.max_metric_decimals),
          );
        } else {
          return null;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.powdermodel_calibration_measures.experiment_1_point_2 = value;
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.powdermodel_calibration_measures.experiment_1_point_2 =
              value / this.$store.state.conversion_factors.um_to_mil;
          } else {
            this.powdermodel_calibration_measures.experiment_1_point_2 = value;
          }
        }
      },
    },
    experiment1Point3: {
      get() {
        if (
          this.powdermodel_calibration_measures.experiment_1_point_3 == null ||
          this.powdermodel_calibration_measures.experiment_1_point_3 == ""
        ) {
          return this.powdermodel_calibration_measures.experiment_1_point_3;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (
              this.powdermodel_calibration_measures.experiment_1_point_3 *
              this.$store.state.conversion_factors.um_to_mil
            ).toFixed(this.max_imperial_decimals),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(
            this.powdermodel_calibration_measures.experiment_1_point_3.toFixed(this.max_metric_decimals),
          );
        } else {
          return null;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.powdermodel_calibration_measures.experiment_1_point_3 = value;
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.powdermodel_calibration_measures.experiment_1_point_3 =
              value / this.$store.state.conversion_factors.um_to_mil;
          } else {
            this.powdermodel_calibration_measures.experiment_1_point_3 = value;
          }
        }
      },
    },
    experiment1Point4: {
      get() {
        if (
          this.powdermodel_calibration_measures.experiment_1_point_4 == null ||
          this.powdermodel_calibration_measures.experiment_1_point_4 == ""
        ) {
          return this.powdermodel_calibration_measures.experiment_1_point_4;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (
              this.powdermodel_calibration_measures.experiment_1_point_4 *
              this.$store.state.conversion_factors.um_to_mil
            ).toFixed(this.max_imperial_decimals),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(
            this.powdermodel_calibration_measures.experiment_1_point_4.toFixed(this.max_metric_decimals),
          );
        } else {
          return null;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.powdermodel_calibration_measures.experiment_1_point_4 = value;
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.powdermodel_calibration_measures.experiment_1_point_4 =
              value / this.$store.state.conversion_factors.um_to_mil;
          } else {
            this.powdermodel_calibration_measures.experiment_1_point_4 = value;
          }
        }
      },
    },
    experiment1Point5: {
      get() {
        if (
          this.powdermodel_calibration_measures.experiment_1_point_5 == null ||
          this.powdermodel_calibration_measures.experiment_1_point_5 == ""
        ) {
          return this.powdermodel_calibration_measures.experiment_1_point_5;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (
              this.powdermodel_calibration_measures.experiment_1_point_5 *
              this.$store.state.conversion_factors.um_to_mil
            ).toFixed(this.max_imperial_decimals),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(
            this.powdermodel_calibration_measures.experiment_1_point_5.toFixed(this.max_metric_decimals),
          );
        } else {
          return null;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.powdermodel_calibration_measures.experiment_1_point_5 = value;
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.powdermodel_calibration_measures.experiment_1_point_5 =
              value / this.$store.state.conversion_factors.um_to_mil;
          } else {
            this.powdermodel_calibration_measures.experiment_1_point_5 = value;
          }
        }
      },
    },
    experiment1Point6: {
      get() {
        if (
          this.powdermodel_calibration_measures.experiment_1_point_6 == null ||
          this.powdermodel_calibration_measures.experiment_1_point_6 == ""
        ) {
          return this.powdermodel_calibration_measures.experiment_1_point_6;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (
              this.powdermodel_calibration_measures.experiment_1_point_6 *
              this.$store.state.conversion_factors.um_to_mil
            ).toFixed(this.max_imperial_decimals),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(
            this.powdermodel_calibration_measures.experiment_1_point_6.toFixed(this.max_metric_decimals),
          );
        } else {
          return null;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.powdermodel_calibration_measures.experiment_1_point_6 = value;
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.powdermodel_calibration_measures.experiment_1_point_6 =
              value / this.$store.state.conversion_factors.um_to_mil;
          } else {
            this.powdermodel_calibration_measures.experiment_1_point_6 = value;
          }
        }
      },
    },
    experiment1Point7: {
      get() {
        if (
          this.powdermodel_calibration_measures.experiment_1_point_7 == null ||
          this.powdermodel_calibration_measures.experiment_1_point_7 == ""
        ) {
          return this.powdermodel_calibration_measures.experiment_1_point_7;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (
              this.powdermodel_calibration_measures.experiment_1_point_7 *
              this.$store.state.conversion_factors.um_to_mil
            ).toFixed(this.max_imperial_decimals),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(
            this.powdermodel_calibration_measures.experiment_1_point_7.toFixed(this.max_metric_decimals),
          );
        } else {
          return null;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.powdermodel_calibration_measures.experiment_1_point_7 = value;
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.powdermodel_calibration_measures.experiment_1_point_7 =
              value / this.$store.state.conversion_factors.um_to_mil;
          } else {
            this.powdermodel_calibration_measures.experiment_1_point_7 = value;
          }
        }
      },
    },
    experiment2Point1: {
      get() {
        if (
          this.powdermodel_calibration_measures.experiment_2_point_1 == null ||
          this.powdermodel_calibration_measures.experiment_2_point_1 == ""
        ) {
          return this.powdermodel_calibration_measures.experiment_2_point_1;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (
              this.powdermodel_calibration_measures.experiment_2_point_1 *
              this.$store.state.conversion_factors.um_to_mil
            ).toFixed(this.max_imperial_decimals),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(
            this.powdermodel_calibration_measures.experiment_2_point_1.toFixed(this.max_metric_decimals),
          );
        } else {
          return null;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.powdermodel_calibration_measures.experiment_2_point_1 = value;
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.powdermodel_calibration_measures.experiment_2_point_1 =
              value / this.$store.state.conversion_factors.um_to_mil;
          } else {
            this.powdermodel_calibration_measures.experiment_2_point_1 = value;
          }
        }
      },
    },
    experiment2Point2: {
      get() {
        if (
          this.powdermodel_calibration_measures.experiment_2_point_2 == null ||
          this.powdermodel_calibration_measures.experiment_2_point_2 == ""
        ) {
          return this.powdermodel_calibration_measures.experiment_2_point_2;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (
              this.powdermodel_calibration_measures.experiment_2_point_2 *
              this.$store.state.conversion_factors.um_to_mil
            ).toFixed(this.max_imperial_decimals),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(
            this.powdermodel_calibration_measures.experiment_2_point_2.toFixed(this.max_metric_decimals),
          );
        } else {
          return null;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.powdermodel_calibration_measures.experiment_2_point_2 = value;
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.powdermodel_calibration_measures.experiment_2_point_2 =
              value / this.$store.state.conversion_factors.um_to_mil;
          } else {
            this.powdermodel_calibration_measures.experiment_2_point_2 = value;
          }
        }
      },
    },
    experiment2Point3: {
      get() {
        if (
          this.powdermodel_calibration_measures.experiment_2_point_3 == null ||
          this.powdermodel_calibration_measures.experiment_2_point_3 == ""
        ) {
          return this.powdermodel_calibration_measures.experiment_2_point_3;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (
              this.powdermodel_calibration_measures.experiment_2_point_3 *
              this.$store.state.conversion_factors.um_to_mil
            ).toFixed(this.max_imperial_decimals),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(
            this.powdermodel_calibration_measures.experiment_2_point_3.toFixed(this.max_metric_decimals),
          );
        } else {
          return null;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.powdermodel_calibration_measures.experiment_2_point_3 = value;
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.powdermodel_calibration_measures.experiment_2_point_3 =
              value / this.$store.state.conversion_factors.um_to_mil;
          } else {
            this.powdermodel_calibration_measures.experiment_2_point_3 = value;
          }
        }
      },
    },
    experiment2Point4: {
      get() {
        if (
          this.powdermodel_calibration_measures.experiment_2_point_4 == null ||
          this.powdermodel_calibration_measures.experiment_2_point_4 == ""
        ) {
          return this.powdermodel_calibration_measures.experiment_2_point_4;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (
              this.powdermodel_calibration_measures.experiment_2_point_4 *
              this.$store.state.conversion_factors.um_to_mil
            ).toFixed(this.max_imperial_decimals),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(
            this.powdermodel_calibration_measures.experiment_2_point_4.toFixed(this.max_metric_decimals),
          );
        } else {
          return null;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.powdermodel_calibration_measures.experiment_2_point_4 = value;
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.powdermodel_calibration_measures.experiment_2_point_4 =
              value / this.$store.state.conversion_factors.um_to_mil;
          } else {
            this.powdermodel_calibration_measures.experiment_2_point_4 = value;
          }
        }
      },
    },
    experiment2Point5: {
      get() {
        if (
          this.powdermodel_calibration_measures.experiment_2_point_5 == null ||
          this.powdermodel_calibration_measures.experiment_2_point_5 == ""
        ) {
          return this.powdermodel_calibration_measures.experiment_2_point_5;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (
              this.powdermodel_calibration_measures.experiment_2_point_5 *
              this.$store.state.conversion_factors.um_to_mil
            ).toFixed(this.max_imperial_decimals),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(
            this.powdermodel_calibration_measures.experiment_2_point_5.toFixed(this.max_metric_decimals),
          );
        } else {
          return null;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.powdermodel_calibration_measures.experiment_2_point_5 = value;
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.powdermodel_calibration_measures.experiment_2_point_5 =
              value / this.$store.state.conversion_factors.um_to_mil;
          } else {
            this.powdermodel_calibration_measures.experiment_2_point_5 = value;
          }
        }
      },
    },
    experiment2Point6: {
      get() {
        if (
          this.powdermodel_calibration_measures.experiment_2_point_6 == null ||
          this.powdermodel_calibration_measures.experiment_2_point_6 == ""
        ) {
          return this.powdermodel_calibration_measures.experiment_2_point_6;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (
              this.powdermodel_calibration_measures.experiment_2_point_6 *
              this.$store.state.conversion_factors.um_to_mil
            ).toFixed(this.max_imperial_decimals),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(
            this.powdermodel_calibration_measures.experiment_2_point_6.toFixed(this.max_metric_decimals),
          );
        } else {
          return null;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.powdermodel_calibration_measures.experiment_2_point_6 = value;
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.powdermodel_calibration_measures.experiment_2_point_6 =
              value / this.$store.state.conversion_factors.um_to_mil;
          } else {
            this.powdermodel_calibration_measures.experiment_2_point_6 = value;
          }
        }
      },
    },
    experiment2Point7: {
      get() {
        if (
          this.powdermodel_calibration_measures.experiment_2_point_7 == null ||
          this.powdermodel_calibration_measures.experiment_2_point_7 == ""
        ) {
          return this.powdermodel_calibration_measures.experiment_2_point_7;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (
              this.powdermodel_calibration_measures.experiment_2_point_7 *
              this.$store.state.conversion_factors.um_to_mil
            ).toFixed(this.max_imperial_decimals),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(
            this.powdermodel_calibration_measures.experiment_2_point_7.toFixed(this.max_metric_decimals),
          );
        } else {
          return null;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.powdermodel_calibration_measures.experiment_2_point_7 = value;
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.powdermodel_calibration_measures.experiment_2_point_7 =
              value / this.$store.state.conversion_factors.um_to_mil;
          } else {
            this.powdermodel_calibration_measures.experiment_2_point_7 = value;
          }
        }
      },
    },
    experiment3Point1: {
      get() {
        if (
          this.powdermodel_calibration_measures.experiment_3_point_1 == null ||
          this.powdermodel_calibration_measures.experiment_3_point_1 == ""
        ) {
          return this.powdermodel_calibration_measures.experiment_3_point_1;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (
              this.powdermodel_calibration_measures.experiment_3_point_1 *
              this.$store.state.conversion_factors.um_to_mil
            ).toFixed(this.max_imperial_decimals),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(
            this.powdermodel_calibration_measures.experiment_3_point_1.toFixed(this.max_metric_decimals),
          );
        } else {
          return null;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.powdermodel_calibration_measures.experiment_3_point_1 = value;
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.powdermodel_calibration_measures.experiment_3_point_1 =
              value / this.$store.state.conversion_factors.um_to_mil;
          } else {
            this.powdermodel_calibration_measures.experiment_3_point_1 = value;
          }
        }
      },
    },
    experiment3Point2: {
      get() {
        if (
          this.powdermodel_calibration_measures.experiment_3_point_2 == null ||
          this.powdermodel_calibration_measures.experiment_3_point_2 == ""
        ) {
          return this.powdermodel_calibration_measures.experiment_3_point_2;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (
              this.powdermodel_calibration_measures.experiment_3_point_2 *
              this.$store.state.conversion_factors.um_to_mil
            ).toFixed(this.max_imperial_decimals),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(
            this.powdermodel_calibration_measures.experiment_3_point_2.toFixed(this.max_metric_decimals),
          );
        } else {
          return null;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.powdermodel_calibration_measures.experiment_3_point_2 = value;
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.powdermodel_calibration_measures.experiment_3_point_2 =
              value / this.$store.state.conversion_factors.um_to_mil;
          } else {
            this.powdermodel_calibration_measures.experiment_3_point_2 = value;
          }
        }
      },
    },
    experiment3Point3: {
      get() {
        if (
          this.powdermodel_calibration_measures.experiment_3_point_3 == null ||
          this.powdermodel_calibration_measures.experiment_3_point_3 == ""
        ) {
          return this.powdermodel_calibration_measures.experiment_3_point_3;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (
              this.powdermodel_calibration_measures.experiment_3_point_3 *
              this.$store.state.conversion_factors.um_to_mil
            ).toFixed(this.max_imperial_decimals),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(
            this.powdermodel_calibration_measures.experiment_3_point_3.toFixed(this.max_metric_decimals),
          );
        } else {
          return null;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.powdermodel_calibration_measures.experiment_3_point_3 = value;
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.powdermodel_calibration_measures.experiment_3_point_3 =
              value / this.$store.state.conversion_factors.um_to_mil;
          } else {
            this.powdermodel_calibration_measures.experiment_3_point_3 = value;
          }
        }
      },
    },
    experiment3Point4: {
      get() {
        if (
          this.powdermodel_calibration_measures.experiment_3_point_4 == null ||
          this.powdermodel_calibration_measures.experiment_3_point_4 == ""
        ) {
          return this.powdermodel_calibration_measures.experiment_3_point_4;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (
              this.powdermodel_calibration_measures.experiment_3_point_4 *
              this.$store.state.conversion_factors.um_to_mil
            ).toFixed(this.max_imperial_decimals),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(
            this.powdermodel_calibration_measures.experiment_3_point_4.toFixed(this.max_metric_decimals),
          );
        } else {
          return null;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.powdermodel_calibration_measures.experiment_3_point_4 = value;
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.powdermodel_calibration_measures.experiment_3_point_4 =
              value / this.$store.state.conversion_factors.um_to_mil;
          } else {
            this.powdermodel_calibration_measures.experiment_3_point_4 = value;
          }
        }
      },
    },
    experiment3Point5: {
      get() {
        if (
          this.powdermodel_calibration_measures.experiment_3_point_5 == null ||
          this.powdermodel_calibration_measures.experiment_3_point_5 == ""
        ) {
          return this.powdermodel_calibration_measures.experiment_3_point_5;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (
              this.powdermodel_calibration_measures.experiment_3_point_5 *
              this.$store.state.conversion_factors.um_to_mil
            ).toFixed(this.max_imperial_decimals),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(
            this.powdermodel_calibration_measures.experiment_3_point_5.toFixed(this.max_metric_decimals),
          );
        } else {
          return null;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.powdermodel_calibration_measures.experiment_3_point_5 = value;
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.powdermodel_calibration_measures.experiment_3_point_5 =
              value / this.$store.state.conversion_factors.um_to_mil;
          } else {
            this.powdermodel_calibration_measures.experiment_3_point_5 = value;
          }
        }
      },
    },
    experiment3Point6: {
      get() {
        if (
          this.powdermodel_calibration_measures.experiment_3_point_6 == null ||
          this.powdermodel_calibration_measures.experiment_3_point_6 == ""
        ) {
          return this.powdermodel_calibration_measures.experiment_3_point_6;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (
              this.powdermodel_calibration_measures.experiment_3_point_6 *
              this.$store.state.conversion_factors.um_to_mil
            ).toFixed(this.max_imperial_decimals),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(
            this.powdermodel_calibration_measures.experiment_3_point_6.toFixed(this.max_metric_decimals),
          );
        } else {
          return null;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.powdermodel_calibration_measures.experiment_3_point_6 = value;
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.powdermodel_calibration_measures.experiment_3_point_6 =
              value / this.$store.state.conversion_factors.um_to_mil;
          } else {
            this.powdermodel_calibration_measures.experiment_3_point_6 = value;
          }
        }
      },
    },
    experiment3Point7: {
      get() {
        if (
          this.powdermodel_calibration_measures.experiment_3_point_7 == null ||
          this.powdermodel_calibration_measures.experiment_3_point_7 == ""
        ) {
          return this.powdermodel_calibration_measures.experiment_3_point_7;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (
              this.powdermodel_calibration_measures.experiment_3_point_7 *
              this.$store.state.conversion_factors.um_to_mil
            ).toFixed(this.max_imperial_decimals),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(
            this.powdermodel_calibration_measures.experiment_3_point_7.toFixed(this.max_metric_decimals),
          );
        } else {
          return null;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.powdermodel_calibration_measures.experiment_3_point_7 = value;
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.powdermodel_calibration_measures.experiment_3_point_7 =
              value / this.$store.state.conversion_factors.um_to_mil;
          } else {
            this.powdermodel_calibration_measures.experiment_3_point_7 = value;
          }
        }
      },
    },
    experiment4Point1: {
      get() {
        if (
          this.powdermodel_calibration_measures.experiment_4_point_1 == null ||
          this.powdermodel_calibration_measures.experiment_4_point_1 == ""
        ) {
          return this.powdermodel_calibration_measures.experiment_4_point_1;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (
              this.powdermodel_calibration_measures.experiment_4_point_1 *
              this.$store.state.conversion_factors.um_to_mil
            ).toFixed(this.max_imperial_decimals),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(
            this.powdermodel_calibration_measures.experiment_4_point_1.toFixed(this.max_metric_decimals),
          );
        } else {
          return null;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.powdermodel_calibration_measures.experiment_4_point_1 = value;
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.powdermodel_calibration_measures.experiment_4_point_1 =
              value / this.$store.state.conversion_factors.um_to_mil;
          } else {
            this.powdermodel_calibration_measures.experiment_4_point_1 = value;
          }
        }
      },
    },
    experiment4Point2: {
      get() {
        if (
          this.powdermodel_calibration_measures.experiment_4_point_2 == null ||
          this.powdermodel_calibration_measures.experiment_4_point_2 == ""
        ) {
          return this.powdermodel_calibration_measures.experiment_4_point_2;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (
              this.powdermodel_calibration_measures.experiment_4_point_2 *
              this.$store.state.conversion_factors.um_to_mil
            ).toFixed(this.max_imperial_decimals),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(
            this.powdermodel_calibration_measures.experiment_4_point_2.toFixed(this.max_metric_decimals),
          );
        } else {
          return null;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.powdermodel_calibration_measures.experiment_4_point_2 = value;
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.powdermodel_calibration_measures.experiment_4_point_2 =
              value / this.$store.state.conversion_factors.um_to_mil;
          } else {
            this.powdermodel_calibration_measures.experiment_4_point_2 = value;
          }
        }
      },
    },
    experiment4Point3: {
      get() {
        if (
          this.powdermodel_calibration_measures.experiment_4_point_3 == null ||
          this.powdermodel_calibration_measures.experiment_4_point_3 == ""
        ) {
          return this.powdermodel_calibration_measures.experiment_4_point_3;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (
              this.powdermodel_calibration_measures.experiment_4_point_3 *
              this.$store.state.conversion_factors.um_to_mil
            ).toFixed(this.max_imperial_decimals),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(
            this.powdermodel_calibration_measures.experiment_4_point_3.toFixed(this.max_metric_decimals),
          );
        } else {
          return null;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.powdermodel_calibration_measures.experiment_4_point_3 = value;
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.powdermodel_calibration_measures.experiment_4_point_3 =
              value / this.$store.state.conversion_factors.um_to_mil;
          } else {
            this.powdermodel_calibration_measures.experiment_4_point_3 = value;
          }
        }
      },
    },
    experiment4Point4: {
      get() {
        if (
          this.powdermodel_calibration_measures.experiment_4_point_4 == null ||
          this.powdermodel_calibration_measures.experiment_4_point_4 == ""
        ) {
          return this.powdermodel_calibration_measures.experiment_4_point_4;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (
              this.powdermodel_calibration_measures.experiment_4_point_4 *
              this.$store.state.conversion_factors.um_to_mil
            ).toFixed(this.max_imperial_decimals),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(
            this.powdermodel_calibration_measures.experiment_4_point_4.toFixed(this.max_metric_decimals),
          );
        } else {
          return null;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.powdermodel_calibration_measures.experiment_4_point_4 = value;
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.powdermodel_calibration_measures.experiment_4_point_4 =
              value / this.$store.state.conversion_factors.um_to_mil;
          } else {
            this.powdermodel_calibration_measures.experiment_4_point_4 = value;
          }
        }
      },
    },
    experiment4Point5: {
      get() {
        if (
          this.powdermodel_calibration_measures.experiment_4_point_5 == null ||
          this.powdermodel_calibration_measures.experiment_4_point_5 == ""
        ) {
          return this.powdermodel_calibration_measures.experiment_4_point_5;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (
              this.powdermodel_calibration_measures.experiment_4_point_5 *
              this.$store.state.conversion_factors.um_to_mil
            ).toFixed(this.max_imperial_decimals),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(
            this.powdermodel_calibration_measures.experiment_4_point_5.toFixed(this.max_metric_decimals),
          );
        } else {
          return null;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.powdermodel_calibration_measures.experiment_4_point_5 = value;
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.powdermodel_calibration_measures.experiment_4_point_5 =
              value / this.$store.state.conversion_factors.um_to_mil;
          } else {
            this.powdermodel_calibration_measures.experiment_4_point_5 = value;
          }
        }
      },
    },
    experiment4Point6: {
      get() {
        if (
          this.powdermodel_calibration_measures.experiment_4_point_6 == null ||
          this.powdermodel_calibration_measures.experiment_4_point_6 == ""
        ) {
          return this.powdermodel_calibration_measures.experiment_4_point_6;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (
              this.powdermodel_calibration_measures.experiment_4_point_6 *
              this.$store.state.conversion_factors.um_to_mil
            ).toFixed(this.max_imperial_decimals),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(
            this.powdermodel_calibration_measures.experiment_4_point_6.toFixed(this.max_metric_decimals),
          );
        } else {
          return null;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.powdermodel_calibration_measures.experiment_4_point_6 = value;
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.powdermodel_calibration_measures.experiment_4_point_6 =
              value / this.$store.state.conversion_factors.um_to_mil;
          } else {
            this.powdermodel_calibration_measures.experiment_4_point_6 = value;
          }
        }
      },
    },
    experiment4Point7: {
      get() {
        if (
          this.powdermodel_calibration_measures.experiment_4_point_7 == null ||
          this.powdermodel_calibration_measures.experiment_4_point_7 == ""
        ) {
          return this.powdermodel_calibration_measures.experiment_4_point_7;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (
              this.powdermodel_calibration_measures.experiment_4_point_7 *
              this.$store.state.conversion_factors.um_to_mil
            ).toFixed(this.max_imperial_decimals),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(
            this.powdermodel_calibration_measures.experiment_4_point_7.toFixed(this.max_metric_decimals),
          );
        } else {
          return null;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.powdermodel_calibration_measures.experiment_4_point_7 = value;
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.powdermodel_calibration_measures.experiment_4_point_7 =
              value / this.$store.state.conversion_factors.um_to_mil;
          } else {
            this.powdermodel_calibration_measures.experiment_4_point_7 = value;
          }
        }
      },
    },
    experiment5Point1: {
      get() {
        if (
          this.powdermodel_calibration_measures.experiment_5_point_1 == null ||
          this.powdermodel_calibration_measures.experiment_5_point_1 == ""
        ) {
          return this.powdermodel_calibration_measures.experiment_5_point_1;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (
              this.powdermodel_calibration_measures.experiment_5_point_1 *
              this.$store.state.conversion_factors.um_to_mil
            ).toFixed(this.max_imperial_decimals),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(
            this.powdermodel_calibration_measures.experiment_5_point_1.toFixed(this.max_metric_decimals),
          );
        } else {
          return null;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.powdermodel_calibration_measures.experiment_5_point_1 = value;
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.powdermodel_calibration_measures.experiment_5_point_1 =
              value / this.$store.state.conversion_factors.um_to_mil;
          } else {
            this.powdermodel_calibration_measures.experiment_5_point_1 = value;
          }
        }
      },
    },
    experiment5Point2: {
      get() {
        if (
          this.powdermodel_calibration_measures.experiment_5_point_2 == null ||
          this.powdermodel_calibration_measures.experiment_5_point_2 == ""
        ) {
          return this.powdermodel_calibration_measures.experiment_5_point_2;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (
              this.powdermodel_calibration_measures.experiment_5_point_2 *
              this.$store.state.conversion_factors.um_to_mil
            ).toFixed(this.max_imperial_decimals),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(
            this.powdermodel_calibration_measures.experiment_5_point_2.toFixed(this.max_metric_decimals),
          );
        } else {
          return null;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.powdermodel_calibration_measures.experiment_5_point_2 = value;
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.powdermodel_calibration_measures.experiment_5_point_2 =
              value / this.$store.state.conversion_factors.um_to_mil;
          } else {
            this.powdermodel_calibration_measures.experiment_5_point_2 = value;
          }
        }
      },
    },
    experiment5Point3: {
      get() {
        if (
          this.powdermodel_calibration_measures.experiment_5_point_3 == null ||
          this.powdermodel_calibration_measures.experiment_5_point_3 == ""
        ) {
          return this.powdermodel_calibration_measures.experiment_5_point_3;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (
              this.powdermodel_calibration_measures.experiment_5_point_3 *
              this.$store.state.conversion_factors.um_to_mil
            ).toFixed(this.max_imperial_decimals),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(
            this.powdermodel_calibration_measures.experiment_5_point_3.toFixed(this.max_metric_decimals),
          );
        } else {
          return null;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.powdermodel_calibration_measures.experiment_5_point_3 = value;
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.powdermodel_calibration_measures.experiment_5_point_3 =
              value / this.$store.state.conversion_factors.um_to_mil;
          } else {
            this.powdermodel_calibration_measures.experiment_5_point_3 = value;
          }
        }
      },
    },
    experiment5Point4: {
      get() {
        if (
          this.powdermodel_calibration_measures.experiment_5_point_4 == null ||
          this.powdermodel_calibration_measures.experiment_5_point_4 == ""
        ) {
          return this.powdermodel_calibration_measures.experiment_5_point_4;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (
              this.powdermodel_calibration_measures.experiment_5_point_4 *
              this.$store.state.conversion_factors.um_to_mil
            ).toFixed(this.max_imperial_decimals),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(
            this.powdermodel_calibration_measures.experiment_5_point_4.toFixed(this.max_metric_decimals),
          );
        } else {
          return null;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.powdermodel_calibration_measures.experiment_5_point_4 = value;
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.powdermodel_calibration_measures.experiment_5_point_4 =
              value / this.$store.state.conversion_factors.um_to_mil;
          } else {
            this.powdermodel_calibration_measures.experiment_5_point_4 = value;
          }
        }
      },
    },
    experiment5Point5: {
      get() {
        if (
          this.powdermodel_calibration_measures.experiment_5_point_5 == null ||
          this.powdermodel_calibration_measures.experiment_5_point_5 == ""
        ) {
          return this.powdermodel_calibration_measures.experiment_5_point_5;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (
              this.powdermodel_calibration_measures.experiment_5_point_5 *
              this.$store.state.conversion_factors.um_to_mil
            ).toFixed(this.max_imperial_decimals),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(
            this.powdermodel_calibration_measures.experiment_5_point_5.toFixed(this.max_metric_decimals),
          );
        } else {
          return null;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.powdermodel_calibration_measures.experiment_5_point_5 = value;
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.powdermodel_calibration_measures.experiment_5_point_5 =
              value / this.$store.state.conversion_factors.um_to_mil;
          } else {
            this.powdermodel_calibration_measures.experiment_5_point_5 = value;
          }
        }
      },
    },
    experiment5Point6: {
      get() {
        if (
          this.powdermodel_calibration_measures.experiment_5_point_6 == null ||
          this.powdermodel_calibration_measures.experiment_5_point_6 == ""
        ) {
          return this.powdermodel_calibration_measures.experiment_5_point_6;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (
              this.powdermodel_calibration_measures.experiment_5_point_6 *
              this.$store.state.conversion_factors.um_to_mil
            ).toFixed(this.max_imperial_decimals),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(
            this.powdermodel_calibration_measures.experiment_5_point_6.toFixed(this.max_metric_decimals),
          );
        } else {
          return null;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.powdermodel_calibration_measures.experiment_5_point_6 = value;
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.powdermodel_calibration_measures.experiment_5_point_6 =
              value / this.$store.state.conversion_factors.um_to_mil;
          } else {
            this.powdermodel_calibration_measures.experiment_5_point_6 = value;
          }
        }
      },
    },
    experiment5Point7: {
      get() {
        if (
          this.powdermodel_calibration_measures.experiment_5_point_7 == null ||
          this.powdermodel_calibration_measures.experiment_5_point_7 == ""
        ) {
          return this.powdermodel_calibration_measures.experiment_5_point_7;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (
              this.powdermodel_calibration_measures.experiment_5_point_7 *
              this.$store.state.conversion_factors.um_to_mil
            ).toFixed(this.max_imperial_decimals),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(
            this.powdermodel_calibration_measures.experiment_5_point_7.toFixed(this.max_metric_decimals),
          );
        } else {
          return null;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.powdermodel_calibration_measures.experiment_5_point_7 = value;
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.powdermodel_calibration_measures.experiment_5_point_7 =
              value / this.$store.state.conversion_factors.um_to_mil;
          } else {
            this.powdermodel_calibration_measures.experiment_5_point_7 = value;
          }
        }
      },
    },
    experiment6Point1: {
      get() {
        if (
          this.powdermodel_calibration_measures.experiment_6_point_1 == null ||
          this.powdermodel_calibration_measures.experiment_6_point_1 == ""
        ) {
          return this.powdermodel_calibration_measures.experiment_6_point_1;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (
              this.powdermodel_calibration_measures.experiment_6_point_1 *
              this.$store.state.conversion_factors.um_to_mil
            ).toFixed(this.max_imperial_decimals),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(
            this.powdermodel_calibration_measures.experiment_6_point_1.toFixed(this.max_metric_decimals),
          );
        } else {
          return null;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.powdermodel_calibration_measures.experiment_6_point_1 = value;
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.powdermodel_calibration_measures.experiment_6_point_1 =
              value / this.$store.state.conversion_factors.um_to_mil;
          } else {
            this.powdermodel_calibration_measures.experiment_6_point_1 = value;
          }
        }
      },
    },
    experiment6Point2: {
      get() {
        if (
          this.powdermodel_calibration_measures.experiment_6_point_2 == null ||
          this.powdermodel_calibration_measures.experiment_6_point_2 == ""
        ) {
          return this.powdermodel_calibration_measures.experiment_6_point_2;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (
              this.powdermodel_calibration_measures.experiment_6_point_2 *
              this.$store.state.conversion_factors.um_to_mil
            ).toFixed(this.max_imperial_decimals),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(
            this.powdermodel_calibration_measures.experiment_6_point_2.toFixed(this.max_metric_decimals),
          );
        } else {
          return null;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.powdermodel_calibration_measures.experiment_6_point_2 = value;
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.powdermodel_calibration_measures.experiment_6_point_2 =
              value / this.$store.state.conversion_factors.um_to_mil;
          } else {
            this.powdermodel_calibration_measures.experiment_6_point_2 = value;
          }
        }
      },
    },
    experiment6Point3: {
      get() {
        if (
          this.powdermodel_calibration_measures.experiment_6_point_3 == null ||
          this.powdermodel_calibration_measures.experiment_6_point_3 == ""
        ) {
          return this.powdermodel_calibration_measures.experiment_6_point_3;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (
              this.powdermodel_calibration_measures.experiment_6_point_3 *
              this.$store.state.conversion_factors.um_to_mil
            ).toFixed(this.max_imperial_decimals),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(
            this.powdermodel_calibration_measures.experiment_6_point_3.toFixed(this.max_metric_decimals),
          );
        } else {
          return null;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.powdermodel_calibration_measures.experiment_6_point_3 = value;
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.powdermodel_calibration_measures.experiment_6_point_3 =
              value / this.$store.state.conversion_factors.um_to_mil;
          } else {
            this.powdermodel_calibration_measures.experiment_6_point_3 = value;
          }
        }
      },
    },
    experiment6Point4: {
      get() {
        if (
          this.powdermodel_calibration_measures.experiment_6_point_4 == null ||
          this.powdermodel_calibration_measures.experiment_6_point_4 == ""
        ) {
          return this.powdermodel_calibration_measures.experiment_6_point_4;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (
              this.powdermodel_calibration_measures.experiment_6_point_4 *
              this.$store.state.conversion_factors.um_to_mil
            ).toFixed(this.max_imperial_decimals),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(
            this.powdermodel_calibration_measures.experiment_6_point_4.toFixed(this.max_metric_decimals),
          );
        } else {
          return null;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.powdermodel_calibration_measures.experiment_6_point_4 = value;
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.powdermodel_calibration_measures.experiment_6_point_4 =
              value / this.$store.state.conversion_factors.um_to_mil;
          } else {
            this.powdermodel_calibration_measures.experiment_6_point_4 = value;
          }
        }
      },
    },
    experiment6Point5: {
      get() {
        if (
          this.powdermodel_calibration_measures.experiment_6_point_5 == null ||
          this.powdermodel_calibration_measures.experiment_6_point_5 == ""
        ) {
          return this.powdermodel_calibration_measures.experiment_6_point_5;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (
              this.powdermodel_calibration_measures.experiment_6_point_5 *
              this.$store.state.conversion_factors.um_to_mil
            ).toFixed(this.max_imperial_decimals),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(
            this.powdermodel_calibration_measures.experiment_6_point_5.toFixed(this.max_metric_decimals),
          );
        } else {
          return null;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.powdermodel_calibration_measures.experiment_6_point_5 = value;
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.powdermodel_calibration_measures.experiment_6_point_5 =
              value / this.$store.state.conversion_factors.um_to_mil;
          } else {
            this.powdermodel_calibration_measures.experiment_6_point_5 = value;
          }
        }
      },
    },
    experiment6Point6: {
      get() {
        if (
          this.powdermodel_calibration_measures.experiment_6_point_6 == null ||
          this.powdermodel_calibration_measures.experiment_6_point_6 == ""
        ) {
          return this.powdermodel_calibration_measures.experiment_6_point_6;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (
              this.powdermodel_calibration_measures.experiment_6_point_6 *
              this.$store.state.conversion_factors.um_to_mil
            ).toFixed(this.max_imperial_decimals),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(
            this.powdermodel_calibration_measures.experiment_6_point_6.toFixed(this.max_metric_decimals),
          );
        } else {
          return null;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.powdermodel_calibration_measures.experiment_6_point_6 = value;
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.powdermodel_calibration_measures.experiment_6_point_6 =
              value / this.$store.state.conversion_factors.um_to_mil;
          } else {
            this.powdermodel_calibration_measures.experiment_6_point_6 = value;
          }
        }
      },
    },
    experiment6Point7: {
      get() {
        if (
          this.powdermodel_calibration_measures.experiment_6_point_7 == null ||
          this.powdermodel_calibration_measures.experiment_6_point_7 == ""
        ) {
          return this.powdermodel_calibration_measures.experiment_6_point_7;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (
              this.powdermodel_calibration_measures.experiment_6_point_7 *
              this.$store.state.conversion_factors.um_to_mil
            ).toFixed(this.max_imperial_decimals),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(
            this.powdermodel_calibration_measures.experiment_6_point_7.toFixed(this.max_metric_decimals),
          );
        } else {
          return null;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.powdermodel_calibration_measures.experiment_6_point_7 = value;
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.powdermodel_calibration_measures.experiment_6_point_7 =
              value / this.$store.state.conversion_factors.um_to_mil;
          } else {
            this.powdermodel_calibration_measures.experiment_6_point_7 = value;
          }
        }
      },
    },
    experiment7Point1: {
      get() {
        if (
          this.powdermodel_calibration_measures.experiment_7_point_1 == null ||
          this.powdermodel_calibration_measures.experiment_7_point_1 == ""
        ) {
          return this.powdermodel_calibration_measures.experiment_7_point_1;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (
              this.powdermodel_calibration_measures.experiment_7_point_1 *
              this.$store.state.conversion_factors.um_to_mil
            ).toFixed(this.max_imperial_decimals),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(
            this.powdermodel_calibration_measures.experiment_7_point_1.toFixed(this.max_metric_decimals),
          );
        } else {
          return null;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.powdermodel_calibration_measures.experiment_7_point_1 = value;
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.powdermodel_calibration_measures.experiment_7_point_1 =
              value / this.$store.state.conversion_factors.um_to_mil;
          } else {
            this.powdermodel_calibration_measures.experiment_7_point_1 = value;
          }
        }
      },
    },
    experiment7Point2: {
      get() {
        if (
          this.powdermodel_calibration_measures.experiment_7_point_2 == null ||
          this.powdermodel_calibration_measures.experiment_7_point_2 == ""
        ) {
          return this.powdermodel_calibration_measures.experiment_7_point_2;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (
              this.powdermodel_calibration_measures.experiment_7_point_2 *
              this.$store.state.conversion_factors.um_to_mil
            ).toFixed(this.max_imperial_decimals),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(
            this.powdermodel_calibration_measures.experiment_7_point_2.toFixed(this.max_metric_decimals),
          );
        } else {
          return null;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.powdermodel_calibration_measures.experiment_7_point_2 = value;
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.powdermodel_calibration_measures.experiment_7_point_2 =
              value / this.$store.state.conversion_factors.um_to_mil;
          } else {
            this.powdermodel_calibration_measures.experiment_7_point_2 = value;
          }
        }
      },
    },
    experiment7Point3: {
      get() {
        if (
          this.powdermodel_calibration_measures.experiment_7_point_3 == null ||
          this.powdermodel_calibration_measures.experiment_7_point_3 == ""
        ) {
          return this.powdermodel_calibration_measures.experiment_7_point_3;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (
              this.powdermodel_calibration_measures.experiment_7_point_3 *
              this.$store.state.conversion_factors.um_to_mil
            ).toFixed(this.max_imperial_decimals),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(
            this.powdermodel_calibration_measures.experiment_7_point_3.toFixed(this.max_metric_decimals),
          );
        } else {
          return null;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.powdermodel_calibration_measures.experiment_7_point_3 = value;
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.powdermodel_calibration_measures.experiment_7_point_3 =
              value / this.$store.state.conversion_factors.um_to_mil;
          } else {
            this.powdermodel_calibration_measures.experiment_7_point_3 = value;
          }
        }
      },
    },
    experiment7Point4: {
      get() {
        if (
          this.powdermodel_calibration_measures.experiment_7_point_4 == null ||
          this.powdermodel_calibration_measures.experiment_7_point_4 == ""
        ) {
          return this.powdermodel_calibration_measures.experiment_7_point_4;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (
              this.powdermodel_calibration_measures.experiment_7_point_4 *
              this.$store.state.conversion_factors.um_to_mil
            ).toFixed(this.max_imperial_decimals),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(
            this.powdermodel_calibration_measures.experiment_7_point_4.toFixed(this.max_metric_decimals),
          );
        } else {
          return null;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.powdermodel_calibration_measures.experiment_7_point_4 = value;
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.powdermodel_calibration_measures.experiment_7_point_4 =
              value / this.$store.state.conversion_factors.um_to_mil;
          } else {
            this.powdermodel_calibration_measures.experiment_7_point_4 = value;
          }
        }
      },
    },
    experiment7Point5: {
      get() {
        if (
          this.powdermodel_calibration_measures.experiment_7_point_5 == null ||
          this.powdermodel_calibration_measures.experiment_7_point_5 == ""
        ) {
          return this.powdermodel_calibration_measures.experiment_7_point_5;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (
              this.powdermodel_calibration_measures.experiment_7_point_5 *
              this.$store.state.conversion_factors.um_to_mil
            ).toFixed(this.max_imperial_decimals),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(
            this.powdermodel_calibration_measures.experiment_7_point_5.toFixed(this.max_metric_decimals),
          );
        } else {
          return null;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.powdermodel_calibration_measures.experiment_7_point_5 = value;
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.powdermodel_calibration_measures.experiment_7_point_5 =
              value / this.$store.state.conversion_factors.um_to_mil;
          } else {
            this.powdermodel_calibration_measures.experiment_7_point_5 = value;
          }
        }
      },
    },
    experiment7Point6: {
      get() {
        if (
          this.powdermodel_calibration_measures.experiment_7_point_6 == null ||
          this.powdermodel_calibration_measures.experiment_7_point_6 == ""
        ) {
          return this.powdermodel_calibration_measures.experiment_7_point_6;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (
              this.powdermodel_calibration_measures.experiment_7_point_6 *
              this.$store.state.conversion_factors.um_to_mil
            ).toFixed(this.max_imperial_decimals),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(
            this.powdermodel_calibration_measures.experiment_7_point_6.toFixed(this.max_metric_decimals),
          );
        } else {
          return null;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.powdermodel_calibration_measures.experiment_7_point_6 = value;
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.powdermodel_calibration_measures.experiment_7_point_6 =
              value / this.$store.state.conversion_factors.um_to_mil;
          } else {
            this.powdermodel_calibration_measures.experiment_7_point_6 = value;
          }
        }
      },
    },
    experiment7Point7: {
      get() {
        if (
          this.powdermodel_calibration_measures.experiment_7_point_7 == null ||
          this.powdermodel_calibration_measures.experiment_7_point_7 == ""
        ) {
          return this.powdermodel_calibration_measures.experiment_7_point_7;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (
              this.powdermodel_calibration_measures.experiment_7_point_7 *
              this.$store.state.conversion_factors.um_to_mil
            ).toFixed(this.max_imperial_decimals),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(
            this.powdermodel_calibration_measures.experiment_7_point_7.toFixed(this.max_metric_decimals),
          );
        } else {
          return null;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.powdermodel_calibration_measures.experiment_7_point_7 = value;
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.powdermodel_calibration_measures.experiment_7_point_7 =
              value / this.$store.state.conversion_factors.um_to_mil;
          } else {
            this.powdermodel_calibration_measures.experiment_7_point_7 = value;
          }
        }
      },
    },
    experiment8Point1: {
      get() {
        if (
          this.powdermodel_calibration_measures.experiment_8_point_1 == null ||
          this.powdermodel_calibration_measures.experiment_8_point_1 == ""
        ) {
          return this.powdermodel_calibration_measures.experiment_8_point_1;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (
              this.powdermodel_calibration_measures.experiment_8_point_1 *
              this.$store.state.conversion_factors.um_to_mil
            ).toFixed(this.max_imperial_decimals),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(
            this.powdermodel_calibration_measures.experiment_8_point_1.toFixed(this.max_metric_decimals),
          );
        } else {
          return null;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.powdermodel_calibration_measures.experiment_8_point_1 = value;
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.powdermodel_calibration_measures.experiment_8_point_1 =
              value / this.$store.state.conversion_factors.um_to_mil;
          } else {
            this.powdermodel_calibration_measures.experiment_8_point_1 = value;
          }
        }
      },
    },
    experiment8Point2: {
      get() {
        if (
          this.powdermodel_calibration_measures.experiment_8_point_2 == null ||
          this.powdermodel_calibration_measures.experiment_8_point_2 == ""
        ) {
          return this.powdermodel_calibration_measures.experiment_8_point_2;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (
              this.powdermodel_calibration_measures.experiment_8_point_2 *
              this.$store.state.conversion_factors.um_to_mil
            ).toFixed(this.max_imperial_decimals),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(
            this.powdermodel_calibration_measures.experiment_8_point_2.toFixed(this.max_metric_decimals),
          );
        } else {
          return null;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.powdermodel_calibration_measures.experiment_8_point_2 = value;
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.powdermodel_calibration_measures.experiment_8_point_2 =
              value / this.$store.state.conversion_factors.um_to_mil;
          } else {
            this.powdermodel_calibration_measures.experiment_8_point_2 = value;
          }
        }
      },
    },
    experiment8Point3: {
      get() {
        if (
          this.powdermodel_calibration_measures.experiment_8_point_3 == null ||
          this.powdermodel_calibration_measures.experiment_8_point_3 == ""
        ) {
          return this.powdermodel_calibration_measures.experiment_8_point_3;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (
              this.powdermodel_calibration_measures.experiment_8_point_3 *
              this.$store.state.conversion_factors.um_to_mil
            ).toFixed(this.max_imperial_decimals),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(
            this.powdermodel_calibration_measures.experiment_8_point_3.toFixed(this.max_metric_decimals),
          );
        } else {
          return null;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.powdermodel_calibration_measures.experiment_8_point_3 = value;
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.powdermodel_calibration_measures.experiment_8_point_3 =
              value / this.$store.state.conversion_factors.um_to_mil;
          } else {
            this.powdermodel_calibration_measures.experiment_8_point_3 = value;
          }
        }
      },
    },
    experiment8Point4: {
      get() {
        if (
          this.powdermodel_calibration_measures.experiment_8_point_4 == null ||
          this.powdermodel_calibration_measures.experiment_8_point_4 == ""
        ) {
          return this.powdermodel_calibration_measures.experiment_8_point_4;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (
              this.powdermodel_calibration_measures.experiment_8_point_4 *
              this.$store.state.conversion_factors.um_to_mil
            ).toFixed(this.max_imperial_decimals),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(
            this.powdermodel_calibration_measures.experiment_8_point_4.toFixed(this.max_metric_decimals),
          );
        } else {
          return null;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.powdermodel_calibration_measures.experiment_8_point_4 = value;
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.powdermodel_calibration_measures.experiment_8_point_4 =
              value / this.$store.state.conversion_factors.um_to_mil;
          } else {
            this.powdermodel_calibration_measures.experiment_8_point_4 = value;
          }
        }
      },
    },
    experiment8Point5: {
      get() {
        if (
          this.powdermodel_calibration_measures.experiment_8_point_5 == null ||
          this.powdermodel_calibration_measures.experiment_8_point_5 == ""
        ) {
          return this.powdermodel_calibration_measures.experiment_8_point_5;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (
              this.powdermodel_calibration_measures.experiment_8_point_5 *
              this.$store.state.conversion_factors.um_to_mil
            ).toFixed(this.max_imperial_decimals),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(
            this.powdermodel_calibration_measures.experiment_8_point_5.toFixed(this.max_metric_decimals),
          );
        } else {
          return null;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.powdermodel_calibration_measures.experiment_8_point_5 = value;
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.powdermodel_calibration_measures.experiment_8_point_5 =
              value / this.$store.state.conversion_factors.um_to_mil;
          } else {
            this.powdermodel_calibration_measures.experiment_8_point_5 = value;
          }
        }
      },
    },
    experiment8Point6: {
      get() {
        if (
          this.powdermodel_calibration_measures.experiment_8_point_6 == null ||
          this.powdermodel_calibration_measures.experiment_8_point_6 == ""
        ) {
          return this.powdermodel_calibration_measures.experiment_8_point_6;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (
              this.powdermodel_calibration_measures.experiment_8_point_6 *
              this.$store.state.conversion_factors.um_to_mil
            ).toFixed(this.max_imperial_decimals),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(
            this.powdermodel_calibration_measures.experiment_8_point_6.toFixed(this.max_metric_decimals),
          );
        } else {
          return null;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.powdermodel_calibration_measures.experiment_8_point_6 = value;
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.powdermodel_calibration_measures.experiment_8_point_6 =
              value / this.$store.state.conversion_factors.um_to_mil;
          } else {
            this.powdermodel_calibration_measures.experiment_8_point_6 = value;
          }
        }
      },
    },
    experiment8Point7: {
      get() {
        if (
          this.powdermodel_calibration_measures.experiment_8_point_7 == null ||
          this.powdermodel_calibration_measures.experiment_8_point_7 == ""
        ) {
          return this.powdermodel_calibration_measures.experiment_8_point_7;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (
              this.powdermodel_calibration_measures.experiment_8_point_7 *
              this.$store.state.conversion_factors.um_to_mil
            ).toFixed(this.max_imperial_decimals),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(
            this.powdermodel_calibration_measures.experiment_8_point_7.toFixed(this.max_metric_decimals),
          );
        } else {
          return null;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.powdermodel_calibration_measures.experiment_8_point_7 = value;
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.powdermodel_calibration_measures.experiment_8_point_7 =
              value / this.$store.state.conversion_factors.um_to_mil;
          } else {
            this.powdermodel_calibration_measures.experiment_8_point_7 = value;
          }
        }
      },
    },
    isInFirstPage() {
      return this.currentPage === this.firstPage;
    },
    isInLastPage() {
      return this.currentPage === this.lastPage;
    },
  },
  watch: {
    "$store.state.user_data.language"() {
      this.$i18n.locale = this.$store.state.user_data.language;
    },
    "$store.state.flightpath_pro_license"() {
      if (this.powdermodel_calibration_measures.powder_manufacturer === null) {
        if (this.$store.state.flightpath_pro_license) {
          this.powdermodel_calibration_measures.powder_manufacturer = "AkzoNobel";
        } else {
          this.powdermodel_calibration_measures.powder_manufacturer = null;
        }
      }
    },
  },
  mounted() {
    // this.populateMeasurementDefaults()
    this.getLine();
    setTooltip(this.$store.state.bootstrap);
    if (this.powdermodel_calibration_measures.powder_manufacturer === null) {
      if (this.$store.state.flightpath_pro_license) {
        this.powdermodel_calibration_measures.powder_manufacturer = "AkzoNobel";
      } else {
        this.powdermodel_calibration_measures.powder_manufacturer = null;
      }
    }
  },
  updated() {
    setTooltip(this.$store.state.bootstrap);
  },
  methods: {
    numericOnly(event) {
      let key = event.key;
      if (
        [
          "Delete",
          "Backspace",
          "Tab",
          "Enter",
          "ArrowUp",
          "ArrowRight",
          "ArrowDown",
          "ArrowLeft",
          ".",
          "Shift",
          "Control",
          ",",
        ].includes(key) ||
        ((event.ctrlKey || event.metaKey) && ["a", "c", "v", "x", "z", "y"].includes(key.toLowerCase()))
      ) {
        return;
      }
      if (!/\d/.test(key)) {
        event.preventDefault();
      }
    },
    onClickPrevPage() {
      if (this.currentPage > this.firstPage) {
        this.currentPage -= 1;
      }
    },
    onClickNextPage() {
      if (this.currentPage < this.lastPage) {
        this.currentPage += 1;
      }
    },
    async getLine() {
      try {
        let response = await axios.get("/api/v1/fp/line/" + this.lineId);
        this.line = JSON.parse(JSON.stringify(response.data));
        this.$store.state.isLoading = false;
      } catch (error) {
        console.error(error);
        setTimeout(
          function () {
            this.getLine();
          }.bind(this),
          5000,
        );
      }
    },
    ProperlyFilledForm() {
      let properly_filled = true;
      let missing_fields = [];
      if (this.powdermodel.name == null || this.powdermodel.name == "") {
        this.name_input_error = true;
        properly_filled = false;
        missing_fields.push(`${this.$t("Name")}`);
      }
      if (
        this.powdermodel_calibration_measures.min_pistol_to_substrate_distance_measurements == null ||
        this.powdermodel_calibration_measures.min_pistol_to_substrate_distance_measurements == ""
      ) {
        this.min_pistol_to_substrate_distance_input_error = true;
        properly_filled = false;
        missing_fields.push(`${this.$t("MIN")} ${this.$t("Gun to substrate distance")}`);
      }
      if (
        this.powdermodel_calibration_measures.max_pistol_to_substrate_distance_measurements == null ||
        this.powdermodel_calibration_measures.max_pistol_to_substrate_distance_measurements == ""
      ) {
        this.max_pistol_to_substrate_distance_input_error = true;
        properly_filled = false;
        missing_fields.push(`${this.$t("MAX")} ${this.$t("Gun to substrate distance")}`);
      }
      if (
        this.powdermodel_calibration_measures.min_air_parameter_measurements == null ||
        this.powdermodel_calibration_measures.min_air_parameter_measurements == ""
      ) {
        this.min_air_parameter_input_error = true;
        properly_filled = false;
        missing_fields.push("Min Air Parameter");
        missing_fields.push(`${this.$t("MIN")} ${this.$t("Air parameter")}`);
      }
      if (
        this.powdermodel_calibration_measures.max_air_parameter_measurements == null ||
        this.powdermodel_calibration_measures.max_air_parameter_measurements == ""
      ) {
        this.max_air_parameter_input_error = true;
        properly_filled = false;
        missing_fields.push("Max Air Parameter");
        missing_fields.push(`${this.$t("MAX")} ${this.$t("Air parameter")}`);
      }
      if (
        this.powdermodel_calibration_measures.calibration_line_speed == null ||
        this.powdermodel_calibration_measures.calibration_line_speed == ""
      ) {
        this.line_speed_input_error = true;
        properly_filled = false;
        missing_fields.push(`${this.$t("Line speed")}`);
      }
      if (this.$store.state.flightpath_pro_license) {
        if (
          this.powdermodel_calibration_measures.nozzles_type == null ||
          this.powdermodel_calibration_measures.nozzles_type == ""
        ) {
          this.nozzles_type_input_error = true;
          properly_filled = false;
          missing_fields.push(`${this.$t("Nozzles Type")}`);
        }
        if (
          this.powdermodel_calibration_measures.batch_id == null ||
          this.powdermodel_calibration_measures.batch_id == ""
        ) {
          this.batch_id_input_error = true;
          properly_filled = false;
          missing_fields.push(`${this.$t("Batch ID")}`);
        }
        if (
          this.powdermodel_calibration_measures.powder_article_id == null ||
          this.powdermodel_calibration_measures.powder_article_id == ""
        ) {
          this.powder_article_id_input_error = true;
          properly_filled = false;
          missing_fields.push(`${this.powderLabel}`);
        }
        if (
          this.powdermodel_calibration_measures.powder_manufacturer == null ||
          this.powdermodel_calibration_measures.powder_manufacturer == ""
        ) {
          this.powder_manufacturer_input_error = true;
          properly_filled = false;
          missing_fields.push(`${this.$t("Powder Manufacturer")}`);
        }
      }
      if (
        this.powdermodel_calibration_measures.experiment_1_point_1 == null ||
        this.powdermodel_calibration_measures.experiment_1_point_1 === ""
      ) {
        this.experiment_1_point_1_input_error = true;
        properly_filled = false;
        missing_fields.push(`${this.$t("Experiment")} 1 ${this.$t("Point")} 1`);
      }
      if (
        this.powdermodel_calibration_measures.experiment_1_point_2 == null ||
        this.powdermodel_calibration_measures.experiment_1_point_2 === ""
      ) {
        this.experiment_1_point_2_input_error = true;
        properly_filled = false;
        missing_fields.push(`${this.$t("Experiment")} 1 ${this.$t("Point")} 2`);
      }
      if (
        this.powdermodel_calibration_measures.experiment_1_point_3 == null ||
        this.powdermodel_calibration_measures.experiment_1_point_3 === ""
      ) {
        this.experiment_1_point_3_input_error = true;
        properly_filled = false;
        missing_fields.push(`${this.$t("Experiment")} 1 ${this.$t("Point")} 3`);
      }
      if (
        this.powdermodel_calibration_measures.experiment_1_point_4 == null ||
        this.powdermodel_calibration_measures.experiment_1_point_4 === ""
      ) {
        this.experiment_1_point_4_input_error = true;
        properly_filled = false;
        missing_fields.push(`${this.$t("Experiment")} 1 ${this.$t("Point")} 4`);
      }
      if (
        this.powdermodel_calibration_measures.experiment_1_point_5 == null ||
        this.powdermodel_calibration_measures.experiment_1_point_5 === ""
      ) {
        this.experiment_1_point_5_input_error = true;
        properly_filled = false;
        missing_fields.push(`${this.$t("Experiment")} 1 ${this.$t("Point")} 5`);
      }
      if (
        this.powdermodel_calibration_measures.experiment_1_point_6 == null ||
        this.powdermodel_calibration_measures.experiment_1_point_6 === ""
      ) {
        this.experiment_1_point_6_input_error = true;
        properly_filled = false;
        missing_fields.push(`${this.$t("Experiment")} 1 ${this.$t("Point")} 6`);
      }
      if (
        this.powdermodel_calibration_measures.experiment_1_point_7 == null ||
        this.powdermodel_calibration_measures.experiment_1_point_7 === ""
      ) {
        this.experiment_1_point_7_input_error = true;
        properly_filled = false;
        missing_fields.push(`${this.$t("Experiment")} 1 ${this.$t("Point")} 7`);
      }
      if (
        this.powdermodel_calibration_measures.experiment_2_point_1 == null ||
        this.powdermodel_calibration_measures.experiment_2_point_1 === ""
      ) {
        this.experiment_2_point_1_input_error = true;
        properly_filled = false;
        missing_fields.push(`${this.$t("Experiment")} 2 ${this.$t("Point")} 1`);
      }
      if (
        this.powdermodel_calibration_measures.experiment_2_point_2 == null ||
        this.powdermodel_calibration_measures.experiment_2_point_2 === ""
      ) {
        this.experiment_2_point_2_input_error = true;
        properly_filled = false;
        missing_fields.push(`${this.$t("Experiment")} 2 ${this.$t("Point")} 2`);
      }
      if (
        this.powdermodel_calibration_measures.experiment_2_point_3 == null ||
        this.powdermodel_calibration_measures.experiment_2_point_3 === ""
      ) {
        this.experiment_2_point_3_input_error = true;
        properly_filled = false;
        missing_fields.push(`${this.$t("Experiment")} 2 ${this.$t("Point")} 3`);
      }
      if (
        this.powdermodel_calibration_measures.experiment_2_point_4 == null ||
        this.powdermodel_calibration_measures.experiment_2_point_4 === ""
      ) {
        this.experiment_2_point_4_input_error = true;
        properly_filled = false;
        missing_fields.push(`${this.$t("Experiment")} 2 ${this.$t("Point")} 4`);
      }
      if (
        this.powdermodel_calibration_measures.experiment_2_point_5 == null ||
        this.powdermodel_calibration_measures.experiment_2_point_5 === ""
      ) {
        this.experiment_2_point_5_input_error = true;
        properly_filled = false;
        missing_fields.push(`${this.$t("Experiment")} 2 ${this.$t("Point")} 5`);
      }
      if (
        this.powdermodel_calibration_measures.experiment_2_point_6 == null ||
        this.powdermodel_calibration_measures.experiment_2_point_6 === ""
      ) {
        this.experiment_2_point_6_input_error = true;
        properly_filled = false;
        missing_fields.push(`${this.$t("Experiment")} 2 ${this.$t("Point")} 6`);
      }
      if (
        this.powdermodel_calibration_measures.experiment_2_point_7 == null ||
        this.powdermodel_calibration_measures.experiment_2_point_7 === ""
      ) {
        this.experiment_2_point_7_input_error = true;
        properly_filled = false;
        missing_fields.push(`${this.$t("Experiment")} 2 ${this.$t("Point")} 7`);
      }
      if (
        this.powdermodel_calibration_measures.experiment_3_point_1 == null ||
        this.powdermodel_calibration_measures.experiment_3_point_1 === ""
      ) {
        this.experiment_3_point_1_input_error = true;
        properly_filled = false;
        missing_fields.push(`${this.$t("Experiment")} 3 ${this.$t("Point")} 1`);
      }
      if (
        this.powdermodel_calibration_measures.experiment_3_point_2 == null ||
        this.powdermodel_calibration_measures.experiment_3_point_2 === ""
      ) {
        this.experiment_3_point_2_input_error = true;
        properly_filled = false;
        missing_fields.push(`${this.$t("Experiment")} 3 ${this.$t("Point")} 2`);
      }
      if (
        this.powdermodel_calibration_measures.experiment_3_point_3 == null ||
        this.powdermodel_calibration_measures.experiment_3_point_3 === ""
      ) {
        this.experiment_3_point_3_input_error = true;
        properly_filled = false;
        missing_fields.push(`${this.$t("Experiment")} 3 ${this.$t("Point")} 3`);
      }
      if (
        this.powdermodel_calibration_measures.experiment_3_point_4 == null ||
        this.powdermodel_calibration_measures.experiment_3_point_4 === ""
      ) {
        this.experiment_3_point_4_input_error = true;
        properly_filled = false;
        missing_fields.push(`${this.$t("Experiment")} 3 ${this.$t("Point")} 4`);
      }
      if (
        this.powdermodel_calibration_measures.experiment_3_point_5 == null ||
        this.powdermodel_calibration_measures.experiment_3_point_5 === ""
      ) {
        this.experiment_3_point_5_input_error = true;
        properly_filled = false;
        missing_fields.push(`${this.$t("Experiment")} 3 ${this.$t("Point")} 5`);
      }
      if (
        this.powdermodel_calibration_measures.experiment_3_point_6 == null ||
        this.powdermodel_calibration_measures.experiment_3_point_6 === ""
      ) {
        this.experiment_3_point_6_input_error = true;
        properly_filled = false;
        missing_fields.push(`${this.$t("Experiment")} 3 ${this.$t("Point")} 6`);
      }
      if (
        this.powdermodel_calibration_measures.experiment_3_point_7 == null ||
        this.powdermodel_calibration_measures.experiment_3_point_7 === ""
      ) {
        this.experiment_3_point_7_input_error = true;
        properly_filled = false;
        missing_fields.push(`${this.$t("Experiment")} 3 ${this.$t("Point")} 7`);
      }
      if (
        this.powdermodel_calibration_measures.experiment_4_point_1 == null ||
        this.powdermodel_calibration_measures.experiment_4_point_1 === ""
      ) {
        this.experiment_4_point_1_input_error = true;
        properly_filled = false;
        missing_fields.push(`${this.$t("Experiment")} 4 ${this.$t("Point")} 1`);
      }
      if (
        this.powdermodel_calibration_measures.experiment_4_point_2 == null ||
        this.powdermodel_calibration_measures.experiment_4_point_2 === ""
      ) {
        this.experiment_4_point_2_input_error = true;
        properly_filled = false;
        missing_fields.push(`${this.$t("Experiment")} 4 ${this.$t("Point")} 2`);
      }
      if (
        this.powdermodel_calibration_measures.experiment_4_point_3 == null ||
        this.powdermodel_calibration_measures.experiment_4_point_3 === ""
      ) {
        this.experiment_4_point_3_input_error = true;
        properly_filled = false;
        missing_fields.push(`${this.$t("Experiment")} 4 ${this.$t("Point")} 3`);
      }
      if (
        this.powdermodel_calibration_measures.experiment_4_point_4 == null ||
        this.powdermodel_calibration_measures.experiment_4_point_4 === ""
      ) {
        this.experiment_4_point_4_input_error = true;
        properly_filled = false;
        missing_fields.push(`${this.$t("Experiment")} 4 ${this.$t("Point")} 4`);
      }
      if (
        this.powdermodel_calibration_measures.experiment_4_point_5 == null ||
        this.powdermodel_calibration_measures.experiment_4_point_5 === ""
      ) {
        this.experiment_4_point_5_input_error = true;
        properly_filled = false;
        missing_fields.push(`${this.$t("Experiment")} 4 ${this.$t("Point")} 5`);
      }
      if (
        this.powdermodel_calibration_measures.experiment_4_point_6 == null ||
        this.powdermodel_calibration_measures.experiment_4_point_6 === ""
      ) {
        this.experiment_4_point_6_input_error = true;
        properly_filled = false;
        missing_fields.push(`${this.$t("Experiment")} 4 ${this.$t("Point")} 6`);
      }
      if (
        this.powdermodel_calibration_measures.experiment_4_point_7 == null ||
        this.powdermodel_calibration_measures.experiment_4_point_7 === ""
      ) {
        this.experiment_4_point_7_input_error = true;
        properly_filled = false;
        missing_fields.push(`${this.$t("Experiment")} 4 ${this.$t("Point")} 7`);
      }
      if (
        this.powdermodel_calibration_measures.experiment_5_point_1 == null ||
        this.powdermodel_calibration_measures.experiment_5_point_1 === ""
      ) {
        this.experiment_5_point_1_input_error = true;
        properly_filled = false;
        missing_fields.push(`${this.$t("Experiment")} 5 ${this.$t("Point")} 1`);
      }
      if (
        this.powdermodel_calibration_measures.experiment_5_point_2 == null ||
        this.powdermodel_calibration_measures.experiment_5_point_2 === ""
      ) {
        this.experiment_5_point_2_input_error = true;
        properly_filled = false;
        missing_fields.push(`${this.$t("Experiment")} 5 ${this.$t("Point")} 2`);
      }
      if (
        this.powdermodel_calibration_measures.experiment_5_point_3 == null ||
        this.powdermodel_calibration_measures.experiment_5_point_3 === ""
      ) {
        this.experiment_5_point_3_input_error = true;
        properly_filled = false;
        missing_fields.push(`${this.$t("Experiment")} 5 ${this.$t("Point")} 3`);
      }
      if (
        this.powdermodel_calibration_measures.experiment_5_point_4 == null ||
        this.powdermodel_calibration_measures.experiment_5_point_4 === ""
      ) {
        this.experiment_5_point_4_input_error = true;
        properly_filled = false;
        missing_fields.push(`${this.$t("Experiment")} 5 ${this.$t("Point")} 4`);
      }
      if (
        this.powdermodel_calibration_measures.experiment_5_point_5 == null ||
        this.powdermodel_calibration_measures.experiment_5_point_5 === ""
      ) {
        this.experiment_5_point_5_input_error = true;
        properly_filled = false;
        missing_fields.push(`${this.$t("Experiment")} 5 ${this.$t("Point")} 5`);
      }
      if (
        this.powdermodel_calibration_measures.experiment_5_point_6 == null ||
        this.powdermodel_calibration_measures.experiment_5_point_6 === ""
      ) {
        this.experiment_5_point_6_input_error = true;
        properly_filled = false;
        missing_fields.push(`${this.$t("Experiment")} 5 ${this.$t("Point")} 6`);
      }
      if (
        this.powdermodel_calibration_measures.experiment_5_point_7 == null ||
        this.powdermodel_calibration_measures.experiment_5_point_7 === ""
      ) {
        this.experiment_5_point_7_input_error = true;
        properly_filled = false;
        missing_fields.push(`${this.$t("Experiment")} 5 ${this.$t("Point")} 7`);
      }
      if (
        this.powdermodel_calibration_measures.experiment_6_point_1 == null ||
        this.powdermodel_calibration_measures.experiment_6_point_1 === ""
      ) {
        this.experiment_6_point_1_input_error = true;
        properly_filled = false;
        missing_fields.push(`${this.$t("Experiment")} 6 ${this.$t("Point")} 1`);
      }
      if (
        this.powdermodel_calibration_measures.experiment_6_point_2 == null ||
        this.powdermodel_calibration_measures.experiment_6_point_2 === ""
      ) {
        this.experiment_6_point_2_input_error = true;
        properly_filled = false;
        missing_fields.push(`${this.$t("Experiment")} 6 ${this.$t("Point")} 2`);
      }
      if (
        this.powdermodel_calibration_measures.experiment_6_point_3 == null ||
        this.powdermodel_calibration_measures.experiment_6_point_3 === ""
      ) {
        this.experiment_6_point_3_input_error = true;
        properly_filled = false;
        missing_fields.push(`${this.$t("Experiment")} 6 ${this.$t("Point")} 3`);
      }
      if (
        this.powdermodel_calibration_measures.experiment_6_point_4 == null ||
        this.powdermodel_calibration_measures.experiment_6_point_4 === ""
      ) {
        this.experiment_6_point_4_input_error = true;
        properly_filled = false;
        missing_fields.push(`${this.$t("Experiment")} 6 ${this.$t("Point")} 4`);
      }
      if (
        this.powdermodel_calibration_measures.experiment_6_point_5 == null ||
        this.powdermodel_calibration_measures.experiment_6_point_5 === ""
      ) {
        this.experiment_6_point_5_input_error = true;
        properly_filled = false;
        missing_fields.push(`${this.$t("Experiment")} 6 ${this.$t("Point")} 5`);
      }
      if (
        this.powdermodel_calibration_measures.experiment_6_point_6 == null ||
        this.powdermodel_calibration_measures.experiment_6_point_6 === ""
      ) {
        this.experiment_6_point_6_input_error = true;
        properly_filled = false;
        missing_fields.push(`${this.$t("Experiment")} 6 ${this.$t("Point")} 6`);
      }
      if (
        this.powdermodel_calibration_measures.experiment_6_point_7 == null ||
        this.powdermodel_calibration_measures.experiment_6_point_7 === ""
      ) {
        this.experiment_6_point_7_input_error = true;
        properly_filled = false;
        missing_fields.push(`${this.$t("Experiment")} 6 ${this.$t("Point")} 7`);
      }
      if (
        this.powdermodel_calibration_measures.experiment_7_point_1 == null ||
        this.powdermodel_calibration_measures.experiment_7_point_1 === ""
      ) {
        this.experiment_7_point_1_input_error = true;
        properly_filled = false;
        missing_fields.push(`${this.$t("Experiment")} 7 ${this.$t("Point")} 1`);
      }
      if (
        this.powdermodel_calibration_measures.experiment_7_point_2 == null ||
        this.powdermodel_calibration_measures.experiment_7_point_2 === ""
      ) {
        this.experiment_7_point_2_input_error = true;
        properly_filled = false;
        missing_fields.push(`${this.$t("Experiment")} 7 ${this.$t("Point")} 2`);
      }
      if (
        this.powdermodel_calibration_measures.experiment_7_point_3 == null ||
        this.powdermodel_calibration_measures.experiment_7_point_3 === ""
      ) {
        this.experiment_7_point_3_input_error = true;
        properly_filled = false;
        missing_fields.push(`${this.$t("Experiment")} 7 ${this.$t("Point")} 3`);
      }
      if (
        this.powdermodel_calibration_measures.experiment_7_point_4 == null ||
        this.powdermodel_calibration_measures.experiment_7_point_4 === ""
      ) {
        this.experiment_7_point_4_input_error = true;
        properly_filled = false;
        missing_fields.push(`${this.$t("Experiment")} 7 ${this.$t("Point")} 4`);
      }
      if (
        this.powdermodel_calibration_measures.experiment_7_point_5 == null ||
        this.powdermodel_calibration_measures.experiment_7_point_5 === ""
      ) {
        this.experiment_7_point_5_input_error = true;
        properly_filled = false;
        missing_fields.push(`${this.$t("Experiment")} 7 ${this.$t("Point")} 5`);
      }
      if (
        this.powdermodel_calibration_measures.experiment_7_point_6 == null ||
        this.powdermodel_calibration_measures.experiment_7_point_6 === ""
      ) {
        this.experiment_7_point_6_input_error = true;
        properly_filled = false;
        missing_fields.push(`${this.$t("Experiment")} 7 ${this.$t("Point")} 6`);
      }
      if (
        this.powdermodel_calibration_measures.experiment_7_point_7 == null ||
        this.powdermodel_calibration_measures.experiment_7_point_7 === ""
      ) {
        this.experiment_7_point_7_input_error = true;
        properly_filled = false;
        missing_fields.push(`${this.$t("Experiment")} 7 ${this.$t("Point")} 7`);
      }
      if (
        this.powdermodel_calibration_measures.experiment_8_point_1 == null ||
        this.powdermodel_calibration_measures.experiment_8_point_1 === ""
      ) {
        this.experiment_8_point_1_input_error = true;
        properly_filled = false;
        missing_fields.push(`${this.$t("Experiment")} 8 ${this.$t("Point")} 1`);
      }
      if (
        this.powdermodel_calibration_measures.experiment_8_point_2 == null ||
        this.powdermodel_calibration_measures.experiment_8_point_2 === ""
      ) {
        this.experiment_8_point_2_input_error = true;
        properly_filled = false;
        missing_fields.push(`${this.$t("Experiment")} 8 ${this.$t("Point")} 2`);
      }
      if (
        this.powdermodel_calibration_measures.experiment_8_point_3 == null ||
        this.powdermodel_calibration_measures.experiment_8_point_3 === ""
      ) {
        this.experiment_8_point_3_input_error = true;
        properly_filled = false;
        missing_fields.push(`${this.$t("Experiment")} 8 ${this.$t("Point")} 3`);
      }
      if (
        this.powdermodel_calibration_measures.experiment_8_point_4 == null ||
        this.powdermodel_calibration_measures.experiment_8_point_4 === ""
      ) {
        this.experiment_8_point_4_input_error = true;
        properly_filled = false;
        missing_fields.push(`${this.$t("Experiment")} 8 ${this.$t("Point")} 4`);
      }
      if (
        this.powdermodel_calibration_measures.experiment_8_point_5 == null ||
        this.powdermodel_calibration_measures.experiment_8_point_5 === ""
      ) {
        this.experiment_8_point_5_input_error = true;
        properly_filled = false;
        missing_fields.push(`${this.$t("Experiment")} 8 ${this.$t("Point")} 5`);
      }
      if (
        this.powdermodel_calibration_measures.experiment_8_point_6 == null ||
        this.powdermodel_calibration_measures.experiment_8_point_6 === ""
      ) {
        this.experiment_8_point_6_input_error = true;
        properly_filled = false;
        missing_fields.push(`${this.$t("Experiment")} 8 ${this.$t("Point")} 6`);
      }
      if (
        this.powdermodel_calibration_measures.experiment_8_point_7 == null ||
        this.powdermodel_calibration_measures.experiment_8_point_7 === ""
      ) {
        this.experiment_8_point_7_input_error = true;
        properly_filled = false;
        missing_fields.push(`${this.$t("Experiment")} 8 ${this.$t("Point")} 7`);
      }

      if (!properly_filled) {
        this.$swal({
          title: this.$t("Required Fields missing"),
          text: `${this.$t("Please fill the following fields")}: ` + missing_fields.join(", ") + ".",
          icon: "warning",
          confirmButtonText: "OK",
        });
      }
      return properly_filled;
    },

    postPowderModel() {
      if (!this.ProperlyFilledForm()) {
        return;
      }

      if (this.powdermodel_calibration_measures.powder_amount == "") {
        this.powdermodel_calibration_measures.powder_amount = null;
      }

      this.calculation_in_progress = true;
      axios
        .post("api/v1/fp/powdercalibrationmeasures/", {
          name: this.powdermodel.name,
          line: this.lineId,
          calibration_type: this.powdermodel_calibration_measures.calibration_type,
          powder_amount: this.powdermodel_calibration_measures.powder_amount,
          powder_manufacturer: this.powdermodel_calibration_measures.powder_manufacturer,
          powder_article_id: this.powdermodel_calibration_measures.powder_article_id,
          batch_id: this.powdermodel_calibration_measures.batch_id,
          pump_operational_hours: this.powdermodel_calibration_measures.pump_operational_hours,
          nozzles_type: this.powdermodel_calibration_measures.nozzles_type,
          additional_info: this.powdermodel_calibration_measures.additional_info,
          booth_side: this.powdermodel_calibration_measures.booth_side,
          booth_temperature: this.powdermodel_calibration_measures.booth_temperature,
          booth_humidity: this.powdermodel_calibration_measures.booth_humidity,
          guns_spacing: this.powdermodel_calibration_measures.guns_spacing,
          calibration_line_speed: this.powdermodel_calibration_measures.calibration_line_speed / 60, // convert [m/min] to [m/s]
          max_pistol_to_substrate_distance_measurements:
            this.powdermodel_calibration_measures.max_pistol_to_substrate_distance_measurements / 100, // convert [cm] to [m]
          min_pistol_to_substrate_distance_measurements:
            this.powdermodel_calibration_measures.min_pistol_to_substrate_distance_measurements / 100, // convert [cm] to [m]
          max_air_parameter_measurements: this.powdermodel_calibration_measures.max_air_parameter_measurements,
          min_air_parameter_measurements: this.powdermodel_calibration_measures.min_air_parameter_measurements,
          experiment_1_point_1: this.powdermodel_calibration_measures.experiment_1_point_1,
          experiment_1_point_2: this.powdermodel_calibration_measures.experiment_1_point_2,
          experiment_1_point_3: this.powdermodel_calibration_measures.experiment_1_point_3,
          experiment_1_point_4: this.powdermodel_calibration_measures.experiment_1_point_4,
          experiment_1_point_5: this.powdermodel_calibration_measures.experiment_1_point_5,
          experiment_1_point_6: this.powdermodel_calibration_measures.experiment_1_point_6,
          experiment_1_point_7: this.powdermodel_calibration_measures.experiment_1_point_7,
          experiment_2_point_1: this.powdermodel_calibration_measures.experiment_2_point_1,
          experiment_2_point_2: this.powdermodel_calibration_measures.experiment_2_point_2,
          experiment_2_point_3: this.powdermodel_calibration_measures.experiment_2_point_3,
          experiment_2_point_4: this.powdermodel_calibration_measures.experiment_2_point_4,
          experiment_2_point_5: this.powdermodel_calibration_measures.experiment_2_point_5,
          experiment_2_point_6: this.powdermodel_calibration_measures.experiment_2_point_6,
          experiment_2_point_7: this.powdermodel_calibration_measures.experiment_2_point_7,
          experiment_3_point_1: this.powdermodel_calibration_measures.experiment_3_point_1,
          experiment_3_point_2: this.powdermodel_calibration_measures.experiment_3_point_2,
          experiment_3_point_3: this.powdermodel_calibration_measures.experiment_3_point_3,
          experiment_3_point_4: this.powdermodel_calibration_measures.experiment_3_point_4,
          experiment_3_point_5: this.powdermodel_calibration_measures.experiment_3_point_5,
          experiment_3_point_6: this.powdermodel_calibration_measures.experiment_3_point_6,
          experiment_3_point_7: this.powdermodel_calibration_measures.experiment_3_point_7,
          experiment_4_point_1: this.powdermodel_calibration_measures.experiment_4_point_1,
          experiment_4_point_2: this.powdermodel_calibration_measures.experiment_4_point_2,
          experiment_4_point_3: this.powdermodel_calibration_measures.experiment_4_point_3,
          experiment_4_point_4: this.powdermodel_calibration_measures.experiment_4_point_4,
          experiment_4_point_5: this.powdermodel_calibration_measures.experiment_4_point_5,
          experiment_4_point_6: this.powdermodel_calibration_measures.experiment_4_point_6,
          experiment_4_point_7: this.powdermodel_calibration_measures.experiment_4_point_7,
          experiment_5_point_1: this.powdermodel_calibration_measures.experiment_5_point_1,
          experiment_5_point_2: this.powdermodel_calibration_measures.experiment_5_point_2,
          experiment_5_point_3: this.powdermodel_calibration_measures.experiment_5_point_3,
          experiment_5_point_4: this.powdermodel_calibration_measures.experiment_5_point_4,
          experiment_5_point_5: this.powdermodel_calibration_measures.experiment_5_point_5,
          experiment_5_point_6: this.powdermodel_calibration_measures.experiment_5_point_6,
          experiment_5_point_7: this.powdermodel_calibration_measures.experiment_5_point_7,
          experiment_6_point_1: this.powdermodel_calibration_measures.experiment_6_point_1,
          experiment_6_point_2: this.powdermodel_calibration_measures.experiment_6_point_2,
          experiment_6_point_3: this.powdermodel_calibration_measures.experiment_6_point_3,
          experiment_6_point_4: this.powdermodel_calibration_measures.experiment_6_point_4,
          experiment_6_point_5: this.powdermodel_calibration_measures.experiment_6_point_5,
          experiment_6_point_6: this.powdermodel_calibration_measures.experiment_6_point_6,
          experiment_6_point_7: this.powdermodel_calibration_measures.experiment_6_point_7,
          experiment_7_point_1: this.powdermodel_calibration_measures.experiment_7_point_1,
          experiment_7_point_2: this.powdermodel_calibration_measures.experiment_7_point_2,
          experiment_7_point_3: this.powdermodel_calibration_measures.experiment_7_point_3,
          experiment_7_point_4: this.powdermodel_calibration_measures.experiment_7_point_4,
          experiment_7_point_5: this.powdermodel_calibration_measures.experiment_7_point_5,
          experiment_7_point_6: this.powdermodel_calibration_measures.experiment_7_point_6,
          experiment_7_point_7: this.powdermodel_calibration_measures.experiment_7_point_7,
          experiment_8_point_1: this.powdermodel_calibration_measures.experiment_8_point_1,
          experiment_8_point_2: this.powdermodel_calibration_measures.experiment_8_point_2,
          experiment_8_point_3: this.powdermodel_calibration_measures.experiment_8_point_3,
          experiment_8_point_4: this.powdermodel_calibration_measures.experiment_8_point_4,
          experiment_8_point_5: this.powdermodel_calibration_measures.experiment_8_point_5,
          experiment_8_point_6: this.powdermodel_calibration_measures.experiment_8_point_6,
          experiment_8_point_7: this.powdermodel_calibration_measures.experiment_8_point_7,
        })
        .then(response => {
          this.powdermodel.id = response.data.powdermodel;
          this.calculation_in_progress = false;

          this.$swal({
            title: `${this.$t("Powder Model created")}`,
            text: this.powdermodel.name + ` ${this.$t("was created successfully")}.`,
            icon: "success",
            confirmButtonText: "OK",
          });

          router.push({
            path: "/applications/flightpath/powdermodelslibrary/calibrated/" + this.powdermodel.id,
            replace: true,
          });
        })
        .catch(error => {
          this.$swal({
            title: this.$t("Error calibrating Powder Model. Please try again."),
            text: error.response.data,
            icon: "warning",
            confirmButtonText: "OK",
          });
          this.calculation_in_progress = false;
          console.error(error);
        });
    },
    populateMeasurementDefaults() {
      this.powdermodel_calibration_measures.max_pistol_to_substrate_distance_measurements = 30;
      this.powdermodel_calibration_measures.min_pistol_to_substrate_distance_measurements = 20;
      this.powdermodel_calibration_measures.max_air_parameter_measurements = 5;
      this.powdermodel_calibration_measures.min_air_parameter_measurements = 3;
      this.powdermodel_calibration_measures.calibration_line_speed = 2;
      this.powdermodel_calibration_measures.nozzles_type = "slit";
      this.powdermodel_calibration_measures.powder_manufacturer = "AkzoNobel";
      this.powdermodel_calibration_measures.powder_article_id = "akzoidXX";
      this.powdermodel_calibration_measures.pistol_to_substrate_distance = 20;
      this.powdermodel_calibration_measures.pump_operational_hours = 100;
      this.powdermodel_calibration_measures.batch_id = "akzoYY";

      this.powdermodel_calibration_measures.powder_amount = 2;
      this.powdermodel_calibration_measures.additional_info = "akzoidXX";
      this.powdermodel_calibration_measures.booth_side = "left";
      this.powdermodel_calibration_measures.booth_temperature = 20;
      this.powdermodel_calibration_measures.booth_humidity = 50;
      this.powdermodel_calibration_measures.guns_spacing = "evenly spaced";

      this.powdermodel_calibration_measures.experiment_1_point_1 = 35;
      this.powdermodel_calibration_measures.experiment_1_point_2 = 83;
      this.powdermodel_calibration_measures.experiment_1_point_3 = 118;
      this.powdermodel_calibration_measures.experiment_1_point_4 = 82;
      this.powdermodel_calibration_measures.experiment_1_point_5 = 59;
      this.powdermodel_calibration_measures.experiment_1_point_6 = 42;
      this.powdermodel_calibration_measures.experiment_1_point_7 = 35;

      this.powdermodel_calibration_measures.experiment_2_point_1 = 37;
      this.powdermodel_calibration_measures.experiment_2_point_2 = 55;
      this.powdermodel_calibration_measures.experiment_2_point_3 = 56;
      this.powdermodel_calibration_measures.experiment_2_point_4 = 47;
      this.powdermodel_calibration_measures.experiment_2_point_5 = 38;
      this.powdermodel_calibration_measures.experiment_2_point_6 = 33;
      this.powdermodel_calibration_measures.experiment_2_point_7 = 30;

      this.powdermodel_calibration_measures.experiment_3_point_1 = 23;
      this.powdermodel_calibration_measures.experiment_3_point_2 = 51;
      this.powdermodel_calibration_measures.experiment_3_point_3 = 72;
      this.powdermodel_calibration_measures.experiment_3_point_4 = 71;
      this.powdermodel_calibration_measures.experiment_3_point_5 = 63;
      this.powdermodel_calibration_measures.experiment_3_point_6 = 50;
      this.powdermodel_calibration_measures.experiment_3_point_7 = 33;

      this.powdermodel_calibration_measures.experiment_4_point_1 = 30;
      this.powdermodel_calibration_measures.experiment_4_point_2 = 42;
      this.powdermodel_calibration_measures.experiment_4_point_3 = 52;
      this.powdermodel_calibration_measures.experiment_4_point_4 = 52;
      this.powdermodel_calibration_measures.experiment_4_point_5 = 45;
      this.powdermodel_calibration_measures.experiment_4_point_6 = 37;
      this.powdermodel_calibration_measures.experiment_4_point_7 = 32;

      this.powdermodel_calibration_measures.experiment_5_point_1 = 17;
      this.powdermodel_calibration_measures.experiment_5_point_2 = 34;
      this.powdermodel_calibration_measures.experiment_5_point_3 = 104;
      this.powdermodel_calibration_measures.experiment_5_point_4 = 194;
      this.powdermodel_calibration_measures.experiment_5_point_5 = 72;
      this.powdermodel_calibration_measures.experiment_5_point_6 = 33;
      this.powdermodel_calibration_measures.experiment_5_point_7 = 0;

      this.powdermodel_calibration_measures.experiment_6_point_1 = 32;
      this.powdermodel_calibration_measures.experiment_6_point_2 = 79;
      this.powdermodel_calibration_measures.experiment_6_point_3 = 180;
      this.powdermodel_calibration_measures.experiment_6_point_4 = 99;
      this.powdermodel_calibration_measures.experiment_6_point_5 = 48;
      this.powdermodel_calibration_measures.experiment_6_point_6 = 27;
      this.powdermodel_calibration_measures.experiment_6_point_7 = 17;

      this.powdermodel_calibration_measures.experiment_7_point_1 = 5;
      this.powdermodel_calibration_measures.experiment_7_point_2 = 53;
      this.powdermodel_calibration_measures.experiment_7_point_3 = 93;
      this.powdermodel_calibration_measures.experiment_7_point_4 = 107;
      this.powdermodel_calibration_measures.experiment_7_point_5 = 85;
      this.powdermodel_calibration_measures.experiment_7_point_6 = 51;
      this.powdermodel_calibration_measures.experiment_7_point_7 = 25;

      this.powdermodel_calibration_measures.experiment_8_point_1 = 7;
      this.powdermodel_calibration_measures.experiment_8_point_2 = 44;
      this.powdermodel_calibration_measures.experiment_8_point_3 = 66;
      this.powdermodel_calibration_measures.experiment_8_point_4 = 72;
      this.powdermodel_calibration_measures.experiment_8_point_5 = 71;
      this.powdermodel_calibration_measures.experiment_8_point_6 = 63;
      this.powdermodel_calibration_measures.experiment_8_point_7 = 34;
    },
  },
};
</script>

<i18n>
  {
    "EN": {
        "Creating Powder Model for":"Creating Powder Model for",
        "Name":"Name",
        "Experiment":"Experiment",
        "Gun Nozzle Slit orientation": "Gun Nozzle Slit orientation",
        "Gun movement": "Gun movement",
        "Deactivated reciprocator. Non-moving guns.": "Deactivated reciprocator. Non-moving guns.",
        "Gun to substrate distance": "Gun to substrate distance",
        "Nozzle orientation": "Nozzle orientation",
        "Powder model name": "Powder model name",
        "Line speed": "Line speed",
        "Optional Parameters": "Optional Parameters",
        "Air parameter": "Air parameter",
        "Booth side used for calibration": "Booth side used for calibration",
        "right": "right",
        "left": "left",
        "Nozzle Type": "Nozzle Type",
        "Powder Amount": "Powder Amount",
        "Powder Manufacturer": "Powder Manufacturer",
        "Powder ID": "Powder ID",
        "Additional Info": "Additional Info",
        "Continue to Experiment": "Continue to Experiment",
        "Measurements": "Measurements",
        "Point": "Point",
        "Fitting PowderModel Distribution from measurements": "Fitting PowderModel Distribution from measurements",
        "Create Powder Distribution Model": "Create Powder Distribution Model",
        "Powder Model created": "Powder Model created",
        "was created successfully": "was created successfully",
        "Configuration for Experiment": "Configuration for Experiment",
        "Required Fields missing": "Required Fields missing",
        "Please fill the following fields": "Please fill the following fields",
        "PowderModel was not created. Try again.": "PowderModel was not created. Try again.",
        "Horizontal (standard orientation)": "Horizontal (standard orientation)",
        "Vertical (turned 90°)": "Vertical (turned 90°)",
        "Calibration Parameters": "Calibration Parameters",
        "MIN": "MIN",
        "MAX": "MAX",
        "Measurements for Experiment": "Measurements for Experiment",
        "evenly spaced": "evenly spaced",
        "unevenly spaced": "unevenly spaced",
        "Booth Humidity": "Booth Humidity",
        "Booth Temperature": "Booth Temperature",
        "Pump Operational Hours": "Pump Operational Hours",
        "Batch ID": "Batch ID",
        "Guns Spacing": "Guns Spacing",
        "Other": "Other",
        "AkzoNobel": "AkzoNobel",
        "Airflow or air volume selected in the powder coating machine": "Airflow or air volume selected in the powder coating machine",
        "Quantity of powder dispensed by the gun per unit time": "Quantity of powder dispensed by the gun per unit time",
        "Operational Hours Since Last Maintanance": "Operational Hours Since Last Maintanance",
        "Operational Hours Injector": "Operational Hours Injector",
    },
    "DE": {
        "Creating Powder Model for":"Erstellung des Sprühbild für",
        "Name":"Name",
        "Experiment":"Experiment",
        "Gun Nozzle Slit orientation": "Ausrichten des Düsenschlitzes der Spritzpistole",
        "Gun movement": "Pistolenbewegung",
        "Deactivated reciprocator. Non-moving guns.": "Deaktiviertes Hubgerät. Pistolen stehen still.",
        "Gun to substrate distance": "Abstand zwischen Pistole und Substrat",
        "Nozzle orientation": "Ausrichtung der Düsen",
        "Powder model name": "Name des Sprühbild",
        "Line speed": "Kettengeschwindigkeit",
        "Optional Parameters": "Optionale Parameter",
        "Air parameter": "Luftparameter",
        "Booth side used for calibration": "Kabinenseite für die Kalibrierung",
        "right": "rechts",
        "left": "links",
        "Nozzle Type": "Düsentyp",
        "Powder Amount": "Pulvermenge",
        "Powder Manufacturer": "Pulverhersteller",
        "Powder ID": "Pulver ID",
        "Additional Info": "Zusätzliche Informationen",
        "Continue to Experiment": "Weiter zum Experiment",
        "Measurements": "Messungen",
        "Point": "Punkt",
        "Fitting PowderModel Distribution from measurements": "Anpassung der Pulvermodellverteilung anhand von Messungen",
        "Create Powder Distribution Model": "Erstellen des Sprühbilds",
        "Powder Model created": "Pulvermodell erstellt",
        "was created successfully": "wurde erfolgreich erstellt",
        "Configuration for Experiment": "Konfiguration für Experiment",
        "Required Fields missing": "Erforderliche Felder fehlen",
        "Please fill the following fields": "Bitte füllen Sie die folgenden Felder aus",
        "PowderModel was not created. Try again.": "Sprühbild wurde nicht erstellt. Versuchen Sie es erneut.",
        "Horizontal (standard orientation)": "Horizontal (Standardausrichtung)",
        "Vertical (turned 90°)": "Vertikal (um 90° gedreht)",
        "Calibration Parameters": "Kalibrierungsparameter",
        "MIN": "MIN",
        "MAX": "MAX",
        "Measurements for Experiment": "Messungen für Experiment",
        "evenly spaced": "gleichmäßig verteilt",
        "unevenly spaced": "ungleichmäßig verteilt",
        "Booth Humidity": "Kabinenfeuchtigkeit",
        "Booth Temperature": "Kabinentemperatur",
        "Pump Operational Hours": "Betriebsstunden der Pumpe",
        "Batch ID": "Chargen-ID",
        "Guns Spacing": "Pistolenabstand",
        "Other": "Andere",
        "AkzoNobel": "AkzoNobel",
        "Airflow or air volume selected in the powder coating machine": "Luftstrom oder Luftvolumen, das in der Pulverbeschichtungsmaschine ausgewählt wurde",
        "Quantity of powder dispensed by the gun per unit time": "Menge an Pulver, die pro Zeiteinheit von der Pistole abgegeben wird",
        "Operational Hours Since Last Maintanance": "Betriebsstunden seit der letzten Wartung",
        "Operational Hours Injector": "Betriebsstunden Injektor",
    },
    "ES": {
        "Creating Powder Model for":"Creando modelo de pintura en polvo para",
        "Name":"Nombre",
        "Experiment":"Experimento",
        "Gun Nozzle Slit orientation": "Orientación de la boquilla de las pistolas",
        "Gun movement": "Movimiento de las pistolas",
        "Deactivated reciprocator. Non-moving guns.": "Reciprocador desactivado. Pistolas inmóviles.",
        "Gun to substrate distance": "Distancia de la pistola al sustrato",
        "Nozzle orientation": "Orientación de la boquilla",
        "Powder model name": "Nombre del modelo de pintura en polvo",
        "Line speed": "Velocidad de la línea",
        "Optional Parameters": "Parámetros opcionales",
        "Air parameter": "Parámetro de flujo aire",
        "Booth side used for calibration": "Lado de la cabina utilizado para la calibración",
        "right": "derecho",
        "left": "izquierdo",
        "Nozzle Type": "Tipo de boquilla",
        "Powder Amount": "Cantidad de pintura en polvo",
        "Powder Manufacturer": "Fabricante de pintura en polvo",
        "Powder ID": "ID de pintura en polvo",
        "Additional Info": "Información adicional",
        "Continue to Experiment": "Continuar con el experimento",
        "Measurements": "Mediciones",
        "Point": "Punto",
        "Fitting PowderModel Distribution from measurements": "Estimación del modelo a partir de las mediciones",
        "Create Powder Distribution Model": "Crear modelo de distribución de polvo",
        "Powder Model created": "Modelo de pintura en polvo creado",
        "was created successfully": "se creó con éxito",
        "Configuration for Experiment": "Configuración para el experimento",
        "Required Fields missing": "Faltan campos obligatorios",
        "Please fill the following fields": "Por favor, rellene los siguientes campos",
        "PowderModel was not created. Try again.": "El modelo de pintura en polvo no se creó. Inténtalo de nuevo.",
        "Horizontal (standard orientation)": "Horizontal (orientación estándar)",
        "Vertical (turned 90°)": "Vertical (girado 90°)",
        "Calibration Parameters": "Parámetros de calibración",
        "MIN": "MIN",
        "MAX": "MAX",
        "Measurements for Experiment": "Mediciones para el experimento",
        "evenly spaced": "equidistantes",
        "unevenly spaced": "no equidistantes",
        "Booth Humidity": "Humedad de la cabina",
        "Booth Temperature": "Temperatura de la cabina",
        "Pump Operational Hours": "Horas de funcionamiento de la bomba",
        "Batch ID": "ID del lote",
        "Guns Spacing": "Espaciado de las pistolas",
        "Other": "Otro",
        "AkzoNobel": "AkzoNobel",
        "Airflow or air volume selected in the powder coating machine": "Flujo de aire o volumen de aire seleccionado en la máquina de recubrimiento en polvo",
        "Quantity of powder dispensed by the gun per unit time": "Cantidad de pintura en polvo dispensada por la pistola por unidad de tiempo",
        "Operational Hours Since Last Maintanance": "Horas de funcionamiento desde el último mantenimiento",
        "Operational Hours Injector": "Horas de funcionamiento del inyector",
    },
    "FR": {
        "Creating Powder Model for":"Création du modèle de poudre pour",
        "Name":"Nom",
        "Experiment":"Expérience",
        "Gun Nozzle Slit orientation": "Orientation de la buse des pistolets",
        "Gun movement": "Mouvement des pistolets",
        "Deactivated reciprocator. Non-moving guns.": "Reciprocator désactivé. Pistolets immobiles.",
        "Gun to substrate distance": "Distance entre le pistolet et le substrat",
        "Nozzle orientation": "Orientation de la buse",
        "Powder model name": "Nom du modèle de poudre",
        "Line speed": "Vitesse de la ligne",
        "Optional Parameters": "Paramètres optionnels",
        "Air parameter": "Paramètre d'air",
        "Booth side used for calibration": "Côté de la cabine utilisé pour l'étalonnage",
        "right": "droite",
        "left": "gauche",
        "Nozzle Type": "Type de buse",
        "Powder Amount": "Quantité de poudre",
        "Powder Manufacturer": "Fabricant de poudre",
        "Powder ID": "Poudre ID",
        "Additional Info": "Informations supplémentaires",
        "Continue to Experiment": "Continuer l'expérience",
        "Measurements": "Mesures",
        "Point": "Point",
        "Fitting PowderModel Distribution from measurements": "Ajustement de la distribution du modèle de poudre à partir des mesures",
        "Create Powder Distribution Model": "Créer un modèle de distribution de poudre",
        "Powder Model created": "Modèle de poudre créé",
        "was created successfully": "a été créé avec succès",
        "Configuration for Experiment": "Configuration pour l'expérience",
        "Required Fields missing": "Champs obligatoires manquants",
        "Please fill the following fields": "Veuillez remplir les champs suivants",
        "PowderModel was not created. Try again.": "Le modèle de poudre n'a pas été créé. Réessayer.",
        "Horizontal (standard orientation)": "Horizontal (orientation standard)",
        "Vertical (turned 90°)": "Vertical (tourné de 90°)",
        "Calibration Parameters": "Paramètres d'étalonnage",
        "MIN": "MIN",
        "MAX": "MAX",
        "Measurements for Experiment": "Mesures pour l'expérience",
        "evenly spaced": "uniformément espacés",
        "unevenly spaced": "espacés de manière inégale",
        "Booth Humidity": "Humidité de la cabine",
        "Booth Temperature": "Température de la cabine",
        "Pump Operational Hours": "Heures de fonctionnement de la pompe",
        "Batch ID": "ID du lot",
        "Guns Spacing": "Espacement des pistolets",
        "Other": "Autre",
        "AkzoNobel": "AkzoNobel",
        "Airflow or air volume selected in the powder coating machine": "Débit d'air ou volume d'air sélectionné dans la machine de revêtement en poudre",
        "Quantity of powder dispensed by the gun per unit time": "Quantité de poudre distribuée par le pistolet par unité de temps",
        "Operational Hours Since Last Maintanance": "Heures de fonctionnement depuis la dernière maintenance",
        "Operational Hours Injector": "Heures de fonctionnement de l'injecteur",
    },
    "IT": {
        "Creating Powder Model for":"Creazione del modello di polvere per",
        "Name":"Nome",
        "Experiment":"Esperimento",
        "Gun Nozzle Slit orientation": "Orientamento della bocchetta della pistola",
        "Gun movement": "Movimento delle pistole",
        "Deactivated reciprocator. Non-moving guns.": "Reciprocator disattivato. Pistole non in movimento.",
        "Gun to substrate distance": "Distanza pistola-substrato",
        "Nozzle orientation": "Orientamento della bocchetta",
        "Powder model name": "Nome del modello di polvere",
        "Line speed": "Velocità di linea",
        "Optional Parameters": "Parametri opzionali",
        "Air parameter": "Parametro dell'aria",
        "Booth side used for calibration": "Lato cabina utilizzato per la calibrazione",
        "right": "destra",
        "left": "sinistra",
        "Nozzle Type": "Tipo di ugello",
        "Powder Amount": "Quantità di polvere",
        "Powder Manufacturer": "Produttore di polvere",
        "Powder ID": "Polvere ID",
        "Additional Info": "Informazioni aggiuntive",
        "Continue to Experiment": "Continua con l'esperimento",
        "Measurements": "Misure",
        "Point": "Punto",
        "Fitting PowderModel Distribution from measurements": "Adattamento della distribuzione del modello di polvere dalle misurazioni",
        "Create Powder Distribution Model": "Crea modello di distribuzione di polvere",
        "Powder Model created": "Modello di polvere creato",
        "was created successfully": "è stato creato con successo",
        "Configuration for Experiment": "Configurazione per l'esperimento",
        "Required Fields missing": "Campi obbligatori mancanti",
        "Please fill the following fields": "Si prega di compilare i seguenti campi",
        "PowderModel was not created. Try again.": "Il modello di polvere non è stato creato. Riprova.",
        "Horizontal (standard orientation)": "Orizzontale (orientamento standard)",
        "Vertical (turned 90°)": "Verticale (ruotato di 90°)",
        "Calibration Parameters": "Parametri di calibrazione",
        "MIN": "MIN",
        "MAX": "MAX",
        "Measurements for Experiment": "Misure per l'esperimento",
        "evenly spaced": "uniformemente spaziati",
        "unevenly spaced": "spaziati in modo non uniforme",
        "Booth Humidity": "Umidità della cabina",
        "Booth Temperature": "Temperatura della cabina",
        "Pump Operational Hours": "Ore operative della pompa",
        "Batch ID": "ID batch",
        "Guns Spacing": "Spaziatura delle pistole",
        "Other": "Altro",
        "AkzoNobel": "AkzoNobel",
        "Airflow or air volume selected in the powder coating machine": "Flusso d'aria o volume d'aria selezionato nella macchina di verniciatura a polvere",
        "Quantity of powder dispensed by the gun per unit time": "Quantità di polvere erogata dalla pistola per unità di tempo",
        "Operational Hours Since Last Maintanance": "Ore operative dall'ultima manutenzione",
        "Operational Hours Injector": "Ore operative dell'iniettore",
    },
    "PL": {
        "Creating Powder Model for":"Tworzenie modelu proszkowego dla",
        "Name":"Nazwa",
        "Experiment":"Eksperyment",
        "Gun Nozzle Slit orientation": "Orientacja szczeliny dyszy pistoletów",
        "Gun movement": "Ruch pistoletów",
        "Deactivated reciprocator. Non-moving guns.": "Dezaktywowany reciprocator. Pistolety nieruchome.",
        "Gun to substrate distance": "Odległość pistoletu od podłoża",
        "Nozzle orientation": "Orientacja dyszy",
        "Powder model name": "Nazwa modelu proszkowego",
        "Line speed": "Prędkość linii",
        "Optional Parameters": "Parametry opcjonalne",
        "Air parameter": "Parametr powietrza",
        "Booth side used for calibration": "Strona kabiny użyta do kalibracji",
        "right": "prawo",
        "left": "lewo",
        "Nozzle Type": "Typ dyszy",
        "Powder Amount": "Ilość proszku",
        "Powder Manufacturer": "Producent proszku",
        "Powder ID": "Proszku ID",
        "Additional Info": "Dodatkowe informacje",
        "Continue to Experiment": "Kontynuuj eksperyment",
        "Measurements": "Pomiary",
        "Point": "Punkt",
        "Fitting PowderModel Distribution from measurements": "Dopasowanie rozkładu modelu proszkowego z pomiarów",
        "Create Powder Distribution Model": "Utwórz model dystrybucji proszku",
        "Powder Model created": "Model proszku utworzony",
        "was created successfully": "został utworzony pomyślnie",
        "Configuration for Experiment": "Konfiguracja do eksperymentu",
        "Required Fields missing": "Brakujące wymagane pola",
        "Please fill the following fields": "Proszę wypełnić następujące pola",
        "PowderModel was not created. Try again.": "Model proszku nie został utworzony. Spróbuj ponownie.",
        "Horizontal (standard orientation)": "Poziomo (standardowa orientacja)",
        "Vertical (turned 90°)": "Pionowo (obrócone o 90°)",
        "Calibration Parameters": "Parametry kalibracji",
        "MIN": "MIN",
        "MAX": "MAX",
        "Measurements for Experiment": "Pomiary do eksperymentu",
        "evenly spaced": "równomiernie rozłożone",
        "unevenly spaced": "unevenly spaced",
        "Booth Humidity": "Wilgotność kabiny",
        "Booth Temperature": "Temperatura kabiny",
        "Pump Operational Hours": "Godziny pracy pompy",
        "Batch ID": "ID partii",
        "Guns Spacing": "Rozstawienie pistoletów",
        "Other": "Inne",
        "AkzoNobel": "AkzoNobel",
        "Airflow or air volume selected in the powder coating machine": "Przepływ powietrza lub objętość powietrza wybrana w maszynie do malowania proszkowego",
        "Quantity of powder dispensed by the gun per unit time": "Ilość proszku wydawana przez pistolet na jednostkę czasu",
        "Operational Hours Since Last Maintanance": "Godziny pracy od ostatniego przeglądu",
        "Operational Hours Injector": "Godziny pracy wtryskiwacza",
    },
    "TR": {
        "Creating Powder Model for":"İçin Toz Modeli Oluşturma",
        "Name":"İsim",
        "Experiment":"Deney",
        "Gun Nozzle Slit orientation": "Tabancaların Nozul Yarığı Yönelimi",
        "Gun movement": "Tabancaların hareketi",
        "Deactivated reciprocator. Non-moving guns.": "Deaktive edilmiş reciprocator. Hareketsiz tabancalar.",
        "Gun to substrate distance": "Tabancadan alt tabakaya mesafe",
        "Nozzle orientation": "Nozul yönelimi",
        "Powder model name": "Toz modeli adı",
        "Line speed": "Hat hızı",
        "Optional Parameters": "İsteğe bağlı parametreler",
        "Air parameter": "Hava parametresi",
        "Booth side used for calibration": "Kalibrasyon için kullanılan kabin tarafı",
        "right": "sağ",
        "left": "sol",
        "Nozzle Type": "Nozul tipi",
        "Powder Amount": "Toz miktarı",
        "Powder Manufacturer": "Toz üreticisi",
        "Powder ID": "Toz ID",
        "Additional Info": "Ek bilgi",
        "Continue to Experiment": "Deneye devam et",
        "Measurements": "Ölçümler",
        "Point": "Nokta",
        "Fitting PowderModel Distribution from measurements": "Ölçümlerden Toz Modeli Dağılımı Uyumu",
        "Create Powder Distribution Model": "Toz Dağıtım Modeli Oluştur",
        "Powder Model created": "Toz Modeli oluşturuldu",
        "was created successfully": "başarıyla oluşturuldu",
        "Configuration for Experiment": "Deney için yapılandırma",
        "Required Fields missing": "Gerekli Alanlar eksik",
        "Please fill the following fields": "Lütfen aşağıdaki alanları doldurun",
        "PowderModel was not created. Try again.": "Toz Modeli oluşturulamadı. Tekrar deneyin.",
        "Horizontal (standard orientation)": "Yatay (standart yönelim)",
        "Vertical (turned 90°)": "Dikey (90° döndürülmüş)",
        "Calibration Parameters": "Kalibrasyon Parametreleri",
        "MIN": "MIN",
        "MAX": "MAX",
        "Measurements for Experiment": "Deney için Ölçümler",
        "evenly spaced": "eşit aralıklı",
        "unevenly spaced": "eşit olmayan aralıklı",
        "Booth Humidity": "Kabin Nem",
        "Booth Temperature": "Kabin Sıcaklığı",
        "Pump Operational Hours": "Pompa Çalışma Saatleri",
        "Batch ID": "Toplu Kimlik",
        "Guns Spacing": "Tabancaların aralığı",
        "Other": "Diğer",
        "AkzoNobel": "AkzoNobel",
        "Airflow or air volume selected in the powder coating machine": "Toz kaplama makinesinde seçilen hava akışı veya hava hacmi",
        "Quantity of powder dispensed by the gun per unit time": "Tabanca tarafından birim zamanda verilen toz miktarı",
        "Operational Hours Since Last Maintanance": "Son Bakımdan Bu Yana Çalışma Saatleri",
        "Operational Hours Injector": "Enjektör Çalışma Saatleri",
    },
    "ZH": {
        "Creating Powder Model for":"创建粉末模型",
        "Name":"名称",
        "Experiment":"实验",
        "Gun Nozzle Slit orientation": "手枪喷嘴缝隙方向",
        "Gun movement": "手枪运动",
        "Deactivated reciprocator. Non-moving guns.": "已停用往复运动。 静止手枪。",
        "Gun to substrate distance": "手枪到基板的距离",
        "Nozzle orientation": "喷嘴方向",
        "Powder model name": "粉末模型名称",
        "Line speed": "线速度",
        "Optional Parameters": "可选参数",
        "Air parameter": "空气参数",
        "Booth side used for calibration": "用于校准的展位侧",
        "right": "对",
        "left": "左",
        "Nozzle Type": "喷嘴类型",
        "Powder Amount": "粉末量",
        "Powder Manufacturer": "粉末制造商",
        "Powder ID": "粉末ID",
        "Additional Info": "额外信息",
        "Continue to Experiment": "继续实验",
        "Measurements": "测量",
        "Point": "点",
        "Fitting PowderModel Distribution from measurements": "从测量中拟合粉末模型分布",
        "Create Powder Distribution Model": "创建粉末分布模型",
        "Powder Model created": "粉末模型已创建",
        "was created successfully": "已成功创建",
        "Configuration for Experiment": "实验配置",
        "Required Fields missing": "缺少必填字段",
        "Please fill the following fields": "请填写以下字段",
        "PowderModel was not created. Try again.": "未创建粉末模型。请重试。",
        "Horizontal (standard orientation)": "水平（标准方向）",
        "Vertical (turned 90°)": "垂直（旋转90°）",
        "Calibration Parameters": "校准参数",
        "MIN": "最小",
        "MAX": "最大",
        "Measurements for Experiment": "实验测量",
        "evenly spaced": "均匀间隔",
        "unevenly spaced": "不均匀间隔",
        "Booth Humidity": "展位湿度",
        "Booth Temperature": "展位温度",
        "Pump Operational Hours": "泵操作小时",
        "Batch ID": "批次ID",
        "Guns Spacing": "枪支间距",
        "Other": "其他",
        "AkzoNobel": "AkzoNobel",
        "Airflow or air volume selected in the powder coating machine": "粉末涂层机中选择的气流或空气体积",
        "Quantity of powder dispensed by the gun per unit time": "枪每单位时间分配的粉末量",
        "Operational Hours Since Last Maintanance": "上次维护以来的运行时间",
        "Operational Hours Injector": "喷油嘴运行小时",
    },
  }
</i18n>
