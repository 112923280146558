<template>
  <div class="py-5 container-fluid">
    <div id="topinfo">
      <div class="row mt-4 justify-content-center">
        <div class="col-12">
          <div class="card">
            <div class="card-header pb-0 text-center my-3">
              <h4 class="font-weight-bolder">{{ $t("Benchmark & Target") }}</h4>
            </div>
            <div class="card-body mb-0 pb-3" style="padding-top: 0.5rem">
              <div class="row">
                <div class="col-6 mt-3">
                  <div class="row mb-3">
                    <h5>Target Thickness</h5>
                    <div class="row mb-3">
                      <div class="col-5">
                        <label
                          >Min [{{
                            this.$store.state.units_system[this.$store.state.user_data.unit_system].thickness
                          }}]</label
                        >
                        <input
                          v-model="minimumTargetThickness"
                          type="number"
                          class="form-control my-2"
                          placeholder="MIN"
                          @keydown="numericOnly"
                        />
                      </div>
                      <div class="col-5">
                        <label
                          >Max [{{
                            this.$store.state.units_system[this.$store.state.user_data.unit_system].thickness
                          }}]</label
                        >
                        <input
                          v-model="maximumTargetThickness"
                          type="number"
                          class="form-control my-2"
                          placeholder="MAX"
                          @keydown="numericOnly"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="row mb-3">
                    <h5>Line Settings</h5>
                    <div class="row mb-3">
                      <div class="col-5">
                        <label>Reciprocator Period [s]</label>
                        <input
                          v-model="benchmark_form.reciprocator_period"
                          type="number"
                          class="form-control my-2"
                          placeholder="PERIOD"
                          @keydown="numericOnly"
                        />
                      </div>
                      <div class="col-5">
                        <label
                          >Reciprocator Stroke [{{
                            this.$store.state.units_system[this.$store.state.user_data.unit_system].distance
                          }}]</label
                        >
                        <input
                          v-model="gunsMovementRange"
                          type="number"
                          class="form-control my-2"
                          placeholder="STROKE"
                          @keydown="numericOnly"
                        />
                      </div>
                    </div>
                    <div class="row mb-3">
                      <div class="col-5">
                        <label
                          >Line Speed [{{
                            this.$store.state.units_system[this.$store.state.user_data.unit_system].velocity_minutes
                          }}]</label
                        >
                        <input
                          v-model="lineSpeed"
                          type="number"
                          min="0"
                          class="form-control my-2"
                          placeholder="LINE SPEED"
                          @keydown="numericOnly"
                        />
                      </div>
                      <div class="col-5">
                        <label
                          >Covered Height [{{
                            this.$store.state.units_system[this.$store.state.user_data.unit_system].distance
                          }}]</label
                        >
                        <input
                          v-model="coatedHeight"
                          type="number"
                          class="form-control my-2"
                          placeholder="COVERED HEIGHT"
                          @keydown="numericOnly"
                        />
                      </div>
                    </div>
                    <div class="row mb-3">
                      <div class="col-5">
                        <label>Nozzle Angle</label>
                        <select
                          id="choices-category"
                          v-model="benchmark_form.nozzle_angle"
                          class="form-control my-2"
                          style="appearance: listbox"
                        >
                          <option disabled :value="null">Please select one</option>
                          <option :value="0">{{ "0 degrees" }} (3h)</option>
                          <option :value="15">{{ "15 degrees" }} (2:30h)</option>
                          <option :value="30">{{ "30 degrees" }} (2h)</option>
                          <option :value="45">{{ "45 degrees" }} (1:30h)</option>
                          <option :value="60">{{ "60 degrees" }} (1h)</option>
                          <option :value="75">{{ "75 degrees" }} (0:30h)</option>
                          <option :value="90">{{ "90 degrees" }} (12h)</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-6 mt-3">
                  <div class="row">
                    <div class="col-12">
                      <h5 class="text-center">Gun Settings</h5>
                      <div class="table-responsive" style="overflow-x: auto">
                        <table class="table table-sm text-center text-xs">
                          <thead>
                            <tr>
                              <th class="text-center">Gun</th>
                              <th class="text-center">Voltage</th>
                              <th class="text-center">Amps</th>
                              <th class="text-center">Air</th>
                              <th class="text-center">Distance</th>
                              <th class="text-center">Powder Amount</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-for="(gun, index) in benchmark_form.gun_settings" :key="index">
                              <td>{{ index + 1 }}</td>
                              <td>
                                <input
                                  type="text"
                                  class="form-control text-center text-sm"
                                  v-model="gun.voltage"
                                  style="width: 70%; margin: 0 auto"
                                  @keydown="numericOnly"
                                  @change="autoFillGunSettings(index, 'voltage')"
                                />
                              </td>
                              <td>
                                <input
                                  type="text"
                                  class="form-control text-center text-sm"
                                  v-model="gun.amps"
                                  style="width: 70%; margin: 0 auto"
                                  @keydown="numericOnly"
                                  @change="autoFillGunSettings(index, 'amps')"
                                />
                              </td>
                              <td>
                                <input
                                  type="text"
                                  class="form-control text-center text-sm"
                                  v-model="gun.air"
                                  style="width: 70%; margin: 0 auto"
                                  @keydown="numericOnly"
                                  @change="autoFillGunSettings(index, 'air')"
                                />
                              </td>
                              <td>
                                <input
                                  type="text"
                                  class="form-control text-center text-sm"
                                  v-model="gun.distance"
                                  style="width: 70%; margin: 0 auto"
                                  @keydown="numericOnly"
                                  @change="autoFillGunSettings(index, 'distance')"
                                />
                              </td>
                              <td>
                                <input
                                  type="text"
                                  class="form-control text-center text-sm"
                                  v-model="gun.powder"
                                  style="width: 70%; margin: 0 auto"
                                  @keydown="numericOnly"
                                  @change="autoFillGunSettings(index, 'powder')"
                                />
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-6">
                  <div class="row">
                    <div class="col-12">
                      <h5>
                        Thickness Measurements [{{
                          this.$store.state.units_system[this.$store.state.user_data.unit_system].thickness
                        }}]
                      </h5>
                      <div class="table-responsive">
                        <table class="table table-sm text-center text-xs">
                          <thead>
                            <tr>
                              <th>Measurement</th>
                              <th>Thickness</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-for="(_, index) in benchmark_form.measurements" :key="index">
                              <td>{{ index + 1 }}</td>
                              <td>
                                <input
                                  type="number"
                                  class="form-control text-center"
                                  style="width: 50%; margin: 0 auto"
                                  v-model="benchmark_form.measurements[index]"
                                  min="0"
                                  @change="checkLastRow(index)"
                                  @keydown="numericOnly"
                                />
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-6">
                  <h5>Thickness Distribution Gaussin Chart</h5>
                  <!-- <gaussian-chart v-if="this.chart" :chart="this.chart"  :minAnnotation="40" :maxAnnotation="100" /> -->
                  <canvas id="measurement-chart" class="chart-canvas" height="300"></canvas>
                  <div class="row justify-content-between mt-2">
                    <div class="col-3">
                      <button class="mt-2 btn btn-primary" @click="showGraph">Show Graph</button>
                    </div>
                    <div v-if="showStatistics(benchmark_form.measurements)" class="card col-3 mt-2 text-center">
                      <h6 class="font-weight-bolder">Initial Benchmark</h6>
                      <h6>Mean: {{ getThicknessMeasurementsMean(benchmark_form.measurements) }}</h6>
                      <h6>Std: {{ getThicknessMeasurementsStd(benchmark_form.measurements) }}</h6>
                      <h6>Min: {{ measurementsMin(benchmark_form.measurements) }}</h6>
                      <h6>Max: {{ measurementsMax(benchmark_form.measurements) }}</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="mt-2 d-flex flex-column justify-content-center align-items-center">
              <button
                type="button"
                class="mt-4 mb-4 btn"
                :class="benchmarkFormUpdated ? 'bg-gradient-success' : 'bg-gradient-secondary'"
                @click="updateBenchmarkData"
                style="width: 60%"
              >
                {{ $t("Save") }}
              </button>
            </div>
          </div>
        </div>
        <stepper-line-visit :lineId="lineId" :visit="visit" />
      </div>
    </div>
  </div>
</template>

<script>
import setTooltip from "@/assets/js/tooltip.js";
import logo from "@/assets/img/logo-ct-white.png";
import Chart from "chart.js/auto";
import axios from "axios";
import StepperLineVisit from "./components/StepperLineVisit.vue";
import { generateX, gaussian, generateY, calculateMu, calculateSigma } from "./utils/gaussianchart.js";
// import gaussianChart from "./components/gaussianChart.vue";

import {
  getVisit,
  getThicknessMeasurementsMean,
  getThicknessMeasurementsStd,
  measurementsMin,
  measurementsMax,
  showStatistics,
  numericOnly,
} from "./utils/utils";

export default {
  name: "BenchmarkAndTarget",
  props: {
    lineId: {
      type: String,
      default: "",
    },
    visitId: {
      type: String,
      default: "",
    },
  },
  components: {
    StepperLineVisit,
    // gaussianChart,
  },
  data() {
    return {
      visit: null,
      benchmark_form: {
        minimum_target_thickness: null,
        maximum_target_thickness: null,
        reciprocator_period: null,
        guns_movement_range: null,
        line_speed: null,
        coated_height: null,
        nozzle_angle: null,
        gun_settings: null,
        measurements: Array(5).fill(null),
      },
      received_data: {
        minimum_target_thickness: null,
        maximum_target_thickness: null,
        reciprocator_period: null,
        guns_movement_range: null,
        line_speed: null,
        coated_height: null,
        nozzle_angle: null,
        gun_settings: null,
        measurements: Array(5).fill(null),
      },
      logo,
      max_imperial_decimals: 2,
      max_metric_decimals: 1,
      chart: {
        labels: [],
        datasets: [],
      },
      gaussian_min: 0,
      gaussian_max: 0,
    };
  },
  computed: {
    benchmarkFormUpdated() {
      return JSON.stringify(this.benchmark_form) !== JSON.stringify(this.received_data);
    },
    minimumTargetThickness: {
      get() {
        if (
          this.benchmark_form.minimum_target_thickness == null ||
          this.benchmark_form.minimum_target_thickness == ""
        ) {
          return this.benchmark_form.minimum_target_thickness;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (this.benchmark_form.minimum_target_thickness * this.$store.state.conversion_factors.um_to_mil).toFixed(
              this.max_imperial_decimals,
            ),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(this.benchmark_form.minimum_target_thickness.toFixed(this.max_metric_decimals));
        } else {
          return this.benchmark_form.minimum_target_thickness;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.benchmark_form.minimum_target_thickness = value;
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.benchmark_form.minimum_target_thickness = value / this.$store.state.conversion_factors.um_to_mil;
          } else if (this.$store.state.user_data.unit_system === "metric") {
            this.benchmark_form.minimum_target_thickness = value;
          }
        }
      },
    },
    maximumTargetThickness: {
      get() {
        if (
          this.benchmark_form.maximum_target_thickness == null ||
          this.benchmark_form.maximum_target_thickness == ""
        ) {
          return this.benchmark_form.maximum_target_thickness;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (this.benchmark_form.maximum_target_thickness * this.$store.state.conversion_factors.um_to_mil).toFixed(
              this.max_imperial_decimals,
            ),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(this.benchmark_form.maximum_target_thickness.toFixed(this.max_metric_decimals));
        } else {
          return this.benchmark_form.maximum_target_thickness;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.benchmark_form.maximum_target_thickness = value;
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.benchmark_form.maximum_target_thickness = value / this.$store.state.conversion_factors.um_to_mil;
          } else if (this.$store.state.user_data.unit_system === "metric") {
            this.benchmark_form.maximum_target_thickness = value;
          }
        }
      },
    },
    gunsMovementRange: {
      get() {
        if (this.benchmark_form.guns_movement_range == null || this.benchmark_form.guns_movement_range == "") {
          return this.benchmark_form.guns_movement_range;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (this.benchmark_form.guns_movement_range * this.$store.state.conversion_factors.cm_to_in).toFixed(
              this.max_imperial_decimals,
            ),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(this.benchmark_form.guns_movement_range.toFixed(this.max_metric_decimals));
        } else {
          return this.benchmark_form.guns_movement_range;
        }
      },
      set(value) {
        if (value == null || this.benchmark_form.guns_movement_range == "") {
          this.benchmark_form.guns_movement_range = value;
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.benchmark_form.guns_movement_range = value / this.$store.state.conversion_factors.cm_to_in;
          } else if (this.$store.state.user_data.unit_system === "metric") {
            this.benchmark_form.guns_movement_range = value;
          }
        }
      },
    },
    lineSpeed: {
      get() {
        if (this.benchmark_form.line_speed == null || this.benchmark_form.line_speed === "") {
          return this.benchmark_form.line_speed;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (this.benchmark_form.line_speed * this.$store.state.conversion_factors.m_to_ft).toFixed(
              this.max_imperial_decimals,
            ),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(this.benchmark_form.line_speed.toFixed(this.max_metric_decimals));
        } else {
          return parseFloat(this.benchmark_form.line_speed);
        }
      },
      set(value) {
        if (value == null || value === "") {
          this.benchmark_form.line_speed = value;
        } else {
          value = parseFloat(value);
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.benchmark_form.line_speed = value / this.$store.state.conversion_factors.m_to_ft;
          } else if (this.$store.state.user_data.unit_system === "metric") {
            this.benchmark_form.line_speed = value;
          }
        }
      },
    },
    coatedHeight: {
      get() {
        if (this.benchmark_form.coated_height == null || this.benchmark_form.coated_height == "") {
          return this.benchmark_form.coated_height;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (this.benchmark_form.coated_height * this.$store.state.conversion_factors.cm_to_in).toFixed(
              this.max_imperial_decimals,
            ),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(this.benchmark_form.coated_height.toFixed(this.max_metric_decimals));
        } else {
          return this.benchmark_form.coated_height;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.benchmark_form.coated_height = value;
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.benchmark_form.coated_height = value / this.$store.state.conversion_factors.cm_to_in;
          } else if (this.$store.state.user_data.unit_system === "metric") {
            this.benchmark_form.coated_height = value;
          }
        }
      },
    },
    progressStatus() {
      const fields = [
        this.benchmark_form.minimum_target_thickness,
        this.benchmark_form.maximum_target_thickness,
        this.benchmark_form.reciprocator_period,
        this.benchmark_form.guns_movement_range,
        this.benchmark_form.measurements,
        this.benchmark_form.line_speed,
        this.benchmark_form.coated_height,
        this.benchmark_form.nozzle_angle,
      ];
      let notEmptyMeasurements = this.benchmark_form.measurements.filter(m => m !== null && m !== "");
      if (
        fields.every(field => field !== null || field !== "") &&
        this.benchmark_form.gun_settings.every(gun =>
          Object.values(gun).every(value => value !== null || value !== ""),
        ) &&
        notEmptyMeasurements.length >= 5
      ) {
        return "Completed";
      } else if (
        fields.some(field => field !== null || field !== "") ||
        this.benchmark_form.gun_settings.some(gun =>
          Object.values(gun).some(value => value !== null || value !== ""),
        ) ||
        notEmptyMeasurements.length > 0
      ) {
        return "In Progress";
      } else {
        return "Pending";
      }
    },
  },
  mounted() {
    this.getData();
    this.$store.state.isAbsolute = true;
    setTooltip(this.$store.state.bootstrap);
  },
  beforeUnmount() {
    this.$store.state.isAbsolute = false;
  },
  methods: {
    getVisit,
    getThicknessMeasurementsMean,
    getThicknessMeasurementsStd,
    measurementsMin,
    measurementsMax,
    showStatistics,
    numericOnly,
    autoFillGunSettings(index, property_key) {
      if (index !== 0) {
        return;
      }

      const inputValue = this.benchmark_form.gun_settings[0][property_key];
      const emptyInputValue = inputValue === "" || inputValue === null;

      const isColumnEmpty = this.benchmark_form.gun_settings
        .slice(1)
        .every(gun => gun[property_key] === "" || gun[property_key] === null);

      if (!emptyInputValue && isColumnEmpty) {
        this.benchmark_form.gun_settings.forEach(gun => {
          gun[property_key] = inputValue;
        });
        return;
      }

      if (emptyInputValue && !isColumnEmpty) {
        const allValuesAreTheSame = this.benchmark_form.gun_settings
          .slice(1)
          .every(gun => gun[property_key] === this.benchmark_form.gun_settings[1][property_key]);
        if (allValuesAreTheSame) {
          this.benchmark_form.gun_settings.forEach(gun => {
            gun[property_key] = "";
          });
        }
      }
    },
    checkLastRow(index) {
      let notEmptyMeasurements = this.benchmark_form.measurements.filter(m => m !== null && m !== "");
      if (
        index === this.benchmark_form.measurements.length - 1 &&
        this.benchmark_form.measurements[index] !== "" &&
        notEmptyMeasurements.length === this.benchmark_form.measurements.length
      ) {
        this.benchmark_form.measurements.push("");
      }
    },
    async getData() {
      await this.getLineData();
      await this.getVisit(this.visitId);
      await this.getBenchmarkData();
    },
    async getLineData() {
      let line_response = await axios.get("/api/v1/fp/line/" + this.lineId);

      line_response.data.total_pistols = JSON.parse(line_response.data.total_pistols);
      line_response.data.pistol_to_pistol_distance = JSON.parse(line_response.data.pistol_to_pistol_distance);
      line_response.data.pistol_columns_distances = JSON.parse(line_response.data.pistol_columns_distances);
      line_response.data.pistol_columns_vertical_offsets = JSON.parse(
        line_response.data.pistol_columns_vertical_offsets,
      );

      line_response.data.pistols_max_movement_range = line_response.data.pistols_max_movement_range * 100;

      this.line = line_response.data;
    },
    async getBenchmarkData() {
      try {
        let response = await axios.get(`/api/v1/onsite/benchmarkstage/` + this.visitId);
        if (response.data !== null) {
          this.received_data.minimum_target_thickness = response.data.minimum_target_thickness;
          this.received_data.maximum_target_thickness = response.data.maximum_target_thickness;
          this.received_data.reciprocator_period = response.data.reciprocator_period;
          this.received_data.guns_movement_range = response.data.guns_movement_range;
          this.received_data.line_speed = response.data.line_speed;
          this.received_data.coated_height = response.data.coated_height;
          this.received_data.nozzle_angle = response.data.nozzle_angle;
          this.received_data.measurements = JSON.parse(response.data.measurements);
          if (this.received_data.measurements === null || this.received_data.measurements === "") {
            this.received_data.measurements = Array(5).fill(null);
          } else if (this.received_data.measurements.length < 5) {
            this.received_data.measurements = this.received_data.measurements.concat(
              Array(5 - this.received_data.measurements.length).fill(null),
            );
          }
          if (response.data.gun_settings !== null) {
            this.received_data.gun_settings = response.data.gun_settings;
          } else {
            this.received_data.gun_settings = Array.from({ length: this.line.total_pistols }, () => ({
              voltage: "",
              amps: "",
              air: "",
              distance: "",
              powder: "",
            }));
          }
        }
        this.benchmark_form = JSON.parse(JSON.stringify(this.received_data));
        if (this.received_data.measurements !== null) {
          this.showGraph();
        }
      } catch (error) {
        console.error(error);
        setTimeout(
          function () {
            this.getBenchmarkData();
          }.bind(this),
          5000,
        );
      }
      this.$store.state.isLoaded = false;
    },
    async updateBenchmarkData() {
      let nonEmptyMeasurements = this.benchmark_form.measurements.filter(m => m !== null && m !== "");
      await axios
        .put(`/api/v1/onsite/benchmarkstage/` + this.visitId + "/", {
          visit: this.visitId,
          minimum_target_thickness: this.benchmark_form.minimum_target_thickness,
          maximum_target_thickness: this.benchmark_form.maximum_target_thickness,
          reciprocator_period: this.benchmark_form.reciprocator_period,
          guns_movement_range: this.benchmark_form.guns_movement_range,
          line_speed: this.benchmark_form.line_speed,
          coated_height: this.benchmark_form.coated_height,
          nozzle_angle: this.benchmark_form.nozzle_angle ? this.benchmark_form.nozzle_angle : 0,
          gun_settings: this.benchmark_form.gun_settings,
          measurements: JSON.stringify(nonEmptyMeasurements),
          progress_status: this.progressStatus,
        })
        .then(() => {
          this.getVisit(this.visitId);

          this.$swal({
            title: this.$t("Changes saved"),
            text: this.$t("Benchmark & Target") + " " + this.$t("was saved successfully"),
            icon: "success",
            confirmButtonText: "OK",
          }).then(this.getBenchmarkData());
        })
        .catch(error => console.error(error));
    },
    generateX,
    gaussian,
    generateY,
    calculateMu,
    calculateSigma,
    thicknessMeasurementsUpdateChartData() {
      const mu = this.calculateMu(this.benchmark_form.measurements);
      const sigma = this.calculateSigma(this.benchmark_form.measurements, mu);
      this.chart.labels = this.generateX(mu, sigma);

      this.chart.datasets[0] = {
        data: this.generateY(sigma, mu),
      };

      if (this.maximumTargetThickness !== null && this.minimumTargetThickness !== null) {
        const arr = this.chart.labels.map(num => parseFloat(num));

        let index = arr.findIndex(num => num > this.minimumTargetThickness);
        let index_max = arr.findIndex(num => num > this.maximumTargetThickness);

        this.gaussian_min = index > 0 ? index - 1 : index;
        this.gaussian_max = index_max > 0 ? index_max - 1 : arr.length - 1;
      }
    },
    createDatasetConfig(data, pointBackgroundColor, borderColor, gradientStroke) {
      return {
        tension: 0.4,
        borderWidth: 3,
        pointRadius: 0,
        pointBackgroundColor: pointBackgroundColor,
        borderColor: borderColor,
        backgroundColor: gradientStroke,
        data: data,
        maxBarThickness: 6,
      };
    },
    createChartConfig(labels, datasets) {
      return {
        type: "line",
        data: {
          labels: labels,
          datasets: datasets,
        },
        options: {
          responsive: true,
          maintainAspectRatio: true,

          legend: {
            display: false,
          },

          interaction: {
            intersect: false,
            mode: "index",
          },
          scales: {
            y: {
              grid: {
                drawBorder: false,
                display: true,
                drawOnChartArea: true,
                drawTicks: false,
                borderDash: [5, 5],
              },
              ticks: {
                display: true,
                padding: 10,
                color: "#9ca2b7",
              },
              title: {
                display: true,
                text: "Distribution",
              },
            },
            x: {
              grid: {
                drawBorder: false,
                display: true,
                drawOnChartArea: true,
                drawTicks: true,
                borderDash: [5, 5],
              },
              position: "bottom",
              title: {
                display: true,
                text: "Thickness",
              },
              ticks: {
                display: true,
                color: "#9ca2b7",
                padding: 10,
              },
            },
          },
        },
      };
    },
    createChartConfigWithAnnotation(labels, datasets, yTitle, xTitle) {
      return {
        type: "line",
        pointRadius: 0,
        tension: 0.4,
        borderWidth: 3,
        maxBarThickness: 6,
        data: {
          labels: labels,
          datasets: datasets,
        },
        options: {
          responsive: true,
          maintainAspectRatio: true,

          plugins: {
            legend: {
              display: false,
            },
            annotation: {
              annotations: {
                minThicknessLine: {
                  type: "line",
                  xMin: this.gaussian_min,
                  xMax: this.gaussian_min,
                  borderColor: "red",
                  borderWidth: 2,
                  borderDash: [6, 6],
                  label: {
                    content: "Min",
                    enabled: true,
                    position: "end",
                  },
                },
                maxThicknessLine: {
                  type: "line",
                  xMin: this.gaussian_max,
                  xMax: this.gaussian_max,
                  borderColor: "red",
                  borderWidth: 2,
                  borderDash: [6, 6],
                  label: {
                    content: "Max",
                    enabled: true,
                    position: "end",
                  },
                },
              },
            },
          },
          interaction: {
            intersect: false,
            mode: "index",
          },
          scales: {
            y: {
              grid: {
                drawBorder: false,
                display: true,
                drawOnChartArea: true,
                drawTicks: false,
                borderDash: [5, 5],
              },
              title: {
                display: true,
                text: yTitle,
              },
              ticks: {
                display: true,
                padding: 10,
                color: "#9ca2b7",
              },
            },
            x: {
              grid: {
                drawBorder: false,
                display: true,
                drawOnChartArea: true,
                drawTicks: true,
                borderDash: [5, 5],
              },
              title: {
                display: true,
                text: xTitle,
              },
              ticks: {
                display: true,
                color: "#9ca2b7",
                padding: 10,
              },
            },
          },
        },
      };
    },
    drawChart(chart, id) {
      var canvas = document.getElementById(id).getContext("2d");

      var gradientStroke2 = canvas.createLinearGradient(0, 230, 0, 50);

      gradientStroke2.addColorStop(1, "rgba(20,23,39,0.2)");
      gradientStroke2.addColorStop(0.2, "rgba(72,72,176,0.0)");
      gradientStroke2.addColorStop(0, "rgba(20,23,39,0)");

      let chartStatus = Chart.getChart(id);
      if (chartStatus != undefined) {
        chartStatus.destroy();
      }

      const colors = ["#cb0c9f", "#3A416F", "#17c1e8", "#f5365c", "#fb6340", "#2dce89", "#11cdef", "#5e72e4"];

      const chartDatasets = chart.datasets.map((dataset, index) => {
        return this.createDatasetConfig(
          // dataset.label,
          dataset.data,
          colors[index % colors.length],
          colors[index % colors.length],
          gradientStroke2,
        );
      });

      if (this.maximumTargetThickness !== null && this.minimumTargetThickness !== null) {
        new Chart(
          canvas,
          this.createChartConfigWithAnnotation(chart.labels, chartDatasets, "Distribution", "Thickness"),
        );
      } else {
        new Chart(canvas, this.createChartConfig(chart.labels, chartDatasets));
      }
    },

    showGraph() {
      this.thicknessMeasurementsUpdateChartData();

      this.drawChart(this.chart, "measurement-chart");
    },
  },
};
</script>
<style scoped>
.table input {
  width: 60px;
  text-align: center;
}

.table-sm th,
.table-sm td {
  padding: 0.3rem;
}

.container-fluid {
  padding-top: 20px;
}

.chart-canvas {
  max-height: 400px;
}
</style>

<i18n>
  {
    "EN": {
      "Benchmark & Target": "Benchmark & Target",
      "Save": "Save",
      "Changes saved": "Changes saved",
      "was saved successfully": "was saved successfully",
    },
    "DE": {
      "Benchmark & Target": "Benchmark & Ziel",
      "Save": "Speichern",
      "Changes saved": "Änderungen gespeichert",
      "was saved successfully": "wurde erfolgreich gespeichert",
    },
    "ES": {
      "Benchmark & Target": "Punto de referencia y objetivo",
      "Save": "Guardar",
      "Changes saved": "Cambios guardados",
      "was saved successfully": "se guardó correctamente",
    },
    "FR": {
      "Benchmark & Target": "Référence et objectif",
      "Save": "Sauvegarder",
      "Changes saved": "Modifications enregistrées",
      "was saved successfully": "a été enregistré avec succès",
    },
    "IT": {
      "Benchmark & Target": "Benchmark e obiettivo",
      "Save": "Salva",
      "Changes saved": "Modifiche salvate",
      "was saved successfully": "è stato salvato con successo",
    },
    "PL": {
      "Benchmark & Target": "Punkt odniesienia i cel",
      "Save": "Zapisz",
      "Changes saved": "Zmiany zapisane",
      "was saved successfully": "został zapisany pomyślnie",
    },
    "TR": {
      "Benchmark & Target": "Referans ve Hedef",
      "Save": "Kaydet",
      "Changes saved": "Değişiklikler kaydedildi",
      "was saved successfully": "başarıyla kaydedildi",
    },
    "ZH": {
      "Benchmark & Target": "基准和目标",
      "Save": "保存",
      "Changes saved": "更改已保存",
      "was saved successfully": "已成功保存",
    }
  }
</i18n>
