export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "EN": {
        "Powders Library": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Powders Library"])},
        "No Powders created.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Powders created."])},
        "Powders List": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Powders List"])},
        "New Powder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Powder"])}
      },
      "DE": {
        "Powders List": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Powders List"])},
        "No Powders created.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Powders created."])},
        "Powders Library": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pulverbibliothek"])},
        "New Powder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neues Pulver"])}
      },
      "ES": {
        "Powders List": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Powders List"])},
        "No Powders created.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Powders created."])},
        "Powders Library": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lista de Pinturas en Polvo"])},
        "New Powder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nueva Pintura en Polvo"])}
      },
      "FR": {
        "Powders List": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Powders List"])},
        "No Powders created.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Powders created."])},
        "Powders Library": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bibliothèque de Poudres"])},
        "New Powder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouvelle Poudre"])}
      },
      "IT": {
        "Powders List": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Powders List"])},
        "No Powders created.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Powders created."])},
        "Powders Library": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Libreria di Polveri"])},
        "New Powder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nuova Polvere"])}
      },
      "PL": {
        "Powders List": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Powders List"])},
        "No Powders created.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Powders created."])},
        "Powders Library": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Biblioteka Proszków"])},
        "New Powder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nowy Proszek"])}
      },
      "TR": {
        "Powders List": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Powders List"])},
        "No Powders created.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Powders created."])},
        "Powders Library": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tozlar Kütüphanesi"])},
        "New Powder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yeni Toz"])}
      },
      "ZH": {
        "Powders List": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Powders List"])},
        "No Powders created.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Powders created."])},
        "Powders Library": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["粉末库"])},
        "New Powder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新粉末"])}
      }
    }
  })
}
