import axios from "axios";

export async function getVisit(visitId) {
  try {
    let response = await axios.get("/api/v1/onsite/visit/" + visitId);
    this.visit = JSON.parse(JSON.stringify(response.data));
  } catch (error) {
    console.error(error);
  }
};

export function getThicknessMeasurementsMean(thickness_measurements) {
  if (thickness_measurements === null || thickness_measurements === "" || thickness_measurements.length === 0) {
    return 0;
  }

  let filtered_thickness_measurements = thickness_measurements.filter(m => m !== null && m !== "");

  if (filtered_thickness_measurements.length === 0) {
    return 0;
  }

  let mean = parseFloat(
    (filtered_thickness_measurements.reduce((a, b) => a + b, 0) / filtered_thickness_measurements.length).toFixed(1),
  );
  return mean;
}

export function getThicknessMeasurementsStd(thickness_measurements) {
  if (thickness_measurements === null || thickness_measurements === "" || thickness_measurements.length === 0) {
    return 0;
  }
  const mean = getThicknessMeasurementsMean(thickness_measurements);

  let filtered_thickness_measurements = thickness_measurements.filter(m => m !== null && m !== "");

  return parseFloat(
    Math.sqrt(
      filtered_thickness_measurements.reduce((sq, n) => sq + Math.pow(n - mean, 2), 0) / (filtered_thickness_measurements.length - 1),
    ).toFixed(1),
  );
}

export function measurementsMin(thickness_measurements) {
  if (thickness_measurements === null || thickness_measurements === "" || thickness_measurements.length === 0) {
    return 0;
  }
  return parseFloat(
    Math.min(
      ...thickness_measurements.filter(m => m !== null && m !== "")
    ).toFixed(1)
  );
}

export function measurementsMax(thickness_measurements) {
  if (thickness_measurements === null || thickness_measurements === "" || thickness_measurements.length === 0) {
    return 0;
  }
  return parseFloat(
    Math.max(
      ...thickness_measurements.filter(m => m !== null && m !== "")
    ).toFixed(1)
  );
}

export function showStatistics(measurements) {
  return (
    getThicknessMeasurementsMean(measurements) > 0
    && getThicknessMeasurementsStd(measurements) > 0
  );
};


export function numericOnly(event) {
  let key = event.key;
  if (
    [
      "Delete",
      "Backspace",
      "Tab",
      "Enter",
      "ArrowUp",
      "ArrowRight",
      "ArrowDown",
      "ArrowLeft",
      ".",
      "Shift",
      "Control",
      ",",
      "-",
    ].includes(key) ||
    ((event.ctrlKey || event.metaKey) && ["a", "c", "v", "x", "z", "y"].includes(key.toLowerCase()))
  ) {
    return;
  }
  if (!/\d/.test(key)) {
    event.preventDefault();
  }
};