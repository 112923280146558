<template>
  <div class="py-2 container-fluid">
    <div class="mt-4 row">
      <div class="col-12"></div>
    </div>
    <div
      class="mt-4 page-header min-height-300 border-radius-xl"
      :style="{
        backgroundImage: 'url(' + require('@/assets/img/curved-images/curved14.jpg') + ')',
        backgroundPositionY: '50%',
      }"
    >
      <span class="mask bg-gradient-success opacity-6"></span>
    </div>
    <div class="mx-4 overflow-hidden card card-body blur shadow-blur mt-n6">
      <div class="row gx-4">
        <div class="col-auto my-auto">
          <h5 class="h-100 mb-1">Navigator</h5>
          <p class="mb-2 text-sm font-weight-bold">Your expert in coating</p>
        </div>
        <div class="mx-auto mt-3 col-lg-7 col-md-7 my-sm-auto ms-sm-auto me-sm-0">
          <div class="nav-wrapper position-relative end-0">
            <ul
              id="main-flightpath-navbar"
              class="p-1 bg-transparent nav nav-pills nav-fill flex-row"
              role="tablist"
              style="flex-wrap: nowrap; flex-direction: row !important"
            ></ul>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="$store.state.navigatorchat_license"
      class="py-1 container-fluid"
    >
      <div class="row mt-4">
        <div class="col-12">
          <div class="card">
            <div class="card-body">
              <div class="container_chat">
                <div
                  class="px-4 flex flex-col"
                  style="background: white"
                >
                  <div class="sticky top-0 w-full">
                    <div
                      class="flex justify-between py-1 mb-4 z-10"
                      style="border-bottom: 1px solid #f4f4f5; background: white"
                    >
                      <div class="flex items-center">
                        <img
                          alt="profile picture"
                          :src="s3_storage_base_URL + 'flightpath-platform/public-assets/static/coating_minilogo.png'"
                          width="35"
                          height="35"
                          decoding="async"
                          data-nimg="1"
                          class="rounded-full m-1 mr-2"
                          style="color: transparent"
                        />
                        <h1 class="text-lg font-bold text-zinc-700">coatingAI Navigator</h1>
                      </div>

                      <div class="flex items-end">
                        <button
                          type="submit"
                          class="flex-none p-2"
                          aria-hidden="true"
                          @click="clearChat"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke-width="1.5"
                            stroke="currentColor"
                            aria-hidden="true"
                            style="width: 20px; height: 20px"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99"
                            ></path>
                          </svg>
                        </button>
                      </div>
                    </div>
                  </div>
                  <div id="messages_container">
                    <div
                      v-for="message in messages"
                      :key="message.id"
                    >
                      <div :class="`${message.sender == 'user' ? 'float-right' : 'float-left'} clear-both mr-8`">
                        <div
                          class="float-right mb-3 overflow-auto rounded-lg py-3 px-4"
                          :style="
                            message.sender == 'user'
                              ? 'background-color: rgb(59, 129, 246); color: white;'
                              : 'background-color:#f4f4f5;color:black'
                          "
                        >
                          <div class="flex space-x-3">
                            <div class="flex-1 gap-4">
                              <div class="prose text-inherit text-left dark:prose-invert">
                                <p
                                  v-for="line in message.text.split('\n')"
                                  :key="line"
                                >
                                  {{ line }}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="sticky bottom-0 bg-inherit">
                    <form>
                      <div class="py-2"></div>
                      <div
                        class="flex p-1 rounded"
                        style="background: white; border: 1px solid #e4e4e7"
                      >
                        <div class="flex items-center w-full">
                          <textarea
                            id="input_message"
                            v-model="message_input"
                            aria-label="chat input"
                            required=""
                            maxlength="750"
                            rows="1"
                            class="m-0 w-full min-h-[1.5rem] max-h-36 pr-7 resize-none border-0 bg-inherit flex-1 appearance-none rounded-md focus:ring-0 focus-visible:ring-0 focus:outline-none"
                            @keyup.enter.exact="sendMessageViaEnter"
                            @input="TextInputAutoResize"
                          >
                          </textarea>
                        </div>
                        <button
                          type="button"
                          @click="sendMessage"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            fill="currentColor"
                            aria-hidden="true"
                            style="height: 20px; width: 20px; vertical-align: middle"
                          >
                            <path
                              d="M3.478 2.405a.75.75 0 00-.926.94l2.432 7.905H13.5a.75.75 0 010 1.5H4.984l-2.432 7.905a.75.75 0 00.926.94 60.519 60.519 0 0018.445-8.986.75.75 0 000-1.218A60.517 60.517 0 003.478 2.405z"
                            ></path>
                          </svg>
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style
  lang="scss"
  scoped
>
.container_chat {
  @import "~@/assets/css/coating_expert_chat.scss";
}
</style>
<script>
import setTooltip from "@/assets/js/tooltip.js";

export default {
  name: "Navigator",
  components: {},
  data() {
    return {
      showMenu: false,
      last_message: "",
      last_answer: "",
      message_input: "",
      messages: [],
      s3_storage_base_URL: "https://d21tewxstkbxbj.cloudfront.net/",
    };
  },
  computed: {},
  mounted() {
    this.$store.state.isAbsolute = true;
    setTooltip(this.$store.state.bootstrap);
    this.addMessageToChat("Hello! What can I help you with?", "assistant");
  },
  beforeUnmount() {
    this.$store.state.isAbsolute = false;
  },
  updated() {},
  methods: {
    addMessageToChat(text, sender) {
      let new_message = {
        id: this.messages.length + 1,
        text: text,
        sender: sender,
      };
      this.messages.push(new_message);
    },
    clearChat() {
      this.messages = [];
      this.addMessageToChat("Hello! What can I help you with?", "assistant");
    },
    sendMessageViaEnter() {
      this.message_input = this.message_input.slice(0, -1);
      this.sendMessage();
    },
    TextInputAutoResize() {
      const textarea = document.getElementById("input_message");
      textarea.style.height = "auto";
      textarea.style.height = textarea.scrollHeight + "px";
    },
    TextInputToDefaultSize() {
      const textarea = document.getElementById("input_message");
      textarea.style.height = "1.62rem";
      this.autoScrollToBottom();
    },
    autoScrollToBottom() {
      requestAnimationFrame(() => {
        window.scrollTo(0, document.body.scrollHeight);
      });
    },
    async sendMessage() {
      let message_input_copy = JSON.parse(JSON.stringify(this.message_input));
      const message_input_removed_enters = message_input_copy.replace(/\n/g, "");

      if (
        message_input_removed_enters == "" ||
        message_input_removed_enters == null ||
        message_input_removed_enters == " "
      ) {
        this.message_input = "";
        this.TextInputToDefaultSize();
        return;
      }

      if (message_input_removed_enters.length < 20) {
        this.addMessageToChat(this.message_input, "user");
        this.addMessageToChat("Messages must be at least 20 characters long.", "assistant");
        this.message_input = "";
        this.TextInputToDefaultSize();
        return;
      }
      this.addMessageToChat(this.message_input, "user");
      let message_content = JSON.parse(JSON.stringify(this.message_input));
      this.message_input = "";
      this.TextInputToDefaultSize();

      try {
        this.TextInputToDefaultSize();

        const response = await fetch("https://www.chatbase.co/api/v1/chat", {
          method: "POST",
          headers: {
            Authorization: "Bearer ceb0300f-f60c-4ccb-ae43-c4c7c09b0f01",
          },
          body: JSON.stringify({
            messages: [{ content: message_content, role: "user" }],
            chatId: "XO7NWUuAc6w2gopeJl68a",
            stream: true,
            temperature: 0,
          }),
        });

        const data = response.body;
        const reader = data.getReader();
        const decoder = new TextDecoder();
        let done = false;
        let first_iter = true;

        while (!done) {
          const { value, done: doneReading } = await reader.read();
          done = doneReading;
          const text_chunk = decoder.decode(value);

          if (first_iter) {
            this.addMessageToChat(text_chunk, "assistant");
            first_iter = false;
          } else {
            this.autoScrollToBottom();
            let new_text = this.messages.at(-1).text.concat(text_chunk);
            this.messages.at(-1).text = new_text;
          }
        }
      } catch (error) {
        console.error("Error:", error.message);
      }
    },
  },
};
</script>
