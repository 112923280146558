export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "EN": {
        "": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
        "Overcoated powder:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Overcoated powder:"])},
        "Oversprayed powder:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oversprayed powder:"])},
        "The amount of extra powder applied because of an uneven powder distribution on the substrate.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The amount of extra powder applied because of an uneven powder distribution on the substrate."])},
        "The powder that does not stick to the part and is then not reclaimed by the recycling system.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The powder that does not stick to the part and is then not reclaimed by the recycling system."])},
        "2nd column height offset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2nd column height offset"])},
        "3rd column height offset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3rd column height offset"])},
        "4th column height offset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["4th column height offset"])},
        "5th column height offset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["5th column height offset"])},
        "gun": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["gun"])},
        "Gun to Gun distance must be smaller than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gun to Gun distance must be smaller than"])},
        "Gun to Gun distance must be greater than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gun to Gun distance must be greater than"])},
        "Gun to gun distance cannot be 0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gun to gun distance cannot be 0"])},
        "degrees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["degrees"])},
        "Gun column height offsets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gun column height offsets"])},
        "Nozzle rotation angle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nozzle rotation angle"])},
        "The covered height, for the number of guns available, must be smaller than ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The covered height, for the number of guns available, must be smaller than "])},
        "Guns movement range cannot be greater than the maximum possible mechanical range of the line:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guns movement range cannot be greater than the maximum possible mechanical range of the line:"])},
        "Loading optimization type...": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loading optimization type..."])},
        "Clear & start new optimization for line": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clear & start new optimization for line"])},
        "Please fill the following fields: ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please fill the following fields: "])},
        "Line speed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Line speed"])},
        "Coated height": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coated height"])},
        "Powder model": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Powder model"])},
        "Specific Powder Optimization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Specific Powder Optimization"])},
        "Input value out of range": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Input value out of range"])},
        "was saved successfully.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["was saved successfully."])},
        "Missing Required Fields": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Missing Required Fields"])},
        "Guns movement range cannot be 0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guns movement range cannot be 0"])},
        "Line speed cannot be 0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Line speed cannot be 0"])},
        "Line speed must be greater than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Line speed must be greater than"])},
        "Line speed must be less than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Line speed must be less than"])},
        "Covered height must be greater than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Covered height must be greater than"])},
        "Guns movement speed cannot be slower than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guns movement speed cannot be slower than"])},
        "Use a faster guns velocity or a different movement range & period time combination.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Use a faster guns velocity or a different movement range & period time combination."])},
        "Guns movement speed cannot be faster than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guns movement speed cannot be faster than"])},
        "Use a slower guns velocity or a different movement range & period time combination.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Use a slower guns velocity or a different movement range & period time combination."])},
        "Guns movement period cannot be longer than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guns movement period cannot be longer than"])},
        "Use a shorter guns period or a different movement range & period time combination.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Use a shorter guns period or a different movement range & period time combination."])},
        "Guns movement period must be greater than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guns movement period must be greater than"])},
        "No fields were changed from the previous optimization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No fields were changed from the previous optimization"])},
        "Do you want to recompute it anyway?": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you want to recompute it anyway?"])},
        "Cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
        "Yes, recompute it!": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes, recompute it!"])},
        "You need to define the Current Settings to generate a report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You need to define the Current Settings to generate a report"])},
        "OK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OK"])},
        "Total guns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total guns"])},
        "Total guns per column": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total guns per column"])},
        "Gun movement range": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gun movement range"])},
        "Gun Movement Speed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gun Movement Speed"])},
        "Gun Movement Period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gun Movement Period"])},
        "Save Optimization to Library": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save Optimization to Library"])},
        "Optimization Saved to Library": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optimization Saved to Library"])},
        "optional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["optional"])},
        "Static Optimization Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Static Optimization Name"])},
        "New Optimization for line": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Optimization for line"])},
        "Status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
        "Optimum found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optimum found"])},
        "Ready to find optimum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ready to find optimum"])},
        "Static Optimization details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Static Optimization details"])},
        "Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
        "Line": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Line"])},
        "Optimization type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optimization type"])},
        "Select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select"])},
        "Powder Models": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Powder Models"])},
        "Calibrated Powder Optimization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calibrated Powder Optimization"])},
        "Pre-Calibrated Powder Optimization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pre-Calibrated Powder Optimization"])},
        "Generalistic Optimization for any powder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generalistic Optimization for any powder"])},
        "Select Powder Model": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Powder Model"])},
        "No Powder Models Created. To create one go to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Powder Models Created. To create one go to"])},
        "Optimization parameters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optimization parameters"])},
        "Line Speed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Line Speed"])},
        "Distance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distance"])},
        "Covered height": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Covered height"])},
        "Simulation width": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Simulation width"])},
        "Continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continue"])},
        "Compare with Current Settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compare with Current Settings"])},
        "Number of active guns 1st column": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of active guns 1st column"])},
        "Please select one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select one"])},
        "Number of active guns 2nd column": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of active guns 2nd column"])},
        "Number of active guns 3rd column": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of active guns 3rd column"])},
        "Number of active guns 4th column": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of active guns 4th column"])},
        "Number of active guns 5th column": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of active guns 5th column"])},
        "Guns movement range": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guns movement range"])},
        "Guns movement period time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guns movement period time"])},
        "Guns movement speed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guns movement speed"])},
        "Compute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compute"])},
        "Optimal machine settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optimal machine settings"])},
        "Simulation results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Simulation results"])},
        "Current Settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current Settings"])},
        "Overspray": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Overspray"])},
        "Optimal Settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optimal Settings"])},
        "Uniformity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uniformity"])},
        "Median uniformity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Median uniformity"])},
        "Overspray": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Overspray"])},
        "Visualize graphs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visualize graphs"])},
        "Calculation running": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calculation running"])},
        "Uniformity comparison": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uniformity comparison"])},
        "Applied spray": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applied spray"])},
        "Movement chart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Movement chart"])},
        "Applied spray animation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applied spray animation"])},
        "Current Settings Coated Area": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current Settings Coated Area"])},
        "Optimal Settings Coated Area": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optimal Settings Coated Area"])},
        "Current Settings Guns Movement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current Settings Guns Movement"])},
        "Optimal Settings Guns Movement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optimal Settings Guns Movement"])},
        "Current Settings Coated Area": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current Settings Coated Area"])},
        "Optimal Settings Coated Area": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optimal Settings Coated Area"])},
        "Comments section for the generated report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comments section for the generated report"])},
        "Hide Optimal Settings in the report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hide Optimal Settings in the report"])},
        "Save Edited Comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save Edited Comments"])},
        "Generate Report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generate Report"])},
        "Generating optimization report...": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generating optimization report..."])},
        "To use a different optimization type create a new Optimization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To use a different optimization type create a new Optimization"])},
        "If you want to use a different set of Powder Models, create a new Optimization.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you want to use a different set of Powder Models, create a new Optimization."])},
        "Select Specific Powder optimization to use one or more powder models to compute the optimal settings. Select the Generalistic Optimization option to get a powder agnostic optimization solution.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Specific Powder optimization to use one or more powder models to compute the optimal settings. Select the Generalistic Optimization option to get a powder agnostic optimization solution."])},
        "Select the Powder Models to be used during the computation of optimal settings.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select the Powder Models to be used during the computation of optimal settings."])},
        "Line velocity value in [meters/minute] units.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Line velocity value in [meters/minute] units."])},
        "Distance from the gun nozzle to the substrate.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distance from the gun nozzle to the substrate."])},
        "Vertical height for which the uniformity will be optimized.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vertical height for which the uniformity will be optimized."])},
        "Only relevant for visualization purposes, determines the horizontal width, of the simulation image shown on the results section.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Only relevant for visualization purposes, determines the horizontal width, of the simulation image shown on the results section."])},
        "Fill this section with your Guns Settings to visualize how they compare with the optimal settings.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fill this section with your Guns Settings to visualize how they compare with the optimal settings."])},
        "Clear current settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clear current settings"])},
        "Number of active guns on the first column of guns. The minimum is 0 and the maximum is": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of active guns on the first column of guns. The minimum is 0 and the maximum is"])},
        "Number of active guns on the second column of guns. The minimum is 0 and the maximum is": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of active guns on the second column of guns. The minimum is 0 and the maximum is"])},
        "Number of active guns on the third column of guns. The minimum is 0 and the maximum is": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of active guns on the third column of guns. The minimum is 0 and the maximum is"])},
        "Number of active guns on the fourth column of guns. The minimum is 0 and the maximum is": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of active guns on the fourth column of guns. The minimum is 0 and the maximum is"])},
        "Number of active guns on the fifth column of guns. The minimum is 0 and the maximum is": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of active guns on the fifth column of guns. The minimum is 0 and the maximum is"])},
        "Number of active guns on one side of the booth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of active guns on one side of the booth"])},
        "Activate Toggle to visualize simulation images": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activate Toggle to visualize simulation images"])},
        "Gun to gun distance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gun to gun distance"])},
        "Current Settings Nozzle Angle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current Settings Nozzle Angle"])},
        "Optimal Settings Nozzle Angle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optimal Settings Nozzle Angle"])},
        "guns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["guns"])},
        "Powder Usage Pie Chart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Powder Usage Pie Chart"])},
        "Optimal Settings Pie Chart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optimal Settings Pie Chart"])},
        "Current Settings Pie Chart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current Settings Pie Chart"])},
        "Powder Waste": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Powder Waste"])}
      },
      "DE": {
        "": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
        "Overcoated powder:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Overcoated powder:"])},
        "Oversprayed powder:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oversprayed powder:"])},
        "The amount of extra powder applied because of an uneven powder distribution on the substrate.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Menge an zusätzlichem Pulver, die aufgrund einer ungleichmäßigen Verteilung des Pulvers auf dem Substrat aufgetragen wird."])},
        "The powder that does not stick to the part and is then not reclaimed by the recycling system.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Pulver, das nicht auf dem Teil haften bleibt und vom Recycling-System nicht zurückgewonnen wird."])},
        "2nd column height offset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Höhenversatz der 2. Spalte"])},
        "3rd column height offset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Höhenversatz der 3. Spalte"])},
        "4th column height offset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Höhenversatz der 4. Spalte"])},
        "5th column height offset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Höhenversatz der 5. Spalte"])},
        "gun": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pistole"])},
        "Gun to Gun distance must be smaller than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Abstand von Pistole zu Pistole muss kleiner sein als"])},
        "Gun to Gun distance must be greater than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Abstand von Pistole zu Pistole muss größer sein als"])},
        "Gun to gun distance cannot be 0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Abstand von Pistole zu Pistole kann nicht 0 sein"])},
        "degrees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grad"])},
        "Gun column height offsets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Höhenversatz der Pistolensäule"])},
        "Nozzle rotation angle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Drehwinkel der Düse"])},
        "The covered height, for the number of guns available, must be smaller than ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die bedeckte Höhe muss bei der Anzahl der verfügbaren Pistolen kleiner sein als"])},
        "Guns movement range cannot be greater than the maximum possible mechanical range of the line:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Bewegungsbereich der Pistolen kann nicht größer sein als die maximal mögliche mechanische Reichweite der Linie:"])},
        "Loading optimization type...": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optimierungstyp laden..."])},
        "Clear & start new optimization for line": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Löschen und neue Optimierung für Linie starten"])},
        "Please fill the following fields: ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte füllen Sie die folgenden Felder aus: "])},
        "Line speed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kettengeschwindigkeit"])},
        "Coated height": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beschichtete Höhe"])},
        "Powder model": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puder-Modell"])},
        "Specific Powder Optimization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spezifische Puder-Optimierung"])},
        "Input value out of range": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eingabewert außerhalb des Bereichs"])},
        "was saved successfully.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["wurde erfolgreich gespeichert."])},
        "Missing Required Fields": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fehlende Pflichtfelder"])},
        "Guns movement range cannot be 0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bewegungsbereich der Pistolen kann nicht 0 sein"])},
        "Line speed cannot be 0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kettengeschwindigkeit kann nicht 0 sein"])},
        "Line speed must be greater than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Kettengeschwindigkeit muss größer sein als"])},
        "Line speed must be less than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kettengeschwindigkeit muss kleiner sein als"])},
        "Covered height must be greater than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beschichtete Höhe muss größer sein als"])},
        "Guns movement speed cannot be slower than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Bewegungsgeschwindigkeit der Pistole kann nicht langsamer sein als"])},
        "Use a faster guns velocity or a different movement range & period time combination.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verwenden Sie eine schnellere Pistolengeschwindigkeit oder eine andere Kombination aus Bewegungsbereich und Zeitspanne."])},
        "Guns movement speed cannot be faster than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Bewegungsgeschwindigkeit der Pistole kann nicht schneller sein als"])},
        "Use a slower guns velocity or a different movement range & period time combination.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verwenden Sie eine langsamere Pistolengeschwindigkeit oder eine andere Kombination aus Bewegungsbereich und Zeitspanne."])},
        "Guns movement period cannot be longer than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Bewegungszeit der Pistole kann nicht länger sein als"])},
        "Use a shorter guns period or a different movement range & period time combination.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verwenden Sie eine kürzere Pistolenzeit oder eine andere Kombination aus Bewegungsbereich und Zeitspanne."])},
        "Guns movement period must be greater than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Bewegungszeit der Pistole muss größer sein als"])},
        "No fields were changed from the previous optimization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bei der vorherigen Optimierung wurden keine Felder geändert."])},
        "Do you want to recompute it anyway?": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Möchten Sie trotzdem eine Neuberechnung durchführen?"])},
        "Cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abbrechen"])},
        "Yes, recompute it!": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ja, berechnen Sie sie neu!"])},
        "You need to define the Current Settings to generate a report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie müssen die aktuellen Einstellungen definieren, um einen Bericht zu erstellen"])},
        "OK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OK"])},
        "Total guns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pistolen insgesamt"])},
        "Total guns per column": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gesamtpistolen pro Spalte"])},
        "Gun movement range": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hubhöhe"])},
        "Gun Movement Speed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pistolen-Bewegungsgeschwindigkeit"])},
        "Gun Movement Period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intervalzeit der Pistolen"])},
        "Save Optimization to Library": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optimierung in der Bibliothek speichern"])},
        "Optimization Saved to Library": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In der Bibliothek gespeicherte Optimierung"])},
        "optional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["optional"])},
        "Static Optimization Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statische Optimierung Name"])},
        "New Optimization for line": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neue Optimierung für Anlage"])},
        "Status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
        "Optimum found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optimum gefunden"])},
        "Ready to find optimum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bereit"])},
        "Static Optimization details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Details zur statischen Optimierung"])},
        "Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
        "Line": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anlage"])},
        "Optimization type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Art der Optimierung"])},
        "Select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wählen Sie"])},
        "Powder Models": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pulver-Modelle"])},
        "Calibrated Powder Optimization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kalibrierte Sprühbilder"])},
        "Pre-Calibrated Powder Optimization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorkalibrierte Sprühbilder"])},
        "Generalistic Optimization for any powder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generalistische Optimierung für beliebige Pulver"])},
        "Select Powder Model": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pulvermodell auswählen"])},
        "No Powder Models Created. To create one go to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Sprühbilder erstellt. Um eines zu erstellen, gehen Sie zu"])},
        "Optimization parameters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optimierungsparameter"])},
        "Line Speed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kettengeschwindigkeit"])},
        "Distance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abstand"])},
        "Covered height": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bauteilhöhe"])},
        "Simulation width": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Simulationsbreite"])},
        "Continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weiter"])},
        "Compare with Current Settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vergleich mit aktuellen Einstellungen"])},
        "Number of active guns 1st column": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anzahl der aktiven Pistolen 1. Spalte"])},
        "Please select one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte wählen Sie eine"])},
        "Number of active guns 2nd column": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anzahl der aktiven Pistolen 2. Spalte"])},
        "Number of active guns 3rd column": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anzahl aktiver Pistolen 3. Spalte"])},
        "Number of active guns 4th column": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anzahl der aktiven Pistolen 4. Spalte"])},
        "Number of active guns 5th column": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anzahl der aktiven Pistolen 5. Spalte"])},
        "Guns movement range": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hubhöhe"])},
        "Guns movement period time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intervalzeit der Pistolen"])},
        "Guns movement speed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bewegungsgeschwindigkeit der Pistolen"])},
        "Compute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optimieren"])},
        "Optimal machine settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optimale Maschineneinstellungen"])},
        "Simulation results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ergebnisse der Simulation"])},
        "Current Settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktuelle Einstellungen"])},
        "Overspray": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Overspray"])},
        "Optimal Settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optimale Einstellungen"])},
        "Uniformity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gleichmäßigkeit"])},
        "Median uniformity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gleichmäßigkeit des Medians"])},
        "Overspray": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Overspray"])},
        "Visualize graphs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diagramme visualisieren"])},
        "Calculation running": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Berechnung läuft"])},
        "Uniformity comparison": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vergleich der Gleichmäßigkeit"])},
        "Applied spray": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auftragsverteilung"])},
        "Movement chart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bewegungsdiagramm"])},
        "Applied spray animation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Animation"])},
        "Current Settings Coated Area": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktuelle Einstellungen Beschichtete Fläche"])},
        "Optimal Settings Coated Area": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optimale Einstellungen Beschichtete Fläche"])},
        "Current Settings Guns Movement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktuelle Pistolenbewegung"])},
        "Optimal Settings Guns Movement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optimale Pistolenbewegung"])},
        "Current Settings Coated Area": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktuelle Auftragsverteilung"])},
        "Optimal Settings Coated Area": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optimale Auftragsverteilung"])},
        "Comments section for the generated report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kommentarbereich für den generierten Bericht"])},
        "Hide Optimal Settings in the report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optimale Einstellungen im Bericht ausblenden"])},
        "Save Edited Comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bearbeitete Kommentare speichern"])},
        "Generate Report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bericht generieren"])},
        "Generating optimization report...": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generierung von Optimierungsberichten..."])},
        "To use a different optimization type create a new Optimization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Um einen anderen Optimierungstyp zu verwenden, erstellen Sie eine neue Optimierung"])},
        "If you want to use a different set of Powder Models, create a new Optimization.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wenn Sie einen anderen Satz von Pulvermodellen verwenden möchten, erstellen Sie eine neue Optimierung."])},
        "Select Specific Powder optimization to use one or more powder models to compute the optimal settings. Select the Generalistic Optimization option to get a powder agnostic optimization solution.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wählen Sie Spezifische Pulveroptimierung, um ein oder mehrere Sprühbilder zur Berechnung der optimalen Einstellungen zu verwenden. Wählen Sie die Option Generalistische Optimierung, um eine pulverunabhängige Optimierungslösung zu erhalten."])},
        "Select the Powder Models to be used during the computation of optimal settings.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wählen Sie die Sprühbilder aus, die bei der Berechnung der optimalen Einstellungen verwendet werden sollen."])},
        "Line velocity value in [meters/minute] units.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wert der Kettengeschwindigkeit in der Einheit [Meter/Minute]."])},
        "Distance from the gun nozzle to the substrate.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abstand von der Pistolendüse zum Substrat."])},
        "Vertical height for which the uniformity will be optimized.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vertikale Höhe, für die die Gleichmäßigkeit optimiert werden soll."])},
        "Only relevant for visualization purposes, determines the horizontal width, of the simulation image shown on the results section.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nur für die Visualisierung relevant, bestimmt die horizontale Breite des Simulationsbildes, das im Ergebnisbereich angezeigt wird."])},
        "Fill this section with your Guns Settings to visualize how they compare with the optimal settings.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Füllen Sie diesen Abschnitt mit Ihren Pistoleneinstellungen, um zu sehen, wie sie im Vergleich zu den optimalen Einstellungen aussehen."])},
        "Clear current settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktuelle Einstellungen löschen"])},
        "Number of active guns on the first column of guns. The minimum is 0 and the maximum is": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anzahl der aktiven Pistolen in der ersten Spalte der Pistolen. Der Mindestwert ist 0 und der Höchstwert ist"])},
        "Number of active guns on the second column of guns. The minimum is 0 and the maximum is": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anzahl der aktiven Pistolen in der zweiten Pistolenspalte. Der Mindestwert ist 0 und der Höchstwert ist"])},
        "Number of active guns on the third column of guns. The minimum is 0 and the maximum is": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anzahl der aktiven Pistolen in der dritten Pistolenspalte. Der Minimalwert ist 0 und der Maximalwert ist"])},
        "Number of active guns on the fourth column of guns. The minimum is 0 and the maximum is": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anzahl der aktiven Pistolen in der vierten Pistolenspalte. Der Minimalwert ist 0 und der Maximalwert ist"])},
        "Number of active guns on the fifth column of guns. The minimum is 0 and the maximum is": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anzahl der aktiven Pistolen in der fünften Pistolenspalte. Der Minimalwert ist 0 und der Maximalwert ist"])},
        "Number of active guns on one side of the booth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anzahl der aktiven Pistolen auf einer Seite der Kabine"])},
        "Activate Toggle to visualize simulation images": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktivieren Sie Toggle, um Simulationsbilder zu visualisieren"])},
        "Gun to gun distance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pistole zu Pistolenabstand"])},
        "Optimal Settings Nozzle Angle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optimale Einstellungen Düsenwinkel"])},
        "Current Settings Nozzle Angle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktuelle Einstellungen Düsenwinkel"])},
        "guns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pistolen"])},
        "Powder Usage Pie Chart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kreisdiagramm zum Pulververbrauch"])},
        "Optimal Settings Pie Chart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kuchendiagramm Optimale Einstellungen"])},
        "Current Settings Pie Chart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kuchendiagramm Optimale Einstellungen"])},
        "Powder Waste": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pulverabfall"])}
      },
      "ES": {
        "": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
        "Overcoated powder:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Overcoated powder:"])},
        "Oversprayed powder:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oversprayed powder:"])},
        "The amount of extra powder applied because of an uneven powder distribution on the substrate.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La cantidad de polvo adicional aplicada debido a una distribución desigual del polvo sobre el sustrato."])},
        "The powder that does not stick to the part and is then not reclaimed by the recycling system.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El polvo que no se adhiere a la pieza y que no es recuperado por el sistema de reciclado."])},
        "2nd column height offset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diferencia de altura de la 2ª columna"])},
        "3rd column height offset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diferencia de altura de la 3ª columna"])},
        "4th column height offset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diferencia de altura de la 4ª columna"])},
        "5th column height offset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diferencia de altura de la 5ª columna"])},
        "gun": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pistola"])},
        "Gun to Gun distance must be smaller than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La distancia de pistola a pistola a debe ser menor que"])},
        "Gun to Gun distance must be greater than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La distancia de pistola a pistola a debe ser mayor que"])},
        "Gun to gun distance cannot be 0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La distancia de pistola a pistola no puede ser 0"])},
        "degrees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["grados"])},
        "Gun column height offsets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diferencia de altura de cada columna de pistolas"])},
        "Nozzle rotation angle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ángulo de la boquilla"])},
        "The covered height, for the number of guns available, must be smaller than ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La altura cubierta, para el número de pistolas disponibles, debe ser inferior a "])},
        "Guns movement range cannot be greater than the maximum possible mechanical range of the line:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El rango del movimiento de las pistolas no puede ser superior al máximo alcance mecánico de la línea:"])},
        "Loading optimization type...": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cargando tipo de optimización..."])},
        "Clear & start new optimization for line": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Limpiar todo y empezar una nueva optimización"])},
        "Please fill the following fields: ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rellene los siguientes campos: "])},
        "Line speed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Velocidad de la línea"])},
        "Coated height": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Altura cubierta"])},
        "Powder model": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modelo de polvo"])},
        "Specific Powder Optimization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optimización para polvos específicos"])},
        "Input value out of range": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valor fuera de rango"])},
        "was saved successfully.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["se ha guardado correctamente."])},
        "Missing Required Fields": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Faltan campos obligatorios"])},
        "Guns movement range cannot be 0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El rango de movimiento de las pistola no puede ser 0"])},
        "Line speed cannot be 0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La velocidad de la línea no puede ser 0"])},
        "Line speed must be greater than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La velocidad de la línea debe ser mayor que"])},
        "Line speed must be less than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La velocidad de la línea debe ser menor que"])},
        "Covered height must be greater than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La altura cubierta por la optimización debe ser mayor que"])},
        "Guns movement speed cannot be slower than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La velocidad de las pistolas no puede ser inferior a"])},
        "Use a faster guns velocity or a different movement range & period time combination.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utiliza una velocidad de las pistolas más rápida o una combinación de rango de movimiento y periodo."])},
        "Guns movement speed cannot be faster than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La velocidad de movimiento de las pistolas no puede ser superior a"])},
        "Use a slower guns velocity or a different movement range & period time combination.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utiliza una velocidad de las pistolas más lenta o una combinación diferente de rango de movimiento y periodo."])},
        "Guns movement period cannot be longer than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El periodo del movimiento de la pistola a no puede ser mayor que"])},
        "Use a shorter guns period or a different movement range & period time combination.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utiliza un periodo de movimiento de las pistolas más corto o una combinación diferente de rango de movimiento y periodo."])},
        "Guns movement period must be greater than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El periodo del movimiento de las pistolas debe ser mayor que"])},
        "No fields were changed from the previous optimization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se ha modificado ningún campo de la optimización."])},
        "Do you want to recompute it anyway?": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Desea volver a calcular de todos modos?"])},
        "Cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancelar"])},
        "Yes, recompute it!": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sí, ¡recalcúla la optimización!"])},
        "You need to define the Current Settings to generate a report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se necesita definir una Configuración actual para generar un informe"])},
        "OK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OK"])},
        "Total guns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numero de pistolas"])},
        "Total guns per column": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numero de pistolas por columna"])},
        "Gun movement range": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rango de movimiento de las pistolas"])},
        "Gun Movement Speed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Velocidad de movimiento de las pistolas"])},
        "Gun Movement Period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Periodo de movimiento de las pistolas"])},
        "Save Optimization to Library": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guardadar optimización en la biblioteca"])},
        "Optimization Saved to Library": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optimización guardada en la biblioteca"])},
        "optional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["opcional"])},
        "Static Optimization Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre de la optimización estática"])},
        "New Optimization for line": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nueva optimización para la línea"])},
        "Status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estado"])},
        "Optimum found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configuración Óptima encontrada"])},
        "Ready to find optimum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Listo para encontrar Configuración Óptima"])},
        "Static Optimization details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detalles de la optimización estática"])},
        "Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre"])},
        "Line": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Línea"])},
        "Optimization type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo de optimización"])},
        "Select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleccione"])},
        "Powder Models": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modelos de polvo"])},
        "Calibrated Powder Optimization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optimización con un polvo calibrado"])},
        "Pre-Calibrated Powder Optimization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optimización con un polvo precalibrado"])},
        "Generalistic Optimization for any powder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optimización generalista para cualquier polvo"])},
        "Select Powder Model": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleccionar Modelo de Polvo"])},
        "No Powder Models Created. To create one go to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se han creado modelos de polvo. Para crear uno vaya a"])},
        "Optimization parameters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parámetros de la optimización"])},
        "Line Speed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Velocidad de la línea"])},
        "Distance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distancia"])},
        "Covered height": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Altura cubierta"])},
        "Simulation width": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anchura de la simulación"])},
        "Continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continuar"])},
        "Compare with Current Settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comparar con la configuración actual"])},
        "Number of active guns 1st column": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de pistolas activas 1ª columna"])},
        "Please select one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleccione una"])},
        "Number of active guns 2nd column": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de pistolas activas 2ª columna"])},
        "Number of active guns 3rd column": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de pistolas activas 3ª columna"])},
        "Number of active guns 4th column": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de pistolas activas 4ª columna"])},
        "Number of active guns 5th column": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de pistolas activas 5ª columna"])},
        "Guns movement range": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rango de movimiento de las pistolas"])},
        "Guns movement period time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Periodo del movimiento de las pistolas"])},
        "Guns movement speed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Velocidad de movimiento de las pistolas"])},
        "Compute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calcular"])},
        "Optimal machine settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajustes óptimos de la máquina"])},
        "Simulation results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resultados de la simulación"])},
        "Current Settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configuración actual"])},
        "Overspray": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sobrepulverización"])},
        "Optimal Settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajustes óptimos"])},
        "Uniformity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uniformidad"])},
        "Median uniformity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uniformidad mediana"])},
        "Overspray": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sobrepulverización"])},
        "Visualize graphs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visualizar gráficos"])},
        "Calculation running": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cálculo en proceso"])},
        "Uniformity comparison": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comparación de la uniformidad"])},
        "Applied spray": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pintura aplicada"])},
        "Movement chart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gráfico del movimiento"])},
        "Applied spray animation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Animación de la pintura aplicada"])},
        "Current Settings Coated Area": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configuración actual Área recubierta"])},
        "Optimal Settings Coated Area": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajustes óptimos Área recubierta"])},
        "Current Settings Guns Movement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configuración actual Movimiento de las pistolas"])},
        "Optimal Settings Guns Movement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajustes óptimos Movimiento de las pistolas"])},
        "Current Settings Coated Area": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configuración actual Área recubierta"])},
        "Optimal Settings Coated Area": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajustes óptimos Área recubierta"])},
        "Comments section for the generated report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sección de comentarios libres para el informe"])},
        "Hide Optimal Settings in the report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ocultar los ajustes óptimos en el informe"])},
        "Save Edited Comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guardar comentarios editados"])},
        "Generate Report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generar informe"])},
        "Generating optimization report...": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generando informe de la optimización..."])},
        "To use a different optimization type create a new Optimization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Para utilizar un tipo de optimización diferente cree una nueva Optimización"])},
        "If you want to use a different set of Powder Models, create a new Optimization.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si desea utilizar un conjunto diferente de Modelos de polvo, cree una nueva Optimización."])},
        "Select Specific Powder optimization to use one or more powder models to compute the optimal settings. Select the Generalistic Optimization option to get a powder agnostic optimization solution.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleccione Optimización específica de polvo para utilizar uno o más modelos de polvo para calcular los ajustes óptimos. Seleccione la opción Optimización generalista para obtener una solución de optimización agnóstica al polvo."])},
        "Select the Powder Models to be used during the computation of optimal settings.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleccione los Modelos de polvo que se utilizarán durante el cálculo de los ajustes óptimos."])},
        "Line velocity value in [meters/minute] units.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valor de velocidad de la línea en unidades [metros/minuto]."])},
        "Distance from the gun nozzle to the substrate.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distancia de la boquilla de la pistola al sustrato."])},
        "Vertical height for which the uniformity will be optimized.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Altura vertical para la que se optimizará la uniformidad."])},
        "Only relevant for visualization purposes, determines the horizontal width, of the simulation image shown on the results section.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sólo relevante a efectos de visualización, determina la anchura de las imagenes de la simulación mostradas en la sección de resultados."])},
        "Fill this section with your Guns Settings to visualize how they compare with the optimal settings.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rellene esta sección con sus ajustes actuales para visualizar la comparación con los ajustes óptimos."])},
        "Clear current settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Borrar estos ajustes actuales"])},
        "Number of active guns on the first column of guns. The minimum is 0 and the maximum is": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de pistolas activas en la primera columna. El mínimo es 0 y el máximo es"])},
        "Number of active guns on the second column of guns. The minimum is 0 and the maximum is": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de pistolas activas en la segunda columna. El mínimo es 0 y el máximo es"])},
        "Number of active guns on the third column of guns. The minimum is 0 and the maximum is": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de pistolas activas en la tercera columna. El mínimo es 0 y el máximo es"])},
        "Number of active guns on the fourth column of guns. The minimum is 0 and the maximum is": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de pistolas activas en la cuarta columna. El mínimo es 0 y el máximo es"])},
        "Number of active guns on the fifth column of guns. The minimum is 0 and the maximum is": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de pistolas activas en la quinta columna. El mínimo es 0 y el máximo es"])},
        "Number of active guns on one side of the booth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de pistolas activas en un lado de la cabina"])},
        "Activate Toggle to visualize simulation images": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activar para visualizar las imágenes de la simulación"])},
        "Gun to gun distance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distancia entre pistolas"])},
        "Optimal Settings Nozzle Angle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ángulo de la boquilla de los ajustes óptimos"])},
        "Current Settings Nozzle Angle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ángulo de la boquilla de la configuración actual"])},
        "guns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pistolas"])},
        "Powder Usage Pie Chart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grafico del uso de la pintura"])},
        "Optimal Settings Pie Chart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configuración óptima"])},
        "Current Settings Pie Chart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configuración actual"])},
        "Powder Waste": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desperdicio de polvo"])}
      },
      "FR": {
        "": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
        "Overcoated powder:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Overcoated powder:"])},
        "Oversprayed powder:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oversprayed powder:"])},
        "The amount of extra powder applied because of an uneven powder distribution on the substrate.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La quantité de poudre supplémentaire appliquée en raison d'une distribution inégale de la poudre sur le substrat."])},
        "The powder that does not stick to the part and is then not reclaimed by the recycling system.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La poudre qui n'adhère pas à la pièce et qui n'est donc pas récupérée par le système de recyclage."])},
        "2nd column height offset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Décalage de la hauteur de la 2ème colonne"])},
        "3rd column height offset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Décalage de la hauteur de la 3ème colonne"])},
        "4th column height offset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Décalage de la hauteur de la 4ème colonne"])},
        "5th column height offset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Décalage de la hauteur de la 5ème colonne"])},
        "gun": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pistolet"])},
        "Gun to Gun distance must be smaller than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La distance de pistolet à pistolet doit être inférieure à"])},
        "Gun to Gun distance must be greater than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La distance de pistolet à pistolet doit être supérieure à"])},
        "Gun to gun distance cannot be 0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La distance de pistolet à pistolet ne peut pas être 0"])},
        "degrees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["degrés"])},
        "Gun column height offsets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Décalage de la hauteur de la colonne du pistolet"])},
        "Nozzle rotation angle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Angle de rotation de la buse"])},
        "The covered height, for the number of guns available, must be smaller than ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La hauteur couverte, pour le nombre de pistolets disponibles, doit être inférieure à "])},
        "Guns movement range cannot be greater than the maximum possible mechanical range of the line:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La portée de mouvement des pistolets ne peut être supérieure à la portée mécanique maximale possible de la ligne:"])},
        "Loading optimization type...": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chargement du type d'optimisation..."])},
        "Clear & start new optimization for line": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Effacer et commencer une nouvelle optimisation de la ligne"])},
        "Please fill the following fields: ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez remplir les champs suivants: "])},
        "Line speed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vitesse de la ligne"])},
        "Coated height": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hauteur du revêtement"])},
        "Powder model": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modèle de poudre"])},
        "Specific Powder Optimization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optimisation des poudres spécifiques"])},
        "Input value out of range": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valeur d'entrée en dehors de la plage"])},
        "was saved successfully.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["a été enregistrée avec succès."])},
        "Missing Required Fields": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Champs obligatoires manquants"])},
        "Guns movement range cannot be 0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La portée des mouvements des pistolets ne peut pas être égale à 0"])},
        "Line speed cannot be 0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La vitesse de la ligne ne peut pas être égale à 0"])},
        "Line speed must be greater than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La vitesse de la ligne doit être supérieure à"])},
        "Line speed must be less than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La vitesse de la ligne doit être inférieure à"])},
        "Covered height must be greater than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La hauteur du revêtement doit être supérieure à"])},
        "Guns movement speed cannot be slower than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La vitesse de mouvement des pistolets ne peut pas être inférieure à"])},
        "Use a faster guns velocity or a different movement range & period time combination.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utiliser une vitesse de pistolet plus rapide ou une combinaison différente de portée de mouvement et de durée."])},
        "Guns movement speed cannot be faster than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La vitesse de mouvement des pistolets ne peut pas être supérieure à"])},
        "Use a slower guns velocity or a different movement range & period time combination.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilisez une vitesse de pistolet plus lente ou une combinaison différente de plage de mouvement et de durée de période."])},
        "Guns movement period cannot be longer than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La période de mouvement des pistolets ne peut pas être plus longue que"])},
        "Use a shorter guns period or a different movement range & period time combination.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilisez une période de mouvement du pistolet plus courte ou une combinaison différente d'amplitude de mouvement et de durée de période."])},
        "Guns movement period must be greater than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La période de mouvement des pistolets doit être supérieure à"])},
        "No fields were changed from the previous optimization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun champ n'a été modifié par rapport à l'optimisation précédente"])},
        "Do you want to recompute it anyway?": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voulez-vous quand même recalculer l'optimisation ?"])},
        "Cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuler"])},
        "Yes, recompute it!": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oui, recalculer !"])},
        "You need to define the Current Settings to generate a report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous devez définir les paramètres actuels pour générer un rapport."])},
        "OK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OK"])},
        "Total guns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total des pistolets"])},
        "Total guns per column": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total des pistolets par colonne"])},
        "Gun movement range": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portée de mouvement des pistolets"])},
        "Gun Movement Speed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vitesse de mouvement du pistolet"])},
        "Gun Movement Period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Période de mouvement du pistolet"])},
        "Save Optimization to Library": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sauvegarde de l'optimisation dans la bibliothèque"])},
        "Optimization Saved to Library": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optimisation sauvegardée dans la bibliothèque"])},
        "optional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["facultatif"])},
        "Static Optimization Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom de l'optimisation statique"])},
        "New Optimization for line": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouvelle optimisation pour la ligne"])},
        "Status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statut"])},
        "Optimum found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optimum trouvé"])},
        "Ready to find optimum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prêt à trouver l'optimum"])},
        "Static Optimization details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Détails de l'optimisation statique"])},
        "Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom de la ligne"])},
        "Line": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ligne"])},
        "Optimization type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type d'optimisation"])},
        "Select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionner"])},
        "Powder Models": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modèles de poudres"])},
        "Calibrated Powder Optimization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optimisation de la poudre calibrée"])},
        "Pre-Calibrated Powder Optimization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optimisation des poudres pré-calibrées"])},
        "Generalistic Optimization for any powder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optimisation généraliste pour n'importe quelle poudre"])},
        "Select Powder Model": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionner un modèle de poudre"])},
        "No Powder Models Created. To create one go to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun modèle de poudre n'a été créé. Pour en créer un, allez à"])},
        "Optimization parameters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paramètres d'optimisation"])},
        "Line Speed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vitesse de la ligne"])},
        "Distance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distance"])},
        "Covered height": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hauteur couverte"])},
        "Simulation width": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Largeur de la simulation"])},
        "Continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continuer"])},
        "Compare with Current Settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comparer avec les paramètres actuels"])},
        "Number of active guns 1st column": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre de pistolets actifs 1ère colonne"])},
        "Please select one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez sélectionner un"])},
        "Number of active guns 2nd column": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre de pistolets actifs 2ème colonne"])},
        "Number of active guns 3rd column": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre de pistolets actifs 3ème colonne"])},
        "Number of active guns 4th column": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre de pistolets actifs 4ème colonne"])},
        "Number of active guns 5th column": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre de pistolets actifs 5ème colonne"])},
        "Guns movement range": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portée du mouvement des pistolets"])},
        "Guns movement period time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durée de la période de mouvement des pistolets"])},
        "Guns movement speed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vitesse de mouvement des pistolets"])},
        "Compute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calculer"])},
        "Optimal machine settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paramètres optimaux de la machine"])},
        "Simulation results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Résultats de la simulation"])},
        "Current Settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réglages actuels"])},
        "Overspray": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Surpulvérisation"])},
        "Optimal Settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réglages optimaux"])},
        "Uniformity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uniformité"])},
        "Median uniformity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uniformité médiane"])},
        "Overspray": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pulvérisation"])},
        "Visualize graphs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visualiser les graphiques"])},
        "Calculation running": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calculs en cours"])},
        "Uniformity comparison": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comparaison de l'uniformité"])},
        "Applied spray": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pulvérisation appliquée"])},
        "Movement chart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Graphique de mouvement"])},
        "Applied spray animation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Animation de la pulvérisation appliquée"])},
        "Current Settings Coated Area": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réglages actuels Surface enrobée"])},
        "Optimal Settings Coated Area": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réglages optimaux Surface enrobée"])},
        "Current Settings Guns Movement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réglages actuels Mouvement des pistolets"])},
        "Optimal Settings Guns Movement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réglages optimaux Mouvement des pistolets"])},
        "Current Settings Coated Area": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réglages actuels de la zone enduite"])},
        "Optimal Settings Coated Area": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réglages optimaux de la zone enduite"])},
        "Comments section for the generated report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Section des commentaires pour le rapport généré"])},
        "Hide Optimal Settings in the report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cacher les paramètres optimaux dans le rapport"])},
        "Save Edited Comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sauvegarder les commentaires édités"])},
        "Generate Report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Générer le rapport"])},
        "Generating optimization report...": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Générer un rapport d'optimisation..."])},
        "To use a different optimization type create a new Optimization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pour utiliser un type d'optimisation différent, créez une nouvelle optimisation."])},
        "If you want to use a different set of Powder Models, create a new Optimization.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si vous souhaitez utiliser un ensemble différent de modèles de poudre, créez une nouvelle optimisation."])},
        "Select Specific Powder optimization to use one or more powder models to compute the optimal settings. Select the Generalistic Optimization option to get a powder agnostic optimization solution.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionnez l'option Optimisation spécifique à la poudre pour utiliser un ou plusieurs modèles de poudre afin de calculer les paramètres optimaux. Sélectionnez l'option Optimisation généraliste pour obtenir une solution d'optimisation indépendante des poudres."])},
        "Select the Powder Models to be used during the computation of optimal settings.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionnez les modèles de poudre à utiliser lors du calcul des paramètres optimaux."])},
        "Line velocity value in [meters/minute] units.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valeur de la vitesse de la ligne en unités [mètres/minute]."])},
        "Distance from the gun nozzle to the substrate.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distance entre la buse du pistolet et le substrat."])},
        "Vertical height for which the uniformity will be optimized.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hauteur verticale pour laquelle l'uniformité sera optimisée."])},
        "Only relevant for visualization purposes, determines the horizontal width, of the simulation image shown on the results section.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La largeur horizontale de l'image de simulation affichée dans la section des résultats n'est utile qu'à des fins de visualisation."])},
        "Fill this section with your Guns Settings to visualize how they compare with the optimal settings.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remplissez cette section avec les paramètres de vos pistolets pour visualiser leur comparaison avec les paramètres optimaux."])},
        "Clear current settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Effacer les paramètres actuels"])},
        "Number of active guns on the first column of guns. The minimum is 0 and the maximum is": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre de pistolets actifs dans la première colonne de pistolets. Le minimum est 0 et le maximum est"])},
        "Number of active guns on the second column of guns. The minimum is 0 and the maximum is": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre de pistolets actifs dans la deuxième colonne de pistolets. Le minimum est 0 et le maximum est"])},
        "Number of active guns on the third column of guns. The minimum is 0 and the maximum is": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre de pistolets actifs sur la troisième colonne de pistolets. Le minimum est 0 et le maximum est"])},
        "Number of active guns on the fourth column of guns. The minimum is 0 and the maximum is": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre de pistolets actifs sur la quatrième colonne de pistolets. Le minimum est 0 et le maximum est"])},
        "Number of active guns on the fifth column of guns. The minimum is 0 and the maximum is": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre de pistolets actifs sur la cinquième colonne de pistolets. Le minimum est 0 et le maximum est"])},
        "Number of active guns on one side of the booth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre de pistolets actifs sur un côté de la cabine"])},
        "Activate Toggle to visualize simulation images": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activer la bascule pour visualiser les images de simulation"])},
        "Gun to gun distance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distance entre pistolets"])},
        "Optimal Settings Nozzle Angle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paramètres optimaux Angle de la buse"])},
        "Current Settings Nozzle Angle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paramètres actuels Angle de la buse"])},
        "guns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pistolets"])},
        "Powder Usage Pie Chart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diagramme à secteurs de l'utilisation de la poudre"])},
        "Optimal Settings Pie Chart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diagramme à secteurs des réglages optimaux"])},
        "Current Settings Pie Chart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diagramme à secteurs des réglages actuels"])},
        "Powder Waste": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Déchets de poudre"])}
      },
      "IT": {
        "": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
        "Overcoated powder:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Overcoated powder:"])},
        "Oversprayed powder:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oversprayed powder:"])},
        "The amount of extra powder applied because of an uneven powder distribution on the substrate.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La quantità di polvere extra applicata a causa di una distribuzione non uniforme della polvere sul substrato."])},
        "The powder that does not stick to the part and is then not reclaimed by the recycling system.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La polvere che non aderisce al pezzo e che non viene recuperata dal sistema di riciclaggio."])},
        "2nd column height offset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sfalsamento dell'altezza della 2a colonna"])},
        "3rd column height offset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sfalsamento dell'altezza della 3a colonna"])},
        "4th column height offset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sfalsamento dell'altezza della 4a colonna"])},
        "5th column height offset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sfalsamento dell'altezza della 5a colonna"])},
        "gun": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pistola"])},
        "Gun to Gun distance must be smaller than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La distanza pistola-pistola deve essere inferiore a"])},
        "Gun to Gun distance must be greater than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La distanza pistola-pistola deve essere superiore a"])},
        "Gun to gun distance cannot be 0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La distanza da pistola a pistola non può essere 0"])},
        "degrees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["gradi"])},
        "Gun column height offsets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disassamento dell'altezza della colonna della pistola"])},
        "Nozzle rotation angle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Angolo di rotazione dell'ugello"])},
        "The covered height, for the number of guns available, must be smaller than ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'altezza coperta, per il numero di pistole disponibili, deve essere inferiore a "])},
        "Guns movement range cannot be greater than the maximum possible mechanical range of the line:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La portata di movimento delle pistole non può essere superiore alla portata meccanica massima possibile della linea:"])},
        "Loading optimization type...": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Caricamento del tipo di ottimizzazione..."])},
        "Clear & start new optimization for line": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancella e avvia una nuova ottimizzazione per la linea"])},
        "Please fill the following fields: ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compilare i seguenti campi: "])},
        "Line speed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Velocità della linea"])},
        "Coated height": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Altezza del rivestimento"])},
        "Powder model": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modello di polvere"])},
        "Specific Powder Optimization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ottimizzazione di polveri specifiche"])},
        "Input value out of range": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valore di ingresso fuori dall'intervallo"])},
        "was saved successfully.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["è stato salvato con successo."])},
        "Missing Required Fields": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Campi obbligatori mancanti"])},
        "Guns movement range cannot be 0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il raggio di movimento delle pistole non può essere 0"])},
        "Line speed cannot be 0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La velocità della linea non può essere 0"])},
        "Line speed must be greater than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La velocità della linea deve essere maggiore di"])},
        "Line speed must be less than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La velocità della linea deve essere inferiore a"])},
        "Covered height must be greater than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'altezza del rivestimento deve essere maggiore di"])},
        "Guns movement speed cannot be slower than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La velocità di movimento delle pistole non può essere inferiore a"])},
        "Use a faster guns velocity or a different movement range & period time combination.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilizzare una velocità di movimento della pistola più elevata o una diversa combinazione di intervallo di movimento e di tempo."])},
        "Guns movement speed cannot be faster than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La velocità di movimento delle pistole non può essere superiore a"])},
        "Use a slower guns velocity or a different movement range & period time combination.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilizzare una velocità della pistola più lenta o una diversa combinazione di intervallo di movimento e periodo."])},
        "Guns movement period cannot be longer than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il periodo di movimento delle pistole non può essere più lungo di"])},
        "Use a shorter guns period or a different movement range & period time combination.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilizzare un periodo di movimento della pistola più breve o una diversa combinazione di intervallo di movimento e periodo di tempo."])},
        "Guns movement period must be greater than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il periodo di movimento delle pistole deve essere maggiore di"])},
        "No fields were changed from the previous optimization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessun campo è stato modificato rispetto all'ottimizzazione precedente"])},
        "Do you want to recompute it anyway?": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Volete comunque ricalcolarla?"])},
        "Cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annulla"])},
        "Yes, recompute it!": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sì, ricalcolo!"])},
        "You need to define the Current Settings to generate a report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["È necessario definire le Impostazioni correnti per generare un rapporto"])},
        "OK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OK"])},
        "Total guns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Totale pistole"])},
        "Total guns per column": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Totale pistole per colonna"])},
        "Gun movement range": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rango di movimento delle pistole"])},
        "Gun Movement Speed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Velocità di movimento della pistola"])},
        "Gun Movement Period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Periodo di movimento della pistola"])},
        "Save Optimization to Library": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salvare l'ottimizzazione nella libreria"])},
        "Optimization Saved to Library": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ottimizzazione salvata nella libreria"])},
        "optional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["opzionale"])},
        "Static Optimization Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome dell'ottimizzazione statica"])},
        "New Optimization for line": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nuova ottimizzazione per la linea"])},
        "Status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stato"])},
        "Optimum found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ottimale trovato"])},
        "Ready to find optimum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pronto per l'ottimizzazione"])},
        "Static Optimization details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dettagli dell'ottimizzazione statica"])},
        "Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome"])},
        "Line": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Linea"])},
        "Optimization type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo di ottimizzazione"])},
        "Select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selezionare"])},
        "Powder Models": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modelli di polvere"])},
        "Calibrated Powder Optimization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ottimizzazione con modello di polvere calibrato"])},
        "Pre-Calibrated Powder Optimization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ottimizzazione con modello di polvere pre-calibrato"])},
        "Generalistic Optimization for any powder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ottimizzazione generalista per qualsiasi polvere"])},
        "Select Powder Model": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selezionare il modello di polvere"])},
        "No Powder Models Created. To create one go to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non sono stati creati modelli di polvere. Per crearne uno, andare su"])},
        "Optimization parameters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parametri di ottimizzazione"])},
        "Line Speed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Velocità della linea"])},
        "Distance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distanza"])},
        "Covered height": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Altezza coperta"])},
        "Simulation width": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Larghezza di simulazione"])},
        "Continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continua"])},
        "Compare with Current Settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confronta con le impostazioni correnti"])},
        "Number of active guns 1st column": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numero di pistole attive 1º colonna"])},
        "Please select one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selezionare una"])},
        "Number of active guns 2nd column": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numero di pistole attive nella 2º colonna"])},
        "Number of active guns 3rd column": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numero di pistole attive nella 3º colonna"])},
        "Number of active guns 4th column": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numero di pistole attive nella 4º colonna"])},
        "Number of active guns 5th column": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numero di pistole attive nella 5º colonna"])},
        "Guns movement range": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rango di movimento delle pistole"])},
        "Guns movement period time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tempo di movimento delle pistole"])},
        "Guns movement speed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Velocità di movimento delle pistole"])},
        "Compute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calcola"])},
        "Optimal machine settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impostazioni ottimali della macchina"])},
        "Simulation results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Risultati della simulazione"])},
        "Current Settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impostazioni attuali"])},
        "Overspray": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Polvere Persa"])},
        "Optimal Settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impostazioni ottimali"])},
        "Uniformity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uniformità"])},
        "Median uniformity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uniformità mediana"])},
        "Overspray": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Polvere Persa"])},
        "Visualize graphs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visualizzazione dei grafici"])},
        "Calculation running": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esecuzione del calcolo"])},
        "Uniformity comparison": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confronto dell'uniformità"])},
        "Applied spray": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spruzzo applicato"])},
        "Movement chart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grafico del movimento"])},
        "Applied spray animation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Animazione dello spruzzo applicato"])},
        "Current Settings Coated Area": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impostazioni attuali Area rivestita"])},
        "Optimal Settings Coated Area": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impostazioni ottimali Area rivestita"])},
        "Current Settings Guns Movement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impostazioni attuali Movimento delle pistole"])},
        "Optimal Settings Guns Movement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impostazioni ottimali Movimento pistole"])},
        "Current Settings Coated Area": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impostazioni attuali Area rivestita"])},
        "Optimal Settings Coated Area": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impostazioni ottimali Area rivestita"])},
        "Comments section for the generated report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sezione commenti per il rapporto generato"])},
        "Hide Optimal Settings in the report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nascondi le impostazioni ottimali nel rapporto"])},
        "Save Edited Comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salva i commenti modificati"])},
        "Generate Report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Genera rapporto"])},
        "Generating optimization report...": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generazione del rapporto di ottimizzazione..."])},
        "To use a different optimization type create a new Optimization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Per utilizzare un tipo di ottimizzazione diverso, creare una nuova Ottimizzazione"])},
        "If you want to use a different set of Powder Models, create a new Optimization.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se si desidera utilizzare una serie diversa di modelli di polvere, creare una nuova ottimizzazione."])},
        "Select Specific Powder optimization to use one or more powder models to compute the optimal settings. Select the Generalistic Optimization option to get a powder agnostic optimization solution.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selezionare l'ottimizzazione Polvere specifica per utilizzare uno o più modelli di polvere per calcolare le impostazioni ottimali. Selezionare l'opzione Ottimizzazione generalista per ottenere una soluzione di ottimizzazione agnostica della polvere."])},
        "Select the Powder Models to be used during the computation of optimal settings.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selezionare i modelli di polvere da utilizzare durante il calcolo delle impostazioni ottimali."])},
        "Line velocity value in [meters/minute] units.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valore della velocità di linea in unità [metri/minuto]."])},
        "Distance from the gun nozzle to the substrate.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distanza dall'ugello della pistola al substrato."])},
        "Vertical height for which the uniformity will be optimized.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Altezza verticale per la quale verrà ottimizzata l'uniformità."])},
        "Only relevant for visualization purposes, determines the horizontal width, of the simulation image shown on the results section.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rilevante solo ai fini della visualizzazione, determina la larghezza orizzontale dell'immagine di simulazione mostrata nella sezione dei risultati."])},
        "Fill this section with your Guns Settings to visualize how they compare with the optimal settings.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Riempire questa sezione con le impostazioni della pistola per visualizzare il confronto con le impostazioni ottimali."])},
        "Clear current settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancella le impostazioni correnti"])},
        "Number of active guns on the first column of guns. The minimum is 0 and the maximum is": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numero di pistole attive nella prima colonna di pistole. Il minimo è 0 e il massimo è"])},
        "Number of active guns on the second column of guns. The minimum is 0 and the maximum is": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numero di pistole attive nella seconda colonna di pistole. Il minimo è 0 e il massimo è"])},
        "Number of active guns on the third column of guns. The minimum is 0 and the maximum is": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numero di pistole attive nella terza colonna di pistole. Il minimo è 0 e il massimo è"])},
        "Number of active guns on the fourth column of guns. The minimum is 0 and the maximum is": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numero di pistole attive nella quarta colonna di pistole. Il minimo è 0 e il massimo è"])},
        "Number of active guns on the fifth column of guns. The minimum is 0 and the maximum is": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numero di pistole attive nella quinta colonna di pistole. Il minimo è 0 e il massimo è"])},
        "Number of active guns on one side of the booth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numero di pistole attive su un lato della cabina"])},
        "Activate Toggle to visualize simulation images": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attivare la funzione Toggle per visualizzare le immagini della simulazione."])},
        "Gun to gun distance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distanza da pistola a pistola"])},
        "Optimal Settings Nozzle Angle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impostazioni ottimali Angolo dell'ugello"])},
        "Current Settings Nozzle Angle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impostazioni correnti Angolo dell'ugello"])},
        "guns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pistole"])},
        "Powder Usage Pie Chart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grafico dell'uso della polvere"])},
        "Optimal Settings Pie Chart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grafico a torta delle impostazioni ottimali"])},
        "Current Settings Pie Chart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grafico a torta delle impostazioni correnti"])},
        "Powder Waste": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spreco di polvere"])}
      },
      "PL": {
        "": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
        "Overcoated powder:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Overcoated powder:"])},
        "Oversprayed powder:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oversprayed powder:"])},
        "The amount of extra powder applied because of an uneven powder distribution on the substrate.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ilość dodatkowego proszku nałożonego z powodu nierównomiernego rozprowadzenia proszku na podłożu."])},
        "The powder that does not stick to the part and is then not reclaimed by the recycling system.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Proszek, który nie przylega do części i nie jest odzyskiwany przez system recyklingu."])},
        "2nd column height offset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Przesunięcie wysokości 2. kolumny"])},
        "3rd column height offset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Przesunięcie wysokości 3. kolumny"])},
        "4th column height offset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Przesunięcie wysokości 4. kolumny"])},
        "5th column height offset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Przesunięcie wysokości 5. kolumny"])},
        "gun": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pistolet"])},
        "Gun to Gun distance must be smaller than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odległość między pistoletami musi być mniejsza niż"])},
        "Gun to Gun distance must be greater than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odległość między pistoletami musi być większa niż"])},
        "Gun to gun distance cannot be 0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odległość między pistoletami nie może wynosić 0"])},
        "degrees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["stopni"])},
        "Gun column height offsets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Przesunięcie wysokości kolumny pistoletu"])},
        "Nozzle rotation angle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kąt obrotu dyszy"])},
        "The covered height, for the number of guns available, must be smaller than ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pokryta wysokość, dla liczby dostępnych pistoletów, musi być mniejsza niż "])},
        "Guns movement range cannot be greater than the maximum possible mechanical range of the line:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zakres ruchu pistoletów nie może być większy niż maksymalny możliwy zakres mechaniczny linii:"])},
        "Loading optimization type...": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ładowanie typu optymalizacji..."])},
        "Clear & start new optimization for line": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wyczyść i rozpocznij nową optymalizację dla linii"])},
        "Please fill the following fields: ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wypełnij następujące pola: "])},
        "Line speed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prędkość linii"])},
        "Coated height": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wysokość powłoki"])},
        "Powder model": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Model proszku"])},
        "Specific Powder Optimization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optymalizacja konkretnego proszku"])},
        "Input value out of range": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wartość wejściowa poza zakresem"])},
        "was saved successfully.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["została pomyślnie zapisana."])},
        "Missing Required Fields": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brakujące wymagane pola"])},
        "Guns movement range cannot be 0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zakres ruchu pistoletów nie może wynosić 0"])},
        "Line speed cannot be 0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prędkość liniowa nie może wynosić 0"])},
        "Line speed must be greater than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prędkość liniowa musi być większa niż"])},
        "Line speed must be less than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prędkość linii musi być mniejsza niż"])},
        "Covered height must be greater than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wysokość powłoki musi być większa niż"])},
        "Guns movement speed cannot be slower than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prędkość ruchu pistoletów nie może być mniejsza niż"])},
        "Use a faster guns velocity or a different movement range & period time combination.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Użyj większej prędkości pistoletów lub innej kombinacji zakresu ruchu i czasu."])},
        "Guns movement speed cannot be faster than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prędkość ruchu pistoletów nie może być większa niż"])},
        "Use a slower guns velocity or a different movement range & period time combination.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Użyj wolniejszej prędkości pistoletów lub innej kombinacji zakresu ruchu i czasu."])},
        "Guns movement period cannot be longer than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Okres ruchu pistoletów nie może być dłuższy niż"])},
        "Use a shorter guns period or a different movement range & period time combination.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Użyj krótszego okresu ruchu pistoletów lub innej kombinacji zakresu ruchu i czasu."])},
        "Guns movement period must be greater than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Okres ruchu pistoletów musi być większy niż"])},
        "No fields were changed from the previous optimization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Żadne pola nie zostały zmienione w stosunku do poprzedniej optymalizacji"])},
        "Do you want to recompute it anyway?": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Czy mimo to chcesz przeprowadzić ponowne obliczenia?"])},
        "Cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anuluj"])},
        "Yes, recompute it!": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tak, oblicz ponownie!"])},
        "You need to define the Current Settings to generate a report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Musisz zdefiniować bieżące ustawienia, aby wygenerować raport"])},
        "OK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OK"])},
        "Total guns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pistolety ogółem"])},
        "Total guns per column": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Łączna liczba pistoletów na kolumnę"])},
        "Gun movement range": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zasięg ruchu pistoletu"])},
        "Gun Movement Speed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prędkość ruchu pistoletu"])},
        "Gun Movement Period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Okres ruchu pistoletu"])},
        "Save Optimization to Library": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zapisywanie optymalizacji w bibliotece"])},
        "Optimization Saved to Library": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optymalizacja zapisana w bibliotece"])},
        "optional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["opcjonalny"])},
        "Static Optimization Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statyczna nazwa optymalizacji"])},
        "New Optimization for line": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nowa optymalizacja dla linii"])},
        "Status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
        "Optimum found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Znaleziono optimum"])},
        "Ready to find optimum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gotowy do znalezienia optimum"])},
        "Static Optimization details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Szczegóły optymalizacji statycznej"])},
        "Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nazwa"])},
        "Line": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Linia"])},
        "Optimization type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Typ optymalizacji"])},
        "Select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wybór"])},
        "Powder Models": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modele proszków"])},
        "Calibrated Powder Optimization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skalibrowana optymalizacja proszku"])},
        "Pre-Calibrated Powder Optimization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wstępnie skalibrowana optymalizacja proszku"])},
        "Generalistic Optimization for any powder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optymalizacja ogólna dla dowolnego proszku"])},
        "Select Powder Model": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wybierz model proszku"])},
        "No Powder Models Created. To create one go to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nie utworzono modeli proszku. Aby go utworzyć, przejdź do"])},
        "Optimization parameters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parametry optymalizacji"])},
        "Line Speed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prędkość linii"])},
        "Distance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odległość"])},
        "Covered height": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pokryta wysokość"])},
        "Simulation width": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Szerokość symulacji"])},
        "Continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontynuuj"])},
        "Compare with Current Settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Porównaj z bieżącymi ustawieniami"])},
        "Number of active guns 1st column": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liczba aktywnych pistoletów 1. kolumna"])},
        "Please select one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wybierz jeden"])},
        "Number of active guns 2nd column": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liczba aktywnych pistoletów 2. kolumna"])},
        "Number of active guns 3rd column": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liczba aktywnych pistoletów 3. kolumna"])},
        "Number of active guns 4th column": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liczba aktywnych pistoletów 4. kolumna"])},
        "Number of active guns 5th column": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liczba aktywnych pistoletów 5. kolumna"])},
        "Guns movement range": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zasięg ruchu pistoletów"])},
        "Guns movement period time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Czas ruchu pistoletów"])},
        "Guns movement speed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prędkość ruchu pistoletów"])},
        "Compute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oblicz"])},
        "Optimal machine settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optymalne ustawienia maszyny"])},
        "Simulation results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wyniki symulacji"])},
        "Current Settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bieżące ustawienia"])},
        "Uniformity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jednorodność"])},
        "Median uniformity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jednorodność mediany"])},
        "Overspray": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nadmierny natrysk"])},
        "Optimal Settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optymalne ustawienia"])},
        "Overspray": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nadmierny natrysk"])},
        "Visualize graphs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wizualizacja wykresów"])},
        "Calculation running": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uruchamianie obliczeń"])},
        "Uniformity comparison": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Porównanie jednorodności"])},
        "Applied spray": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zastosowany natrysk"])},
        "Movement chart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wykres ruchu"])},
        "Applied spray animation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Animacja natrysku"])},
        "Current Settings Coated Area": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bieżące ustawienia Powlekany obszar"])},
        "Optimal Settings Coated Area": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ustawienia optymalne Obszar powlekany"])},
        "Current Settings Guns Movement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bieżące ustawienia Ruch pistoletów"])},
        "Optimal Settings Guns Movement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optymalne ustawienia ruchu pistoletów"])},
        "Current Settings Coated Area": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bieżące ustawienia Pokryty obszar"])},
        "Optimal Settings Coated Area": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ustawienia optymalne Obszar pokryty"])},
        "Comments section for the generated report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sekcja komentarzy do wygenerowanego raportu"])},
        "Hide Optimal Settings in the report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ukryj ustawienia optymalne w raporcie"])},
        "Save Edited Comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zapisz edytowane komentarze"])},
        "Generate Report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generowanie raportu"])},
        "Generating optimization report...": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generowanie raportów optymalizacji..."])},
        "To use a different optimization type create a new Optimization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aby użyć innego typu optymalizacji, utwórz nową optymalizację"])},
        "If you want to use a different set of Powder Models, create a new Optimization.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jeśli chcesz użyć innego zestawu modeli proszku, utwórz nową optymalizację."])},
        "Select Specific Powder optimization to use one or more powder models to compute the optimal settings. Select the Generalistic Optimization option to get a powder agnostic optimization solution.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wybierz opcję Specific Powder optimization, aby użyć jednego lub więcej modeli proszku do obliczenia optymalnych ustawień. Wybierz opcję Generalistic Optimization, aby uzyskać rozwiązanie optymalizacyjne niezależne od proszku."])},
        "Select the Powder Models to be used during the computation of optimal settings.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wybierz modele proszku, które mają być używane podczas obliczania optymalnych ustawień."])},
        "Line velocity value in [meters/minute] units.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wartość prędkości liniowej w jednostkach [metry/minutę]."])},
        "Distance from the gun nozzle to the substrate.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odległość od dyszy pistoletu do podłoża."])},
        "Vertical height for which the uniformity will be optimized.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wysokość pionowa, dla której zostanie zoptymalizowana jednorodność."])},
        "Only relevant for visualization purposes, determines the horizontal width, of the simulation image shown on the results section.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ma znaczenie tylko dla celów wizualizacji, określa szerokość poziomą obrazu symulacji wyświetlanego w sekcji wyników."])},
        "Fill this section with your Guns Settings to visualize how they compare with the optimal settings.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wypełnij tę sekcję ustawieniami broni, aby zobaczyć, jak wypadają one w porównaniu z ustawieniami optymalnymi."])},
        "Clear current settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wyczyść bieżące ustawienia"])},
        "Number of active guns on the first column of guns. The minimum is 0 and the maximum is": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liczba aktywnych pistoletów w pierwszej kolumnie pistoletów. Minimum to 0, a maksimum to"])},
        "Number of active guns on the second column of guns. The minimum is 0 and the maximum is": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liczba aktywnych pistoletów w drugiej kolumnie pistoletów. Minimalna wartość to 0, a maksymalna to"])},
        "Number of active guns on the third column of guns. The minimum is 0 and the maximum is": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liczba aktywnych pistoletów w trzeciej kolumnie pistoletów. Minimalna wartość to 0, a maksymalna to"])},
        "Number of active guns on the fourth column of guns. The minimum is 0 and the maximum is": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liczba aktywnych pistoletów w czwartej kolumnie pistoletów. Minimalna wartość to 0, a maksymalna to"])},
        "Number of active guns on the fifth column of guns. The minimum is 0 and the maximum is": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liczba aktywnych pistoletów w piątej kolumnie pistoletów. Minimalna wartość to 0, a maksymalna to"])},
        "Number of active guns on one side of the booth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liczba aktywnych pistoletów po jednej stronie kabiny"])},
        "Activate Toggle to visualize simulation images": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktywuj przełącznik, aby wyświetlić obrazy symulacji"])},
        "Gun to gun distance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odległość między Działami"])},
        "Optimal Settings Nozzle Angle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optymalne ustawienia kąta dyszy"])},
        "Current Settings Nozzle Angle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bieżące ustawienia kąta dyszy"])},
        "guns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pistolety"])},
        "Powder Usage Pie Chart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wykres kołowy zużycia proszku"])},
        "Optimal Settings Pie Chart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wykres kołowy optymalnych ustawień"])},
        "Current Settings Pie Chart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wykres kołowy bieżących ustawień"])},
        "Powder Waste": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odpady proszkowe"])}
      },
      "TR": {
        "": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
        "Overcoated powder:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Overcoated powder:"])},
        "Oversprayed powder:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oversprayed powder:"])},
        "The amount of extra powder applied because of an uneven powder distribution on the substrate.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alt tabaka üzerinde eşit olmayan toz dağılımı nedeniyle uygulanan ekstra toz miktarı."])},
        "The powder that does not stick to the part and is then not reclaimed by the recycling system.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parçaya yapışmayan ve daha sonra geri dönüşüm sistemi tarafından geri kazanılmayan toz."])},
        "Gun to Gun distance must be smaller than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tabanca-Tabanca mesafesi aşağıdakilerden daha küçük olmalıdır"])},
        "Gun to Gun distance must be greater than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tabanca-Tabanca mesafesi aşağıdakilerden daha büyük olmalıdır"])},
        "Gun to gun distance cannot be 0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tabancadan tabancaya mesafe 0 olamaz"])},
        "gun": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tabanca"])},
        "degrees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["dereceydi"])},
        "Gun column height offsets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tabanca kolon yüksekliği ofsetleri"])},
        "Nozzle rotation angle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nozul dönüş açısı"])},
        "The covered height, for the number of guns available, must be smaller than ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mevcut tabanca sayısı için kapalı yükseklik, aşağıdakilerden daha küçük olmalıdır "])},
        "Guns movement range cannot be greater than the maximum possible mechanical range of the line:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tabancaların hareket menzili, hattın mümkün olan maksimum mekanik menzilinden daha büyük olamaz:"])},
        "Loading optimization type...": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optimizasyon türü yükleniyor..."])},
        "Clear & start new optimization for line": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hat için yeni optimizasyonu temizleyin ve başlatın"])},
        "Please fill the following fields: ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lütfen aşağıdaki alanları doldurunuz: "])},
        "Line speed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hat hızı"])},
        "Coated height": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kaplamalı yükseklik"])},
        "Powder model": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toz modeli"])},
        "Specific Powder Optimization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spesifik Toz Optimizasyonu"])},
        "Input value out of range": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Giriş değeri aralık dışında"])},
        "was saved successfully.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["başarıyla kaydedildi."])},
        "Missing Required Fields": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eksik Gerekli Alanlar"])},
        "Guns movement range cannot be 0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tabancaların hareket menzili 0 olamaz"])},
        "Line speed cannot be 0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hat hızı 0 olamaz"])},
        "Line speed must be greater than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hat hızı aşağıdakilerden daha yüksek olmalıdır"])},
        "Line speed must be less than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hat hızı aşağıdakilerden daha az olmalıdır"])},
        "Covered height must be greater than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kaplanmış yükseklik aşağıdakilerden daha büyük olmalıdır"])},
        "Guns movement speed cannot be slower than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tabancaların hareket hızı aşağıdakilerden daha yavaş olamaz"])},
        "Use a faster guns velocity or a different movement range & period time combination.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Daha hızlı bir tabanca hızı veya farklı bir hareket aralığı ve süre kombinasyonu kullanın."])},
        "Guns movement speed cannot be faster than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tabanca hareket hızı aşağıdakilerden daha hızlı olamaz"])},
        "Use a slower guns velocity or a different movement range & period time combination.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Daha yavaş bir tabanca hızı veya farklı bir hareket aralığı ve süre kombinasyonu kullanın."])},
        "Guns movement period cannot be longer than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tabancanın hareket süresi şundan daha uzun olamaz"])},
        "Use a shorter guns period or a different movement range & period time combination.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Daha kısa bir tabanca süresi veya farklı bir hareket aralığı ve süre kombinasyonu kullanın."])},
        "Guns movement period must be greater than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tabanca hareket süresi aşağıdakilerden daha büyük olmalıdır"])},
        "No fields were changed from the previous optimization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Önceki optimizasyona göre hiçbir alan değiştirilmedi"])},
        "Do you want to recompute it anyway?": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yine de yeniden hesaplamak ister misiniz?"])},
        "Cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İptal et"])},
        "Yes, recompute it!": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evet, yeniden hesaplayın!"])},
        "You need to define the Current Settings to generate a report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bir rapor oluşturmak için Geçerli Ayarları tanımlamanız gerekir"])},
        "OK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TAMAM"])},
        "Total guns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toplam tabanca"])},
        "Total guns per column": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sütun başına toplam tabanca"])},
        "Gun movement range": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tabanca hareket aralığı"])},
        "Gun Movement Speed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tabanca Hareket Hızı"])},
        "Gun Movement Period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tabanca Hareket Dönemi"])},
        "Save Optimization to Library": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optimizasyonu Kütüphaneye Kaydet"])},
        "Optimization Saved to Library": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kütüphaneye Kaydedilen Optimizasyon"])},
        "optional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["isteğe bağlı"])},
        "Static Optimization Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statik Optimizasyon Adı"])},
        "New Optimization for line": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hat için Yeni Optimizasyon"])},
        "Status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durum"])},
        "Optimum found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optimum bulundu"])},
        "Ready to find optimum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optimum bulmaya hazır"])},
        "Static Optimization details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statik Optimizasyon ayrıntıları"])},
        "Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İsim"])},
        "Line": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hat"])},
        "Optimization type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optimizasyon türü"])},
        "Select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seçiniz"])},
        "Powder Models": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toz Modelleri"])},
        "Calibrated Powder Optimization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kalibre Edilmiş Toz Optimizasyonu"])},
        "Pre-Calibrated Powder Optimization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Önceden Kalibre Edilmiş Toz Optimizasyonu"])},
        "Generalistic Optimization for any powder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Herhangi bir toz için Genelistik Optimizasyon"])},
        "Select Powder Model": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toz Modelini Seçin"])},
        "No Powder Models Created. To create one go to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oluşturulmuş Toz Modeli Yok. Bir tane oluşturmak için şu adrese gidin"])},
        "Optimization parameters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optimizasyon parametreleri"])},
        "Line Speed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hat Hızı"])},
        "Distance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mesafe"])},
        "Covered height": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kapalı yükseklik"])},
        "Simulation width": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Simülasyon genişliği"])},
        "Continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Devam et"])},
        "Compare with Current Settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mevcut Ayarlarla Karşılaştırın"])},
        "Number of active guns 1st column": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktif tabanca sayısı 1. sütun"])},
        "Please select one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lütfen birini seçin"])},
        "Number of active guns 2nd column": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktif tabanca sayısı 2. sütun"])},
        "Number of active guns 3rd column": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktif tabanca sayısı 3. sütun"])},
        "Number of active guns 4th column": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktif tabanca sayısı 4. sütun"])},
        "Number of active guns 5th column": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktif tabanca sayısı 5. sütun"])},
        "Guns movement range": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tabancaların hareket aralığı"])},
        "Guns movement period time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tabancaların hareket süresi"])},
        "Guns movement speed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tabancaların hareket hızı"])},
        "Compute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hesaplama"])},
        "Optimal machine settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optimum makine ayarları"])},
        "Simulation results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Simülasyon sonuçları"])},
        "Current Settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geçerli Ayarlar"])},
        "Overspray": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aşırı püskürtme"])},
        "Optimal Settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optimal Ayarlar"])},
        "Uniformity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tekdüzelik"])},
        "Median uniformity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Medyan tekdüzeliği"])},
        "Overspray": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aşırı püskürtme"])},
        "Visualize graphs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grafikleri görselleştirin"])},
        "Calculation running": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Çalışan hesaplama"])},
        "Uniformity comparison": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tekdüzelik karşılaştırması"])},
        "Applied spray": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uygulanan sprey"])},
        "Movement chart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hareket tablosu"])},
        "Applied spray animation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uygulamalı sprey animasyonu"])},
        "Current Settings Coated Area": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mevcut Ayarlar Kaplamalı Alan"])},
        "Optimal Settings Coated Area": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optimum Ayarlar Kaplanmış Alan"])},
        "Current Settings Guns Movement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mevcut Ayarlar Tabanca Hareketi"])},
        "Optimal Settings Guns Movement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optimal Ayarlar Tabanca Hareketi"])},
        "Current Settings Coated Area": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mevcut Ayarlar Kaplamalı Alan"])},
        "Optimal Settings Coated Area": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optimum Ayarlar Kaplanmış Alan"])},
        "Comments section for the generated report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oluşturulan rapor için yorumlar bölümü"])},
        "Hide Optimal Settings in the report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Raporda Optimal Ayarları Gizle"])},
        "Save Edited Comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Düzenlenmiş Yorumları Kaydet"])},
        "Generate Report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rapor Oluştur"])},
        "Generating optimization report...": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optimizasyon raporu oluşturma..."])},
        "To use a different optimization type create a new Optimization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Farklı bir optimizasyon türü kullanmak için yeni bir Optimizasyon oluşturun"])},
        "If you want to use a different set of Powder Models, create a new Optimization.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Farklı bir Toz Modeli seti kullanmak istiyorsanız, yeni bir Optimizasyon oluşturun."])},
        "Select Specific Powder optimization to use one or more powder models to compute the optimal settings. Select the Generalistic Optimization option to get a powder agnostic optimization solution.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optimum ayarları hesaplamak üzere bir veya daha fazla toz modeli kullanmak için Spesifik Toz optimizasyonunu seçin. Tozdan bağımsız bir optimizasyon çözümü elde etmek için Genelistik Optimizasyon seçeneğini seçin."])},
        "Select the Powder Models to be used during the computation of optimal settings.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optimum ayarların hesaplanması sırasında kullanılacak Toz Modellerini seçin."])},
        "Line velocity value in [meters/minute] units.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Metre/dakika] biriminde hat hızı değeri."])},
        "Distance from the gun nozzle to the substrate.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tabanca nozülünden alt tabakaya olan mesafe."])},
        "Vertical height for which the uniformity will be optimized.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Homojenliğin optimize edileceği dikey yükseklik."])},
        "Only relevant for visualization purposes, determines the horizontal width, of the simulation image shown on the results section.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sadece görselleştirme amacıyla ilgili olup, sonuçlar bölümünde gösterilen simülasyon görüntüsünün yatay genişliğini belirler."])},
        "Fill this section with your Guns Settings to visualize how they compare with the optimal settings.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optimum ayarlarla nasıl karşılaştırıldıklarını görselleştirmek için bu bölümü Tabanca Ayarlarınızla doldurun."])},
        "Clear current settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geçerli ayarları temizle"])},
        "Number of active guns on the first column of guns. The minimum is 0 and the maximum is": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tabancaların ilk sütunundaki aktif tabanca sayısı. Minimum 0 ve maksimum"])},
        "Number of active guns on the second column of guns. The minimum is 0 and the maximum is": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tabancaların ikinci sütunundaki aktif tabanca sayısı. Minimum 0 ve maksimum"])},
        "Number of active guns on the third column of guns. The minimum is 0 and the maximum is": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tabancaların üçüncü sütunundaki aktif tabanca sayısı. Minimum 0 ve maksimum"])},
        "Number of active guns on the fourth column of guns. The minimum is 0 and the maximum is": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tabancaların dördüncü sütunundaki aktif tabanca sayısı. Minimum 0 ve maksimum"])},
        "Number of active guns on the fifth column of guns. The minimum is 0 and the maximum is": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tabancaların beşinci sütunundaki aktif tabanca sayısı. Minimum 0 ve maksimum"])},
        "Number of active guns on one side of the booth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kabinin bir tarafındaki aktif tabanca sayısı"])},
        "Activate Toggle to visualize simulation images": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Simülasyon görüntülerini görselleştirmek için Toggle özelliğini etkinleştirin"])},
        "Gun to gun distance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tabancadan tabancaya mesafe"])},
        "Optimal Settings Nozzle Angle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optimal Ayarlar Nozül Açısı"])},
        "Current Settings Nozzle Angle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geçerli Ayarlar Nozül Açısı"])},
        "guns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tabancalar"])},
        "Powder Usage Pie Chart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toz Kullanımı Pasta Grafiği"])},
        "Optimal Settings Pie Chart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optimal Ayarlar Pasta Grafiği"])},
        "Current Settings Pie Chart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mevcut Ayarlar Pasta Grafiği"])},
        "Powder Waste": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toz Atık"])}
      },
      "ZH": {
        "": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
        "Overcoated powder:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Overcoated powder:"])},
        "Oversprayed powder:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oversprayed powder:"])},
        "The amount of extra powder applied because of an uneven powder distribution on the substrate.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["由于粉末在基材上分布不均匀而多喷涂的粉末量。"])},
        "The powder that does not stick to the part and is then not reclaimed by the recycling system.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["未粘在部件上的粉末，回收系统无法回收。"])},
        "2nd column height offset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["第 2 栏高度偏移"])},
        "3rd column height offset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["第 3 栏高度偏移"])},
        "4th column height offset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["第 4 栏高度偏移"])},
        "5th column height offset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["第 5 栏高度偏移"])},
        "Gun to Gun distance must be smaller than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["手枪与手枪之间的距离必须小于"])},
        "Gun to Gun distance must be greater than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["手枪到手枪的距离必须大于"])},
        "Gun to gun distance cannot be 0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["手枪到手枪的距离不能为 0"])},
        "gun": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["枪"])},
        "degrees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["度"])},
        "Gun column height offsets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["枪柱高度偏移"])},
        "Nozzle rotation angle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["喷嘴旋转角度"])},
        "The covered height, for the number of guns available, must be smaller than ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["就现有手枪数量而言，覆盖高度必须小于 "])},
        "Guns movement range cannot be greater than the maximum possible mechanical range of the line:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["手枪的移动范围不能大于生产线的最大机械范围。:"])},
        "Loading optimization type...": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["加载优化类型..."])},
        "Clear & start new optimization for line": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["清除并重新优化生产线"])},
        "Please fill the following fields: ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请填写以下字段： "])},
        "Line speed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["生产线速度"])},
        "Coated height": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["涂层高度"])},
        "Powder model": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["粉末型号"])},
        "Specific Powder Optimization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["特定粉末优化"])},
        "Input value out of range": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["输入值超出范围"])},
        "was saved successfully.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已成功保存。"])},
        "Missing Required Fields": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["缺少必填字段"])},
        "Guns movement range cannot be 0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["手枪移动范围不能为 0"])},
        "Line speed cannot be 0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["线路速度不能为 0"])},
        "Line speed must be greater than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["行进速度必须大于"])},
        "Line speed must be less than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["线速度必须小于"])},
        "Covered height must be greater than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["涂层高度必须大于"])},
        "Guns movement speed cannot be slower than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["手枪移动速度不能低于"])},
        "Use a faster guns velocity or a different movement range & period time combination.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["使用更快的手枪速度或不同的移动范围和时间组合。"])},
        "Guns movement speed cannot be faster than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["手枪移动速度不能快于"])},
        "Use a slower guns velocity or a different movement range & period time combination.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["使用较慢的手枪速度或不同的移动范围和周期组合。"])},
        "Guns movement period cannot be longer than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["手枪移动时间不能长于"])},
        "Use a shorter guns period or a different movement range & period time combination.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["使用较短的手枪移动周期或不同的移动范围和周期组合。"])},
        "Guns movement period must be greater than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["手枪移动周期必须大于"])},
        "No fields were changed from the previous optimization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["与上次优化相比，没有更改字段"])},
        "Do you want to recompute it anyway?": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["还是要重新计算？"])},
        "Cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["取消"])},
        "Yes, recompute it!": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["是的，重新计算！"])},
        "You need to define the Current Settings to generate a report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您需要定义当前设置以生成报告"])},
        "OK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["确定"])},
        "Total guns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["手枪总数"])},
        "Total guns per column": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["每列手枪总数"])},
        "Gun movement range": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["手枪移动范围"])},
        "Gun Movement Speed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["手枪移动速度"])},
        "Gun Movement Period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["手枪移动周期"])},
        "Save Optimization to Library": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["将优化保存到资料库"])},
        "Optimization Saved to Library": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["保存到库的优化"])},
        "optional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["可选的"])},
        "Static Optimization Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["静态优化名称"])},
        "New Optimization for line": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["生产线的新优化"])},
        "Status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["状态"])},
        "Optimum found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["找到最佳状态"])},
        "Ready to find optimum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["准备查找最佳值"])},
        "Static Optimization details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["静态优化详细信息"])},
        "Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["优化名称"])},
        "Line": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["线路"])},
        "Optimization type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["优化类型"])},
        "Select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["选择"])},
        "Powder Models": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["粉末模型"])},
        "Calibrated Powder Optimization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["校准粉末优化"])},
        "Pre-Calibrated Powder Optimization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["预校准粉末优化"])},
        "Generalistic Optimization for any powder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["针对任何粉末的通用优化"])},
        "Select Powder Model": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["选择粉末模型"])},
        "No Powder Models Created. To create one go to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["未创建粉末模型。要创建粉末模型，请转到"])},
        "Optimization parameters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["优化参数"])},
        "Line Speed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["线速度"])},
        "Distance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["距离"])},
        "Covered height": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["覆盖高度"])},
        "Simulation width": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["模拟宽度"])},
        "Continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["继续"])},
        "Compare with Current Settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["与当前设置比较"])},
        "Number of active guns 1st column": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["活动手枪数量 第 1 栏"])},
        "Please select one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请选择一个"])},
        "Number of active guns 2nd column": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["活动手枪数量 第 2 栏"])},
        "Number of active guns 3rd column": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["活动手枪数量 第三栏"])},
        "Number of active guns 4th column": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["现役手枪数量 第 4 栏"])},
        "Number of active guns 5th column": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["现役手枪数量 第 5 栏"])},
        "Guns movement range": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["手枪移动范围"])},
        "Guns movement period time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["手枪移动时间"])},
        "Guns movement speed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["手枪移动速度"])},
        "Compute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["计算"])},
        "Optimal machine settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最佳机器设置"])},
        "Simulation results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["模拟结果"])},
        "Current Settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["当前设置"])},
        "Overspray": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["过喷"])},
        "Optimal Settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最佳设置"])},
        "Visualize graphs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["可视化图表"])},
        "Calculation running": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["计算运行"])},
        "Uniformity comparison": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["均匀度比较"])},
        "Median uniformity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["中位均匀性"])},
        "Applied spray": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["应用喷雾"])},
        "Movement chart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["运动图表"])},
        "Applied spray animation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["喷涂动画"])},
        "Current Settings Coated Area": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["当前设置 涂层面积"])},
        "Optimal Settings Coated Area": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最佳设置喷涂面积"])},
        "Current Settings Guns Movement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["当前设置手枪移动"])},
        "Optimal Settings Guns Movement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最佳设置手枪移动"])},
        "Current Settings Coated Area": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["当前设置涂装区域"])},
        "Optimal Settings Coated Area": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最佳设置涂层区域"])},
        "Comments section for the generated report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["生成报告的评论部分"])},
        "Hide Optimal Settings in the report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["在报告中隐藏最佳设置"])},
        "Save Edited Comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["保存已编辑的注释"])},
        "Generate Report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["生成报告"])},
        "Generating optimization report...": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["生成优化报告..."])},
        "To use a different optimization type create a new Optimization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["要使用不同的优化类型，请创建新优化"])},
        "If you want to use a different set of Powder Models, create a new Optimization.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["如果要使用一组不同的粉末模型，请创建一个新的优化。"])},
        "Select Specific Powder optimization to use one or more powder models to compute the optimal settings. Select the Generalistic Optimization option to get a powder agnostic optimization solution.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["选择特定粉末优化，使用一个或多个粉末模型来计算最佳设置。选择通用优化（Generalistic Optimization）选项，获得与粉末无关的优化解决方案。"])},
        "Select the Powder Models to be used during the computation of optimal settings.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["选择计算优化设置时要使用的粉末模型。"])},
        "Line velocity value in [meters/minute] units.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["线速度值，单位为 [米/分钟]。"])},
        "Distance from the gun nozzle to the substrate.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["喷枪喷嘴到基底的距离。"])},
        "Vertical height for which the uniformity will be optimized.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["将优化均匀性的垂直高度。"])},
        "Only relevant for visualization purposes, determines the horizontal width, of the simulation image shown on the results section.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["仅用于可视化目的，决定显示在结果部分的模拟图像的水平宽度。"])},
        "Fill this section with your Guns Settings to visualize how they compare with the optimal settings.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["在此部分填写您的喷枪设置，以便直观地显示它们与最佳设置的比较。"])},
        "Clear current settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["清除当前设置"])},
        "Number of active guns on the first column of guns. The minimum is 0 and the maximum is": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["第一列手枪中的活动手枪数量。最小值为 0，最大值为"])},
        "Number of active guns on the second column of guns. The minimum is 0 and the maximum is": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["第二列手枪的活动手枪数量。最小值为 0，最大值为"])},
        "Number of active guns on the third column of guns. The minimum is 0 and the maximum is": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["第三列已激活手枪的数量。最小值为 0，最大值为"])},
        "Number of active guns on the fourth column of guns. The minimum is 0 and the maximum is": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["第四列手枪中的现役手枪数量。最小值为 0，最大值为"])},
        "Number of active guns on the fifth column of guns. The minimum is 0 and the maximum is": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["第五列手枪中的现役手枪数量。最小值为 0，最大值为"])},
        "Number of active guns on one side of the booth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["展台一侧的活动手枪数量"])},
        "Activate Toggle to visualize simulation images": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["激活 切换 可视化模拟图像"])},
        "Gun to gun distance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["手枪到手枪的距离"])},
        "Optimal Settings Nozzle Angle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最佳设置喷嘴角度"])},
        "Current Settings Nozzle Angle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["当前设置喷嘴角度"])},
        "guns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["枪"])},
        "Powder Usage Pie Chart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["粉末用量饼图"])},
        "Optimal Settings Pie Chart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最佳设置饼图"])},
        "Current Settings Pie Chart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["当前设置饼图"])},
        "Powder Waste": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["粉末废料"])}
      }
    }
  })
}
