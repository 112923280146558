<template>
  <div class="py-1 container-fluid">
    <div class="mt-4 row">
      <div class="col-12">
        <div class="card-body mt-2">
          <h5 class="mb-0">
            {{ $t("Optimization List for line ")
            }}<b
              ><i>{{ line.name }}</i></b
            >
          </h5>
          <div v-if="specific_optimizations_list.length || general_optimizations_list.length">
            <div class="table-responsive">
              <table
                id="datatable-search"
                class="table table-flush table-hover"
              >
                <thead class="thead-light">
                  <tr>
                    <th
                      class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                      style="padding: 0.5rem 0.5rem"
                    >
                      {{ $t("Name") }}
                    </th>
                    <th
                      class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                      style="padding: 0.5rem 0.5rem"
                    >
                      {{ $t("Line Speed") }} [{{
                        this.$store.state.units_system[this.$store.state.user_data.unit_system].velocity_minutes
                      }}]
                    </th>
                    <th
                      class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                      style="padding: 0.5rem 0.5rem"
                    >
                      {{ $t("Distance") }} [{{
                        this.$store.state.units_system[this.$store.state.user_data.unit_system].distance
                      }}]
                    </th>
                    <th
                      class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                      style="padding: 0.5rem 0.5rem"
                    >
                      {{ $t("Coated Height") }} [{{
                        this.$store.state.units_system[this.$store.state.user_data.unit_system].distance
                      }}]
                    </th>
                    <th
                      class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                      style="padding: 0.5rem 0.5rem"
                    >
                      {{ $t("Updated") }}
                    </th>
                    <th
                      class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                      style="padding: 0.5rem 0.5rem"
                    >
                      {{ $t("Created") }}
                    </th>
                    <th
                      class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                      style="padding: 0.5rem 0.5rem"
                    >
                      {{ $t("Remove Optimization") }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="optimization in OrderByDateUpdated(optimizations_list)"
                    :key="optimization.id"
                  >
                    <td
                      class="text-sm font-weight-normal clickable"
                      style="cursor: pointer; vertical-align: middle"
                      :onclick="`window.location='/applications/flightpath/${
                        line.pistols_layout === 's' || line.pistols_layout === 'staticmixed'
                          ? 'staticlineoptimization'
                          : 'optimization'
                      }/${optimization.line}/${optimization.type}/${optimization.id}'`"
                    >
                      {{ optimization.name }}
                    </td>
                    <td
                      class="text-sm font-weight-normal clickable text-center"
                      style="cursor: pointer; vertical-align: middle"
                      :onclick="`window.location='/applications/flightpath/${
                        line.pistols_layout === 's' || line.pistols_layout === 'staticmixed'
                          ? 'staticlineoptimization'
                          : 'optimization'
                      }/${optimization.line}/${optimization.type}/${optimization.id}'`"
                    >
                      {{ getLineSpeed(optimization) }}
                    </td>
                    <td
                      class="text-sm font-weight-normal clickable text-center"
                      style="cursor: pointer; vertical-align: middle"
                      :onclick="`window.location='/applications/flightpath/${
                        line.pistols_layout === 's' || line.pistols_layout === 'staticmixed'
                          ? 'staticlineoptimization'
                          : 'optimization'
                      }/${optimization.line}/${optimization.type}/${optimization.id}'`"
                    >
                      {{ getPistolToSubstrateDistance(optimization) }}
                    </td>
                    <td
                      class="text-sm font-weight-normal clickable text-center"
                      style="cursor: pointer; vertical-align: middle"
                      :onclick="`window.location='/applications/flightpath/${
                        line.pistols_layout === 's' || line.pistols_layout === 'staticmixed'
                          ? 'staticlineoptimization'
                          : 'optimization'
                      }/${optimization.line}/${optimization.type}/${optimization.id}'`"
                    >
                      {{ getCoatedHeight(optimization) }}
                    </td>
                    <td
                      class="text-sm font-weight-normal clickable text-center"
                      style="cursor: pointer; vertical-align: middle"
                      :onclick="`window.location='/applications/flightpath/${
                        line.pistols_layout === 's' || line.pistols_layout === 'staticmixed'
                          ? 'staticlineoptimization'
                          : 'optimization'
                      }/${optimization.line}/${optimization.type}/${optimization.id}'`"
                    >
                      {{ dateToString(optimization.updated_at) }}
                    </td>
                    <td
                      class="text-sm font-weight-normal clickable text-center"
                      style="cursor: pointer; vertical-align: middle"
                      :onclick="`window.location='/applications/flightpath/${
                        line.pistols_layout === 's' || line.pistols_layout === 'staticmixed'
                          ? 'staticlineoptimization'
                          : 'optimization'
                      }/${optimization.line}/${optimization.type}/${optimization.id}'`"
                    >
                      {{ dateToString(optimization.created_at) }}
                    </td>
                    <td
                      class="text-sm font-weight-normal clickable"
                      :onclick="`deleteOptimization(${optimization.id}, '${optimization.name}', '${optimization.type}')`"
                      style="vertical-align: middle"
                    >
                      <a class="btn btn-link text-danger text-gradient px-3 mb-0 my-auto">
                        <i
                          class="far fa-trash-alt me-2"
                          aria-hidden="true"
                        ></i
                        >{{ $t("Delete") }}
                      </a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div v-else>
            <p>{{ $t("No Optimizations created.") }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { orderBy } from "lodash";
import { DataTable } from "simple-datatables";

export default {
  name: "OptimizationList",
  props: {
    lineId: {
      type: String,
      default: null,
    },
  },
  data() {
    this.$i18n.locale = this.$store.state.user_data.language;
    {
      return {
        specific_optimizations_list: [],
        general_optimizations_list: [],
        optimizations_list: [],
        line: [],
      };
    }
  },
  watch: {
    "$store.state.user_data.language"() {
      this.$i18n.locale = this.$store.state.user_data.language;
    },
  },
  mounted() {
    this.getData();
  },
  created() {
    window.deleteOptimization = this.deleteOptimization;
  },
  methods: {
    async getData() {
      await Promise.all([this.getLine(), this.getSpecificOptimizationsList(), this.getGeneralOptimizationsList()]);
      this.$store.state.isLoading = false;

      await this.concatenateAndFilterLists();
      // eslint-disable-next-line no-unused-vars
      const dataTableSearch = new DataTable("#datatable-search", {
        searchable: true,
        fixedHeight: false,
      });
    },
    async getLine() {
      try {
        let response = await axios.get("/api/v1/fp/line/" + this.lineId);
        this.line = response.data;
      } catch (error) {
        console.error(error);
        setTimeout(
          function () {
            this.getLine();
          }.bind(this),
          5000,
        );
      }
    },
    OrderByDateUpdated(array) {
      return orderBy(array, ["updated_at", "created_at"], ["desc"]);
    },
    async concatenateAndFilterLists() {
      this.optimizations_list = this.specific_optimizations_list
        .concat(this.general_optimizations_list)
        .filter(optimization => optimization.line == parseInt(this.lineId))
        .filter(optimization => optimization.library_display == true);
    },
    async getGeneralOptimizationsList() {
      await axios
        .get("/api/v1/fp/generalflightpathoptimization/")
        .then(response => {
          this.general_optimizations_list = response.data;
        })
        .catch(error => {
          console.error(error);
          setTimeout(
            function () {
              this.getGeneralOptimizationsList();
            }.bind(this),
            5000,
          );
        });
    },
    async getSpecificOptimizationsList() {
      await axios
        .get("/api/v1/fp/flightpathoptimization/")
        .then(response => {
          this.specific_optimizations_list = response.data;
        })
        .catch(error => {
          console.error(error);
          setTimeout(
            function () {
              this.getSpecificOptimizationsList();
            }.bind(this),
            5000,
          );
        });
    },
    getLineSpeed(flightpath) {
      if (this.$store.state.user_data.unit_system === "metric") {
        return parseFloat(flightpath.line_speed * 60).toFixed(2);
      } else if (this.$store.state.user_data.unit_system === "imperial") {
        return parseFloat(flightpath.line_speed * 60 * this.$store.state.conversion_factors.m_to_ft).toFixed(2);
      } else {
        return null;
      }
    },
    getPistolToSubstrateDistance(flightpath) {
      if (flightpath.pistol_to_substrate_distance === undefined) {
        return "-";
      }
      if (this.$store.state.user_data.unit_system === "metric") {
        return parseFloat(flightpath.pistol_to_substrate_distance * 100).toFixed(2);
      } else if (this.$store.state.user_data.unit_system === "imperial") {
        return parseFloat(
          flightpath.pistol_to_substrate_distance * 100 * this.$store.state.conversion_factors.cm_to_in,
        ).toFixed(2);
      } else {
        return null;
      }
    },
    getCoatedHeight(flightpath) {
      if (this.$store.state.user_data.unit_system === "metric") {
        return parseFloat(flightpath.coated_height * 100).toFixed(2);
      } else if (this.$store.state.user_data.unit_system === "imperial") {
        return parseFloat(flightpath.coated_height * 100 * this.$store.state.conversion_factors.cm_to_in).toFixed(2);
      } else {
        return null;
      }
    },
    async deleteOptimization(optimization_id, optimization_name, optimization_type) {
      this.$swal({
        title: `${this.$t("Are you sure you want to delete Optimization")} ${optimization_name}?`,
        text: `${this.$t("You will not be able to revert this!")}`,
        showCancelButton: true,
        confirmButtonText: `${this.$t("Yes, delete it")}`,
        cancelButtonText: `${this.$t("No, cancel")}`,
        reverseButtons: true,
        customClass: {
          confirmButton: "btn bg-gradient-success",
          cancelButton: "btn bg-gradient-danger",
        },
        buttonsStyling: false,
      }).then(async result => {
        if (result.isConfirmed) {
          this.$store.state.isLoading = true;
          if (optimization_type == "general") {
            await axios
              .patch("/api/v1/fp/generalflightpathoptimization/" + optimization_id + "/", { library_display: false })
              .then(() => {
                this.$store.state.isLoading = false;
                this.optimizationDeletedSweetAlert();
              })
              .catch(error => {
                console.log(error);
              });
          } else if (optimization_type == "specific") {
            await axios
              .patch("/api/v1/fp/flightpathoptimization/" + optimization_id + "/", { library_display: false })
              .then(() => {
                this.$store.state.isLoading = false;
                this.optimizationDeletedSweetAlert();
              })
              .catch(error => {
                console.log(error);
              });
          }
        }
      });
    },
    async optimizationDeletedSweetAlert() {
      this.$swal({
        title: `${this.$t("Deleted")}`,
        text: `${this.$t("Your optimization has been deleted.")}`,
        icon: "success",
        showCancelButton: false,
        confirmButtonText: "OK",
        customClass: {
          confirmButton: "btn bg-gradient-success",
        },
        buttonsStyling: false,
      }).then(async result => {
        if (result.isConfirmed) {
          window.location.reload(true);
        }
      });
    },
    dateToString(date) {
      const date_object = new Date(date);
      if (this.$store.state.user_data.unit_system == "metric") {
        return date_object.toLocaleString("en-GB", {
          year: "numeric",
          month: "numeric",
          day: "numeric",
          hour: "2-digit",
          minute: "2-digit",
        });
      } else if (this.$store.state.user_data.unit_system == "imperial") {
        return date_object.toLocaleString("en-US", {
          year: "numeric",
          month: "numeric",
          day: "numeric",
          hour: "2-digit",
          minute: "2-digit",
        });
      } else {
        return null;
      }
    },
  },
};
</script>

<i18n>
  {
    "EN": {
        "Optimization List for line ":"Optimization List for line ",
        "Name":"Name",
        "Line Speed":"Line Speed",
        "Distance":"Distance",
        "Coated Height":"Coated Height",
        "Updated":"Updated",
        "Created":"Created",
        "Remove Optimization":"Remove Optimization",
        "Are you sure you want to delete Optimization":"Are you sure you want to delete Optimization",
        "You will not be able to revert this!":"You will not be able to revert this!",
        "Yes, delete it":"Yes, delete it",
        "No, cancel":"No, cancel",
        "Deleted":"Deleted",
        "Your optimization has been deleted.":"Your optimization has been deleted.",
        "Delete":"Delete",
        "No Optimizations created.":"No Optimizations created.",
    },
    "DE": {
        "Optimization List for line ":"Optimierungsliste für die linie ",
        "Name":"Name",
        "Line Speed":"Kettengeschwindigkeit",
        "Distance":"Entfernung",
        "Coated Height":"Beschichtete Höhe",
        "Updated":"Aktualisiert",
        "Created":"Erstellt",
        "Remove Optimization":"Optimierung Entfernen",
        "Are you sure you want to delete Optimization":"Sind Sie sicher, dass Sie die Optimierung löschen möchten",
        "You will not be able to revert this!":"Sie können dies nicht rückgängig machen!",
        "Yes, delete it":"Ja, lösche es",
        "No, cancel":"Nein, abbrechen",
        "Deleted":"Gelöscht",
        "Your optimization has been deleted.":"Ihre Optimierung wurde gelöscht.",
        "Delete":"Löschen",
        "No Optimizations created.":"Keine Optimierungen erstellt.",
    },
    "ES": {
        "Optimization List for line ":"Lista de Optimizaciónes de la línea ",
        "Name":"Nombre",
        "Line Speed":"Velocidad de la Línea",
        "Distance":"Distancia",
        "Coated Height":"Altura Recubrimiento",
        "Updated":"Actualizada",
        "Created":"Creada",
        "Remove Optimization":"Eliminar Optimización",
        "Are you sure you want to delete Optimization":"¿Estás seguro de que quieres eliminar la optimización",
        "You will not be able to revert this!":"¡No podrás deshacer esto!",
        "Yes, delete it":"Sí, bórrala",
        "No, cancel":"No, cancelar",
        "Deleted":"Eliminada",
        "Your optimization has been deleted.":"Su optimización ha sido eliminada.",
        "Delete":"Eliminar",
        "No Optimizations created.":"No existe ninguna optimización",
    },
    "FR": {
        "Optimization List for line ":"Liste des Optimisations pour la ligne ",
        "Name":"Nom",
        "Line Speed":"Vitesse de la Ligne",
        "Distance":"Distance",
        "Coated Height":"Hauteur Revêtu",
        "Updated":"Mise à jour",
        "Created":"Créée",
        "Remove Optimization":"Supprimer l'Optimisation",
        "Are you sure you want to delete Optimization":"Êtes-vous sûr de vouloir supprimer l'optimisation",
        "You will not be able to revert this!":"Vous ne pourrez pas revenir en arrière!",
        "Yes, delete it":"Oui, supprimez-le",
        "No, cancel":"Non, annuler",
        "Deleted":"Supprimé",
        "Your optimization has been deleted.":"Votre optimisation a été supprimée.",
        "Delete":"Effacer",
        "No Optimizations created.":"Aucune optimisation créée.",
    },
    "IT": {
        "Optimization List for line ":"Lista di Ottimizzazioni per la linea ",
        "Name":"Nome",
        "Line Speed":"Velocitá della Linea",
        "Distance":"Distanza",
        "Coated Height":"Altezza Rivestita",
        "Updated":"Aggiornata",
        "Created":"Creata",
        "Remove Optimization":"Rimuovere l'Ottimizzazione",
        "Are you sure you want to delete Optimization":"Sei sicuro di voler eliminare l'ottimizzazione",
        "You will not be able to revert this!":"Non sarai in grado di annullare questa operazione!",
        "Yes, delete it":"Sì, cancellalo",
        "No, cancel":"No, annulla",
        "Deleted":"Eliminato",
        "Your optimization has been deleted.":"La tua ottimizzazione è stata eliminata.",
        "Delete":"Elimina",
        "No Optimizations created.":"Nessuna ottimizzazione creata.",
    },
    "PL": {
        "Optimization List for line ":"Lista optymalizacji dla linii ",
        "Name":"Nazwa",
        "Line Speed":"Prędkość Linii",
        "Distance":"Odległość",
        "Coated Height":"Wysokość Powłoki",
        "Updated":"Aktualizacja",
        "Created":"Utworzony",
        "Remove Optimization":"Usuń optymalizację",
        "Are you sure you want to delete Optimization":"Czy na pewno chcesz usunąć optymalizację",
        "You will not be able to revert this!":"Nie będziesz mógł tego cofnąć!",
        "Yes, delete it":"Tak, usuń to",
        "No, cancel":"Nie, anuluj",
        "Deleted":"Usunięty",
        "Your optimization has been deleted.":"Twoja optymalizacja została usunięta.",
        "Delete":"Kasować",
        "No Optimizations created.":"Nie utworzono optymalizacji.",
    },
    "TR": {
        "Optimization List for line ":"Hattı için Optimizasyonlar listesi ",
        "Name":"İsim",
        "Line Speed":"Hat Hızı",
        "Distance":"Mesafe",
        "Coated Height":"Kaplamalı Yükseklik",
        "Updated":"Güncellenmiş",
        "Created":"Oluşturuldu",
        "Remove Optimization":"Optimizasyonu Kaldır",
        "Are you sure you want to delete Optimization":"Optimizasyonu silmek istediğinizden emin misiniz?",
        "You will not be able to revert this!":"Bunu geri alamayacaksın!",
        "Yes, delete it":"Evet, sil",
        "No, cancel":"Hayır, iptal et",
        "Deleted":"Silindi",
        "Your optimization has been deleted.":"Optimizasyonunuz silindi.",
        "Delete":"Sil",
        "No Optimizations created.":"Oluşturulan Optimizasyon Yok.",
    },
    "ZH": {
        "Optimization List for line ":"线路优化列表 ",
        "Name":"名称",
        "Line Speed":"线路速度",
        "Distance":"距离",
        "Coated Height":"涂布高度",
        "Updated":"已更新",
        "Created":"创建",
        "Remove Optimization":"删除优化",
        "Are you sure you want to delete Optimization":"您确定要删除优化吗",
        "You will not be able to revert this!":"您将无法撤消此操作！",
        "Yes, delete it":"是的，删除它",
        "No, cancel":"不，取消",
        "Deleted":"已删除",
        "Your optimization has been deleted.":"您的优化已被删除。",
        "Delete":"删除",
        "No Optimizations created.":"未创建优化。",
    }
  }
</i18n>
