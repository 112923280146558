export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "EN": {
        "Language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Language"])},
        "Unit System": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unit System"])},
        "metric": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["metric"])},
        "imperial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["imperial"])},
        "Currency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Currency"])},
        "Powder Savings Calculation Created Successfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Powder Savings Calculation Created Successfully"])},
        "A report on the potential powder savings is going to be generated and sent to your email. You will receive it in a few minutes.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A report on the potential powder savings is going to be generated and sent to your email. You will receive it in a few minutes."])},
        "Contact us if you do not receive it at ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact us if you do not receive it at "])},
        "Powder Savings Calculator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Powder Savings Calculator"])},
        "Calculate your potential powder savings in a few simple steps": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calculate your potential powder savings in a few simple steps"])},
        "User Information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User Information"])},
        "Line Settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Line Settings"])},
        "Machine Settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Machine Settings"])},
        "Your Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your Name"])},
        "Email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
        "Company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company"])},
        "Yearly Powder Consumption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yearly Powder Consumption"])},
        "Do you have a recycling powder system?": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you have a recycling powder system?"])},
        "Yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes"])},
        "No": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])},
        "Recycling System Efficiency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recycling System Efficiency"])},
        "Optional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optional"])},
        "Next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Next"])},
        "Previous": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Previous"])},
        "Your Line Setup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your Line Setup"])},
        "Number of Columns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of Columns"])},
        "Column Distance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Column Distance"])},
        "Column Shift": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Column Shift"])},
        "Number of Rows": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of Rows"])},
        "Row Distance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Row Distance"])},
        "Row Shift": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Row Shift"])},
        "Gun Max Movement Range": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gun Max Movement Range"])},
        "Gun Min Velocity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gun Min Velocity"])},
        "Gun Max Velocity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gun Max Velocity"])},
        "Current Machine Settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current Machine Settings"])},
        "Height of the Part Coated with this Line Settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Height of the Part Coated with this Line Settings"])},
        "Line Speed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Line Speed"])},
        "Gun Movement Range": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gun Movement Range"])},
        "Gun Movement Period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gun Movement Period"])},
        "Time that it takes for the pistol to go from the highest point to the lowest and back to the highest point.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Time that it takes for the pistol to go from the highest point to the lowest and back to the highest point."])},
        "Fill first the line setup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fill first the line setup"])},
        "Fill first the gun movement range": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fill first the gun movement range"])},
        "Create Powder Savings Report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create Powder Savings Report"])},
        "Input value out of range": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Input value out of range"])},
        "Number of columns cannot be greater than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of columns cannot be greater than"])},
        "Number of rows cannot be greater than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of rows cannot be greater than"])},
        "Column distance must be greater than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Column distance must be greater than"])},
        "Column distance must be smaller than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Column distance must be smaller than"])},
        "Row distance must be greater than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Row distance must be greater than"])},
        "Row distance must be smaller than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Row distance must be smaller than"])},
        "Column Shift must be greater than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Column Shift must be greater than"])},
        "Column Shift must be smaller than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Column Shift must be smaller than"])},
        "Row Shift must be greater than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Row Shift must be greater than"])},
        "Row Shift must be smaller than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Row Shift must be smaller than"])},
        "Gun Max Movement Range must be greater than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gun Max Movement Range must be greater than"])},
        "Gun Max Movement Range must be smaller than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gun Max Movement Range must be smaller than"])},
        "Gun Min Velocity must be greater than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gun Min Velocity must be greater than"])},
        "Gun Min Velocity must be smaller than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gun Min Velocity must be smaller than"])},
        "Gun Max Velocity must be greater than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gun Max Velocity must be greater than"])},
        "Gun Max Velocity must be smaller than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gun Max Velocity must be smaller than"])},
        "Height of the Part Coated with this Line Settings must be greater than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Height of the Part Coated with this Line Settings must be greater than"])},
        "Height of the Part Coated with this Line Settings must be smaller than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Height of the Part Coated with this Line Settings must be smaller than"])},
        "For the line settings defined, you cannnot obtain good results for this part height. Should be smaller than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For the line settings defined, you cannnot obtain good results for this part height. Should be smaller than"])},
        "Line Speed must be greater than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Line Speed must be greater than"])},
        "Line Speed must be smaller than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Line Speed must be smaller than"])},
        "Gun Movement Range cannot be negative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gun Movement Range cannot be negative"])},
        "Gun Movement Range must be smaller than the mechanical range of the line:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gun Movement Range must be smaller than the mechanical range of the line:"])},
        "Gun Movement Period cannot be longer than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gun Movement Period cannot be longer than"])},
        "Gun Movement Period cannot be less than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gun Movement Period cannot be less than"])},
        "Missing Required Fields": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Missing Required Fields"])},
        "The following fields are required:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The following fields are required:"])},
        "Gun Min Velocity cannot be greater than or equal to the Gun Max Velocity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gun Min Velocity cannot be greater than or equal to the Gun Max Velocity"])},
        "tonnes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tonnes"])},
        "tons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tons"])},
        "Email Already Registered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email Already Registered"])},
        "This email has already been used to generate a powder saving report. If this is a mistake or you need another Powder Saving Report, contact us at ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This email has already been used to generate a powder saving report. If this is a mistake or you need another Powder Saving Report, contact us at "])},
        "If you liked what you saw and you came for more, check ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you liked what you saw and you came for more, check "])},
        "and get your quote.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["and get your quote."])},
        "If you don't receive it contact us at ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you don't receive it contact us at "])},
        "Lines with 1 gun per column are not allowed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lines with 1 gun per column are not allowed"])},
        "For Mixed Lines the number of gun columns must be smaller than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For Mixed Lines the number of gun columns must be smaller than"])},
        "The total number of guns must be smaller than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The total number of guns must be smaller than"])},
        "Distance between highest and lowest point of one moving gun": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distance between highest and lowest point of one moving gun"])},
        "Time it takes for one gun to move from the lowest point to the highest point and back.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Time it takes for one gun to move from the lowest point to the highest point and back."])}
      },
      "DE": {
        "Language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sprache"])},
        "Unit System": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einheitensystem"])},
        "metric": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["metrisch"])},
        "imperial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["imperial"])},
        "Currency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Währung"])},
        "Powder Savings Calculation Created Successfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pulvereinsparungberechnung erfolgreich erstellt"])},
        "A report on the potential powder savings is going to be generated and sent to your email. You will receive it in a few minutes.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ein Bericht über das mögliche Pulverersparnis wird generiert und an Ihre E-Mail gesendet. Sie erhalten es in wenigen Minuten."])},
        "Contact us if you do not receive it at ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontaktieren Sie uns, wenn Sie es nicht erhalten unter "])},
        "Powder Savings Calculator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pulvereinsparungenrechner"])},
        "Calculate your potential powder savings in a few simple steps": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Berechnen Sie Ihre möglichen Pulverersparnisse in wenigen einfachen Schritten"])},
        "User Information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzerinformation"])},
        "Line Settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Linien Einstellungen"])},
        "Machine Settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maschineneinstellungen"])},
        "Your Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihr Name"])},
        "Email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
        "Company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unternehmen"])},
        "Yearly Powder Consumption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jährlicher Pulververbrauch"])},
        "Do you have a recycling powder system?": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Haben Sie ein Recycling-Pulversystem?"])},
        "Yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ja"])},
        "No": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nein"])},
        "Recycling System Efficiency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Effizienz des Recycling-Systems"])},
        "Optional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optional"])},
        "Next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nächster"])},
        "Previous": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorherige"])},
        "Your Line Setup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihre Linienaufstellung"])},
        "Number of Columns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anzahl der Spalten"])},
        "Column Distance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spaltenabstand"])},
        "Column Shift": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spaltenverschiebung"])},
        "Number of Rows": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anzahl der Zeilen"])},
        "Row Distance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zeilenabstand"])},
        "Row Shift": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zeilenverschiebung"])},
        "Gun Max Movement Range": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maximaler Bewegungsbereich des Hubgeräts"])},
        "Gun Min Velocity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Waffen Min Geschwindigkeit"])},
        "Gun Max Velocity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Waffen Max Geschwindigkeit"])},
        "Current Machine Settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktuelle Maschineneinstellungen"])},
        "Height of the Part Coated with this Line Settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Höhe des mit dieser Linie beschichteten Teils"])},
        "Line Speed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kettengeschwindigkeit"])},
        "Gun Movement Range": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bewegungsbereich des Hubgeräts"])},
        "Gun Movement Period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bewegungsgeschwindigkeit des Hubgeräts"])},
        "Time that it takes for the pistol to go from the highest point to the lowest and back to the highest point.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zeit, die die Pistole benötigt, um vom höchsten Punkt zum niedrigsten und zurück zum höchsten Punkt zu gelangen."])},
        "Fill first the line setup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Füllen Sie zuerst das Line Setup aus"])},
        "Fill first the gun movement range": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Füllen Sie zuerst den Bewegungsbereich des Reziprokators aus"])},
        "Create Powder Savings Report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erstellen Sie einen Pulverersparnisbericht"])},
        "Input value out of range": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eingabewert außerhalb des Bereichs"])},
        "Number of columns cannot be greater than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anzahl der Spalten darf nicht größer sein als"])},
        "Number of rows cannot be greater than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anzahl der Zeilen darf nicht größer sein als"])},
        "Column distance must be greater than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spaltenabstand muss größer sein als"])},
        "Column distance must be smaller than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spaltenabstand muss kleiner sein als"])},
        "Row distance must be greater than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zeilenabstand muss größer sein als"])},
        "Row distance must be smaller than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zeilenabstand muss kleiner sein als"])},
        "Column Shift must be greater than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spaltenverschiebung muss größer sein als"])},
        "Column Shift must be smaller than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spaltenverschiebung muss kleiner sein als"])},
        "Row Shift must be greater than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zeilenverschiebung muss größer sein als"])},
        "Row Shift must be smaller than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zeilenverschiebung muss kleiner sein als"])},
        "Gun Max Movement Range must be greater than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maximaler Bewegungsbereich des Hubgeräts muss größer sein als"])},
        "Gun Max Movement Range must be smaller than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maximaler Bewegungsbereich des Hubgeräts muss kleiner sein als"])},
        "Gun Min Velocity must be greater than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Waffen Min Geschwindigkeit muss größer sein als"])},
        "Gun Min Velocity must be smaller than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Waffen Min Geschwindigkeit muss kleiner sein als"])},
        "Gun Max Velocity must be greater than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Waffen Max Geschwindigkeit muss größer sein als"])},
        "Gun Max Velocity must be smaller than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Waffen Max Geschwindigkeit muss kleiner sein als"])},
        "Height of the Part Coated with this Line Settings must be greater than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Höhe des mit dieser Linie beschichteten Teils muss größer sein als"])},
        "Height of the Part Coated with this Line Settings must be smaller than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Höhe des mit dieser Linie beschichteten Teils muss kleiner sein als"])},
        "For the line settings defined, you cannnot obtain good results for this part height. Should be smaller than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Für die definierten Linienparameter können Sie für diese Teilehöhe keine guten Ergebnisse erzielen. Sollte kleiner sein als"])},
        "Line Speed must be greater than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kettengeschwindigkeit muss größer sein als"])},
        "Line Speed must be smaller than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kettengeschwindigkeit muss kleiner sein als"])},
        "Gun Movement Range cannot be negative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bewegungsbereich des Hubgeräts kann nicht negativ sein"])},
        "Gun Movement Range must be smaller than the mechanical range of the line:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bewegungsbereich des Hubgeräts muss kleiner sein als der mechanische Bereich der Linie:"])},
        "Gun Movement Period cannot be longer than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bewegungsgeschwindigkeit des Hubgeräts darf nicht länger sein als"])},
        "Gun Movement Period cannot be less than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bewegungsgeschwindigkeit des Hubgeräts darf nicht weniger als"])},
        "Missing Required Fields": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fehlende Pflichtfelder"])},
        "The following fields are required:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die folgenden Felder sind erforderlich:"])},
        "Gun Min Velocity cannot be greater than or equal to the Gun Max Velocity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Waffen Min Geschwindigkeit darf nicht größer oder gleich der Waffen Max Geschwindigkeit sein"])},
        "tonnes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tonnen"])},
        "tons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tonnen"])},
        "Email Already Registered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail bereits registriert"])},
        "This email has already been used to generate a powder saving report. If this is a mistake or you need another Powder Saving Report, contact us at ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diese E-Mail wurde bereits verwendet, um einen Pulverersparnisbericht zu erstellen. Wenn dies ein Fehler ist oder Sie einen anderen Pulverersparnisbericht benötigen, kontaktieren Sie uns unter "])},
        "If you liked what you saw and you came for more, check ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wenn Ihnen gefallen hat, was Sie gesehen haben und Sie mehr wollten, überprüfen Sie "])},
        "and get your quote.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["und erhalten Sie Ihr Angebot."])},
        "If you don't receive it contact us at ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wenn Sie es nicht erhalten, kontaktieren Sie uns unter "])},
        "Lines with 1 gun per column are not allowed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Linien mit 1 Pistole pro Spalte sind nicht zulässig"])},
        "For Mixed Lines the number of gun columns must be smaller than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bei Mixed Lines muss die Anzahl der Pistolenspalten kleiner sein als"])},
        "The total number of guns must be smaller than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Gesamtzahl der Waffen muss kleiner sein als"])},
        "Distance between highest and lowest point of one moving gun": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abstand zwischen höchstem und niedrigstem Punkt einer beweglichen Waffe"])},
        "Time it takes for one gun to move from the lowest point to the highest point and back.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zeit, die eine Waffe benötigt, um vom niedrigsten Punkt zum höchsten Punkt und zurück zu gelangen."])}
      },
      "ES": {
        "Language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Idioma"])},
        "Unit System": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sistema de Unidades"])},
        "metric": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["métrico"])},
        "imperial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["imperial"])},
        "Currency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moneda"])},
        "Powder Savings Calculation Created Successfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cálculo de ahorro de pintura en polvo creado con éxito"])},
        "A report on the potential powder savings is going to be generated and sent to your email. You will receive it in a few minutes.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se generará un informe sobre el potencial de ahorro de pintura en polvo y se enviará a su correo electrónico. Lo recibirá en unos minutos."])},
        "Contact us if you do not receive it at ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contáctenos si no lo recibe en "])},
        "Powder Savings Calculator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calculadora de ahorro de pintura en polvo"])},
        "Calculate your potential powder savings in a few simple steps": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calcule sus posibles ahorros de pintura en polvo en unos sencillos pasos"])},
        "User Information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Información del usuario"])},
        "Line Settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configuración de la línea"])},
        "Machine Settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configuración de la máquina"])},
        "Your Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tu nombre"])},
        "Email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
        "Company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Empresa"])},
        "Yearly Powder Consumption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consumo anual de pintura en polvo"])},
        "Do you have a recycling powder system?": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Tiene un sistema de reciclaje de pintura en polvo?"])},
        "Yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sí"])},
        "No": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])},
        "Recycling System Efficiency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eficiencia del sistema de reciclaje"])},
        "Optional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opcional"])},
        "Next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Siguiente"])},
        "Previous": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anterior"])},
        "Your Line Setup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Su configuración de línea"])},
        "Number of Columns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de columnas"])},
        "Column Distance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distancia entre columnas"])},
        "Column Shift": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desplazamiento de columnas"])},
        "Number of Rows": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de filas"])},
        "Row Distance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distancia entre filas"])},
        "Row Shift": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desplazamiento de filas"])},
        "Gun Max Movement Range": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rango de movimiento máximo del pistola"])},
        "Gun Min Velocity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Velocidad mínima del pistola"])},
        "Gun Max Velocity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Velocidad máxima del pistola"])},
        "Current Machine Settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configuración actual de la máquina"])},
        "Height of the Part Coated with this Line Settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Altura de la pieza recubierta con esta configuración de línea"])},
        "Line Speed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Velocidad de la línea"])},
        "Gun Movement Range": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rango de movimiento del pistola"])},
        "Gun Movement Period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Periodo de movimiento del pistola"])},
        "Time that it takes for the pistol to go from the highest point to the lowest and back to the highest point.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tiempo que tarda la pistola en ir desde el punto más alto hasta el más bajo y volver al punto más alto."])},
        "Fill first the line setup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Complete primero la configuración de la línea"])},
        "Fill first the gun movement range": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Complete primero el rango de movimiento del pistola"])},
        "Create Powder Savings Report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crear informe de ahorro de pintura en polvo"])},
        "Input value out of range": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valor introducido fuera de rango"])},
        "Number of columns cannot be greater than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El número de columnas no puede ser mayor que"])},
        "Number of rows cannot be greater than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El número de filas no puede ser mayor que"])},
        "Column distance must be greater than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La distancia entre columnas debe ser mayor que"])},
        "Column distance must be smaller than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La distancia entre columnas debe ser menor que"])},
        "Row distance must be greater than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La distancia entre filas debe ser mayor que"])},
        "Row distance must be smaller than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La distancia entre filas debe ser menor que"])},
        "Column Shift must be greater than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El desplazamiento de columnas debe ser mayor que"])},
        "Column Shift must be smaller than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El desplazamiento de columnas debe ser menor que"])},
        "Row Shift must be greater than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El desplazamiento de filas debe ser mayor que"])},
        "Row Shift must be smaller than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El desplazamiento de filas debe ser menor que"])},
        "Gun Max Movement Range must be greater than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El rango de movimiento máximo del pistola debe ser mayor que"])},
        "Gun Max Movement Range must be smaller than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El rango de movimiento máximo del pistola debe ser menor que"])},
        "Gun Min Velocity must be greater than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La velocidad mínima del pistola debe ser mayor que"])},
        "Gun Min Velocity must be smaller than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La velocidad mínima del pistola debe ser menor que"])},
        "Gun Max Velocity must be greater than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La velocidad máxima del pistola debe ser mayor que"])},
        "Gun Max Velocity must be smaller than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La velocidad máxima del pistola debe ser menor que"])},
        "Height of the Part Coated with this Line Settings must be greater than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La altura de la parte recubierta con esta configuración de línea debe ser mayor que"])},
        "Height of the Part Coated with this Line Settings must be smaller than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La altura de la parte recubierta con esta configuración de línea debe ser menor que"])},
        "For the line settings defined, you cannnot obtain good results for this part height. Should be smaller than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Para la configuración de línea definida, no puede obtener buenos resultados para esta altura de parte. Debería ser menor que"])},
        "Line Speed must be greater than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La velocidad de la línea debe ser mayor que"])},
        "Line Speed must be smaller than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La velocidad de la línea debe ser menor que"])},
        "Gun Movement Range cannot be negative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El rango de movimiento del pistola no puede ser negativo"])},
        "Gun Movement Range must be smaller than the mechanical range of the line:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El rango de movimiento del pistola debe ser menor que el rango mecánico de la línea:"])},
        "Gun Movement Period cannot be longer than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El período de movimiento del pistola no puede ser más largo que"])},
        "Gun Movement Period cannot be less than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El período de movimiento del pistola no puede ser menor que"])},
        "Missing Required Fields": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Campos Requeridos"])},
        "The following fields are required:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Los siguientes campos son requeridos:"])},
        "Gun Min Velocity cannot be greater than or equal to the Gun Max Velocity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La velocidad mínima del pistola no puede ser mayor o igual que la velocidad máxima del pistola"])},
        "tonnes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["toneladas"])},
        "tons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["toneladas cortas"])},
        "Email Already Registered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correo electrónico ya registrado"])},
        "This email has already been used to generate a powder saving report. If this is a mistake or you need another Powder Saving Report, contact us at ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Este correo electrónico ya se ha utilizado para generar un informe de ahorro de pintura en polvo. Si esto es un error o necesita otro informe de ahorro de pintura en polvo, contáctenos en "])},
        "If you liked what you saw and you came for more, check ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si le gustó lo que vió y vino por más, revise "])},
        "and get your quote.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["y obtén tu presupuesto."])},
        "If you don't receive it contact us at ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si no lo recibe, contáctenos en "])},
        "Lines with 1 gun per column are not allowed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se permiten líneas con 1 pistola por columna"])},
        "For Mixed Lines the number of gun columns must be smaller than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Para líneas mixtas, el número de columnas de pistola debe ser menor que"])},
        "The total number of guns must be smaller than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El número total de pistolas debe ser menor que"])},
        "Distance between highest and lowest point of one moving gun": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distancia entre el punto más alto y el punto más bajo de una pistola en movimiento"])},
        "Time it takes for one gun to move from the lowest point to the highest point and back.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tiempo que tarda una pistola en moverse desde el punto más bajo hasta el punto más alto y regresar"])}
      },
      "FR": {
        "Language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Langue"])},
        "Unit System": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Système d'unités"])},
        "metric": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["métrique"])},
        "imperial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["impérial"])},
        "Currency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Devise"])},
        "Powder Savings Calculation Created Successfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calcul des économies de poudre créé avec succès"])},
        "A report on the potential powder savings is going to be generated and sent to your email. You will receive it in a few minutes.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un rapport sur les économies potentielles de poudre va être généré et envoyé à votre adresse e-mail. Vous le recevrez dans quelques minutes."])},
        "Contact us if you do not receive it at ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contactez-nous si vous ne le recevez pas à "])},
        "Powder Savings Calculator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calculateur d'économies de poudre"])},
        "Calculate your potential powder savings in a few simple steps": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calculez vos économies potentielles de poudre en quelques étapes simples"])},
        "User Information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informations sur l'utilisateur"])},
        "Line Settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paramètres de ligne"])},
        "Machine Settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paramètres de la machine"])},
        "Your Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre nom"])},
        "Email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
        "Company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entreprise"])},
        "Yearly Powder Consumption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consommation annuelle de poudre"])},
        "Do you have a recycling powder system?": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avez-vous un système de recyclage de poudre?"])},
        "Yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oui"])},
        "No": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non"])},
        "Recycling System Efficiency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Efficacité du système de recyclage"])},
        "Optional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optionnel"])},
        "Next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suivant"])},
        "Previous": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Précédent"])},
        "Your Line Setup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre configuration de ligne"])},
        "Number of Columns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre de colonnes"])},
        "Column Distance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distance entre colonnes"])},
        "Column Shift": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Décalage de colonne"])},
        "Number of Rows": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre de rangées"])},
        "Row Distance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distance entre rangées"])},
        "Row Shift": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Décalage de rangée"])},
        "Gun Max Movement Range": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plage de mouvement maximale du pistolet"])},
        "Gun Min Velocity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vitesse minim du pistolet"])},
        "Gun Max Velocity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vitesse maximale du pistolet"])},
        "Current Machine Settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paramètres actuels de la machine"])},
        "Height of the Part Coated with this Line Settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hauteur de la pièce revêtue avec ces paramètres de ligne"])},
        "Line Speed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vitesse de la ligne"])},
        "Gun Movement Range": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plage de mouvement du pistolet"])},
        "Gun Movement Period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Période de mouvement du pistolet"])},
        "Time that it takes for the pistol to go from the highest point to the lowest and back to the highest point.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Temps qu'il faut pour que le pistolet aille du point le plus haut au point le plus bas et revienne au point le plus haut."])},
        "Fill first the line setup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remplissez d'abord la configuration de la ligne"])},
        "Fill first the gun movement range": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remplissez d'abord la plage de mouvement du pistolet"])},
        "Create Powder Savings Report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer un rapport d'économies de poudre"])},
        "Input value out of range": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valeur d'entrée hors de portée"])},
        "Number of columns cannot be greater than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le nombre de colonnes ne peut pas être supérieur à"])},
        "Number of rows cannot be greater than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le nombre de rangées ne peut pas être supérieur à"])},
        "Column distance must be greater than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La distance entre les colonnes doit être supérieure à"])},
        "Column distance must be smaller than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La distance entre les colonnes doit être inférieure à"])},
        "Row distance must be greater than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La distance entre les rangées doit être supérieure à"])},
        "Row distance must be smaller than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La distance entre les rangées doit être inférieure à"])},
        "Column Shift must be greater than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le décalage de colonne doit être supérieur à"])},
        "Column Shift must be smaller than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le décalage de colonne doit être inférieur à"])},
        "Row Shift must be greater than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le décalage de rangée doit être supérieur à"])},
        "Row Shift must be smaller than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le décalage de rangée doit être inférieur à"])},
        "Gun Max Movement Range must be greater than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La plage de mouvement maximale du pistolet doit être supérieure à"])},
        "Gun Max Movement Range must be smaller than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La plage de mouvement maximale du pistolet doit être inférieure à"])},
        "Gun Min Velocity must be greater than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La vitesse minimale du pistolet doit être supérieure à"])},
        "Gun Min Velocity must be smaller than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La vitesse minimale du pistolet doit être inférieure à"])},
        "Gun Max Velocity must be greater than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La vitesse maximale du pistolet doit être supérieure à"])},
        "Gun Max Velocity must be smaller than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La vitesse maximale du pistolet doit être inférieure à"])},
        "Height of the Part Coated with this Line Settings must be greater than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La hauteur de la pièce revêtue avec ces paramètres de ligne doit être supérieure à"])},
        "Height of the Part Coated with this Line Settings must be smaller than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La hauteur de la pièce revêtue avec ces paramètres de ligne doit être inférieure à"])},
        "For the line settings defined, you cannnot obtain good results for this part height. Should be smaller than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pour les paramètres de ligne définis, vous ne pouvez pas obtenir de bons résultats pour cette hauteur de pièce. Doit être inférieur à"])},
        "Line Speed must be greater than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La vitesse de la ligne doit être supérieure à"])},
        "Line Speed must be smaller than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La vitesse de la ligne doit être inférieure à"])},
        "Gun Movement Range cannot be negative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La plage de mouvement du pistolet ne peut pas être négative"])},
        "Gun Movement Range must be smaller than the mechanical range of the line:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La plage de mouvement du pistolet doit être inférieure à la plage mécanique de la ligne:"])},
        "Gun Movement Period cannot be longer than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La période de mouvement du pistolet ne peut pas être plus longue que"])},
        "Gun Movement Period cannot be less than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La période de mouvement du pistolet ne peut pas être inférieure à"])},
        "Missing Required Fields": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Champs Requis Manquants"])},
        "The following fields are required:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les champs suivants sont requis:"])},
        "Gun Min Velocity cannot be greater than or equal to the Gun Max Velocity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La vitesse minimale du pistolet ne peut pas être supérieure ou égale à la vitesse maximale du pistolet"])},
        "tonnes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tonnes"])},
        "tons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tonnes courtes"])},
        "Email Already Registered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email déjà enregistré"])},
        "This email has already been used to generate a powder saving report. If this is a mistake or you need another Powder Saving Report, contact us at ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cet e-mail a déjà été utilisé pour générer un rapport d'économies de poudre. Si c'est une erreur ou si vous avez besoin d'un autre rapport d'économies de poudre, contactez-nous à "])},
        "If you liked what you saw and you came for more, check ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si vous avez aimé ce que vous avez vu et que vous êtes venu pour plus, vérifiez "])},
        "and get your quote.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["et obtenez votre devis."])},
        "If you don't receive it contact us at ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si vous ne le recevez pas, contactez-nous à "])},
        "Lines with 1 gun per column are not allowed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les lignes avec 1 pistolet par colonne ne sont pas autorisées"])},
        "For Mixed Lines the number of gun columns must be smaller than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pour les lignes mixtes, le nombre de colonnes de pistolet doit être inférieur à"])},
        "The total number of guns must be smaller than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le nombre total de pistolets doit être inférieur à"])},
        "Distance between highest and lowest point of one moving gun": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distance entre le point le plus haut et le point le plus bas d'un pistolet en mouvement"])},
        "Time it takes for one gun to move from the lowest point to the highest point and back.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Temps qu'il faut pour qu'un pistolet se déplace du point le plus bas au point le plus haut et revienne."])}
      }
    }
  })
}
