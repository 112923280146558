<template>
  <main class="main-content bg-gray-100">
    <div class="mx-4 d-flex align-items-center justify-content-between">
      <div class="mt-4">
        <a :href="calculatorTypeProp == 'expo' ? '/applications/flightpath/' : 'https://www.coatingai.com'">
          <img
            :src="logo"
            style="height: 60px; z-index: 999"
            alt="Coating AI Logo"
          />
        </a>
      </div>
      <div class="d-flex">
        <div class="mr-2">
          <a class="text-bold">{{ $t("Language") }}:&nbsp;</a>
          <select
            id="language-choices-category"
            v-model="user_information.language"
            name="language-choices"
            class="bg-transparent border-0 text-black"
            @change="changeLanguage()"
          >
            <option
              v-for="(language_option, key) in languages"
              :key="key"
              :value="key"
              class="text-dark"
            >
              <span class="d-sm-inline d-none">
                {{ language_option.emoji }} {{ key }} - {{ language_option.name }}
              </span>
            </option>
          </select>
        </div>
        <div class="mx-3">
          <a class="text-bold">{{ $t("Unit System") }}:&nbsp;</a>
          <select
            id="unit-system-choices-category"
            v-model="user_information.unit_system"
            name="unit-system-choices"
            class="bg-transparent border-0 text-black"
          >
            <option
              v-for="unit_system_option in unit_system"
              :key="unit_system_option"
              :value="unit_system_option"
              class="text-dark"
            >
              <span class="d-sm-inline d-none">
                {{ capitalizeFirstLetter($t(unit_system_option)) }}
              </span>
            </option>
          </select>
        </div>
        <!-- <div class="mr-2">
          <a class="text-bold">{{ $t("Currency") }}:&nbsp;</a>
          <select id="currency-choices-category" v-model=user_information.currency name="currency-choices"
            class="bg-transparent border-0 text-black">
            <option v-for="(currency_option, key) in currencies" :key="key" :value="key" class="text-dark">
              <span class="d-sm-inline d-none">
                {{ currency_option.symbol }}
              </span>
            </option>
          </select>
        </div> -->
      </div>
    </div>
    <div
      v-if="request_sent && calculatorTypeProp != 'expo' && email_already_exists"
      class="py-4 container-fluid"
    >
      <div style="width: 100%; height: 100%">
        <div class="mx-auto col-xl-8 col-lg-9 col-md-6 d-flex flex-column">
          <div class="bg-white card-body border-radius-xl">
            <h3 class="font-weight-bolder text-info text-gradient text-center my-4">
              {{ $t("Email Already Registered") }}
            </h3>
            <div class="warning-sign my-2">
              <div class="warning-icon">
                <div class="icon-circle"></div>
                <div class="icon-triangle">
                  <div class="exclamation-mark"></div>
                </div>
              </div>
            </div>
            <p
              class="my-3 col-8"
              style="margin: auto; text-align: justify"
            >
              {{
                $t(
                  "This email has already been used to generate a powder saving report. If this is a mistake or you need another Powder Saving Report, contact us at ",
                )
              }}<a
                href="mailto: info@coatingai.com"
                class="text-bold"
              >
                info@coatingai.com</a
              >. {{ $t("If you liked what you saw and you came for more, check ") }}
              <a
                href="https://www.coatingai.com/get-your-quote"
                class="text-bold"
                >coatingAI</a
              >
              {{ $t("and get your quote.") }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="request_sent && calculatorTypeProp != 'expo' && !email_already_exists"
      class="py-4 container-fluid"
    >
      <div style="width: 100%; height: 100%">
        <div class="mx-auto col-xl-8 col-lg-9 col-md-6 d-flex flex-column">
          <div class="bg-white card-body border-radius-xl">
            <h3 class="font-weight-bolder text-success text-gradient text-center my-4">
              {{ $t("Powder Savings Calculation Created Successfully") }}
            </h3>
            <div class="success-checkmark my-2">
              <div class="check-icon">
                <span class="icon-line line-tip"></span>
                <span class="icon-line line-long"></span>
                <div class="icon-circle"></div>
                <div class="icon-fix"></div>
              </div>
            </div>
            <p
              class="my-3 col-8"
              style="margin: auto; text-align: justify"
            >
              {{
                $t(
                  "A report on the potential powder savings is going to be generated and sent to your email. You will receive it in a few minutes.",
                )
              }}
            </p>
          </div>
          <small
            class="my-3 col-8"
            style="margin: auto; text-align: center"
          >
            {{ $t("If you don't receive it contact us at ") }}
            <a
              href="mailto: info@coatingai.com"
              class="text-bold"
            >
              info@coatingai.com
            </a>
          </small>
        </div>
      </div>
    </div>
    <div
      v-if="request_sent && calculatorTypeProp == 'expo'"
      class="py-4 container-fluid"
    >
      <div class="mx-auto col-xl-10 col-lg-11 col-md-10 d-flex flex-column">
        <div class="bg-white border-radius-xl">
          <powder-savings-results
            :user-email="user_information.email"
            :powder-savings-report-id="powder_savings_report_id"
            :user-language="user_information.language"
            :unit-system="user_information.unit_system"
          />
        </div>
      </div>
    </div>
    <div
      v-if="!request_sent"
      class="container-fluid"
    >
      <div class="mx-auto col-xl-8 col-lg-9 col-md-6 d-flex flex-column card card-body blur shadow-blur mt-4">
        <div class="card-header text-start">
          <h3 class="font-weight-bolder text-success text-gradient">
            {{ $t("Powder Savings Calculator") }}
          </h3>
          <p class="mb-0">{{ $t("Calculate your potential powder savings in a few simple steps") }}</p>
        </div>
      </div>
    </div>
    <div
      v-if="!request_sent"
      class="py-4 container-fluid"
    >
      <div class="mx-auto col-xl-8 col-lg-9 col-md-6 d-flex flex-column">
        <div class="row my-4">
          <div class="multisteps-form mt-4">
            <div class="multisteps-form__progress">
              <button
                class="multisteps-form__progress-btn js-active"
                type="button"
                title="User Information"
                :class="activeStep >= 0 ? activeClass : ''"
                @click="
                  activeStep = 0;
                  currentPage = 0;
                "
              >
                <span>1. {{ $t("User Information") }}</span>
              </button>
              <button
                class="multisteps-form__progress-btn"
                type="button"
                title="Line Settings"
                :class="activeStep >= 1 ? activeClass : ''"
                @click="
                  activeStep = 1;
                  currentPage = 1;
                "
              >
                2. {{ $t("Line Settings") }}
              </button>
              <button
                class="multisteps-form__progress-btn"
                type="button"
                title="Machine Settings"
                :class="activeStep >= 2 ? activeClass : ''"
                @click="
                  activeStep = 2;
                  currentPage = 2;
                "
              >
                3. {{ $t("Machine Settings") }}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="mx-auto col-xl-8 col-lg-9 col-md-6 d-flex flex-column">
        <div class="row">
          <div class="bg-white card-body border-radius-xl">
            <div>
              <div v-if="currentPage === 0">
                <label>{{ $t("Your Name") }}</label>
                <div class="mb-3">
                  <input
                    v-model="user_information.name"
                    :class="name_input_error ? `form-control is-invalid` : `form-control`"
                    type="text"
                    :placeholder="$t('Your Name')"
                    @input="name_input_error = false"
                  />
                </div>
                <label>{{ $t("Email") }}</label>
                <div class="mb-3">
                  <input
                    v-model="user_information.email"
                    :class="email_input_error ? `form-control is-invalid` : `form-control`"
                    type="email"
                    :placeholder="$t('Email')"
                    @input="validateEmail"
                  />
                </div>
                <label>{{ $t("Company") }}</label>
                <div class="mb-3">
                  <input
                    v-model="user_information.company"
                    :class="company_input_error ? `form-control is-invalid` : `form-control`"
                    type="text"
                    :placeholder="$t('Company')"
                    @input="company_input_error = false"
                  />
                </div>
                <label
                  >{{ $t("Yearly Powder Consumption") }} [{{
                    $store.state.units_system[user_information.unit_system].tonnes
                  }}]</label
                >
                <div class="mb-3">
                  <input
                    v-model="powderConsumption"
                    :class="powder_yearly_consumption_input_error ? `form-control is-invalid` : `form-control`"
                    type="number"
                    min="0"
                    :placeholder="$t('Yearly Powder Consumption')"
                    @input="powder_yearly_consumption_input_error = false"
                    @keydown="numericPositiveOnly"
                  />
                </div>
                <label>{{ $t("Do you have a recycling powder system?") }}</label>
                <div class="mb-3">
                  <select
                    id="choices-category"
                    v-model="user_information.recycling_system"
                    :class="recycling_system_input_error ? `form-control is-invalid` : `form-control`"
                    name="line-manufacturer-choices"
                    style="appearance: listbox"
                    @input="recycling_system_input_error = false"
                  >
                    <option :value="true">{{ $t("Yes") }}</option>
                    <option :value="false">{{ $t("No") }}</option>
                  </select>
                </div>
                <div v-if="user_information.recycling_system">
                  <label
                    >{{ $t("Recycling System Efficiency") }} [%]
                    <div class="text-xs text-muted">({{ $t("Optional") }})</div>
                  </label>
                  <div class="mb-3">
                    <input
                      v-model="user_information.recycling_percentage"
                      class="form-control"
                      type="number"
                      min="0"
                      max="100"
                      @blur="AdjustRecycledPercentage()"
                    />
                  </div>
                </div>
                <div class="mt-4 button-row d-flex col-12">
                  <soft-button
                    type="button"
                    color="dark"
                    variant="gradient"
                    class="mb-0 ms-auto js-btn-next"
                    title="Next"
                    @click="
                      activeStep = 1;
                      currentPage = 1;
                    "
                    >{{ $t("Next") }}</soft-button
                  >
                </div>
              </div>
              <div v-if="currentPage === 1">
                <h6 class="font-weight">{{ $t("Your Line Setup") }}</h6>
                <div class="row mb-3">
                  <div class="col-3">
                    <label>{{ $t("Number of Columns") }}</label>
                    <input
                      id="columns"
                      v-model.lazy="numberOfColumns"
                      :class="number_of_columns_input_error ? 'form-control is-invalid' : 'form-control'"
                      type="number"
                      :min="minColumnsAllowedComputed"
                      :max="maxColumnsAllowedComputed"
                      @input="number_of_columns_input_error = false"
                      @blur="
                        AdjustNumberOfColumns();
                        MaxNumberOfColumnsAllowed();
                      "
                    />
                  </div>
                  <div class="col-3">
                    <label>
                      {{ $t("Column Distance") }}
                      [{{ $store.state.units_system[user_information.unit_system].distance }}]
                    </label>
                    <input
                      id="col-distance"
                      v-model="columnDistance"
                      :class="column_distance_input_error ? 'form-control is-invalid' : 'form-control'"
                      :disabled="grid_form.number_of_columns == 1"
                      type="number"
                      min="1"
                      max="150"
                      @input="column_distance_input_error = false"
                      @blur="WithinRangeColumnToColumnDistAlertCheck()"
                    />
                  </div>
                  <div class="col-3">
                    <label
                      >{{ $t("Column Shift") }} [{{
                        $store.state.units_system[user_information.unit_system].distance
                      }}]</label
                    >
                    <input
                      id="col-shift"
                      v-model="columnShift"
                      :class="column_shift_input_error ? 'form-control is-invalid' : 'form-control'"
                      type="number"
                      :disabled="grid_form.row_shift != 0 || gunLayout == 'v'"
                      @input="column_shift_input_error = false"
                      @blur="
                        WithinRangeColumnShiftAlertCheck();
                        AdjustColumnShift();
                      "
                    />
                  </div>
                </div>
                <div class="container">
                  <div class="main-area col-12">
                    <div class="grid-container">
                      <div id="grid">
                        <div
                          v-for="(circle, index) in circles"
                          :key="index"
                          class="circle"
                          :style="circle.style"
                        ></div>
                      </div>
                    </div>
                    <div class="col-3 mx-4">
                      <label>{{ $t("Number of Rows") }}</label>
                      <div class="row">
                        <div class="col">
                          <input
                            id="rows"
                            v-model.lazy="grid_form.number_of_rows"
                            :class="number_of_rows_input_error ? 'form-control is-invalid' : 'form-control'"
                            type="number"
                            :min="minRowsAllowedComputed"
                            :max="max_number_of_rows_allowed"
                            @input="number_of_rows_input_error = false"
                            @blur="
                              AdjustNumberOfRows();
                              MaxNumberOfRowsAllowed();
                            "
                          />
                        </div>
                      </div>
                      <label
                        >{{ $t("Row Distance") }} [{{
                          $store.state.units_system[user_information.unit_system].distance
                        }}]</label
                      >
                      <div class="row">
                        <div class="col">
                          <input
                            id="row-distance"
                            v-model="rowDistance"
                            :class="row_distance_input_error ? 'form-control is-invalid' : 'form-control'"
                            type="number"
                            min="1"
                            max="150"
                            :disabled="grid_form.number_of_rows == 1"
                            @input="row_distance_input_error = false"
                            @blur="WithinRangeRowToRowDistAlertCheck()"
                          />
                        </div>
                      </div>
                      <label
                        >{{ $t("Row Shift") }} [{{
                          $store.state.units_system[user_information.unit_system].distance
                        }}]</label
                      >
                      <div class="row">
                        <div class="col">
                          <input
                            id="row-shift"
                            v-model="rowShift"
                            :class="row_shift_input_error ? 'form-control is-invalid' : 'form-control'"
                            type="number"
                            :disabled="grid_form.column_shift != 0 || gunLayout == 'h'"
                            @input="row_shift_input_error = false"
                            @blur="
                              WithinRangeRowShiftAlertCheck();
                              AdjustRowShift();
                            "
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <label
                  >{{ $t("Gun Max Movement Range") }} [{{
                    $store.state.units_system[user_information.unit_system].distance
                  }}]</label
                >
                <div class="mb-3">
                  <input
                    v-model="gunMaxMovementRange"
                    :class="gun_max_movement_range_input_error ? `form-control is-invalid` : `form-control`"
                    type="number"
                    min="0"
                    :step="$store.state.user_data.unit_system === 'metric' ? 1 : 0.01"
                    @input="gun_max_movement_range_input_error = false; user_input_max_movement_range = true;"
                    @keydown="numericOnly"
                    @blur="WithinRangeMaxMovementRangeAlertCheck()"
                  />
                </div>
                <div class="mt-4 button-row d-flex col-12">
                  <soft-button
                    color="secondary"
                    variant="gradient"
                    class="mb-0 js-btn-prev"
                    title="Previous"
                    @click="
                      activeStep = 0;
                      currentPage = 0;
                    "
                    >{{ $t("Previous") }}</soft-button
                  >
                  <soft-button
                    type="button"
                    color="dark"
                    variant="gradient"
                    class="mb-0 ms-auto js-btn-next"
                    title="Next"
                    @click="
                      activeStep = 2;
                      currentPage = 2;
                    "
                    >{{ $t("Next") }}</soft-button
                  >
                </div>
              </div>
              <div v-if="currentPage === 2">
                <div class="row">
                  <h6 class="font-weight">{{ $t("Current Machine Settings") }}</h6>
                  <label
                    >{{ $t("Height of the Part Coated with this Line Settings") }} [{{
                      $store.state.units_system[user_information.unit_system].distance
                    }}]</label
                  >
                  <div class="mb-3">
                    <input
                      v-model="coatedHeight"
                      :class="coated_height_input_error ? `form-control is-invalid` : `form-control`"
                      type="number"
                      :placeholder="coatedHeightPlaceholder"
                      min="0"
                      @input="coated_height_input_error = false"
                      @blur="WithinRangeCoatedHeightAlertCheck()"
                    />
                  </div>
                  <label
                    >{{ $t("Line Speed") }} [{{
                      $store.state.units_system[user_information.unit_system].velocity_minutes
                    }}]</label
                  >
                  <div class="mb-3">
                    <input
                      v-model="lineSpeed"
                      :class="line_speed_input_error ? `form-control is-invalid` : `form-control`"
                      type="number"
                      min="0"
                      :placeholder="lineSpeedPlaceholder"
                      @input="line_speed_input_error = false"
                      @blur="WithinRangeLineSpeedAlertCheck()"
                    />
                  </div>
                  <label>
                    {{ $t("Gun Movement Range") }} [{{
                      $store.state.units_system[user_information.unit_system].distance
                    }}]
                    <i
                      class="fa fa-info-circle"
                      aria-hidden="true"
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      :title="$t('Distance between highest and lowest point of one moving gun')"
                      data-container="body"
                      data-animation="true"
                    ></i>
                  </label>
                  <div
                    class="text-xs text-muted mx-1"
                  >
                    ({{ $t("Fill first the line setup") }})
                  </div>
                  <div class="mb-3">
                    <input
                      v-model="gunCurrentMovementRange"
                      :class="gun_movement_range_input_error ? `form-control is-invalid` : `form-control`"
                      :placeholder="gunCurrentMovementRangePlaceholder"
                      type="number"
                      min="0"
                      @input="gun_movement_range_input_error = false"
                      @blur="WithinRangeGunMovementRangeAlertCheck()"
                    />
                  </div>
                  <label
                    >{{ $t("Gun Movement Period") }}
                    [s]
                    <i
                      class="fa fa-info-circle"
                      aria-hidden="true"
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      :title="
                        $t('Time it takes for one gun to move from the lowest point to the highest point and back.')
                      "
                      data-container="body"
                      data-animation="true"
                    >
                    </i>
                  </label>
                  <div
                    v-if="gunCurrentMovementRange == null"
                    class="text-xs text-muted mx-1"
                  >
                    ({{ $t("Fill first the gun movement range") }})
                  </div>
                  <div class="mb-3">
                    <input
                      v-model="current_settings_form.gun_movement_period"
                      :class="gun_movement_period_input_error ? `form-control is-invalid` : `form-control`"
                      :disabled="gunCurrentMovementRange == null"
                      :placeholder="gunCurrentMovementPeriodPlaceholder"
                      step="0.1"
                      type="number"
                      min="0"
                      @input="gun_movement_period_input_error = false"
                      @blur="WithinRangeGunMovementPeriodAlertCheck()"
                    />
                  </div>
                  <div
                    v-if="currentPage == 2"
                    class="mt-2 d-flex flex-column justify-content-center"
                  >
                    <div class="mt-4 button-row d-flex col-12">
                      <soft-button
                        color="secondary"
                        variant="gradient"
                        class="mb-0 js-btn-prev"
                        title="Previous"
                        @click="
                          activeStep = 1;
                          currentPage = 1;
                        "
                        >{{ $t("Previous") }}</soft-button
                      >
                    </div>
                    <div class="mt-4 button-row d-flex col-12 justify-content-center">
                      <button
                        type="button"
                        class="mb-2 btn bg-gradient-success"
                        @click="createPowderSavingsReport"
                      >
                        {{ $t("Create Powder Savings Report") }}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import axios from "axios";
import { mapMutations } from "vuex";
import SoftButton from "@/components/SoftButton.vue";
import logo from "@/assets/img/logo-ct.png";
import setTooltip from "@/assets/js/tooltip.js";
import PowderSavingsResults from "./components/PowderSavingsResults.vue";

export default {
  name: "PowderSavingsCalculator",
  components: {
    SoftButton,
    PowderSavingsResults,
  },
  props: {
    calculatorTypeProp: {
      type: String,
      default: null,
    },
  },
  data() {
    this.$i18n.locale = "EN";
    return {
      activeClass: "js-active position-relative",
      activeStep: 0,
      formSteps: 3,
      logo,
      user_information: {
        language: "EN",
        unit_system: "metric",
        // currency: 'DOLLAR',
        email: "",
        name: "",
        company: "",
        powder_yearly_consumption: "",
        recycling_system: false,
        recycling_percentage: 100,
      },
      powder_savings_report_id: null,
      grid_form: {
        number_of_columns: 1,
        column_distance: 0,
        column_shift: 0,
        number_of_rows: 5,
        row_distance: 30,
        row_shift: 0,
      },
      line_form: {
        gun_min_velocity: 0.05,
        gun_max_velocity: 0.5,
        gun_max_movement_range: 30,
      },
      current_settings_form: {
        coated_height: null,
        line_speed: null,
        gun_movement_range: null,
        gun_movement_period: null,
      },
      currentPage: 0,
      email_input_error: false,
      name_input_error: false,
      company_input_error: false,
      powder_yearly_consumption_input_error: false,
      recycling_system_input_error: false,
      number_of_columns_input_error: false,
      column_distance_input_error: false,
      column_shift_input_error: false,
      number_of_rows_input_error: false,
      row_distance_input_error: false,
      row_shift_input_error: false,
      gun_max_movement_range_input_error: false,
      gun_min_velocity_input_error: false,
      gun_max_velocity_input_error: false,
      coated_height_input_error: false,
      line_speed_input_error: false,
      gun_movement_range_input_error: false,
      gun_movement_period_input_error: false,
      unit_system: ["metric", "imperial"],
      languages: {
        EN: { name: "English", emoji: "🇺🇸🇬🇧" },
        DE: { name: "Deutsch", emoji: "🇩🇪" },
        ES: { name: "Español", emoji: "🇪🇸" },
        FR: { name: "Français", emoji: "🇫🇷" },
        // 'IT': { 'name': 'Italiano', 'emoji': '🇮🇹' },
        // 'PL': { 'name': 'Polski', 'emoji': '🇵🇱' },
        // 'TR': { 'name': 'Türkçe', 'emoji': '🇹🇷' },
        // 'ZH': { 'name': '中文', 'emoji': '🇨🇳' },
      },
      currencies: {
        DOLLAR: { symbol: "$" },
        EUR: { symbol: "€" },
        POUND: { symbol: "£" },
        YUAN: { symbol: "¥" },
        CHF: { symbol: "CHF" },
        PLN: { symbol: "zł" },
        TRY: { symbol: "₺" },
        // Add more currencies as needed
      },
      min_column_to_column_distance_allowed: 1,
      max_column_to_column_distance_allowed: 300,
      max_total_line_guns: 30,
      max_number_of_columns_allowed: 7,
      max_horizontal_line_guns: 20,
      max_number_of_rows_allowed: 20,
      max_column_shift_allowed: 600,
      max_row_shift_allowed: 600,
      min_gun_min_velocity_allowed: 0,
      max_gun_min_velocity_allowed: 2,
      min_gun_max_velocity_allowed: 0.05,
      max_gun_max_velocity_allowed: 3,
      min_coated_height_allowed: 10,
      max_coated_height_allowed: 1500,
      min_line_speed_allowed: 0.1,
      max_line_speed_allowed: 12,
      max_imperial_decimals: 2,
      max_metric_decimals: 2,
      gridElementStatus: null,
      gridElementDimensions: { width: null, height: null },
      request_sent: false,
      email_already_exists: false,
      user_input_max_movement_range: false,
    };
  },
  computed: {
    minColumnsAllowedComputed() {
      if (this.grid_form.number_of_rows == 1) {
        return 2;
      }
      return 1;
    },
    maxColumnsAllowedComputed() {
      if (this.grid_form.number_of_rows == 1) {
        return this.max_horizontal_line_guns;
      }
      return this.max_number_of_columns_allowed;
    },
    minRowsAllowedComputed() {
      if (this.grid_form.number_of_columns == 1) {
        return 2;
      }
      return 1;
    },
    powderConsumption: {
      get() {
        if (
          this.user_information.powder_yearly_consumption == null ||
          this.user_information.powder_yearly_consumption == ""
        ) {
          return this.user_information.powder_yearly_consumption;
        }
        if (this.user_information.unit_system === "imperial") {
          return parseFloat(
            (
              this.user_information.powder_yearly_consumption * this.$store.state.conversion_factors.tonnes_to_tons
            ).toFixed(0),
          );
        } else if (this.user_information.unit_system === "metric") {
          return parseFloat(this.user_information.powder_yearly_consumption.toFixed(this.max_metric_decimals));
        } else {
          return null;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.user_information.powder_yearly_consumption = value;
        } else {
          if (this.user_information.unit_system === "imperial") {
            this.user_information.powder_yearly_consumption =
              value / this.$store.state.conversion_factors.tonnes_to_tons;
          } else if (this.user_information.unit_system === "metric") {
            this.user_information.powder_yearly_consumption = value;
          } else {
            this.user_information.powder_yearly_consumption = null;
          }
        }
      },
    },
    gunLayout: function () {
      let gun_layout = "";
      if (this.grid_form.number_of_columns == 1 && this.grid_form.row_shift == 0) {
        gun_layout = "v";
      } else if (this.grid_form.number_of_rows == 1 && this.grid_form.column_shift == 0) {
        gun_layout = "h";
      } else {
        gun_layout = "m";
      }
      return gun_layout;
    },
    columnDistance: {
      get() {
        if (this.grid_form.column_distance == null || this.grid_form.column_distance == "") {
          return this.grid_form.column_distance;
        }
        if (this.user_information.unit_system === "imperial") {
          return parseFloat(
            (this.grid_form.column_distance * this.$store.state.conversion_factors.cm_to_in).toFixed(
              this.max_imperial_decimals,
            ),
          );
        } else if (this.user_information.unit_system === "metric") {
          return parseFloat(this.grid_form.column_distance.toFixed(this.max_metric_decimals));
        } else {
          return null;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.grid_form.column_distance = value;
        } else {
          if (this.user_information.unit_system === "imperial") {
            this.grid_form.column_distance = value / this.$store.state.conversion_factors.cm_to_in;
          } else if (this.user_information.unit_system === "metric") {
            this.grid_form.column_distance = value;
          } else {
            this.grid_form.column_distance = null;
          }
        }
      },
    },
    rowDistance: {
      get() {
        if (this.grid_form.row_distance == null || this.grid_form.row_distance == "") {
          return this.grid_form.row_distance;
        }
        if (this.user_information.unit_system === "imperial") {
          return parseFloat(
            (this.grid_form.row_distance * this.$store.state.conversion_factors.cm_to_in).toFixed(
              this.max_imperial_decimals,
            ),
          );
        } else if (this.user_information.unit_system === "metric") {
          return parseFloat(this.grid_form.row_distance.toFixed(this.max_metric_decimals));
        } else {
          return null;
        }
      },
      set(value) {
        let coupled_with_max_movement_range = false;
        if (this.grid_form.row_distance == this.line_form.gun_max_movement_range && !this.user_input_max_movement_range) {
          coupled_with_max_movement_range = true;
        }
        if (value == null || value == "") {
          this.grid_form.row_distance = value;
        } else {
          if (this.user_information.unit_system === "imperial") {
            this.grid_form.row_distance = value / this.$store.state.conversion_factors.cm_to_in;
          } else if (this.user_information.unit_system === "metric") {
            this.grid_form.row_distance = value;
          } else {
            this.grid_form.row_distance = null;
          }
          if (coupled_with_max_movement_range) {
            this.line_form.gun_max_movement_range = this.grid_form.row_distance;
          }
        }
      },
    },
    columnShift: {
      get() {
        if (this.grid_form.column_shift == null || this.grid_form.column_shift == "") {
          return this.grid_form.column_shift;
        }
        if (this.user_information.unit_system === "imperial") {
          return parseFloat(
            (this.grid_form.column_shift * this.$store.state.conversion_factors.cm_to_in).toFixed(
              this.max_imperial_decimals,
            ),
          );
        } else if (this.user_information.unit_system === "metric") {
          return parseFloat(this.grid_form.column_shift.toFixed(this.max_metric_decimals));
        } else {
          return null;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.grid_form.column_shift = value;
        } else {
          if (this.user_information.unit_system === "imperial") {
            this.grid_form.column_shift = value / this.$store.state.conversion_factors.cm_to_in;
          } else if (this.user_information.unit_system === "metric") {
            this.grid_form.column_shift = value;
          } else {
            this.grid_form.column_shift = null;
          }
        }
      },
    },
    rowShift: {
      get() {
        if (this.grid_form.row_shift === null || this.grid_form.row_shift === "") {
          return this.grid_form.row_shift;
        }
        if (this.user_information.unit_system === "imperial") {
          return parseFloat(
            (this.grid_form.row_shift * this.$store.state.conversion_factors.cm_to_in).toFixed(
              this.max_imperial_decimals,
            ),
          );
        } else if (this.user_information.unit_system === "metric") {
          return parseFloat(this.grid_form.row_shift.toFixed(this.max_metric_decimals));
        } else {
          return null;
        }
      },
      set(value) {
        if (value === null || value === "") {
          this.grid_form.row_shift = value;
        } else {
          if (this.user_information.unit_system === "imperial") {
            this.grid_form.row_shift = value / this.$store.state.conversion_factors.cm_to_in;
          } else if (this.user_information.unit_system === "metric") {
            this.grid_form.row_shift = value;
          } else {
            this.grid_form.row_shift = null;
          }
        }
      },
    },
    minColumnToColumnDistanceAllowed: function () {
      if (this.user_information.unit_system === "imperial") {
        return (this.min_column_to_column_distance_allowed * this.$store.state.conversion_factors.cm_to_in).toFixed(
          this.max_imperial_decimals,
        );
      } else if (this.user_information.unit_system === "metric") {
        return this.min_column_to_column_distance_allowed;
      } else {
        return null;
      }
    },
    maxColumnToColumnDistanceAllowed: function () {
      if (this.user_information.unit_system === "imperial") {
        return (this.max_column_to_column_distance_allowed * this.$store.state.conversion_factors.cm_to_in).toFixed(
          this.max_imperial_decimals,
        );
      } else if (this.user_information.unit_system === "metric") {
        return this.max_column_to_column_distance_allowed;
      } else {
        return null;
      }
    },
    maxColumnShiftAllowed: function () {
      if (this.user_information.unit_system === "imperial") {
        return (this.max_column_shift_allowed * this.$store.state.conversion_factors.cm_to_in).toFixed(
          this.max_imperial_decimals,
        );
      } else if (this.user_information.unit_system === "metric") {
        return this.max_column_shift_allowed;
      } else {
        return null;
      }
    },
    maxRowShiftAllowed: function () {
      if (this.user_information.unit_system === "imperial") {
        return (this.max_row_shift_allowed * this.$store.state.conversion_factors.cm_to_in).toFixed(
          this.max_imperial_decimals,
        );
      } else if (this.user_information.unit_system === "metric") {
        return this.max_row_shift_allowed;
      } else {
        return null;
      }
    },
    minGunMaxMovementRangeAllowed: function () {
      if (this.user_information.unit_system === "imperial") {
        return (
          this.$store.state.constraints.min_gun_max_movement_range_allowed * this.$store.state.conversion_factors.cm_to_in
        ).toFixed(this.max_imperial_decimals);
      } else if (this.user_information.unit_system === "metric") {
        return this.$store.state.constraints.min_gun_max_movement_range_allowed;
      } else {
        return null;
      }
    },
    maxGunMaxMovementRangeAllowed: function () {
      if (this.user_information.unit_system === "imperial") {
        return (
          this.$store.state.constraints.max_gun_max_movement_range_allowed * this.$store.state.conversion_factors.cm_to_in
        ).toFixed(this.max_imperial_decimals);
      } else if (this.user_information.unit_system === "metric") {
        return this.$store.state.constraints.max_gun_max_movement_range_allowed;
      } else {
        return null;
      }
    },
    circles() {
      return this.createCirclesLayout();
    },
    lineSpeed: {
      get() {
        if (this.current_settings_form.line_speed == null || this.current_settings_form.line_speed == "") {
          return this.current_settings_form.line_speed;
        }
        if (this.user_information.unit_system === "imperial") {
          return parseFloat(
            (this.current_settings_form.line_speed * this.$store.state.conversion_factors.m_to_ft).toFixed(
              this.max_imperial_decimals,
            ),
          );
        } else if (this.user_information.unit_system === "metric") {
          return parseFloat(this.current_settings_form.line_speed.toFixed(this.max_metric_decimals));
        } else {
          return null;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.current_settings_form.line_speed = value;
        } else {
          if (this.user_information.unit_system === "imperial") {
            this.current_settings_form.line_speed = value / this.$store.state.conversion_factors.m_to_ft;
          } else if (this.user_information.unit_system === "metric") {
            this.current_settings_form.line_speed = value;
          } else {
            this.current_settings_form.line_speed = null;
          }
        }
      },
    },
    lineSpeedPlaceholder: {
      get() {
        if (this.user_information.unit_system === "imperial") {
          return (
            "e.g: " + parseFloat((2 * this.$store.state.conversion_factors.m_to_ft).toFixed(this.max_imperial_decimals))
          );
        } else {
          return "e.g: " + 2;
        }
      },
    },
    gunCurrentMovementRange: {
      get() {
        if (
          this.current_settings_form.gun_movement_range == null ||
          this.current_settings_form.gun_movement_range == ""
        ) {
          return this.current_settings_form.gun_movement_range;
        }
        if (this.user_information.unit_system === "imperial") {
          return parseFloat(
            (
              this.current_settings_form.gun_movement_range * this.$store.state.conversion_factors.cm_to_in
            ).toFixed(this.max_imperial_decimals),
          );
        } else if (this.user_information.unit_system === "metric") {
          return parseFloat(this.current_settings_form.gun_movement_range.toFixed(this.max_metric_decimals));
        } else {
          return null;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.current_settings_form.gun_movement_range = value;
        } else {
          if (this.user_information.unit_system === "imperial") {
            this.current_settings_form.gun_movement_range =
              value / this.$store.state.conversion_factors.cm_to_in;
          } else if (this.user_information.unit_system === "metric") {
            this.current_settings_form.gun_movement_range = value;
          } else {
            this.current_settings_form.gun_movement_range = null;
          }
        }
      },
    },
    gunCurrentMovementRangePlaceholder: {
      get() {
        if (this.user_information.unit_system === "imperial") {
          return (
            "e.g: " +
            parseFloat((27 * this.$store.state.conversion_factors.m_to_ft).toFixed(this.max_imperial_decimals))
          );
        } else {
          return "e.g: " + 27;
        }
      },
    },
    gunCurrentMovementPeriodPlaceholder: {
      get() {
        let gun_velocity =
          (this.line_form.gun_max_velocity + this.line_form.gun_min_velocity) / 2;
        let movement_period = parseFloat(
          ((2 * (this.line_form.gun_max_movement_range / 100)) / gun_velocity).toFixed(2),
        );
        return "e.g: " + movement_period;
      },
    },
    gunMinVelocity: {
      get() {
        if (this.line_form.gun_min_velocity == null || this.line_form.gun_min_velocity == "") {
          return this.line_form.gun_min_velocity;
        }
        if (this.user_information.unit_system === "imperial") {
          return parseFloat(
            (this.line_form.gun_min_velocity * this.$store.state.conversion_factors.m_to_ft).toFixed(
              this.max_imperial_decimals,
            ),
          );
        } else if (this.user_information.unit_system === "metric") {
          return parseFloat(this.line_form.gun_min_velocity.toFixed(this.max_metric_decimals));
        } else {
          return null;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.line_form.gun_min_velocity = value;
        } else {
          if (this.user_information.unit_system === "imperial") {
            this.line_form.gun_min_velocity = value / this.$store.state.conversion_factors.m_to_ft;
          } else {
            this.line_form.gun_min_velocity = value;
          }
        }
      },
    },
    gunMaxVelocity: {
      get() {
        if (this.line_form.gun_max_velocity == null || this.line_form.gun_max_velocity == "") {
          return this.line_form.gun_max_velocity;
        }
        if (this.user_information.unit_system === "imperial") {
          return parseFloat(
            (this.line_form.gun_max_velocity * this.$store.state.conversion_factors.m_to_ft).toFixed(
              this.max_imperial_decimals,
            ),
          );
        } else if (this.user_information.unit_system === "metric") {
          return parseFloat(this.line_form.gun_max_velocity.toFixed(this.max_metric_decimals));
        } else {
          return null;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.line_form.gun_max_velocity = value;
        } else {
          if (this.user_information.unit_system === "imperial") {
            this.line_form.gun_max_velocity = value / this.$store.state.conversion_factors.m_to_ft;
          } else {
            this.line_form.gun_max_velocity = value;
          }
        }
      },
    },
    gunMinVelocityPlaceholder: {
      get() {
        if (this.user_information.unit_system === "imperial") {
          return (
            "e.g: " +
            parseFloat((0.05 * this.$store.state.conversion_factors.m_to_ft).toFixed(this.max_imperial_decimals))
          );
        } else {
          return "e.g: " + 0.05;
        }
      },
    },
    gunMaxVelocityPlaceholder: {
      get() {
        if (this.user_information.unit_system === "imperial") {
          return (
            "e.g: " +
            parseFloat((0.5 * this.$store.state.conversion_factors.m_to_ft).toFixed(this.max_imperial_decimals))
          );
        } else {
          return "e.g: " + 0.5;
        }
      },
    },
    gunMaxMovementRange: {
      get() {
        if (
          this.line_form.gun_max_movement_range == null ||
          this.line_form.gun_max_movement_range == ""
        ) {
          return this.line_form.gun_max_movement_range;
        }
        if (this.user_information.unit_system === "imperial") {
          return parseFloat(
            (this.line_form.gun_max_movement_range * this.$store.state.conversion_factors.cm_to_in).toFixed(
              this.max_imperial_decimals,
            ),
          );
        } else if (this.user_information.unit_system === "metric") {
          return parseFloat(this.line_form.gun_max_movement_range.toFixed(this.max_metric_decimals));
        } else {
          return null;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.line_form.gun_max_movement_range = value;
        } else {
          if (this.user_information.unit_system === "imperial") {
            this.line_form.gun_max_movement_range = value / this.$store.state.conversion_factors.cm_to_in;
          } else if (this.user_information.unit_system === "metric") {
            this.line_form.gun_max_movement_range = value;
          } else {
            this.line_form.gun_max_movement_range = value;
          }
        }
      },
    },
    numberOfColumns: {
      get() {
        return this.grid_form.number_of_columns;
      },
      set(value) {

        if (value > 1 && !this.user_input_max_movement_range) {
          this.line_form.gun_max_movement_range = 150;

        }
        if (value == 1 && !this.user_input_max_movement_range) {
          this.line_form.gun_max_movement_range = this.grid_form.row_distance;
        }
        
        this.grid_form.number_of_columns = value;
      },
    },
    gunMaxMovementRangePlaceholder: {
      get() {
        let placeholder_value = 150;
        if (this.gunLayout != "h") {
          const filtered_gun_to_gun_distance_array =
            this.GridFormToLineFormTransform().gun_to_gun_distance_array.filter(value => value != 0);
          const filtered_gun_columns_vertical_offsets_array =
            this.GridFormToLineFormTransform().gun_columns_vertical_offsets_array.filter(value => value != 0);
          placeholder_value =
            Math.max(
              ...filtered_gun_columns_vertical_offsets_array,
              ...filtered_gun_to_gun_distance_array,
            ) * 100;
        }
        return placeholder_value;
      },
    },
    coatedHeight: {
      get() {
        if (this.current_settings_form.coated_height == null || this.current_settings_form.coated_height == "") {
          return this.current_settings_form.coated_height;
        }
        if (this.user_information.unit_system === "imperial") {
          return parseFloat(
            (this.current_settings_form.coated_height * this.$store.state.conversion_factors.cm_to_in).toFixed(
              this.max_imperial_decimals,
            ),
          );
        } else if (this.user_information.unit_system === "metric") {
          return parseFloat(this.current_settings_form.coated_height.toFixed(this.max_metric_decimals));
        } else {
          return this.current_settings_form.coated_height;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.current_settings_form.coated_height = value;
        } else {
          if (this.user_information.unit_system === "imperial") {
            this.current_settings_form.coated_height = value / this.$store.state.conversion_factors.cm_to_in;
          } else if (this.user_information.unit_system === "metric") {
            this.current_settings_form.coated_height = value;
          }
        }
      },
    },
    coatedHeightPlaceholder: {
      get() {
        if (this.user_information.unit_system === "imperial") {
          return (
            "e.g: " +
            parseFloat((100 * this.$store.state.conversion_factors.cm_to_in).toFixed(this.max_imperial_decimals))
          );
        } else {
          return "e.g: " + 100;
        }
      },
    },
    minGunMinVelocityAllowed: function () {
      if (this.user_information.unit_system === "imperial") {
        return (this.min_gun_min_velocity_allowed * this.$store.state.conversion_factors.m_to_ft).toFixed(
          this.max_imperial_decimals,
        );
      } else if (this.user_information.unit_system === "metric") {
        return this.min_gun_min_velocity_allowed;
      } else {
        return null;
      }
    },
    maxGunMinVelocityAllowed: function () {
      if (this.user_information.unit_system === "imperial") {
        return (this.max_gun_min_velocity_allowed * this.$store.state.conversion_factors.m_to_ft).toFixed(
          this.max_imperial_decimals,
        );
      } else if (this.user_information.unit_system === "metric") {
        return this.max_gun_min_velocity_allowed;
      } else {
        return null;
      }
    },
    minGunMaxVelocityAllowed: function () {
      if (this.user_information.unit_system === "imperial") {
        return (this.min_gun_max_velocity_allowed * this.$store.state.conversion_factors.m_to_ft).toFixed(
          this.max_imperial_decimals,
        );
      } else if (this.user_information.unit_system === "metric") {
        return this.min_gun_max_velocity_allowed;
      } else {
        return null;
      }
    },
    maxGunMaxVelocityAllowed: function () {
      if (this.user_information.unit_system === "imperial") {
        return (this.max_gun_max_velocity_allowed * this.$store.state.conversion_factors.m_to_ft).toFixed(
          this.max_imperial_decimals,
        );
      } else if (this.user_information.unit_system === "metric") {
        return this.max_gun_max_velocity_allowed;
      } else {
        return null;
      }
    },
    minCoatedHeightAllowed: function () {
      let line_min_coated_height_allowed = this.min_coated_height_allowed;
      if (this.gunLayout == "v") {
        line_min_coated_height_allowed = Math.max(this.min_coated_height_allowed, this.grid_form.row_distance);
      } else if (this.gunLayout == "m") {
        let gun_to_gun_distance_array =
          this.GridFormToLineFormTransform().gun_to_gun_distance_array;
        line_min_coated_height_allowed =
          Math.max(...gun_to_gun_distance_array, this.min_coated_height_allowed / 100) * 100;
      }
      if (this.user_information.unit_system === "imperial") {
        return (line_min_coated_height_allowed * this.$store.state.conversion_factors.cm_to_in).toFixed(
          this.max_imperial_decimals,
        );
      } else if (this.user_information.unit_system === "metric") {
        return line_min_coated_height_allowed;
      } else {
        return null;
      }
    },
    maxCoatedHeightAllowed: function () {
      if (this.user_information.unit_system === "imperial") {
        return (this.max_coated_height_allowed * this.$store.state.conversion_factors.cm_to_in).toFixed(
          this.max_imperial_decimals,
        );
      } else if (this.user_information.unit_system === "metric") {
        return this.max_coated_height_allowed;
      } else {
        return null;
      }
    },
    lineMaxVerticalRange: function () {
      let line_max_vertical_range =
        this.grid_form.number_of_rows * this.grid_form.row_distance -
        this.grid_form.row_distance +
        this.grid_form.column_shift +
        this.line_form.gun_max_movement_range;
      let computed_line_max_vertical_range = 0;
      if (this.user_information.unit_system === "imperial") {
        computed_line_max_vertical_range = (
          line_max_vertical_range * this.$store.state.conversion_factors.cm_to_in
        ).toFixed(this.max_imperial_decimals);
      } else if (this.user_information.unit_system === "metric") {
        computed_line_max_vertical_range = line_max_vertical_range;
      } else {
        computed_line_max_vertical_range = null;
      }
      return computed_line_max_vertical_range;
    },
    minLineSpeedAllowed: function () {
      if (this.user_information.unit_system === "imperial") {
        return (this.min_line_speed_allowed * this.$store.state.conversion_factors.m_to_ft).toFixed(
          this.max_imperial_decimals,
        );
      } else if (this.user_information.unit_system === "metric") {
        return this.min_line_speed_allowed;
      } else {
        return null;
      }
    },
    maxLineSpeedAllowed: function () {
      if (this.user_information.unit_system === "imperial") {
        return (this.max_line_speed_allowed * this.$store.state.conversion_factors.m_to_ft).toFixed(
          this.max_imperial_decimals,
        );
      } else if (this.user_information.unit_system === "metric") {
        return this.max_line_speed_allowed;
      } else {
        return null;
      }
    },
    lineFormGunVelocityFilled: function () {
      return (
        this.line_form.gun_min_velocity !== null &&
        this.line_form.gun_max_velocity !== null &&
        this.line_form.gun_min_velocity !== "" &&
        this.line_form.gun_max_velocity !== ""
      );
    },
    maximumPeriodTime() {
      return parseFloat(
        (
          (2 * (this.current_settings_form.gun_movement_range / 100)) /
          this.line_form.gun_min_velocity
        ).toFixed(2),
      );
    },
    minimumPeriodTime() {
      return parseFloat(
        (
          (2 * (this.current_settings_form.gun_movement_range / 100)) /
          this.line_form.gun_max_velocity
        ).toFixed(2),
      );
    },
    NumberofColumns() {
      let number_of_columns = 0;
      if (this.gunLayout === "m" || this.gunLayout === "staticmixed") {
        let grid_coordinates = this.createCirclesCoordinates();
        let x_dictionary = {};
        for (let i = 0; i < grid_coordinates.length; i++) {
          let x = grid_coordinates[i].x;
          let y = grid_coordinates[i].y;
          if (x_dictionary[x] === undefined) {
            x_dictionary[x] = [];
          }
          x_dictionary[x].push(y);
        }

        let x_keys = Object.keys(x_dictionary);
        number_of_columns = x_keys.length;
      }
      return number_of_columns;
    },
    TotalNumberOfGunsMixed() {
      let total_number_of_guns_mixed = 0;
      if (this.gunLayout === "m" || this.gunLayout === "staticmixed") {
        total_number_of_guns_mixed = this.grid_form.number_of_columns * this.grid_form.number_of_rows;
      }
      return total_number_of_guns_mixed;
    },
    MixedGunsPerColumnArray() {
      let total_pistols_array = this.GridFormToLineFormTransform().total_gun_array;
      return total_pistols_array;
    },
  },
  watch: {
    "$store.state.user_data.powder_savings_calculator_license": function (newValue) {
      if (newValue == 1) {
        this.$router.push("/powdersavingscalculator/expo");
      }
    },
    gridElementStatus(newStatus) {
      if (newStatus) {
        this.gridElementDimensions = { ...newStatus };
      }
    },
    gunLayout(newLayout) {
      if (newLayout == "v") {
        this.grid_form.column_distance = 0;
        if (this.grid_form.row_distance < 10) {
          this.grid_form.row_distance = 10;
        }
        this.grid_form.column_shift = 0;
        this.grid_form.row_shift = 0;
      } else if (newLayout == "h") {
        this.grid_form.row_distance = 0;
        this.grid_form.column_shift = 0;
        this.grid_form.row_shift = 0;
        if (this.grid_form.column_distance < 10) {
          this.grid_form.column_distance = 10;
        }
      } else {
        if (this.grid_form.column_distance < 10 && this.grid_form.number_of_columns > 1) {
          this.grid_form.column_distance = 10;
        }
        if (this.grid_form.row_distance < 10 && this.grid_form.number_of_rows > 1) {
          this.grid_form.row_distance = 10;
        }
      }
    },
    NumberofColumns(newNumberofColumns) {
      if (
        newNumberofColumns > 10 &&
        this.gunLayout != "v" &&
        this.gunLayout != "h" &&
        this.gunLayout != "s"
      ) {
        this.$swal({
          title: this.$t("Input value out of range"),
          text: `${this.$t("For Mixed Lines the number of gun columns must be smaller than")} 10`,
          icon: "warning",
          confirmButtonText: "OK",
        });
        this.grid_form.number_of_columns = Math.min(5, this.grid_form.number_of_columns);
      }
    },
    TotalNumberOfGunsMixed(newNumberOfGuns) {
      if (
        newNumberOfGuns > this.max_total_line_guns &&
        this.gunLayout != "v" &&
        this.gunLayout != "h" &&
        this.gunLayout != "s"
      ) {
        this.$swal({
          title: this.$t("Input value out of range"),
          text: `${this.$t("The number of guns must be smaller than")} ` + this.max_total_line_guns,
          icon: "warning",
          confirmButtonText: "OK",
        });
        this.grid_form.number_of_rows = Math.min(4, this.grid_form.number_of_rows);
        this.grid_form.number_of_columns = Math.min(4, this.grid_form.number_of_columns);
      }
    },
    MixedGunsPerColumnArray(newMixedGunsPerColumnArray) {
      // THIS CHECK NEEDS TO BE REMOVED IF CORE ALLOWS FOR 1 GUN RECIPROCATORS
      if (newMixedGunsPerColumnArray.includes(1)) {
        this.$swal({
          title: this.$t("Input value out of range"),
          text: `${this.$t("Lines with 1 gun per column are not allowed")}`,
          icon: "warning",
          confirmButtonText: "OK",
        });
        this.grid_form.number_of_rows += 1;
      }
    },
  },
  created() {
    this.$store.state.isLoading = false;
    this.toggleEveryDisplay();
    this.toggleHideConfig();
  },
  mounted() {
    // this.populateMeasurementDefaults();
    this.$store.state.isLoading = false;
    setTooltip(this.$store.state.bootstrap);
    window.addEventListener("resize", this.onWindowResize);
    this.checkForGridElement();
  },
  updated() {
    setTooltip(this.$store.state.bootstrap);
  },
  beforeUnmount() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
    window.removeEventListener("resize", this.onWindowResize);
  },
  methods: {
    ...mapMutations(["toggleEveryDisplay", "toggleHideConfig"]),
    changeLanguage() {
      this.$i18n.locale = this.user_information.language;
    },
    numericOnly(event) {
      let key = event.key;
      if (
        [
          "Delete",
          "Backspace",
          "Tab",
          "Enter",
          "ArrowUp",
          "ArrowRight",
          "ArrowDown",
          "ArrowLeft",
          ".",
          "Shift",
          "Control",
          ",",
          "-",
        ].includes(key) ||
        ((event.ctrlKey || event.metaKey) && ["a", "c", "v", "x", "z", "y"].includes(key.toLowerCase()))
      ) {
        return;
      }
      if (!/\d/.test(key)) {
        event.preventDefault();
      }
    },
    numericPositiveOnly(event) {
      let key = event.key;
      if (
        [
          "Delete",
          "Backspace",
          "Tab",
          "Enter",
          "ArrowUp",
          "ArrowRight",
          "ArrowDown",
          "ArrowLeft",
          ".",
          "Shift",
          "Control",
          ",",
        ].includes(key) ||
        ((event.ctrlKey || event.metaKey) && ["a", "c", "v", "x", "z", "y"].includes(key.toLowerCase()))
      ) {
        return;
      }
      if (!/\d/.test(key)) {
        event.preventDefault();
      }
    },
    validateEmail() {
      const regex = /^\S+@\S+\.\S+$/;
      this.email_input_error = !regex.test(this.user_information.email);
    },
    AdjustRecycledPercentage() {
      if (this.user_information.recycling_percentage < 0) {
        this.user_information.recycling_percentage = 0;
      } else if (this.user_information.recycling_percentage > 100) {
        this.user_information.recycling_percentage = 100;
      }
    },
    AdjustNumberOfColumns() {
      if (this.grid_form.number_of_columns < 1) {
        this.grid_form.number_of_columns = 1;
      }
    },
    AdjustColumnShift() {
      if (this.grid_form.column_shift === null || this.grid_form.column_shift === "") {
        this.grid_form.column_shift = 0;
      }
    },
    MaxNumberOfColumnsAllowed() {
      if (this.grid_form.number_of_columns > this.max_number_of_columns_allowed && this.grid_form.number_of_rows > 1) {
        this.$swal({
          title: this.$t("Input value out of range"),
          text: `${this.$t("Number of columns cannot be greater than")} ${this.max_number_of_columns_allowed}`,
          icon: "warning",
          confirmButtonText: "OK",
        });
        this.grid_form.number_of_columns = this.max_number_of_columns_allowed;
      } else if (
        this.grid_form.number_of_columns > this.max_horizontal_line_guns &&
        this.grid_form.number_of_rows == 1
      ) {
        this.$swal({
          title: this.$t("Input value out of range"),
          text: `${this.$t("Number of columns cannot be greater than")} ${this.max_horizontal_line_guns}`,
          icon: "warning",
          confirmButtonText: "OK",
        });
        this.grid_form.number_of_columns = this.max_horizontal_line_guns;
      }
    },
    AdjustNumberOfRows() {
      if (this.grid_form.number_of_rows < 1) {
        this.grid_form.number_of_rows = 1;
      }
    },
    AdjustRowShift() {
      if (this.grid_form.row_shift == null || this.grid_form.row_shift == "") {
        this.grid_form.row_shift = 0;
      }
    },
    MaxNumberOfRowsAllowed() {
      if (this.grid_form.number_of_rows > this.max_number_of_rows_allowed) {
        this.$swal({
          title: this.$t("Input value out of range"),
          text: `${this.$t("Number of rows cannot be greater than")} ${this.max_number_of_rows_allowed}`,
          icon: "warning",
          confirmButtonText: "OK",
        });
        this.grid_form.number_of_rows = this.max_number_of_rows_allowed;
      }
    },
    createCirclesLayout() {
      let circles = [];
      const cols = parseInt(this.grid_form.number_of_columns) || 1;
      const rows = parseInt(this.grid_form.number_of_rows) || 1;
      const baseCircleSize = 10;
      const colDistance = Math.max(parseInt(this.grid_form.column_distance), 11);
      const rowDistance = Math.max(parseInt(this.grid_form.row_distance), 11);
      const colShift = parseInt(this.grid_form.column_shift);
      const rowShift = parseInt(this.grid_form.row_shift);

      const totalWidth = cols * (baseCircleSize + colDistance) - colDistance + Math.abs(rowShift);
      const totalHeight = rows * (baseCircleSize + rowDistance) - rowDistance + Math.abs(colShift);

      let gridDimensions = this.gridElementDimensions;
      let drawingAreaWidth = gridDimensions.width;
      let drawingAreaHeight = gridDimensions.height;

      let scale_factor = 0.5;

      if (rows + cols <= 3) {
        scale_factor = 0.5;
      } else {
        let gun_circle_diameter = 10;
        let max_scale_factor = 0.85;
        let min_scale_factor = 0.4;
        let max_row_distance = 120;
        let max_col_distance = 150;

        let row_distance_to_max_distance_ratio = rowDistance / (max_row_distance - gun_circle_diameter);
        let col_distance_to_max__distance_ratio = colDistance / (max_col_distance - gun_circle_diameter);
        let max_distance_ratio = Math.max(row_distance_to_max_distance_ratio, col_distance_to_max__distance_ratio);

        scale_factor = (max_scale_factor - min_scale_factor) * max_distance_ratio + min_scale_factor;
      }

      let scale = Math.min(
        (drawingAreaWidth * scale_factor) / totalWidth,
        (drawingAreaHeight * scale_factor) / totalHeight,
      );

      const offsetX = (drawingAreaWidth - totalWidth * scale) / 2;
      const offsetY = (drawingAreaHeight - totalHeight * scale) / 2;

      for (let row = 0; row < rows; row++) {
        for (let col = 0; col < cols; col++) {
          let top = offsetY + row * (baseCircleSize + rowDistance) * scale;
          let left = offsetX + col * (baseCircleSize + colDistance) * scale;

          if (row % 2 === 1) {
            left += rowShift * scale;
            if (rowShift > 0) {
              left += ((baseCircleSize * rowShift) / colDistance) * scale;
            } else if (rowShift < 0) {
              left -= ((baseCircleSize * -rowShift) / colDistance) * scale;
            }
          }

          if (col % 2 === 1) {
            top += colShift * scale;
            if (colShift > 0) {
              top += ((baseCircleSize * colShift) / rowDistance) * scale;
            } else if (colShift < 0) {
              top -= ((baseCircleSize * -colShift) / rowDistance) * scale;
            }
          }
          circles.push({
            style: {
              left: `${left}px`,
              top: `${top}px`,
              transform: `scale(${scale})`,
            },
          });
        }
      }
      return circles;
    },
    createCirclesCoordinates() {
      let coordinates = [];
      const cols = parseInt(this.grid_form.number_of_columns);
      const rows = parseInt(this.grid_form.number_of_rows);
      const colDistance = this.grid_form.column_distance;
      const rowDistance = this.grid_form.row_distance;
      const colShift = this.grid_form.column_shift;
      const rowShift = this.grid_form.row_shift;

      for (let row = 0; row < rows; row++) {
        for (let col = 0; col < cols; col++) {
          let x = col * colDistance;
          let y = row * rowDistance;

          if (row % 2 === 1) {
            x += rowShift;
          }

          if (col % 2 === 1) {
            y += colShift;
          }
          coordinates.push({
            x: x,
            y: y,
          });
        }
      }
      return coordinates;
    },
    checkForGridElement() {
      const gridElement = document.getElementById("grid");
      if (gridElement) {
        const rect = gridElement.getBoundingClientRect();
        this.gridElementStatus = { width: rect.width, height: rect.height };
      } else {
        setTimeout(() => {
          this.checkForGridElement();
        }, 500);
      }
    },
    onWindowResize() {
      this.checkForGridElement();
    },
    WithinRangeColumnToColumnDistAlertCheck() {
      const distanceUnit = this.$store.state.units_system[this.user_information.unit_system].distance;
      let isOutOfRange = false;
      let message = "";
      if (this.gunLayout != "v") {
        if (this.columnDistance < this.minColumnToColumnDistanceAllowed) {
          isOutOfRange = true;
          message = `${this.$t("Column distance must be greater than")} ${this.minColumnToColumnDistanceAllowed}`;
          this.columnDistance = this.minColumnToColumnDistanceAllowed;
        } else if (this.columnDistance > this.maxColumnToColumnDistanceAllowed) {
          isOutOfRange = true;
          message = `${this.$t("Column distance must be smaller than")} ${this.maxColumnToColumnDistanceAllowed}`;
          this.columnDistance = this.maxColumnToColumnDistanceAllowed;
        }
      }

      if (isOutOfRange) {
        this.$swal({
          title: this.$t("Input value out of range"),
          text: `${message} [${distanceUnit}]`,
          icon: "warning",
          confirmButtonText: "OK",
        });
      }
    },
    WithinRangeRowToRowDistAlertCheck() {
      const distanceUnit = this.$store.state.units_system[this.user_information.unit_system].distance;
      let isOutOfRange = false;
      let message = "";
      if (this.gunLayout != "h") {
        if (this.rowDistance < this.minColumnToColumnDistanceAllowed) {
          isOutOfRange = true;
          message = `${this.$t("Row distance must be greater than")} ${this.minColumnToColumnDistanceAllowed}`;
          this.rowDistance = this.minColumnToColumnDistanceAllowed;
        } else if (this.rowDistance > this.maxColumnToColumnDistanceAllowed) {
          isOutOfRange = true;
          message = `${this.$t("Row distance must be smaller than")} ${this.maxColumnToColumnDistanceAllowed}`;
          this.rowDistance = this.maxColumnToColumnDistanceAllowed;
        }
      }

      if (isOutOfRange) {
        this.$swal({
          title: this.$t("Input value out of range"),
          text: `${message} [${distanceUnit}]`,
          icon: "warning",
          confirmButtonText: "OK",
        });
      }
    },
    WithinRangeColumnShiftAlertCheck() {
      const distanceUnit = this.$store.state.units_system[this.user_information.unit_system].distance;
      let isOutOfRange = false;
      let message = "";
      if (this.columnShift < -this.maxColumnShiftAllowed) {
        isOutOfRange = true;
        message = `${this.$t("Column Shift must be greater than")} ${-this.maxColumnShiftAllowed}`;
        this.columnShift = -this.maxColumnShiftAllowed;
      } else if (this.columnShift > this.maxColumnShiftAllowed) {
        isOutOfRange = true;
        message = `${this.$t("Column Shift must be smaller than")} ${this.maxColumnShiftAllowed}`;
        this.columnShift = this.maxColumnShiftAllowed;
      }

      if (isOutOfRange) {
        this.$swal({
          title: this.$t("Input value out of range"),
          text: `${message} [${distanceUnit}]`,
          icon: "warning",
          confirmButtonText: "OK",
        });
      }
    },
    WithinRangeRowShiftAlertCheck() {
      const distanceUnit = this.$store.state.units_system[this.user_information.unit_system].distance;
      let isOutOfRange = false;
      let message = "";
      if (this.rowShift < -this.maxRowShiftAllowed) {
        isOutOfRange = true;
        message = `${this.$t("Row Shift must be greater than")} ${-this.maxRowShiftAllowed}`;
        this.rowShift = -this.maxRowShiftAllowed;
      } else if (this.rowShift > this.maxRowShiftAllowed) {
        isOutOfRange = true;
        message = `${this.$t("Row Shift must be smaller than")} ${this.maxRowShiftAllowed}`;
        this.rowShift = this.maxRowShiftAllowed;
      }

      if (isOutOfRange) {
        this.$swal({
          title: this.$t("Input value out of range"),
          text: `${message} [${distanceUnit}]`,
          icon: "warning",
          confirmButtonText: "OK",
        });
      }
    },
    WithinRangeMaxMovementRangeAlertCheck() {
      const distanceUnit = this.$store.state.units_system[this.user_information.unit_system].distance;
      let isOutOfRange = false;
      let message = "";
      if (this.gunMaxMovementRange < this.minGunMaxMovementRangeAllowed) {
        isOutOfRange = true;
        message = `${this.$t("Gun Max Movement Range must be greater than")} ${
          this.minGunMaxMovementRangeAllowed
        }`;
        this.gunMaxMovementRange = this.minGunMaxMovementRangeAllowed;
      } else if (this.gunMaxMovementRange > this.maxGunMaxMovementRangeAllowed) {
        isOutOfRange = true;
        message = `${this.$t("Gun Max Movement Range must be smaller than")} ${
          this.maxGunMaxMovementRangeAllowed
        }`;
        this.gunMaxMovementRange = this.maxGunMaxMovementRangeAllowed;
      }

      if (isOutOfRange) {
        this.$swal({
          title: this.$t("Input value out of range"),
          text: `${message} [${distanceUnit}]`,
          icon: "warning",
          confirmButtonText: "OK",
        });
      }
    },
    WithinRangeGunMinVelAlertCheck() {
      const velocityUnit = this.$store.state.units_system[this.user_information.unit_system].velocity_seconds;
      let isOutOfRange = false;
      let message = "";
      if (this.gunMinVelocity < this.minGunMinVelocityAllowed) {
        isOutOfRange = true;
        message = `${this.$t("Gun Min Velocity must be greater than")} ${
          this.minGunMinVelocityAllowed
        }`;
        this.gunMinVelocity = this.minGunMinVelocityAllowed;
      } else if (this.gunMinVelocity > this.maxGunMinVelocityAllowed) {
        isOutOfRange = true;
        message = `${this.$t("Gun Min Velocity must be smaller than")} ${
          this.maxGunMinVelocityAllowed
        }`;
        this.gunMinVelocity = this.maxGunMinVelocityAllowed;
      }

      if (isOutOfRange) {
        this.$swal({
          title: this.$t("Input value out of range"),
          text: `${message} [${velocityUnit}]`,
          icon: "warning",
          confirmButtonText: "OK",
        });
      }
    },
    WithinRangeGunMaxVelAlertCheck() {
      const velocityUnit = this.$store.state.units_system[this.user_information.unit_system].velocity_seconds;
      let isOutOfRange = false;
      let message = "";
      if (this.gunMaxVelocity < this.minGunMaxVelocityAllowed) {
        isOutOfRange = true;
        message = `${this.$t("Gun Max Velocity must be greater than")} ${
          this.minGunMaxVelocityAllowed
        }`;
        this.gunMaxVelocity = this.minGunMaxVelocityAllowed;
      } else if (this.gunMaxVelocity > this.maxGunMaxVelocityAllowed) {
        isOutOfRange = true;
        message = `${this.$t("Gun Max Velocity must be smaller than")} ${
          this.maxGunMaxVelocityAllowed
        }`;
        this.gunMaxVelocity = this.maxGunMaxVelocityAllowed;
      }

      if (isOutOfRange) {
        this.$swal({
          title: this.$t("Input value out of range"),
          text: `${message} [${velocityUnit}]`,
          icon: "warning",
          confirmButtonText: "OK",
        });
      }
    },
    WithinRangeCoatedHeightAlertCheck() {
      const distanceUnit = this.$store.state.units_system[this.user_information.unit_system].distance;
      let isOutOfRange = false;
      let message = "";
      if (this.coatedHeight < this.minCoatedHeightAllowed) {
        isOutOfRange = true;
        message = `${this.$t("Height of the Part Coated with this Line Settings must be greater than")} ${
          this.minCoatedHeightAllowed
        }`;
        this.coatedHeight = this.minCoatedHeightAllowed;
      } else if (this.coatedHeight > this.maxCoatedHeightAllowed) {
        isOutOfRange = true;
        message = `${this.$t("Height of the Part Coated with this Line Settings must be smaller than")} ${
          this.maxCoatedHeightAllowed
        }`;
        this.coatedHeight = this.maxCoatedHeightAllowed;
      } else if (this.coatedHeight > this.lineMaxVerticalRange) {
        isOutOfRange = true;
        message = `${this.$t(
          "For the line settings defined, you cannnot obtain good results for this part height. Should be smaller than",
        )} ${this.lineMaxVerticalRange}`;
        this.coatedHeight = this.lineMaxVerticalRange;
      }

      if (isOutOfRange) {
        this.$swal({
          title: this.$t("Input value out of range"),
          text: `${message} [${distanceUnit}]`,
          icon: "warning",
          confirmButtonText: "OK",
        });
      }
    },
    WithinRangeLineSpeedAlertCheck() {
      const velocityUnit = this.$store.state.units_system[this.user_information.unit_system].velocity_minutes;
      let isOutOfRange = false;
      let message = "";
      if (this.lineSpeed < this.minLineSpeedAllowed) {
        isOutOfRange = true;
        message = `${this.$t("Line Speed must be greater than")} ${this.minLineSpeedAllowed}`;
        this.lineSpeed = this.minLineSpeedAllowed;
      } else if (this.lineSpeed > this.maxLineSpeedAllowed) {
        isOutOfRange = true;
        message = `${this.$t("Line Speed must be smaller than")} ${this.maxLineSpeedAllowed}`;
        this.lineSpeed = this.maxLineSpeedAllowed;
      }

      if (isOutOfRange) {
        this.$swal({
          title: this.$t("Input value out of range"),
          text: `${message} [${velocityUnit}]`,
          icon: "warning",
          confirmButtonText: "OK",
        });
      }
    },
    WithinRangeGunMovementRangeAlertCheck() {
      const distanceUnit = this.$store.state.units_system[this.user_information.unit_system].distance;
      let isOutOfRange = false;
      let message = "";
      if (this.gunCurrentMovementRange < 0) {
        isOutOfRange = true;
        message = `${this.$t("Gun Movement Range cannot be negative")}`;
        this.gunCurrentMovementRange = 0;
      } else if (this.gunCurrentMovementRange > this.gunMaxMovementRange) {
        isOutOfRange = true;
        message = `${this.$t("Gun Movement Range must be smaller than the mechanical range of the line: ")} ${
          this.gunMaxMovementRange
        }`;
        this.gunCurrentMovementRange = this.gunMaxMovementRange;
      }

      if (isOutOfRange) {
        this.$swal({
          title: this.$t("Input value out of range"),
          text: message + ` [${distanceUnit}]`,
          icon: "warning",
          confirmButtonText: "OK",
        });
      }
    },
    WithinRangeGunMovementPeriodAlertCheck() {
      let gun_velocity = parseFloat(
        (
          (2 * (this.current_settings_form.gun_movement_range / 100)) /
          this.current_settings_form.gun_movement_period
        ).toFixed(2),
      );
      let isOutOfRange = false;
      let message = "";
      if (gun_velocity < this.line_form.gun_min_velocity) {
        isOutOfRange = true;
        message = `${this.$t("Gun Movement Period cannot be longer than")} ${this.maximumPeriodTime} [s]`;
        this.current_settings_form.gun_movement_period = "";
      } else if (gun_velocity > this.line_form.gun_max_velocity) {
        isOutOfRange = true;
        message = `${this.$t("Gun Movement Period cannot be less than")} ${this.minimumPeriodTime} [s]`;
        this.current_settings_form.gun_movement_period = "";
      }

      if (isOutOfRange) {
        this.$swal({
          title: this.$t("Input value out of range"),
          text: message,
          icon: "warning",
          confirmButtonText: "OK",
        });
      }
    },
    GridFormToLineFormTransform() {
      let total_gun_array = [];
      let gun_to_gun_distance_array = [];
      let gun_columns_distances_array = [];
      let gun_columns_vertical_offsets_array = [];

      let grid_coordinates = this.createCirclesCoordinates();
      let x_dictionary = {};
      for (let i = 0; i < grid_coordinates.length; i++) {
        let x = grid_coordinates[i].x;
        let y = grid_coordinates[i].y;
        if (x_dictionary[x] === undefined) {
          x_dictionary[x] = [];
        }
        x_dictionary[x].push(y);
      }

      let x_keys = Object.keys(x_dictionary);
      let x_keys_sorted = x_keys.sort(function (a, b) {
        return a - b;
      });

      if (this.grid_form.number_of_rows == 1 && this.grid_form.column_shift == 0) {
        total_gun_array.push(this.grid_form.number_of_columns);
        gun_to_gun_distance_array.push(this.grid_form.column_distance / 100);
        gun_columns_distances_array.push(0);
        gun_columns_vertical_offsets_array.push(0);
      } else if (this.grid_form.number_of_columns == 1 && this.grid_form.row_shift == 0) {
        total_gun_array.push(this.grid_form.number_of_rows);
        gun_to_gun_distance_array.push(this.grid_form.row_distance / 100);
        gun_columns_distances_array.push(0);
        gun_columns_vertical_offsets_array.push(0);
      } else {
        for (let i = 0; i < x_keys_sorted.length; i++) {
          let length_y = x_dictionary[x_keys_sorted[i]].length;
          total_gun_array.push(length_y);
          let y_sorted_array = x_dictionary[x_keys_sorted[i]].sort(function (a, b) {
            return a - b;
          });
          if (y_sorted_array.length > 1) {
            let gun_to_gun_distance = Math.abs(y_sorted_array[1] - y_sorted_array[0]) / 100;
            gun_to_gun_distance_array.push(gun_to_gun_distance);
          } else {
            let gun_to_gun_distance = 0;
            gun_to_gun_distance_array.push(gun_to_gun_distance);
          }
          if (i > 0) {
            let gun_columns_distance = Math.abs(x_keys_sorted[i] - x_keys_sorted[i - 1]) / 100;
            gun_columns_distances_array.push(gun_columns_distance);
            let previous_y_sorted_array = x_dictionary[x_keys_sorted[i - 1]].sort(function (a, b) {
              return a - b;
            });
            let column_offset = (previous_y_sorted_array.at(-1) - y_sorted_array.at(-1)) / 100;
            gun_columns_vertical_offsets_array.push(column_offset);
          } else {
            gun_columns_distances_array.push(0);
            gun_columns_vertical_offsets_array.push(0);
          }
        }
      }

      return {
        total_gun_array,
        gun_to_gun_distance_array,
        gun_columns_distances_array,
        gun_columns_vertical_offsets_array,
      };
    },
    UserInformationFormProperlyFilled() {
      let missing_fields = [];
      let properly_filled = true;
      if (this.user_information.name === null || this.user_information.name === "") {
        this.name_input_error = true;
        properly_filled = false;
        missing_fields.push(this.$t("Your Name"));
      }
      if (this.user_information.company === null || this.user_information.company === "") {
        this.company_input_error = true;
        properly_filled = false;
        missing_fields.push(this.$t("Company"));
      }
      if (this.user_information.email === null || this.user_information.email === "") {
        this.email_input_error = true;
        properly_filled = false;
        missing_fields.push(this.$t("Email"));
      }
      if (
        this.user_information.powder_yearly_consumption === null ||
        this.user_information.powder_yearly_consumption === ""
      ) {
        this.powder_yearly_consumption_input_error = true;
        properly_filled = false;
        missing_fields.push(this.$t("Yearly Powder Consumption"));
      }
      if (this.user_information.recycling_system === null || this.user_information.recycling_system === "") {
        this.recycling_system_input_error = true;
        properly_filled = false;
        missing_fields.push(this.$t("Recycling System"));
      }
      return [properly_filled, missing_fields];
    },
    GridFormProperlyFilled() {
      let missing_fields = [];
      let properly_filled = true;
      if (this.grid_form.number_of_columns === null || this.grid_form.number_of_columns === "") {
        this.number_of_columns_input_error = true;
        properly_filled = false;
        missing_fields.push(this.$t("Number of Columns"));
      }
      if (this.grid_form.number_of_rows === null || this.grid_form.number_of_rows === "") {
        this.number_of_rows_input_error = true;
        properly_filled = false;
        missing_fields.push(this.$t("Number of Rows"));
      }
      if (this.grid_form.column_distance === null || this.grid_form.column_distance === "") {
        this.column_distance_input_error = true;
        properly_filled = false;
        missing_fields.push(this.$t("Column Distance"));
      }
      if (this.grid_form.row_distance === null || this.grid_form.row_distance === "") {
        this.row_distance_input_error = true;
        properly_filled = false;
        missing_fields.push(this.$t("Row Distance"));
      }
      if (this.grid_form.column_shift === null || this.grid_form.column_shift === "") {
        this.column_shift_input_error = true;
        properly_filled = false;
        missing_fields.push(this.$t("Column Shift"));
      }
      if (this.grid_form.row_shift === null || this.grid_form.row_shift === "") {
        this.row_shift_input_error = true;
        properly_filled = false;
        missing_fields.push(this.$t("Row Shift"));
      }
      return [properly_filled, missing_fields];
    },
    LineFormProperlyFilled() {
      let missing_fields = [];
      let properly_filled = true;
      if (
        this.line_form.gun_max_movement_range === null ||
        this.line_form.gun_max_movement_range === ""
      ) {
        this.gun_max_movement_range_input_error = true;
        properly_filled = false;
        missing_fields.push(this.$t("Gun max movement range"));
      }
      if (this.line_form.gun_min_velocity === null || this.line_form.gun_min_velocity === "") {
        this.gun_min_velocity_input_error = true;
        properly_filled = false;
        missing_fields.push(this.$t("Gun min velocity"));
      }
      if (this.line_form.gun_max_velocity === null || this.line_form.gun_max_velocity === "") {
        this.gun_max_velocity_input_error = true;
        properly_filled = false;
        missing_fields.push(this.$t("Gun max velocity"));
      }
      return [properly_filled, missing_fields];
    },
    CurrentSettingsFormProperlyFilled() {
      let missing_fields = [];
      let properly_filled = true;
      if (this.current_settings_form.coated_height === null || this.current_settings_form.coated_height === "") {
        this.coated_height_input_error = true;
        properly_filled = false;
        missing_fields.push(this.$t("Height of the Part Coated with this Line Settings"));
      }
      if (this.current_settings_form.line_speed === null || this.current_settings_form.line_speed === "") {
        this.line_speed_input_error = true;
        properly_filled = false;
        missing_fields.push(this.$t("Line Speed"));
      }
      if (
        this.current_settings_form.gun_movement_range === null ||
        this.current_settings_form.gun_movement_range === ""
      ) {
        this.gun_movement_range_input_error = true;
        properly_filled = false;
        missing_fields.push(this.$t("Gun Movement Range"));
      }
      if (
        this.current_settings_form.gun_movement_period === null ||
        this.current_settings_form.gun_movement_period === ""
      ) {
        this.gun_movement_period_input_error = true;
        properly_filled = false;
        missing_fields.push(this.$t("Gun Movement Period"));
      }
      return [properly_filled, missing_fields];
    },
    ProperlyFilledForms() {
      let properly_filled = true;
      let missing_fields = [];
      let user_information_properly_filled = this.UserInformationFormProperlyFilled();
      let grid_form_properly_filled = this.GridFormProperlyFilled();
      let line_form_properly_filled = this.LineFormProperlyFilled();
      let current_settings_form_properly_filled = this.CurrentSettingsFormProperlyFilled();

      if (!user_information_properly_filled[0]) {
        properly_filled = false;
        missing_fields = missing_fields.concat(user_information_properly_filled[1]);
      }
      if (!grid_form_properly_filled[0]) {
        properly_filled = false;
        missing_fields = missing_fields.concat(grid_form_properly_filled[1]);
      }
      if (!line_form_properly_filled[0]) {
        properly_filled = false;
        missing_fields = missing_fields.concat(line_form_properly_filled[1]);
      }
      if (!current_settings_form_properly_filled[0]) {
        properly_filled = false;
        missing_fields = missing_fields.concat(current_settings_form_properly_filled[1]);
      }
      if (!properly_filled) {
        this.$swal({
          title: this.$t("Missing Required Fields"),
          text: this.$t("The following fields are required:") + missing_fields.join(", "),
          icon: "warning",
          button: "OK",
        });
      }
      if (this.line_form.gun_min_velocity >= this.line_form.gun_max_velocity) {
        this.$swal({
          title: this.$t("Input value out of range"),
          text: this.$t("Gun Min Velocity cannot be greater than or equal to the Gun Max Velocity"),
          icon: "warning",
          confirmButtonText: "OK",
        });
        this.line_form.gun_min_velocity = this.line.gun_min_velocity;
        this.line_form.gun_max_velocity = this.line.gun_max_velocity;
        properly_filled = false;
      }

      return properly_filled;
    },
    async createPowderSavingsReport() {
      if (!this.ProperlyFilledForms()) {
        return;
      }

      let {
        total_gun_array,
        gun_to_gun_distance_array,
        gun_columns_distances_array,
        gun_columns_vertical_offsets_array,
      } = this.GridFormToLineFormTransform();

      let total_gun_json = JSON.stringify(total_gun_array);
      let gun_columns_distances_json = null;
      let gun_to_gun_distance_json = null;
      let gun_columns_vertical_offsets_json = null;

      if (gun_columns_distances_array) {
        gun_columns_distances_json = JSON.stringify(gun_columns_distances_array);
      }

      if (
        gun_to_gun_distance_array != null &&
        gun_to_gun_distance_array != [null]
      ) {
        gun_to_gun_distance_json = JSON.stringify(gun_to_gun_distance_array);
      }

      if (gun_columns_vertical_offsets_array) {
        gun_columns_vertical_offsets_json = JSON.stringify(gun_columns_vertical_offsets_array);
      }
      let gun_current_velocity = parseFloat(
        (
          (2 * (this.current_settings_form.gun_movement_range / 100)) /
          this.current_settings_form.gun_movement_period
        ).toFixed(2),
      );
      let body = {
        powder_yearly_consumption: this.user_information.powder_yearly_consumption,
        name: this.user_information.name,
        email: this.user_information.email,
        company: this.user_information.company,
        unit_system: this.user_information.unit_system,
        language: this.user_information.language,
        powder_recycling_efficiency_percentage: this.user_information.recycling_system
          ? this.user_information.recycling_percentage
          : 0,
        total_guns: total_gun_json,
        guns_layout: this.gunLayout,
        guns_min_velocity: this.line_form.gun_min_velocity,
        guns_max_velocity: this.line_form.gun_max_velocity,
        guns_max_movement_range: this.line_form.gun_max_movement_range / 100,
        gun_to_gun_distance: gun_to_gun_distance_json,
        gun_columns_distances: gun_columns_distances_json,
        gun_columns_vertical_offsets: gun_columns_vertical_offsets_json,
        line_speed: this.current_settings_form.line_speed / 60,
        coated_height: this.current_settings_form.coated_height / 100,
        current_num_active_guns: total_gun_json,
        current_guns_movement_range: this.current_settings_form.gun_movement_range / 100,
        current_guns_velocity: gun_current_velocity,
      };
      if (this.calculatorTypeProp == "expo") {
        body = {
          ...body,
          ...{
            generated_by_licensed_user: this.$store.state.user_data.id,
          },
        };
      }

      if (this.calculatorTypeProp == "expo") {
        try {
          this.request_sent = true;
          let response = await axios.post("/api/v1/powdersavingscalculator/powdersavingscalculator/", body);
          console.log("Response", response);
          this.$store.commit("addPowderSavingsCalculationInProgress", this.user_information.email);
          this.powder_savings_report_id = response.data.id;
        } catch (error) {
          console.error("Error in post", error);
          this.request_sent = false;
        }
      } else {
        try {
          let response = await axios.post("/api/v1/powdersavingscalculator/powdersavingscalculator/", body);
          this.request_sent = true;
          console.log("Response", response);
        } catch (error) {
          console.log("Error", error);
          if (error.request.status == 406) {
            this.request_sent = true;
            this.email_already_exists = true;
          } else {
            this.$swal({
              title: this.$t("Error"),
              text: error.response.data.error,
              icon: "error",
              confirmButtonText: "OK",
            });
          }
        }
      }
    },
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    getGridDimensions() {
      const gridElement = document.getElementById("grid");
      if (!gridElement) {
        return { width: 0, height: 0 };
      }
      const rect = gridElement.getBoundingClientRect();
      return { width: rect.width, height: rect.height };
    },
    populateMeasurementDefaults() {
      this.user_information.name = "John Doe";
      this.user_information.company = "Company";
      this.user_information.email = "pol.valls@coatingai.com";
      this.user_information.language = "EN";
      this.user_information.unit_system = "metric";
      // this.user_information.currency = 'EUR';
      this.user_information.powder_yearly_consumption = 100;
      this.user_information.recycling_percentage = 90;
      this.user_information.recycling_system = true;
      this.grid_form.number_of_columns = 1;
      this.grid_form.column_distance = 0;
      this.grid_form.column_shift = 0;
      this.grid_form.number_of_rows = 4;
      this.grid_form.row_distance = 40;
      this.grid_form.row_shift = 0;
      this.line_form.gun_max_movement_range = 50;
      this.line_form.gun_min_velocity = 0.05;
      this.line_form.gun_max_velocity = 0.5;
      this.current_settings_form.coated_height = 100;
      this.current_settings_form.line_speed = 2;
      this.current_settings_form.gun_movement_range = 27;
      this.current_settings_form.gun_movement_period = 2;
    },
  },
};
</script>
<style lang="scss" scoped>
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.column-controls {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.main-area {
  display: flex;
}

.grid-container {
  border: 2px solid black;
  width: 100%;
  height: 100%;
  padding-top: 60%;
  overflow: auto;
  position: relative;
  /* Important for absolute positioning of children */
}

#grid {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.circle {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #333;
  position: absolute;
}

.row-controls {
  display: flex;
  flex-direction: column;
  margin-left: 20px;
}

label,
input {
  margin: 5px;
}

label {
  margin-right: 10px;
}
.success-checkmark {
  width: 80px;
  height: 115px;
  margin: 0 auto;
}
.success-checkmark .check-icon {
  width: 80px;
  height: 80px;
  position: relative;
  border-radius: 50%;
  box-sizing: content-box;
  border: 4px solid #edf2f7;
}
.success-checkmark .check-icon::before {
  top: 3px;
  left: -2px;
  width: 30px;
  transform-origin: 100% 50%;
  border-radius: 100px 0 0 100px;
}
.success-checkmark .check-icon::after {
  top: 0;
  left: 30px;
  width: 60px;
  transform-origin: 0 50%;
  border-radius: 0 100px 100px 0;
  animation: rotate-circle 4.25s ease-in;
}
.success-checkmark .check-icon::before,
.success-checkmark .check-icon::after {
  content: "";
  height: 100px;
  position: absolute;
  background: #06b60f;
  transform: rotate(-45deg);
}
.success-checkmark .check-icon .icon-line {
  height: 5px;
  background-color: #edf2f7;
  display: block;
  border-radius: 2px;
  position: absolute;
  z-index: 10;
}
.success-checkmark .check-icon .icon-line.line-tip {
  top: 46px;
  left: 14px;
  width: 25px;
  transform: rotate(45deg);
  animation: icon-line-tip 0.75s;
}
.success-checkmark .check-icon .icon-line.line-long {
  top: 38px;
  right: 8px;
  width: 47px;
  transform: rotate(-45deg);
  animation: icon-line-long 0.75s;
}
.success-checkmark .check-icon .icon-circle {
  top: -4px;
  left: -4px;
  z-index: 10;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  position: absolute;
  box-sizing: content-box;
  border: 4px solid rgba(255, 255, 255, 0.5);
}
.success-checkmark .check-icon .icon-fix {
  top: 8px;
  width: 5px;
  left: 26px;
  z-index: 1;
  height: 85px;
  position: absolute;
  transform: rotate(-45deg);
  background-color: #06b60f;
}
@keyframes rotate-circle {
  0% {
    transform: rotate(-45deg);
  }
  5% {
    transform: rotate(-45deg);
  }
  12% {
    transform: rotate(-405deg);
  }
  100% {
    transform: rotate(-405deg);
  }
}
@keyframes icon-line-tip {
  0% {
    width: 0;
    left: 1px;
    top: 19px;
  }
  54% {
    width: 0;
    left: 1px;
    top: 19px;
  }
  70% {
    width: 50px;
    left: -8px;
    top: 37px;
  }
  84% {
    width: 17px;
    left: 21px;
    top: 48px;
  }
  100% {
    width: 25px;
    left: 14px;
    top: 45px;
  }
}
@keyframes icon-line-long {
  0% {
    width: 0;
    right: 46px;
    top: 54px;
  }
  65% {
    width: 0;
    right: 46px;
    top: 54px;
  }
  84% {
    width: 55px;
    right: 0px;
    top: 35px;
  }
  100% {
    width: 47px;
    right: 8px;
    top: 38px;
  }
}
.warning-sign {
  width: 100px;
  height: 100px;
  margin: 0 auto;
  position: relative;
}

.warning-sign .warning-icon {
  width: 100%;
  height: 100%;
  position: relative;
}

.warning-sign .icon-circle {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  position: absolute;
  top: 0;
  left: 0;
  box-sizing: border-box;
  border: 4px solid #ffcc00;
  animation: rotate-circle 1s linear infinite;
}

.warning-sign .icon-triangle {
  width: 0;
  height: 0;
  border-left: 40px solid transparent;
  border-right: 40px solid transparent;
  border-bottom: 70px solid #ffcc00;
  position: absolute;
  top: 5px;
  left: 10px;
}

.warning-sign .exclamation-mark {
  position: absolute;
  top: 15px;
  left: 50%;
  transform: translateX(-50%);
  width: 7px;
  height: 30px;
  background-color: #ffffff;
}

.warning-sign .exclamation-mark::after {
  content: "";
  position: absolute;
  bottom: -15px;
  left: 50%;
  transform: translateX(-50%);
  width: 10px;
  height: 10px;
  background-color: #ffffff;
  border-radius: 50%;
}
</style>

<i18n>
  {
    "EN": {
      "Language": "Language",
      "Unit System": "Unit System",
      "metric": "metric",
      "imperial": "imperial",
      "Currency": "Currency",
      "Powder Savings Calculation Created Successfully": "Powder Savings Calculation Created Successfully",
      "A report on the potential powder savings is going to be generated and sent to your email. You will receive it in a few minutes.": "A report on the potential powder savings is going to be generated and sent to your email. You will receive it in a few minutes.",
      "Contact us if you do not receive it at ": "Contact us if you do not receive it at ",
      "Powder Savings Calculator": "Powder Savings Calculator",
      "Calculate your potential powder savings in a few simple steps": "Calculate your potential powder savings in a few simple steps",
      "User Information": "User Information",
      "Line Settings": "Line Settings",
      "Machine Settings": "Machine Settings",
      "Your Name": "Your Name",
      "Email": "Email",
      "Company": "Company",
      "Yearly Powder Consumption": "Yearly Powder Consumption",
      "Do you have a recycling powder system?": "Do you have a recycling powder system?",
      "Yes": "Yes",
      "No": "No",
      "Recycling System Efficiency": "Recycling System Efficiency",
      "Optional": "Optional",
      "Next": "Next",
      "Previous": "Previous",
      "Your Line Setup": "Your Line Setup",
      "Number of Columns": "Number of Columns",
      "Column Distance": "Column Distance",
      "Column Shift": "Column Shift",
      "Number of Rows": "Number of Rows",
      "Row Distance": "Row Distance",
      "Row Shift": "Row Shift",
      "Gun Max Movement Range": "Gun Max Movement Range",
      "Gun Min Velocity": "Gun Min Velocity",
      "Gun Max Velocity": "Gun Max Velocity",
      "Current Machine Settings": "Current Machine Settings",
      "Height of the Part Coated with this Line Settings": "Height of the Part Coated with this Line Settings",
      "Line Speed": "Line Speed",
      "Gun Movement Range": "Gun Movement Range",
      "Gun Movement Period": "Gun Movement Period",
      "Time that it takes for the pistol to go from the highest point to the lowest and back to the highest point.": "Time that it takes for the pistol to go from the highest point to the lowest and back to the highest point.",
      "Fill first the line setup": "Fill first the line setup",
      "Fill first the gun movement range": "Fill first the gun movement range",
      "Create Powder Savings Report": "Create Powder Savings Report",
      "Input value out of range": "Input value out of range",
      "Number of columns cannot be greater than": "Number of columns cannot be greater than",
      "Number of rows cannot be greater than": "Number of rows cannot be greater than",
      "Column distance must be greater than": "Column distance must be greater than",
      "Column distance must be smaller than": "Column distance must be smaller than",
      "Row distance must be greater than": "Row distance must be greater than",
      "Row distance must be smaller than": "Row distance must be smaller than",
      "Column Shift must be greater than": "Column Shift must be greater than",
      "Column Shift must be smaller than": "Column Shift must be smaller than",
      "Row Shift must be greater than": "Row Shift must be greater than",
      "Row Shift must be smaller than": "Row Shift must be smaller than",
      "Gun Max Movement Range must be greater than": "Gun Max Movement Range must be greater than",
      "Gun Max Movement Range must be smaller than": "Gun Max Movement Range must be smaller than",
      "Gun Min Velocity must be greater than": "Gun Min Velocity must be greater than",
      "Gun Min Velocity must be smaller than": "Gun Min Velocity must be smaller than",
      "Gun Max Velocity must be greater than": "Gun Max Velocity must be greater than",
      "Gun Max Velocity must be smaller than": "Gun Max Velocity must be smaller than",
      "Height of the Part Coated with this Line Settings must be greater than": "Height of the Part Coated with this Line Settings must be greater than",
      "Height of the Part Coated with this Line Settings must be smaller than": "Height of the Part Coated with this Line Settings must be smaller than",
      "For the line settings defined, you cannnot obtain good results for this part height. Should be smaller than": "For the line settings defined, you cannnot obtain good results for this part height. Should be smaller than",
      "Line Speed must be greater than": "Line Speed must be greater than",
      "Line Speed must be smaller than": "Line Speed must be smaller than",
      "Gun Movement Range cannot be negative": "Gun Movement Range cannot be negative",
      "Gun Movement Range must be smaller than the mechanical range of the line:": "Gun Movement Range must be smaller than the mechanical range of the line:",
      "Gun Movement Period cannot be longer than": "Gun Movement Period cannot be longer than",
      "Gun Movement Period cannot be less than": "Gun Movement Period cannot be less than",
      "Missing Required Fields": "Missing Required Fields",
      "The following fields are required:": "The following fields are required:",
      "Gun Min Velocity cannot be greater than or equal to the Gun Max Velocity": "Gun Min Velocity cannot be greater than or equal to the Gun Max Velocity",
      "tonnes": "tonnes",
      "tons": "tons",
      "Email Already Registered": "Email Already Registered",
      "This email has already been used to generate a powder saving report. If this is a mistake or you need another Powder Saving Report, contact us at ": "This email has already been used to generate a powder saving report. If this is a mistake or you need another Powder Saving Report, contact us at ",
      "If you liked what you saw and you came for more, check ": "If you liked what you saw and you came for more, check ",
      "and get your quote.": "and get your quote.",
      "If you don\'t receive it contact us at ": "If you don\'t receive it contact us at ",
      "Lines with 1 gun per column are not allowed": "Lines with 1 gun per column are not allowed",
      "For Mixed Lines the number of gun columns must be smaller than": "For Mixed Lines the number of gun columns must be smaller than",
      "The total number of guns must be smaller than": "The total number of guns must be smaller than",
      "Distance between highest and lowest point of one moving gun": "Distance between highest and lowest point of one moving gun",
      "Time it takes for one gun to move from the lowest point to the highest point and back.": "Time it takes for one gun to move from the lowest point to the highest point and back.",
    },
    "DE": {
        "Language": "Sprache",
        "Unit System": "Einheitensystem",
        "metric": "metrisch",
        "imperial": "imperial",
        "Currency": "Währung",
        "Powder Savings Calculation Created Successfully": "Pulvereinsparungberechnung erfolgreich erstellt",
        "A report on the potential powder savings is going to be generated and sent to your email. You will receive it in a few minutes.": "Ein Bericht über das mögliche Pulverersparnis wird generiert und an Ihre E-Mail gesendet. Sie erhalten es in wenigen Minuten.",
        "Contact us if you do not receive it at ": "Kontaktieren Sie uns, wenn Sie es nicht erhalten unter ",
        "Powder Savings Calculator": "Pulvereinsparungenrechner",
        "Calculate your potential powder savings in a few simple steps": "Berechnen Sie Ihre möglichen Pulverersparnisse in wenigen einfachen Schritten",
        "User Information": "Benutzerinformation",
        "Line Settings": "Linien Einstellungen",
        "Machine Settings": "Maschineneinstellungen",
        "Your Name": "Ihr Name",
        "Email": "Email",
        "Company": "Unternehmen",
        "Yearly Powder Consumption": "Jährlicher Pulververbrauch",
        "Do you have a recycling powder system?": "Haben Sie ein Recycling-Pulversystem?",
        "Yes": "Ja",
        "No": "Nein",
        "Recycling System Efficiency": "Effizienz des Recycling-Systems",
        "Optional": "Optional",
        "Next": "Nächster",
        "Previous": "Vorherige",
        "Your Line Setup": "Ihre Linienaufstellung",
        "Number of Columns": "Anzahl der Spalten",
        "Column Distance": "Spaltenabstand",
        "Column Shift": "Spaltenverschiebung",
        "Number of Rows": "Anzahl der Zeilen",
        "Row Distance": "Zeilenabstand",
        "Row Shift": "Zeilenverschiebung",
        "Gun Max Movement Range": "Maximaler Bewegungsbereich des Hubgeräts",
        "Gun Min Velocity": "Waffen Min Geschwindigkeit",
        "Gun Max Velocity": "Waffen Max Geschwindigkeit",
        "Current Machine Settings": "Aktuelle Maschineneinstellungen",
        "Height of the Part Coated with this Line Settings": "Höhe des mit dieser Linie beschichteten Teils",
        "Line Speed": "Kettengeschwindigkeit",
        "Gun Movement Range": "Bewegungsbereich des Hubgeräts",
        "Gun Movement Period": "Bewegungsgeschwindigkeit des Hubgeräts",
        "Time that it takes for the pistol to go from the highest point to the lowest and back to the highest point.": "Zeit, die die Pistole benötigt, um vom höchsten Punkt zum niedrigsten und zurück zum höchsten Punkt zu gelangen.",
        "Fill first the line setup": "Füllen Sie zuerst das Line Setup aus",
        "Fill first the gun movement range": "Füllen Sie zuerst den Bewegungsbereich des Reziprokators aus",
        "Create Powder Savings Report": "Erstellen Sie einen Pulverersparnisbericht",
        "Input value out of range": "Eingabewert außerhalb des Bereichs",
        "Number of columns cannot be greater than": "Anzahl der Spalten darf nicht größer sein als",
        "Number of rows cannot be greater than": "Anzahl der Zeilen darf nicht größer sein als",
        "Column distance must be greater than": "Spaltenabstand muss größer sein als",
        "Column distance must be smaller than": "Spaltenabstand muss kleiner sein als",
        "Row distance must be greater than": "Zeilenabstand muss größer sein als",
        "Row distance must be smaller than": "Zeilenabstand muss kleiner sein als",
        "Column Shift must be greater than": "Spaltenverschiebung muss größer sein als",
        "Column Shift must be smaller than": "Spaltenverschiebung muss kleiner sein als",
        "Row Shift must be greater than": "Zeilenverschiebung muss größer sein als",
        "Row Shift must be smaller than": "Zeilenverschiebung muss kleiner sein als",
        "Gun Max Movement Range must be greater than": "Maximaler Bewegungsbereich des Hubgeräts muss größer sein als",
        "Gun Max Movement Range must be smaller than": "Maximaler Bewegungsbereich des Hubgeräts muss kleiner sein als",
        "Gun Min Velocity must be greater than": "Waffen Min Geschwindigkeit muss größer sein als",
        "Gun Min Velocity must be smaller than": "Waffen Min Geschwindigkeit muss kleiner sein als",
        "Gun Max Velocity must be greater than": "Waffen Max Geschwindigkeit muss größer sein als",
        "Gun Max Velocity must be smaller than": "Waffen Max Geschwindigkeit muss kleiner sein als",
        "Height of the Part Coated with this Line Settings must be greater than": "Höhe des mit dieser Linie beschichteten Teils muss größer sein als",
        "Height of the Part Coated with this Line Settings must be smaller than": "Höhe des mit dieser Linie beschichteten Teils muss kleiner sein als",
        "For the line settings defined, you cannnot obtain good results for this part height. Should be smaller than": "Für die definierten Linienparameter können Sie für diese Teilehöhe keine guten Ergebnisse erzielen. Sollte kleiner sein als",
        "Line Speed must be greater than": "Kettengeschwindigkeit muss größer sein als",
        "Line Speed must be smaller than": "Kettengeschwindigkeit muss kleiner sein als",
        "Gun Movement Range cannot be negative": "Bewegungsbereich des Hubgeräts kann nicht negativ sein",
        "Gun Movement Range must be smaller than the mechanical range of the line:": "Bewegungsbereich des Hubgeräts muss kleiner sein als der mechanische Bereich der Linie:",
        "Gun Movement Period cannot be longer than": "Bewegungsgeschwindigkeit des Hubgeräts darf nicht länger sein als",
        "Gun Movement Period cannot be less than": "Bewegungsgeschwindigkeit des Hubgeräts darf nicht weniger als",
        "Missing Required Fields": "Fehlende Pflichtfelder",
        "The following fields are required:": "Die folgenden Felder sind erforderlich:",
        "Gun Min Velocity cannot be greater than or equal to the Gun Max Velocity": "Waffen Min Geschwindigkeit darf nicht größer oder gleich der Waffen Max Geschwindigkeit sein",
        "tonnes": "Tonnen",
        "tons": "Tonnen",
        "Email Already Registered": "E-Mail bereits registriert",
        "This email has already been used to generate a powder saving report. If this is a mistake or you need another Powder Saving Report, contact us at ": "Diese E-Mail wurde bereits verwendet, um einen Pulverersparnisbericht zu erstellen. Wenn dies ein Fehler ist oder Sie einen anderen Pulverersparnisbericht benötigen, kontaktieren Sie uns unter ",
        "If you liked what you saw and you came for more, check ": "Wenn Ihnen gefallen hat, was Sie gesehen haben und Sie mehr wollten, überprüfen Sie ",
        "and get your quote.": "und erhalten Sie Ihr Angebot.",
        "If you don\'t receive it contact us at ": "Wenn Sie es nicht erhalten, kontaktieren Sie uns unter ",
        "Lines with 1 gun per column are not allowed": "Linien mit 1 Pistole pro Spalte sind nicht zulässig",
        "For Mixed Lines the number of gun columns must be smaller than": "Bei Mixed Lines muss die Anzahl der Pistolenspalten kleiner sein als",
        "The total number of guns must be smaller than": "Die Gesamtzahl der Waffen muss kleiner sein als",
        "Distance between highest and lowest point of one moving gun": "Abstand zwischen höchstem und niedrigstem Punkt einer beweglichen Waffe",
        "Time it takes for one gun to move from the lowest point to the highest point and back.": "Zeit, die eine Waffe benötigt, um vom niedrigsten Punkt zum höchsten Punkt und zurück zu gelangen.",
    },
    "ES": {
        "Language": "Idioma",
        "Unit System": "Sistema de Unidades",
        "metric": "métrico",
        "imperial": "imperial",
        "Currency": "Moneda",
        "Powder Savings Calculation Created Successfully": "Cálculo de ahorro de pintura en polvo creado con éxito",
        "A report on the potential powder savings is going to be generated and sent to your email. You will receive it in a few minutes.": "Se generará un informe sobre el potencial de ahorro de pintura en polvo y se enviará a su correo electrónico. Lo recibirá en unos minutos.",
        "Contact us if you do not receive it at ": "Contáctenos si no lo recibe en ",
        "Powder Savings Calculator": "Calculadora de ahorro de pintura en polvo",
        "Calculate your potential powder savings in a few simple steps": "Calcule sus posibles ahorros de pintura en polvo en unos sencillos pasos",
        "User Information": "Información del usuario",
        "Line Settings": "Configuración de la línea",
        "Machine Settings": "Configuración de la máquina",
        "Your Name": "Tu nombre",
        "Email": "Email",
        "Company": "Empresa",
        "Yearly Powder Consumption": "Consumo anual de pintura en polvo",
        "Do you have a recycling powder system?": "¿Tiene un sistema de reciclaje de pintura en polvo?",
        "Yes": "Sí",
        "No": "No",
        "Recycling System Efficiency": "Eficiencia del sistema de reciclaje",
        "Optional": "Opcional",
        "Next": "Siguiente",
        "Previous": "Anterior",
        "Your Line Setup": "Su configuración de línea",
        "Number of Columns": "Número de columnas",
        "Column Distance": "Distancia entre columnas",
        "Column Shift": "Desplazamiento de columnas",
        "Number of Rows": "Número de filas",
        "Row Distance": "Distancia entre filas",
        "Row Shift": "Desplazamiento de filas",
        "Gun Max Movement Range": "Rango de movimiento máximo del pistola",
        "Gun Min Velocity": "Velocidad mínima del pistola",
        "Gun Max Velocity": "Velocidad máxima del pistola",
        "Current Machine Settings": "Configuración actual de la máquina",
        "Height of the Part Coated with this Line Settings": "Altura de la pieza recubierta con esta configuración de línea",
        "Line Speed": "Velocidad de la línea",
        "Gun Movement Range": "Rango de movimiento del pistola",
        "Gun Movement Period": "Periodo de movimiento del pistola",
        "Time that it takes for the pistol to go from the highest point to the lowest and back to the highest point.": "Tiempo que tarda la pistola en ir desde el punto más alto hasta el más bajo y volver al punto más alto.",
        "Fill first the line setup": "Complete primero la configuración de la línea",
        "Fill first the gun movement range": "Complete primero el rango de movimiento del pistola",
        "Create Powder Savings Report": "Crear informe de ahorro de pintura en polvo",
        "Input value out of range": "Valor introducido fuera de rango",
        "Number of columns cannot be greater than": "El número de columnas no puede ser mayor que",
        "Number of rows cannot be greater than": "El número de filas no puede ser mayor que",
        "Column distance must be greater than": "La distancia entre columnas debe ser mayor que",
        "Column distance must be smaller than": "La distancia entre columnas debe ser menor que",
        "Row distance must be greater than": "La distancia entre filas debe ser mayor que",
        "Row distance must be smaller than": "La distancia entre filas debe ser menor que",
        "Column Shift must be greater than": "El desplazamiento de columnas debe ser mayor que",
        "Column Shift must be smaller than": "El desplazamiento de columnas debe ser menor que",
        "Row Shift must be greater than": "El desplazamiento de filas debe ser mayor que",
        "Row Shift must be smaller than": "El desplazamiento de filas debe ser menor que",
        "Gun Max Movement Range must be greater than": "El rango de movimiento máximo del pistola debe ser mayor que",
        "Gun Max Movement Range must be smaller than": "El rango de movimiento máximo del pistola debe ser menor que",
        "Gun Min Velocity must be greater than": "La velocidad mínima del pistola debe ser mayor que",
        "Gun Min Velocity must be smaller than": "La velocidad mínima del pistola debe ser menor que",
        "Gun Max Velocity must be greater than": "La velocidad máxima del pistola debe ser mayor que",
        "Gun Max Velocity must be smaller than": "La velocidad máxima del pistola debe ser menor que",
        "Height of the Part Coated with this Line Settings must be greater than": "La altura de la parte recubierta con esta configuración de línea debe ser mayor que",
        "Height of the Part Coated with this Line Settings must be smaller than": "La altura de la parte recubierta con esta configuración de línea debe ser menor que",
        "For the line settings defined, you cannnot obtain good results for this part height. Should be smaller than": "Para la configuración de línea definida, no puede obtener buenos resultados para esta altura de parte. Debería ser menor que",
        "Line Speed must be greater than": "La velocidad de la línea debe ser mayor que",
        "Line Speed must be smaller than": "La velocidad de la línea debe ser menor que",
        "Gun Movement Range cannot be negative": "El rango de movimiento del pistola no puede ser negativo",
        "Gun Movement Range must be smaller than the mechanical range of the line:": "El rango de movimiento del pistola debe ser menor que el rango mecánico de la línea:",
        "Gun Movement Period cannot be longer than": "El período de movimiento del pistola no puede ser más largo que",
        "Gun Movement Period cannot be less than": "El período de movimiento del pistola no puede ser menor que",
        "Missing Required Fields": "Campos Requeridos",
        "The following fields are required:": "Los siguientes campos son requeridos:",
        "Gun Min Velocity cannot be greater than or equal to the Gun Max Velocity": "La velocidad mínima del pistola no puede ser mayor o igual que la velocidad máxima del pistola",
        "tonnes": "toneladas",
        "tons": "toneladas cortas",
        "Email Already Registered": "Correo electrónico ya registrado",
        "This email has already been used to generate a powder saving report. If this is a mistake or you need another Powder Saving Report, contact us at ": "Este correo electrónico ya se ha utilizado para generar un informe de ahorro de pintura en polvo. Si esto es un error o necesita otro informe de ahorro de pintura en polvo, contáctenos en ",
        "If you liked what you saw and you came for more, check ": "Si le gustó lo que vió y vino por más, revise ",
        "and get your quote.": "y obtén tu presupuesto.",
        "If you don\'t receive it contact us at ": "Si no lo recibe, contáctenos en ",
        "Lines with 1 gun per column are not allowed": "No se permiten líneas con 1 pistola por columna",
        "For Mixed Lines the number of gun columns must be smaller than": "Para líneas mixtas, el número de columnas de pistola debe ser menor que",
        "The total number of guns must be smaller than": "El número total de pistolas debe ser menor que",
        "Distance between highest and lowest point of one moving gun": "Distancia entre el punto más alto y el punto más bajo de una pistola en movimiento",
        "Time it takes for one gun to move from the lowest point to the highest point and back.": "Tiempo que tarda una pistola en moverse desde el punto más bajo hasta el punto más alto y regresar",
      },
    "FR": {
        "Language": "Langue",
        "Unit System": "Système d'unités",
        "metric": "métrique",
        "imperial": "impérial",
        "Currency": "Devise",
        "Powder Savings Calculation Created Successfully": "Calcul des économies de poudre créé avec succès",
        "A report on the potential powder savings is going to be generated and sent to your email. You will receive it in a few minutes.": "Un rapport sur les économies potentielles de poudre va être généré et envoyé à votre adresse e-mail. Vous le recevrez dans quelques minutes.",
        "Contact us if you do not receive it at ": "Contactez-nous si vous ne le recevez pas à ",
        "Powder Savings Calculator": "Calculateur d'économies de poudre",
        "Calculate your potential powder savings in a few simple steps": "Calculez vos économies potentielles de poudre en quelques étapes simples",
        "User Information": "Informations sur l'utilisateur",
        "Line Settings": "Paramètres de ligne",
        "Machine Settings": "Paramètres de la machine",
        "Your Name": "Votre nom",
        "Email": "Email",
        "Company": "Entreprise",
        "Yearly Powder Consumption": "Consommation annuelle de poudre",
        "Do you have a recycling powder system?": "Avez-vous un système de recyclage de poudre?",
        "Yes": "Oui",
        "No": "Non",
        "Recycling System Efficiency": "Efficacité du système de recyclage",
        "Optional": "Optionnel",
        "Next": "Suivant",
        "Previous": "Précédent",
        "Your Line Setup": "Votre configuration de ligne",
        "Number of Columns": "Nombre de colonnes",
        "Column Distance": "Distance entre colonnes",
        "Column Shift": "Décalage de colonne",
        "Number of Rows": "Nombre de rangées",
        "Row Distance": "Distance entre rangées",
        "Row Shift": "Décalage de rangée",
        "Gun Max Movement Range": "Plage de mouvement maximale du pistolet",
        "Gun Min Velocity": "Vitesse minim du pistolet",
        "Gun Max Velocity": "Vitesse maximale du pistolet",
        "Current Machine Settings": "Paramètres actuels de la machine",
        "Height of the Part Coated with this Line Settings": "Hauteur de la pièce revêtue avec ces paramètres de ligne",
        "Line Speed": "Vitesse de la ligne",
        "Gun Movement Range": "Plage de mouvement du pistolet",
        "Gun Movement Period": "Période de mouvement du pistolet",
        "Time that it takes for the pistol to go from the highest point to the lowest and back to the highest point.": "Temps qu'il faut pour que le pistolet aille du point le plus haut au point le plus bas et revienne au point le plus haut.",
        "Fill first the line setup": "Remplissez d'abord la configuration de la ligne",
        "Fill first the gun movement range": "Remplissez d'abord la plage de mouvement du pistolet",
        "Create Powder Savings Report": "Créer un rapport d'économies de poudre",
        "Input value out of range": "Valeur d'entrée hors de portée",
        "Number of columns cannot be greater than": "Le nombre de colonnes ne peut pas être supérieur à",
        "Number of rows cannot be greater than": "Le nombre de rangées ne peut pas être supérieur à",
        "Column distance must be greater than": "La distance entre les colonnes doit être supérieure à",
        "Column distance must be smaller than": "La distance entre les colonnes doit être inférieure à",
        "Row distance must be greater than": "La distance entre les rangées doit être supérieure à",
        "Row distance must be smaller than": "La distance entre les rangées doit être inférieure à",
        "Column Shift must be greater than": "Le décalage de colonne doit être supérieur à",
        "Column Shift must be smaller than": "Le décalage de colonne doit être inférieur à",
        "Row Shift must be greater than": "Le décalage de rangée doit être supérieur à",
        "Row Shift must be smaller than": "Le décalage de rangée doit être inférieur à",
        "Gun Max Movement Range must be greater than": "La plage de mouvement maximale du pistolet doit être supérieure à",
        "Gun Max Movement Range must be smaller than": "La plage de mouvement maximale du pistolet doit être inférieure à",
        "Gun Min Velocity must be greater than": "La vitesse minimale du pistolet doit être supérieure à",
        "Gun Min Velocity must be smaller than": "La vitesse minimale du pistolet doit être inférieure à",
        "Gun Max Velocity must be greater than": "La vitesse maximale du pistolet doit être supérieure à",
        "Gun Max Velocity must be smaller than": "La vitesse maximale du pistolet doit être inférieure à",
        "Height of the Part Coated with this Line Settings must be greater than": "La hauteur de la pièce revêtue avec ces paramètres de ligne doit être supérieure à",
        "Height of the Part Coated with this Line Settings must be smaller than": "La hauteur de la pièce revêtue avec ces paramètres de ligne doit être inférieure à",
        "For the line settings defined, you cannnot obtain good results for this part height. Should be smaller than": "Pour les paramètres de ligne définis, vous ne pouvez pas obtenir de bons résultats pour cette hauteur de pièce. Doit être inférieur à",
        "Line Speed must be greater than": "La vitesse de la ligne doit être supérieure à",
        "Line Speed must be smaller than": "La vitesse de la ligne doit être inférieure à",
        "Gun Movement Range cannot be negative": "La plage de mouvement du pistolet ne peut pas être négative",
        "Gun Movement Range must be smaller than the mechanical range of the line:": "La plage de mouvement du pistolet doit être inférieure à la plage mécanique de la ligne:",
        "Gun Movement Period cannot be longer than": "La période de mouvement du pistolet ne peut pas être plus longue que",
        "Gun Movement Period cannot be less than": "La période de mouvement du pistolet ne peut pas être inférieure à",
        "Missing Required Fields": "Champs Requis Manquants",
        "The following fields are required:": "Les champs suivants sont requis:",
        "Gun Min Velocity cannot be greater than or equal to the Gun Max Velocity": "La vitesse minimale du pistolet ne peut pas être supérieure ou égale à la vitesse maximale du pistolet",
        "tonnes": "tonnes",
        "tons": "tonnes courtes",
        "Email Already Registered": "Email déjà enregistré",
        "This email has already been used to generate a powder saving report. If this is a mistake or you need another Powder Saving Report, contact us at ": "Cet e-mail a déjà été utilisé pour générer un rapport d'économies de poudre. Si c'est une erreur ou si vous avez besoin d'un autre rapport d'économies de poudre, contactez-nous à ",
        "If you liked what you saw and you came for more, check ": "Si vous avez aimé ce que vous avez vu et que vous êtes venu pour plus, vérifiez ",
        "and get your quote.": "et obtenez votre devis.",
        "If you don\'t receive it contact us at ": "Si vous ne le recevez pas, contactez-nous à ",
        "Lines with 1 gun per column are not allowed": "Les lignes avec 1 pistolet par colonne ne sont pas autorisées",
        "For Mixed Lines the number of gun columns must be smaller than": "Pour les lignes mixtes, le nombre de colonnes de pistolet doit être inférieur à",
        "The total number of guns must be smaller than": "Le nombre total de pistolets doit être inférieur à",
        "Distance between highest and lowest point of one moving gun": "Distance entre le point le plus haut et le point le plus bas d'un pistolet en mouvement",
        "Time it takes for one gun to move from the lowest point to the highest point and back.": "Temps qu'il faut pour qu'un pistolet se déplace du point le plus bas au point le plus haut et revienne.",
      },
    }
</i18n>
